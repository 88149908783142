import React from 'react';
import DateRangePickerWrapper, { DateRangePickerWrapperProps } from './DateRangePickerWrapper';

const DateRangePickerExporter: React.FunctionComponent<DateRangePickerWrapperProps> = function (
    props: DateRangePickerWrapperProps,
) {
    return <DateRangePickerWrapper {...props} />;
};

export default DateRangePickerExporter;
