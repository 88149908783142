import { PackageApi, PackageJsonldPackageRead } from '@europrocurement/l2d-domain/openApi/ApiOffre';
import { offerPackageIcon } from '@europrocurement/l2d-icons';
import { DataSourcesThunksType } from '@europrocurement/l2d-redux-utils';

import { type ModelConfiguration, type ModelMethods, type ModelItem } from '../../..';
import { PACKAGES_SLICE_NAME } from '../constants';

const modelKey = 'offer_package';
const modelName = 'Package';
const sliceName = PACKAGES_SLICE_NAME;

type ModelStructure = PackageJsonldPackageRead;

const methods: ModelMethods<ModelStructure> = {
    getItemDefaultLabel: (model: ModelStructure) => `${model.nomCommercial}`,
};

export type OfferPackageEndpoints = {
    list: InstanceType<typeof PackageApi>['apiPackagesGetCollection'];
    delete: InstanceType<typeof PackageApi>['apiPackagesIdDelete'];
    dependencies: InstanceType<typeof PackageApi>['apiPackagesIdDependenciesGet'];
    read: InstanceType<typeof PackageApi>['apiPackagesIdGet'];
    update: InstanceType<typeof PackageApi>['apiPackagesIdPut'];
    create: InstanceType<typeof PackageApi>['apiPackagesPost'];
};

export type OfferPackageModel = ModelItem<ModelStructure> & OfferPackageEndpoints;

export const buildOfferPackageModel = (
    api: PackageApi,
    datasourcesThunks: DataSourcesThunksType<PackageJsonldPackageRead>,
) => {
    const configuration: ModelConfiguration = {
        key: modelKey,
        name: modelName,
        type: 'Package',
        sliceName,
        api,
        datasourcesThunks,
        icon: offerPackageIcon,
    };

    const endpoints = {
        list: api.apiPackagesGetCollection.bind(api),
        delete: api.apiPackagesIdDelete.bind(api),
        dependencies: api.apiPackagesIdDependenciesGet.bind(api),
        read: api.apiPackagesIdGet.bind(api),
        update: api.apiPackagesIdPut.bind(api),
        create: api.apiPackagesPost.bind(api),
    };

    const model: OfferPackageModel = {
        ...configuration,
        ...methods,
        ...endpoints,
    };

    return model;
};
