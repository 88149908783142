import React, { useState } from 'react';
import { CatalogApiObject } from '@europrocurement/l2d-domain';
import { Box, CircularProgress, Typography } from '@mui/material';
import { ContinueButton, SearchBar } from '@europrocurement/flexy-components';
import useSelectFormality from '../hooks/useSelectFormality';
import SelectFormalityChoices from './SelectFormalityChoices';
import SelectFormalitySearchResults from './SelectFormalitySearchResults';
import SearchChips from './SearchChips';

export type SelectFormalityProps = {
    defaultGroups?: Array<number>;
    defaultCatalogs?: Array<number>;
    onChange?: (values: { ids: Array<number>; catalogs: CatalogApiObject[] }) => void;
    prescripteurId: number;
    origine: number;
    domaine: number;
    nomCommercial?: string;
};

const SelectFormality: React.FunctionComponent<SelectFormalityProps> = function (props) {
    const {
        onChange,
        prescripteurId,
        origine,
        domaine,
        nomCommercial,
        defaultGroups = [],
        defaultCatalogs = [],
    } = props;
    const [searchTerm, setSearchTerm] = useState<string>(nomCommercial || '');
    const [isSearching, setIsSearching] = useState<boolean>(false);
    const {
        catalogs,
        groups,
        requestState,
        getFilteredCatalogs,
        checkGroup,
        uncheckGroup,
        checkCatalog,
        uncheckCatalog,
    } = useSelectFormality(prescripteurId, origine, domaine, defaultGroups, defaultCatalogs);
    const checkedCatalogs = catalogs.filter((catalog) => catalog.checked);

    if (requestState.isProcessing) {
        return (
            <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: '25px' }}>
                <CircularProgress />
            </Box>
        );
    }

    if (!requestState.success) {
        return (
            <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: '25px' }}>
                <Typography variant="l2dbodys">Une erreur est survenue</Typography>
            </Box>
        );
    }

    return (
        <>
            <SearchChips
                catalogs={catalogs.filter((catalog) => catalog.checked)}
                uncheckCatalog={uncheckCatalog}
            />
            <SearchBar
                className="search"
                placeholder="Rechercher une formalité"
                value={searchTerm}
                onDelete={() => {
                    setSearchTerm('');
                    setIsSearching(false);
                }}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    const searchValue = event.target.value || '';
                    setSearchTerm(searchValue);
                    setIsSearching(searchValue !== '');
                }}
            />
            {isSearching ? (
                <SelectFormalitySearchResults
                    catalogs={getFilteredCatalogs(searchTerm)}
                    onCheckCatalog={checkCatalog}
                    onUncheckCatalog={uncheckCatalog}
                />
            ) : (
                <SelectFormalityChoices
                    catalogs={getFilteredCatalogs(searchTerm)}
                    groups={groups}
                    onCheckGroup={checkGroup}
                    onUncheckGroup={uncheckGroup}
                    onCheckCatalog={checkCatalog}
                    onUncheckCatalog={uncheckCatalog}
                />
            )}
            {checkedCatalogs.length > 0 && (
                <ContinueButton
                    onClick={() => {
                        if (onChange) {
                            onChange({
                                ids: checkedCatalogs.map((catalog) => catalog.id),
                                catalogs: checkedCatalogs.map((catalog) => catalog.apiObject),
                            });
                        }
                    }}
                />
            )}
        </>
    );
};

export default SelectFormality;
