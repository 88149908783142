import { ModelConfiguration, ModelItem, ModelMethods } from '@europrocurement/l2d-domain/types';
import { DevisLignesApi } from '@europrocurement/l2d-domain/openApi/ApiFacturation';
import { DataSourcesThunksType } from '@europrocurement/l2d-redux-utils';
import { quoteLineIcon } from '@europrocurement/l2d-icons';
import { DEVIS_LIGNE_SLICE_NAME, DevisLigne, DevisLigneApiObject } from '../slices/devisLigneSlice';

const modelKey = 'quote_line';
const modelName = 'QuoteLine';
const sliceName = DEVIS_LIGNE_SLICE_NAME;
type ModelStructure = DevisLigne;

const methods: ModelMethods<ModelStructure> = {
    getItemDefaultLabel: (model: ModelStructure) =>
        `Devis: ${model.devis} - Numéro: ${model.numero}`,
    getItemSecondaryLabel: (model: ModelStructure) =>
        `Libelle: ${model.libelle} - Rubrique: ${model.rubfac}`,
};

export type QuoteLineEndpoints = {
    list: InstanceType<typeof DevisLignesApi>['apiDevisLignesGetDevisCollection'];
    read: InstanceType<typeof DevisLignesApi>['apiDevisLignesGetItem'];
};

export type QuoteLineModel = ModelItem<ModelStructure> & QuoteLineEndpoints;

export const buildQuoteLineModel = (
    api: DevisLignesApi,
    datasourcesThunks: DataSourcesThunksType<DevisLigneApiObject>,
) => {
    const configuration: ModelConfiguration = {
        key: modelKey,
        name: modelName,
        type: 'QuoteLine',
        // selector: baseSelector,
        sliceName,
        api,
        datasourcesThunks,
        icon: quoteLineIcon,
        // color: 'blue',
    };

    const endpoints = {
        list: api.apiDevisLignesGetDevisCollection.bind(api),
        read: api.apiDevisLignesGetItem.bind(api),
    };

    const model: QuoteLineModel = {
        ...configuration,
        ...methods,
        ...endpoints,
    };
    return model;
};
