import {
    SliceFactory,
    FetchCollectionData,
    FetchItemDataType,
    ApiCollectionResponse,
} from '@europrocurement/l2d-redux-utils';
import {
    ConfigurationParameters as StatutParameters,
    Configuration,
    StatutStatutRead,
    StatutApi,
    StatutApiApiStatutsGetCollectionRequest,
    StatutApiApiStatutsIdGetRequest,
} from '../../../openApi/ApiFormalite';
import { FORMALITES_REDUCER_NAME, STATUS_SLICE_NAME } from '../constants';

export type StatusApiObject = StatutStatutRead;

export type Status = StatusApiObject;

export const mapperStatus = function (status: Status) {
    return {
        ...status,
    } as Status;
};

export function createStatusSlice(configuration: StatutParameters) {
    const conf = new Configuration(configuration);
    const api = new StatutApi(conf);

    const fetchStatusesCollection: FetchCollectionData<Status> = ({ pagination }) => {
        const options = undefined;
        const requestParameters: StatutApiApiStatutsGetCollectionRequest = {
            page: pagination.page + 1,
            itemsPerPage: 100,
        };

        return api
            .apiStatutsGetCollection(requestParameters, options)
            .then((res) => res.data) as Promise<ApiCollectionResponse<Status>>;
    };

    const fetchStatusItem: FetchItemDataType<Status> = ({ idItem }) => {
        const requestParameters: StatutApiApiStatutsIdGetRequest = {
            id: String(idItem),
        };

        return api.apiStatutsIdGet(requestParameters).then((res) => res.data) as Promise<Status>;
    };

    const { slice: statusSlice, dataSourcesThunks: statusDataSourcesThunks } =
        SliceFactory.createSlice<Status, Status>(
            STATUS_SLICE_NAME,
            FORMALITES_REDUCER_NAME,
            [],
            fetchStatusesCollection,
            fetchStatusItem,
            mapperStatus,
            {},
            {},
        );

    return { statusSlice, statusDataSourcesThunks };
}
