import { useTheme } from '@mui/material';
import React, { ReactNode } from 'react';
import { Link } from 'react-router-dom';

export type L2DErrorImageProps = {
    /**
     * Redirect URL ( default to '/')
     */
    redirect?: string;
};

const L2DErrorImage: React.FunctionComponent<L2DErrorImageProps> = function ({
    redirect,
}: L2DErrorImageProps) {
    const theme = useTheme();

    const secondary = { fill: theme.palette.secondary.main };
    const primary = { fill: theme.palette.primary.main };

    const logoIcon: ReactNode = (
        <svg
            width="660"
            height="477"
            viewBox="0 0 660 477"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g filter="url(#filter0_dd_8218_660)">
                <path
                    d="M384.633 316.81C382.811 324.473 377.032 330.966 368.925 333.321L232.117 373.07C220.115 376.557 207.561 369.653 204.074 357.653L156.017 192.242C152.531 180.242 159.432 167.686 171.432 164.199L308.24 124.452C320.242 120.966 332.796 127.867 336.283 139.869L384.34 305.278C385.471 309.174 385.51 313.127 384.633 316.81Z"
                    fill="white"
                />
                <path
                    d="M384.633 316.81C382.811 324.473 377.032 330.966 368.925 333.321L232.117 373.07C220.115 376.557 207.561 369.653 204.074 357.653L156.017 192.242C152.531 180.242 159.432 167.686 171.432 164.199L308.24 124.452C320.242 120.966 332.796 127.867 336.283 139.869L384.34 305.278C385.471 309.174 385.51 313.127 384.633 316.81Z"
                    style={secondary}
                    fillOpacity="0.35"
                />
                <path
                    d="M413.682 307.871H271.218C258.72 307.871 248.59 297.74 248.59 285.243V112.993C248.59 100.494 258.72 90.3647 271.218 90.3647H413.682C426.179 90.3647 436.311 100.494 436.311 112.993V285.243C436.311 297.74 426.179 307.871 413.682 307.871Z"
                    fill="white"
                />
                <path
                    d="M442.99 341.098L396.462 285.647C394.82 283.692 395.077 280.775 397.032 279.134L413.292 265.49C415.248 263.851 418.164 264.105 419.804 266.06L466.332 321.511C470.174 326.089 469.576 332.915 464.997 336.755L458.234 342.43C453.658 346.272 446.833 345.674 442.99 341.098Z"
                    fill="white"
                />
                <path
                    d="M442.99 341.098L396.462 285.647C394.82 283.692 395.077 280.775 397.032 279.134L413.292 265.49C415.248 263.851 418.164 264.105 419.804 266.06L466.332 321.511C470.174 326.089 469.576 332.915 464.997 336.755L458.234 342.43C453.658 346.272 446.833 345.674 442.99 341.098Z"
                    style={secondary}
                    fillOpacity="0.35"
                />
            </g>
            <g clipPath="url(#clip0_8218_660)">
                <path
                    opacity="0.25"
                    d="M505.248 281.742C464.321 327.34 415.274 354.477 358.22 363.041C301.166 371.604 257.57 348.916 227.652 294.643C197.735 240.369 193.843 179.534 216.086 112.026C238.329 44.5173 292.714 15.7123 356.441 39.2901C420.168 62.868 461.874 92.2291 503.691 136.271C545.509 180.312 546.065 236.365 505.248 281.742Z"
                    style={primary}
                />
            </g>
            <path
                d="M384.633 316.809C382.811 324.473 377.032 330.966 368.925 333.321L232.117 373.07C220.115 376.557 207.561 369.653 204.074 357.653L156.017 192.242C152.531 180.242 159.432 167.686 171.432 164.199L256.994 139.34L308.24 124.452C320.242 120.966 332.796 127.867 336.283 139.869L384.34 305.278C385.471 309.174 385.51 313.126 384.633 316.809Z"
                fill="white"
            />
            <path
                d="M384.633 316.809C382.811 324.473 377.032 330.966 368.925 333.321L232.117 373.07C220.115 376.557 207.561 369.653 204.074 357.653L156.017 192.242C152.531 180.242 159.432 167.686 171.432 164.199L256.994 139.34L308.24 124.452C320.242 120.966 332.796 127.867 336.283 139.869L384.34 305.278C385.471 309.174 385.51 313.126 384.633 316.809Z"
                style={secondary}
                fillOpacity="0.1"
            />
            <path
                d="M384.633 316.809C382.811 324.473 377.032 330.966 368.925 333.321L232.117 373.07C220.115 376.557 207.561 369.653 204.074 357.653L156.017 192.242C152.531 180.242 159.432 167.686 171.432 164.199L256.994 139.34L308.24 124.452C320.242 120.966 332.796 127.867 336.283 139.869L384.34 305.278C385.471 309.174 385.51 313.126 384.633 316.809Z"
                fill="url(#paint0_linear_8218_660)"
                fillOpacity="0.1"
            />
            <path
                d="M413.682 307.871H271.218C258.72 307.871 248.589 297.739 248.589 285.243V112.993C248.589 100.494 258.72 90.3646 271.218 90.3646H413.682C426.179 90.3646 436.31 100.494 436.31 112.993V285.243C436.31 297.739 426.179 307.871 413.682 307.871Z"
                fill="white"
            />
            <path
                d="M389.844 243.836L391.25 245.512C391.066 245.702 390.879 245.891 390.687 246.08C389.431 247.337 388.108 248.549 386.718 249.712C385.335 250.869 383.915 251.966 382.456 252.998C382.125 253.224 381.795 253.453 381.465 253.677C381.354 253.751 381.243 253.827 381.13 253.899L379.777 252.285L379.004 251.363L378.232 250.444C378.563 250.22 378.893 249.992 379.223 249.766C380.682 248.733 382.102 247.637 383.485 246.48C384.875 245.316 386.198 244.104 387.455 242.848C387.743 242.564 388.023 242.278 388.297 241.989L389.075 242.915L389.844 243.836Z"
                fill="#E6E6E6"
            />
            <path
                d="M416.174 275.211L406.107 283.662L400.893 277.455L399.357 275.619L391.883 266.711C391.992 266.635 392.1 266.559 392.209 266.48C392.534 266.252 392.86 266.016 393.183 265.783C394.619 264.73 396.032 263.622 397.418 262.465C398.801 261.301 400.133 260.101 401.419 258.865C401.611 258.683 401.803 258.498 401.992 258.309L409.429 267.173L410.193 268.09L410.965 269.002L416.174 275.211Z"
                fill="white"
            />
            <path
                d="M416.174 275.211L406.107 283.662L400.893 277.455L399.357 275.619L391.883 266.711C391.992 266.635 392.1 266.559 392.209 266.48C392.534 266.252 392.86 266.016 393.183 265.783C394.619 264.73 396.032 263.622 397.418 262.465C398.801 261.301 400.133 260.101 401.419 258.865C401.611 258.683 401.803 258.498 401.992 258.309L409.429 267.173L410.193 268.09L410.965 269.002L416.174 275.211Z"
                style={secondary}
                fillOpacity="0.1"
            />
            <path
                d="M416.174 275.211L406.107 283.662L400.893 277.455L399.357 275.619L391.883 266.711C391.992 266.635 392.1 266.559 392.209 266.48C392.534 266.252 392.86 266.016 393.183 265.783C394.619 264.73 396.032 263.622 397.418 262.465C398.801 261.301 400.133 260.101 401.419 258.865C401.611 258.683 401.803 258.498 401.992 258.309L409.429 267.173L410.193 268.09L410.965 269.002L416.174 275.211Z"
                fill="url(#paint1_linear_8218_660)"
                fillOpacity="0.1"
            />
            <path
                d="M403.774 260.435C403.587 260.625 403.396 260.81 403.204 260.992C401.916 262.227 400.586 263.428 399.2 264.592C397.815 265.748 396.402 266.857 394.968 267.91C394.642 268.143 394.317 268.378 393.991 268.607C393.883 268.685 393.774 268.762 393.668 268.838L388.976 263.248L381.13 253.899C381.243 253.827 381.354 253.751 381.465 253.677C381.795 253.453 382.125 253.224 382.456 252.998C383.915 251.966 385.335 250.869 386.718 249.713C388.108 248.549 389.431 247.337 390.687 246.08C390.879 245.891 391.066 245.702 391.251 245.512L398.275 253.878L399.043 254.797L399.819 255.718L403.774 260.435Z"
                fill="white"
            />
            <path
                d="M403.774 260.435C403.587 260.625 403.396 260.81 403.204 260.992C401.916 262.227 400.586 263.428 399.2 264.592C397.815 265.748 396.402 266.857 394.968 267.91C394.642 268.143 394.317 268.378 393.991 268.607C393.883 268.685 393.774 268.762 393.668 268.838L388.976 263.248L381.13 253.899C381.243 253.827 381.354 253.751 381.465 253.677C381.795 253.453 382.125 253.224 382.456 252.998C383.915 251.966 385.335 250.869 386.718 249.713C388.108 248.549 389.431 247.337 390.687 246.08C390.879 245.891 391.066 245.702 391.251 245.512L398.275 253.878L399.043 254.797L399.819 255.718L403.774 260.435Z"
                style={secondary}
                fillOpacity="0.1"
            />
            <path
                d="M403.774 260.435C403.587 260.625 403.396 260.81 403.204 260.992C401.916 262.227 400.586 263.428 399.2 264.592C397.815 265.748 396.402 266.857 394.968 267.91C394.642 268.143 394.317 268.378 393.991 268.607C393.883 268.685 393.774 268.762 393.668 268.838L388.976 263.248L381.13 253.899C381.243 253.827 381.354 253.751 381.465 253.677C381.795 253.453 382.125 253.224 382.456 252.998C383.915 251.966 385.335 250.869 386.718 249.713C388.108 248.549 389.431 247.337 390.687 246.08C390.879 245.891 391.066 245.702 391.251 245.512L398.275 253.878L399.043 254.797L399.819 255.718L403.774 260.435Z"
                fill="url(#paint2_linear_8218_660)"
                fillOpacity="0.1"
            />
            <path
                d="M303.108 150.692C329.521 128.53 369.033 131.989 391.193 158.399C411.662 182.791 410.27 218.37 389.126 241.118C388.854 241.409 388.579 241.703 388.297 241.989C388.023 242.278 387.743 242.564 387.455 242.848C386.198 244.104 384.875 245.316 383.485 246.48C382.102 247.639 380.682 248.733 379.223 249.766C378.893 249.992 378.563 250.22 378.232 250.444C377.9 250.668 377.565 250.89 377.23 251.109C365.6 258.655 351.88 262.098 337.851 260.872C321.213 259.413 306.139 251.567 295.404 238.77C273.244 212.367 276.699 172.851 303.108 150.692ZM403.941 147.701C375.836 114.205 325.906 109.839 292.411 137.944C258.919 166.046 254.548 215.978 282.656 249.472C309.074 280.955 354.78 286.707 387.993 263.934C388.323 263.71 388.651 263.481 388.976 263.248C389.302 263.019 389.627 262.784 389.951 262.55C391.387 261.498 392.8 260.389 394.185 259.232C395.568 258.069 396.901 256.868 398.187 255.633C398.473 255.358 398.762 255.083 399.043 254.797C399.332 254.518 399.611 254.234 399.891 253.947C428.084 225.198 430.362 179.184 403.941 147.701Z"
                fill="white"
            />
            <path
                d="M303.108 150.692C329.521 128.53 369.033 131.989 391.193 158.399C411.662 182.791 410.27 218.37 389.126 241.118C388.854 241.409 388.579 241.703 388.297 241.989C388.023 242.278 387.743 242.564 387.455 242.848C386.198 244.104 384.875 245.316 383.485 246.48C382.102 247.639 380.682 248.733 379.223 249.766C378.893 249.992 378.563 250.22 378.232 250.444C377.9 250.668 377.565 250.89 377.23 251.109C365.6 258.655 351.88 262.098 337.851 260.872C321.213 259.413 306.139 251.567 295.404 238.77C273.244 212.367 276.699 172.851 303.108 150.692ZM403.941 147.701C375.836 114.205 325.906 109.839 292.411 137.944C258.919 166.046 254.548 215.978 282.656 249.472C309.074 280.955 354.78 286.707 387.993 263.934C388.323 263.71 388.651 263.481 388.976 263.248C389.302 263.019 389.627 262.784 389.951 262.55C391.387 261.498 392.8 260.389 394.185 259.232C395.568 258.069 396.901 256.868 398.187 255.633C398.473 255.358 398.762 255.083 399.043 254.797C399.332 254.518 399.611 254.234 399.891 253.947C428.084 225.198 430.362 179.184 403.941 147.701Z"
                style={secondary}
                fillOpacity="0.1"
            />
            <path
                d="M303.108 150.692C329.521 128.53 369.033 131.989 391.193 158.399C411.662 182.791 410.27 218.37 389.126 241.118C388.854 241.409 388.579 241.703 388.297 241.989C388.023 242.278 387.743 242.564 387.455 242.848C386.198 244.104 384.875 245.316 383.485 246.48C382.102 247.639 380.682 248.733 379.223 249.766C378.893 249.992 378.563 250.22 378.232 250.444C377.9 250.668 377.565 250.89 377.23 251.109C365.6 258.655 351.88 262.098 337.851 260.872C321.213 259.413 306.139 251.567 295.404 238.77C273.244 212.367 276.699 172.851 303.108 150.692ZM403.941 147.701C375.836 114.205 325.906 109.839 292.411 137.944C258.919 166.046 254.548 215.978 282.656 249.472C309.074 280.955 354.78 286.707 387.993 263.934C388.323 263.71 388.651 263.481 388.976 263.248C389.302 263.019 389.627 262.784 389.951 262.55C391.387 261.498 392.8 260.389 394.185 259.232C395.568 258.069 396.901 256.868 398.187 255.633C398.473 255.358 398.762 255.083 399.043 254.797C399.332 254.518 399.611 254.234 399.891 253.947C428.084 225.198 430.362 179.184 403.941 147.701Z"
                fill="url(#paint3_linear_8218_660)"
                fillOpacity="0.1"
            />
            <path
                d="M442.99 341.098L396.462 285.647C394.82 283.692 395.076 280.775 397.032 279.134L413.292 265.49C415.248 263.85 418.164 264.104 419.803 266.06L466.332 321.51C470.174 326.089 469.576 332.915 464.997 336.754L458.234 342.43C453.658 346.272 446.832 345.674 442.99 341.098Z"
                fill="white"
            />
            <path
                d="M442.99 341.098L396.462 285.647C394.82 283.692 395.076 280.775 397.032 279.134L413.292 265.49C415.248 263.85 418.164 264.104 419.803 266.06L466.332 321.51C470.174 326.089 469.576 332.915 464.997 336.754L458.234 342.43C453.658 346.272 446.832 345.674 442.99 341.098Z"
                style={secondary}
                fillOpacity="0.35"
            />
            <path
                d="M389.126 241.118C388.854 241.409 388.579 241.703 388.297 241.989C388.023 242.278 387.743 242.564 387.455 242.848C386.198 244.104 384.875 245.316 383.485 246.48C382.102 247.639 380.682 248.734 379.223 249.766C378.893 249.992 378.563 250.22 378.232 250.444C377.9 250.668 377.565 250.89 377.23 251.109C365.6 258.655 351.88 262.098 337.851 260.872C321.213 259.413 306.139 251.567 295.404 238.77C273.244 212.367 276.699 172.851 303.108 150.692C329.521 128.53 369.033 131.989 391.193 158.399C411.662 182.791 410.27 218.37 389.126 241.118ZM392.107 157.628C369.486 130.675 329.301 127.156 302.342 149.777C275.384 172.394 271.87 212.586 294.487 239.541C315.416 264.488 351.388 269.357 378.002 252.031C378.339 251.811 378.669 251.587 379.004 251.363C379.334 251.139 379.662 250.915 379.987 250.678C381.442 249.655 382.864 248.56 384.254 247.397C385.642 246.235 386.965 245.021 388.228 243.769C388.512 243.485 388.796 243.199 389.075 242.915C389.353 242.624 389.627 242.335 389.904 242.044C411.59 218.844 413.036 182.574 392.107 157.628Z"
                fill="#E6E6E6"
            />
            <path
                d="M389.126 241.119C388.854 241.409 388.579 241.703 388.297 241.989C388.023 242.278 387.743 242.564 387.455 242.848C386.198 244.104 384.875 245.316 383.485 246.48C382.102 247.639 380.682 248.734 379.223 249.766C378.893 249.992 378.563 250.221 378.232 250.445C377.9 250.669 377.565 250.89 377.23 251.11C365.6 258.655 351.88 262.098 337.851 260.872C321.213 259.413 306.139 251.567 295.404 238.77C273.244 212.367 276.699 172.851 303.108 150.692C329.521 128.53 369.033 131.989 391.193 158.399C411.662 182.791 410.27 218.37 389.126 241.119Z"
                fill="white"
            />
            <path
                d="M454.071 321.072C453.485 321.072 452.903 320.827 452.49 320.349L414.592 276.58C413.837 275.707 413.932 274.386 414.805 273.631C415.677 272.874 416.998 272.969 417.755 273.844L455.651 317.611C456.408 318.483 456.311 319.804 455.438 320.561C455.043 320.903 454.556 321.072 454.071 321.072Z"
                fill="white"
            />
            <path
                d="M285.968 155.166C285.638 155.166 285.306 155.06 285.026 154.841C284.362 154.321 284.245 153.361 284.765 152.696C289.672 146.431 295.537 141.019 302.196 136.609C308.76 132.263 315.937 128.996 323.528 126.895C339.312 122.526 355.711 123.443 370.957 129.543C371.74 129.857 372.12 130.746 371.806 131.529C371.492 132.314 370.603 132.695 369.821 132.381C340.566 120.675 306.581 129.802 287.172 154.58C286.87 154.965 286.421 155.166 285.968 155.166Z"
                fill="white"
            />
            <path
                d="M275.678 173.061C275.488 173.061 275.296 173.026 275.11 172.95C274.327 172.636 273.945 171.747 274.259 170.964C275.85 166.991 277.785 163.142 280.011 159.526C280.453 158.808 281.395 158.584 282.113 159.025C282.832 159.468 283.056 160.41 282.614 161.128C280.479 164.596 278.623 168.286 277.097 172.101C276.858 172.696 276.284 173.061 275.678 173.061Z"
                fill="white"
            />
            <path
                d="M333.5 187V189H325.5V187C325.5 178.25 332.625 171 341.5 171H345.5C354.25 171 361.5 178.25 361.5 187V188.25C361.5 193.125 359.25 197.75 355.375 200.75L347.5 207V211H339.5V205V203.125L341 201.875L350.375 194.5C352.375 193 353.5 190.625 353.5 188.25V187C353.5 182.625 349.875 179 345.5 179H341.5C337 179 333.5 182.625 333.5 187ZM348.5 217V227H338.5V217H348.5Z"
                style={primary}
                fillOpacity="0.25"
            />
            <defs>
                <filter
                    id="filter0_dd_8218_660"
                    x="103.768"
                    y="90.3647"
                    width="416.44"
                    height="386.299"
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB"
                >
                    <feFlood
                        floodOpacity="0"
                        result="BackgroundImageFix"
                    />
                    <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                    />
                    <feMorphology
                        radius="10.2689"
                        operator="erode"
                        in="SourceAlpha"
                        result="effect1_dropShadow_8218_660"
                    />
                    <feOffset dy="20.5378" />
                    <feGaussianBlur stdDeviation="10.2689" />
                    <feComposite
                        in2="hardAlpha"
                        operator="out"
                    />
                    <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0.0627451 0 0 0 0 0.0941176 0 0 0 0 0.156863 0 0 0 0.03 0"
                    />
                    <feBlend
                        mode="normal"
                        in2="BackgroundImageFix"
                        result="effect1_dropShadow_8218_660"
                    />
                    <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                    />
                    <feMorphology
                        radius="10.2689"
                        operator="erode"
                        in="SourceAlpha"
                        result="effect2_dropShadow_8218_660"
                    />
                    <feOffset dy="51.3446" />
                    <feGaussianBlur stdDeviation="30.8068" />
                    <feComposite
                        in2="hardAlpha"
                        operator="out"
                    />
                    <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0.0627451 0 0 0 0 0.0941176 0 0 0 0 0.156863 0 0 0 0.08 0"
                    />
                    <feBlend
                        mode="normal"
                        in2="effect1_dropShadow_8218_660"
                        result="effect2_dropShadow_8218_660"
                    />
                    <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="effect2_dropShadow_8218_660"
                        result="shape"
                    />
                </filter>
                <linearGradient
                    id="paint0_linear_8218_660"
                    x1="251.615"
                    y1="95.4999"
                    x2="451.115"
                    y2="345.5"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop
                        style={secondary}
                        stopOpacity="0"
                    />
                    <stop
                        offset="1"
                        style={secondary}
                    />
                </linearGradient>
                <linearGradient
                    id="paint1_linear_8218_660"
                    x1="250.115"
                    y1="92.5"
                    x2="464.463"
                    y2="335.489"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop
                        style={secondary}
                        stopOpacity="0"
                    />
                    <stop
                        offset="1"
                        style={secondary}
                    />
                </linearGradient>
                <linearGradient
                    id="paint2_linear_8218_660"
                    x1="250.115"
                    y1="92.5"
                    x2="464.463"
                    y2="335.489"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop
                        style={secondary}
                        stopOpacity="0"
                    />
                    <stop
                        offset="1"
                        style={secondary}
                    />
                </linearGradient>
                <linearGradient
                    id="paint3_linear_8218_660"
                    x1="250.115"
                    y1="92.5"
                    x2="464.463"
                    y2="335.489"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop
                        style={secondary}
                        stopOpacity="0"
                    />
                    <stop
                        offset="1"
                        style={secondary}
                    />
                </linearGradient>
                <clipPath id="clip0_8218_660">
                    <rect
                        width="333.649"
                        height="333.649"
                        fill="white"
                        transform="translate(201.851 31.1714)"
                    />
                </clipPath>
            </defs>
        </svg>
    );

    return redirect ? <Link to={redirect || '/'}>{logoIcon}</Link> : logoIcon;
};

export default L2DErrorImage;
