import React, { MouseEvent } from 'react';
import { exitIcon } from '@europrocurement/l2d-icons';
import FlexyIconButton from '../FlexyIconButton';

export type ExitButtonProps = {
    handleClickExitButton?: (event: MouseEvent<HTMLButtonElement>) => void;
};

/**
 * ExitButton component allows the user to navigate back to the previous page
 * or to a specific page based on the current location.
 */
const ExitButton: React.FunctionComponent<ExitButtonProps> = function ({ handleClickExitButton }) {
    return (
        <FlexyIconButton
            data-testid="test-id-exit-button"
            aria-label="menu"
            icon={exitIcon}
            onClick={handleClickExitButton}
            iconOverwriteProps={{
                color: 'grey.A300',
                size: 'sm',
            }}
            buttonOverwriteProps={{
                sx: {
                    marginLeft: 0,
                    paddingLeft: 0,
                },
            }}
        />
    );
};

export default ExitButton;
