import React from 'react';
import { Typography } from '@mui/material';
import { Box } from '@mui/system';

import { useParcoursFormalite } from '../provider';

const CurrentFormalityAndClient: React.FunctionComponent = function () {
    const { currentCatalog, currentSousClient } = useParcoursFormalite();

    return currentCatalog ? (
        <Box
            sx={{
                with: '100%',
                textAlign: 'center',
            }}
        >
            <Typography
                variant="l2dbodyxs"
                color="text.secondary"
                sx={{
                    fontWeight: 500,
                    fontStyle: 'italic',
                }}
            >
                {' '}
                {currentCatalog.nomCommercial}{' '}
                {currentSousClient ? ` - ${currentSousClient.raisonSociale}` : ''}
            </Typography>
        </Box>
    ) : null;
};

export default CurrentFormalityAndClient;
