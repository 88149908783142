import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { FACTURATION_REDUCER_NAME } from './constants';
import { buildFacturationRedux } from './facturationReducer';

const { reducer } = buildFacturationRedux({});

export const facturationReducer = combineReducers({
    [FACTURATION_REDUCER_NAME]: reducer,
});

export const facturationStore = configureStore({
    reducer: facturationReducer,
});

export type FacturationReducerType = typeof facturationReducer;
export type FacturationStateType = ReturnType<typeof facturationStore.getState>;
export type FacturationAppDispatch = typeof facturationStore.dispatch;
