import { FormStructure } from '@europrocurement/flexy-form/components';
import { jsonHeader } from '../types/formTypes';

const jsonHeaderToFormElement: (header: jsonHeader) => FormStructure = (header) => {
    const baseField: FormStructure = {
        type: header.type,
        label: header.label,
        name: header.name,
        variant: header.variant || 'l2dh4',
        outlined: false,
        xs: header.xs,
        sm: header.sm,
        md: header.md,
        lg: header.lg,
    };

    return baseField;
};

export default jsonHeaderToFormElement;
