import { FaOptionIcon } from '@europrocurement/l2d-icons';
import { IconName } from '@fortawesome/pro-duotone-svg-icons';
import { Typography } from '@mui/material';
import { Box, lighten, useTheme } from '@mui/system';
import React, { CSSProperties } from 'react';
import { OptionType } from '../FlexySelect';

export type BaseItemProps = {
    label: string;
    baseline?: string;
    value: OptionType['value'];
    isChecked?: boolean;
    isDisabled?: boolean | ((id: number) => boolean);
    onChange?: (value: BaseItemProps['value'], checked: boolean) => void;
};

export type SelectItemProps = BaseItemProps & {
    icone?: string;
    fullWidth?: boolean;
    design?: 'block' | 'inline' | 'basic';
};

const SelectItem: React.FunctionComponent<SelectItemProps> = function ({
    label,
    baseline,
    value,
    icone,
    fullWidth = true,
    design = 'inline',
    isChecked = false,
    isDisabled = false,
    onChange = () => {},
}) {
    const theme = useTheme();

    const changecheck = () => {
        onChange(value, !isChecked);
    };

    let flexDirection: CSSProperties;
    let cssChecked: CSSProperties = {};
    let cssIconeBox: CSSProperties;
    let cssDisabled: CSSProperties = {};

    let iconeWithShape: boolean = false;
    let colorIcone = 'text.gris70';

    if (design === 'inline') {
        flexDirection = {
            flexDirection: 'row',
            justifyContent: 'flex-start',
            height: '48px',
            padding: '4px 16px 4px 16px',
        };
        cssIconeBox = {
            fontSize: '18px',
            marginRight: '5px',
        };
    } else if (design === 'block') {
        iconeWithShape = true;
        flexDirection = {
            flexDirection: 'column',
            height: '149px',
            justifyContent: 'space-around',
            padding: '10px',
            textAlign: 'center',
        };
        cssIconeBox = {
            marginTop: '15px',
            fontSize: '28px',
        };
    } else {
        flexDirection = {
            flexDirection: 'row',
            justifyContent: 'flex-start',
            height: '48px',
            padding: '4px 16px 4px 16px',
        };
        cssIconeBox = {
            fontSize: '18px',
            marginRight: '5px',
        };
    }

    if (isDisabled) {
        cssDisabled = {
            borderColor: theme.palette.grey[300],
            background: theme.palette.grey[300],
            cursor: 'not-allowed',
        };
    } else if (isChecked) {
        cssChecked = {
            borderColor: theme.palette.primary.main,
            background: lighten(theme.palette.primary.main, 0.95),
            color: theme.palette.primary.dark,
        };
        colorIcone = theme.palette.primary.dark;
    } else {
        cssChecked = {
            borderColor: theme.palette.grey[300],
            background: theme.palette.background.paper,
        };
    }

    const iconeBox = (
        <Typography
            component="span"
            style={{
                ...cssIconeBox,
            }}
        >
            <FaOptionIcon
                icon={['fasl', icone as IconName]}
                color={colorIcone}
                withShape={iconeWithShape}
                shapeSize={48}
            />
        </Typography>
    );

    return (
        <Box
            style={{
                width: fullWidth ? '100%' : 'fit-content',
                display: 'flex',
                gap: 1,
                border: '1px solid',
                borderRadius: '15px',
                cursor: 'pointer',
                position: 'relative',
                alignItems: 'center',
                ...flexDirection,
                ...cssDisabled,
                ...cssChecked,
            }}
            className={isChecked ? 'selectItem-checked' : 'selectItem-unchecked'}
            onClick={() => {
                if (!isDisabled) {
                    changecheck();
                }
            }}
        >
            {icone ? iconeBox : null}
            <Box
                style={{
                    display: 'flex',
                    gap: 1,
                    flexDirection: 'column',
                    justifyContent: 'flex-start',
                }}
            >
                <Typography
                    variant="l2dcaption"
                    color="text.gris70"
                    sx={
                        design === 'basic'
                            ? {
                                  whiteSpace: 'nowrap',
                              }
                            : {}
                    }
                >
                    {label}
                </Typography>
                {baseline && design !== 'basic' ? (
                    <Typography
                        sx={{
                            fontSize: '13px',
                            lineHeight: '16px',
                            marginTop: '5px',
                        }}
                        color="text.secondary"
                    >
                        {baseline}
                    </Typography>
                ) : null}
            </Box>
        </Box>
    );
};

export default SelectItem;
