import {
    SliceFactory,
    FetchCollectionData,
    FetchItemDataType,
    ApiCollectionResponse,
} from '@europrocurement/l2d-redux-utils';

import {
    UtilisateursApi,
    UtilisateursApiApiUtilisateursGetCollectionRequest,
    Configuration as UtilisateursConfiguration,
} from '../../../openApi/ApiUtilisateur';
import { UTILISATEUR_REDUCER_NAME, UTILISATEUR_SLICE_NAME } from '../constants';
import {
    type Mutable,
    type UtilisateurApiObject,
    type UtilisateursConfigurationParameters,
} from '../types';

export type Utilisateur = UtilisateurApiObject;

const mapperUtilisateur = function (user: UtilisateurApiObject) {
    return {
        ...user,
    } as Utilisateur;
};

export function createUtilisateurSlice(configuration: UtilisateursConfigurationParameters) {
    const conf = new UtilisateursConfiguration(configuration);
    const api = new UtilisateursApi(conf);

    const fetchUtilisateurCollection: FetchCollectionData<UtilisateurApiObject> = ({
        pagination: { page, itemsPerPage },
        xIdSociete,
        filters,
        search,
    }) => {
        const input: Mutable<UtilisateursApiApiUtilisateursGetCollectionRequest> = {
            xIdSociete,
            page: page + 1,
            itemsPerPage,
        };

        if (search && search !== '') {
            input.multiFieldSearch = search;
        }

        if (filters.user) {
            input.user = filters.user as string;
        }

        return api
            .apiUtilisateursGetCollection(input)
            .then((res) => res.data)
            .catch((error) => {
                console.error(error);
            }) as Promise<ApiCollectionResponse<UtilisateurApiObject>>;
    };

    const fetchUtilisateurItem: FetchItemDataType<UtilisateurApiObject> = ({
        idItem,
        // pagination,
        xIdSociete,
    }) =>
        api
            .apiUtilisateursIdGet({ id: String(idItem), xIdSociete })
            .then((res) => res.data) as Promise<UtilisateurApiObject>;

    const { slice: utilisateurSlice, dataSourcesThunks: utilisateurDataSourcesThunks } =
        SliceFactory.createSlice<UtilisateurApiObject, Utilisateur>(
            UTILISATEUR_SLICE_NAME,
            UTILISATEUR_REDUCER_NAME,
            [],
            fetchUtilisateurCollection,
            fetchUtilisateurItem,
            mapperUtilisateur,
        );

    return { utilisateurSlice, utilisateurDataSourcesThunks };
}
