import { FaOptionIcon } from '@europrocurement/l2d-icons';
import { IconButton, Menu, MenuItem } from '@mui/material';
import React, { useState } from 'react';

const ActionMenu: React.FunctionComponent<unknown> = function () {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <div
            onClick={(e) => {
                e.stopPropagation();
            }}
            onKeyDown={() => {}}
            role="button"
            tabIndex={0}
        >
            <IconButton
                aria-label="more"
                id="long-button"
                aria-controls={open ? 'long-menu' : undefined}
                aria-expanded={open ? 'true' : undefined}
                aria-haspopup="true"
                onClick={handleClick}
            >
                <FaOptionIcon
                    icon={['fasl', 'ellipsis-vertical']}
                    sx={{ fontSize: '1rem' }}
                />
            </IconButton>
            <Menu
                id="long-menu"
                MenuListProps={{
                    'aria-labelledby': 'long-button',
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
            >
                <MenuItem onClick={handleClose}>Déplacer dans un groupe</MenuItem>
                <MenuItem onClick={handleClose}>Télécharger</MenuItem>
                <MenuItem onClick={handleClose}>Partager</MenuItem>
                <MenuItem onClick={handleClose}>Envoyer un récap</MenuItem>
                <MenuItem onClick={handleClose}>Suspendre</MenuItem>
                <MenuItem onClick={handleClose}>Supprimer</MenuItem>
            </Menu>
        </div>
    );
};

export default ActionMenu;
