import { CatalogueApi, CatalogueCatalogueRead } from '@europrocurement/l2d-domain/openApi/ApiOffre';
import { catalogIcon } from '@europrocurement/l2d-icons';
import { DataSourcesThunksType } from '@europrocurement/l2d-redux-utils';
import { type ModelConfiguration, type ModelMethods, type ModelItem } from '../../..';
import { CATALOGS_SLICE_NAME } from '../constants';

const modelKey = 'catalog';
const modelName = 'Catalog';
const sliceName = CATALOGS_SLICE_NAME;

type ModelStructure = CatalogueCatalogueRead;

const methods: ModelMethods<ModelStructure> = {
    getItemDefaultLabel: (model: ModelStructure) => `${model.nomCommercial}`,
};

export type CatalogueEndpoints = {
    list: InstanceType<typeof CatalogueApi>['apiCataloguesGetCollection'];
    listWaitingAction: InstanceType<
        typeof CatalogueApi
    >['apiCataloguesIdActionsAEffectuerGetCollection'];
    delete: InstanceType<typeof CatalogueApi>['apiCataloguesIdDelete'];
    dependencies: InstanceType<typeof CatalogueApi>['apiCataloguesIdDependenciesGet'];
    listRequiredDocuments: InstanceType<
        typeof CatalogueApi
    >['apiCataloguesIdDocumentsAFournirGetCollection'];
    listByPrescripteur: InstanceType<
        typeof CatalogueApi
    >['apiPrescripteursCataloguesGetCollection'];
    read: InstanceType<typeof CatalogueApi>['apiCataloguesIdGet'];
    update: InstanceType<typeof CatalogueApi>['apiCataloguesIdPut'];
    create: InstanceType<typeof CatalogueApi>['apiCataloguesPost'];
};

export type CatalogModel = ModelItem<ModelStructure> & CatalogueEndpoints;

export const buildCatalogModel = (
    api: CatalogueApi,
    datasourcesThunks: DataSourcesThunksType<CatalogueCatalogueRead>,
) => {
    const configuration: ModelConfiguration = {
        key: modelKey,
        name: modelName,
        type: 'Catalogue',
        sliceName,
        api,
        datasourcesThunks,
        icon: catalogIcon,
    };

    const endpoints = {
        list: api.apiCataloguesGetCollection.bind(api),
        listWaitingAction: api.apiCataloguesIdActionsAEffectuerGetCollection.bind(api),
        delete: api.apiCataloguesIdDelete.bind(api),
        dependencies: api.apiCataloguesIdDependenciesGet.bind(api),
        listRequiredDocuments: api.apiCataloguesIdDocumentsAFournirGetCollection.bind(api),
        listByPrescripteur: api.apiPrescripteursCataloguesGetCollection.bind(api),
        read: api.apiCataloguesIdGet.bind(api),
        update: api.apiCataloguesIdPut.bind(api),
        create: api.apiCataloguesPost.bind(api),
    };

    const model: CatalogModel = {
        ...configuration,
        ...methods,
        ...endpoints,
    };

    return model;
};
