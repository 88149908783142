import * as React from 'react';
import { UseKeycloakService } from '@europrocurement/l2d-keycloak';

import { useNavigate } from 'react-router';
import { TextIconButton } from '@europrocurement/flexy-components/components/atoms';
import { logoutIcon } from '@europrocurement/l2d-icons';
import { Box } from '@mui/system';
import { ErrorPage } from '../ErrorPage';

export const NoPrescriber: React.FunctionComponent = function () {
    const keycloak = UseKeycloakService();
    const navigate = useNavigate();

    React.useEffect(() => {
        if (!keycloak.isInitialized || !keycloak.isAutenticated || !keycloak.getTokenParsed()) {
            return;
        }

        const pId = keycloak.getTokenParsed()?.IdPrescripteur || null;

        if (pId) {
            navigate('/');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [keycloak]);

    return (
        <ErrorPage
            statusCode={400}
            message="Oups, compte prescripteur non trouvé"
            details="Vous ne pouvez pas acceder a cette page sans etre rataché a un prescripteur"
        >
            <Box sx={{ display: 'inline-block', marginLeft: '30px' }}>
                <TextIconButton
                    icon={logoutIcon}
                    btnProps={{
                        onClick: () => {
                            navigate('/');
                            keycloak.getKc().logout();
                        },
                    }}
                >
                    Se déconnecter
                </TextIconButton>
            </Box>
        </ErrorPage>
    );
};
export default NoPrescriber;
