import { useFormaliteService } from '@europrocurement/l2d-domain';
import { DossierOpenSearchJsonldOpensearchDossierClientRead } from '@europrocurement/l2d-domain/openApi/ApiFormalite';

export type DossierClient = DossierOpenSearchJsonldOpensearchDossierClientRead &
    Record<string, unknown>;

const useDossierClient = () => {
    const { dossierOpenSearchApi } = useFormaliteService();
    let searchClientTimeout: number | undefined;

    const searchDossierClient = (term: string): Promise<Array<DossierClient>> =>
        new Promise((resolve, reject) => {
            clearTimeout(searchClientTimeout);
            const requestParameters = { q: term || undefined };

            searchClientTimeout = setTimeout(() => {
                dossierOpenSearchApi
                    .apiOpensearchClientsGetCollection(requestParameters)
                    .then((res) => resolve(res.data['hydra:member'] as Array<DossierClient>))
                    .catch(() => reject());
            }, 300);
        });

    return { searchDossierClient };
};

export default useDossierClient;
