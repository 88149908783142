import * as React from 'react';
import { UseKeycloakService } from '@europrocurement/l2d-keycloak';
import { TextIconButton } from '@europrocurement/flexy-components/components/atoms';

import { useNavigate } from 'react-router';
import { Box } from '@mui/system';
import { logoutIcon } from '@europrocurement/l2d-icons';
import { ErrorPage } from '../ErrorPage';

export const Forbidden = function () {
    const keycloak = UseKeycloakService();

    const navigate = useNavigate();

    return (
        <ErrorPage
            statusCode={403}
            message="Oups, acces refusé"
            details="Vous n'avez pas les droits d'acceder a cette page"
        >
            <Box sx={{ display: 'inline-block', marginLeft: '30px' }}>
                <TextIconButton
                    icon={logoutIcon}
                    btnProps={{
                        onClick: () => {
                            navigate('/');
                            keycloak.getKc().logout();
                        },
                    }}
                >
                    Se déconnecter
                </TextIconButton>
            </Box>
        </ErrorPage>
    );
};
export default Forbidden;
