/* eslint-disable @typescript-eslint/no-unused-vars */

import {
    SliceFactory,
    FetchCollectionData,
    FetchItemDataType,
    ApiCollectionResponse,
} from '@europrocurement/l2d-redux-utils';
import {
    ConfigurationParameters as DossiersConfigurationParameters,
    Configuration,
    DossierApi,
    DossierDossierRead,
    DossierApiApiDossiersGetCollectionRequest,
    DossierApiApiDossiersIdGetRequest,
    CollectionDeDossierCollectiondossierRead,
} from '../../../openApi/ApiFormalite';
import {
    DOSSIERS_FORMALITES_AUTOCOMPLETE_DATASOURCE_NAME,
    DOSSIERS_FORMALITES_SLICE_NAME,
    FORMALITES_REDUCER_NAME,
} from '../constants';

export type DossierFormaliteApiObject = DossierDossierRead;

export type DossierFormalite = DossierFormaliteApiObject;

export type CollectionDossierFormaliteApiObject = CollectionDeDossierCollectiondossierRead;

export type CollectionDossierFormalite = CollectionDossierFormaliteApiObject;

export const additionalDossiersFormalitesDataSources: Array<string> = [
    DOSSIERS_FORMALITES_AUTOCOMPLETE_DATASOURCE_NAME,
];

export const mapperDossiersFormalites = function (dosform: DossierFormalite) {
    return {
        ...dosform,
    } as DossierFormalite;
};

export function createDossierFormaliteSlice(configuration: DossiersConfigurationParameters) {
    const conf = new Configuration(configuration);
    const api = new DossierApi(conf);

    const fetchDossiersFormalitesCollection: FetchCollectionData<DossierFormalite> = ({
        search,
        pagination,
        filters,
        orders,
        xIdSociete,
        rootstate,
    }) => {
        const options = undefined;

        let dateCreationBefore: string | undefined;
        let dateCreationAfter: string | undefined;
        let numero: string | undefined;
        let societeDenomination: string | undefined;
        let idDematfacile: number | undefined;
        let societeSiren: string | undefined;
        let affaire: string | undefined;
        let statutId: number | undefined;
        let statutId2: Array<number> | undefined;
        let orderNumero: 'asc' | 'desc' | undefined;
        let orderSocieteDenomination: 'asc' | 'desc' | undefined;
        let orderContactNom: 'asc' | 'desc' | undefined;
        let orderDateCreation: 'asc' | 'desc' | undefined;
        let orderStatutLibelle: 'asc' | 'desc' | undefined;
        let orderFormalisteNom: 'asc' | 'desc' | undefined;

        // Vérifier que la range est bien gérée côté API.
        if (filters.dateCreation) {
            [dateCreationBefore, dateCreationAfter] = filters.dateCreation as string[];
        }

        if (filters.numero) {
            numero = filters.numero as string;
        }

        if (filters.societeDenomination) {
            societeDenomination = filters.societeDenomination as string;
        }

        if (filters.idDematfacile) {
            idDematfacile = filters.idDematfacile as number;
        }

        if (filters.societeSiren) {
            societeSiren = filters.societeSiren as string;
        }

        if (filters.affaire) {
            affaire = filters.affaire as string;
        }

        if (filters.statutId) {
            statutId = filters.statutId as number;
        }

        if (filters.statutId2) {
            statutId2 = filters.statutId2 as Array<number>;
        }

        if (search && search !== '') {
            numero = search;
        }

        orders.forEach((order) => {
            if (order.field === 'contact.nom') {
                orderContactNom = order.value;
            }

            if (order.field === 'numero') {
                orderNumero = order.value;
            }

            if (order.field === 'societeDenomination') {
                orderSocieteDenomination = order.value;
            }

            if (order.field === 'dateCreation') {
                orderDateCreation = order.value;
            }

            if (order.field === 'statut') {
                orderStatutLibelle = order.value;
            }

            if (order.field === 'formaliste') {
                orderFormalisteNom = order.value;
            }
        });

        const requestParameters: DossierApiApiDossiersGetCollectionRequest = {
            page: pagination.page + 1,
            itemsPerPage: pagination.itemsPerPage,
            dateCreationBefore,
            dateCreationAfter,
            numero,
            societeDenomination,
            idDematfacile,
            societeSiren,
            affaire,
            orderNumero,
            orderContactNom,
            orderSocieteDenomination,
            orderDateCreation,
            orderStatutLibelle,
            orderFormalisteNom,
        };

        return api
            .apiDossiersGetCollection(requestParameters, options)
            .then((res) => res.data) as Promise<ApiCollectionResponse<DossierFormalite>>;
    };

    const searchByNumeroFormalite: FetchCollectionData<DossierFormalite> = ({
        search,
        pagination,
        filters,
        orders,
        xIdSociete,
        rootstate,
    }) => {
        const requestParameters: DossierApiApiDossiersGetCollectionRequest = {
            page: pagination.page + 1,
            itemsPerPage: pagination.itemsPerPage,
            numero: search,
        };

        return api.apiDossiersGetCollection(requestParameters).then((res) => res.data) as Promise<
            ApiCollectionResponse<DossierFormalite>
        >;
    };

    const fetchDossierFormaliteItem: FetchItemDataType<DossierFormalite> = ({
        idItem,
        search,
        pagination,
        filters,
        orders,
        xIdSociete,
        rootstate,
    }) => {
        const requestParameters: DossierApiApiDossiersIdGetRequest = {
            id: String(idItem),
        };

        return api
            .apiDossiersIdGet(requestParameters)
            .then((res) => res.data) as Promise<DossierFormalite>;
    };

    const { slice: dossierFormaliteSlice, dataSourcesThunks: dossierFormaliteDataSourcesThunks } =
        SliceFactory.createSlice<DossierFormalite, DossierFormalite>(
            DOSSIERS_FORMALITES_SLICE_NAME,
            FORMALITES_REDUCER_NAME,
            additionalDossiersFormalitesDataSources,
            fetchDossiersFormalitesCollection,
            fetchDossierFormaliteItem,
            mapperDossiersFormalites,
            {},
            {
                data: {
                    searchByNumeroFormalite,
                },
            },
        );

    return { dossierFormaliteSlice, dossierFormaliteDataSourcesThunks };
}
