import { FormObject } from '@europrocurement/flexy-form/components';
import { FieldValues, UseFormReturn } from 'react-hook-form';

const checkFieldValue = function (
    formContext: UseFormReturn<FieldValues, FormObject>,
    valueToWatch: string | boolean | string[],
    fieldNameToWatch: string,
) {
    const valueSelected = formContext.watch(fieldNameToWatch as string);
    if (Array.isArray(valueToWatch)) {
        return typeof valueSelected === 'object'
            ? valueToWatch.includes(valueSelected?.value)
            : valueToWatch.includes(valueSelected);
    }
    return typeof valueSelected === 'object' || typeof valueSelected === 'function'
        ? valueSelected?.value === valueToWatch
        : valueSelected === valueToWatch;
};

export default checkFieldValue;
