import React from 'react';
import { Typography, Grid, Box, Card } from '@mui/material';

const PanelTypography: React.FunctionComponent = function () {
    return (
        <Grid container>
            <Grid
                item
                md={6}
            >
                <Card>
                    <Box
                        flexDirection="column"
                        display="flex"
                        gap="25px"
                    >
                        <Box>
                            <Typography
                                sx={{ display: 'inline-block' }}
                                variant="l2dh1"
                            >
                                Titre niveau 1
                            </Typography>
                            <Typography
                                color="text.secondary"
                                variant="subtitle2"
                            >
                                <b>
                                    variant : <i>l2dh1</i>{' '}
                                </b>{' '}
                                - Inter - medium 60px
                            </Typography>
                        </Box>
                        <Box>
                            {' '}
                            <Typography
                                sx={{ display: 'inline-block' }}
                                variant="l2dh2"
                            >
                                Titre niveau 2
                            </Typography>
                            <Typography
                                color="text.secondary"
                                variant="subtitle2"
                            >
                                <b>
                                    variant : <i>l2dh2</i>{' '}
                                </b>{' '}
                                - Inter - medium 60px
                            </Typography>
                        </Box>
                        <Box>
                            <Typography
                                sx={{ display: 'inline-block' }}
                                variant="l2dh3"
                            >
                                Titre niveau 3
                            </Typography>
                            <Typography
                                color="text.secondary"
                                variant="subtitle2"
                            >
                                <b>
                                    variant : <i>l2dh3</i>{' '}
                                </b>{' '}
                                - Inter - medium 60px
                            </Typography>
                        </Box>
                        <Box>
                            {' '}
                            <Typography
                                sx={{ display: 'inline-block' }}
                                variant="l2dh4"
                            >
                                Titre niveau 4
                            </Typography>
                            <Typography
                                color="text.secondary"
                                variant="subtitle2"
                            >
                                <b>
                                    variant : <i>l2dh4</i>{' '}
                                </b>{' '}
                                - Inter - medium 60px
                            </Typography>
                        </Box>
                        <Box>
                            {' '}
                            <Typography
                                sx={{ display: 'inline-block' }}
                                variant="l2dh5"
                            >
                                Titre niveau 5
                            </Typography>
                            <Typography
                                color="text.secondary"
                                variant="subtitle2"
                            >
                                <b>
                                    variant : <i>l2dh5</i>{' '}
                                </b>{' '}
                                - Inter - medium 60px
                            </Typography>
                        </Box>
                        <Box>
                            {' '}
                            <Typography
                                sx={{ display: 'inline-block' }}
                                variant="l2dh6"
                            >
                                Titre niveau 6
                            </Typography>
                            <Typography
                                color="text.secondary"
                                variant="subtitle2"
                            >
                                <b>
                                    variant : <i>l2dh6</i>{' '}
                                </b>{' '}
                                - Inter - medium 60px
                            </Typography>
                        </Box>
                        <Box>
                            {' '}
                            <Typography
                                sx={{ display: 'inline-block' }}
                                variant="l2dh7"
                            >
                                Titre niveau 7
                            </Typography>
                            <Typography
                                color="text.secondary"
                                variant="subtitle2"
                            >
                                <b>
                                    variant : <i>l2dh7</i>{' '}
                                </b>{' '}
                                - Inter - medium 60px
                            </Typography>
                        </Box>
                        <Box>
                            {' '}
                            <Typography
                                sx={{ display: 'inline-block' }}
                                variant="l2dsubtitles"
                            >
                                Sous titre
                            </Typography>
                            <Typography
                                color="text.secondary"
                                variant="subtitle2"
                            >
                                <b>
                                    variant : <i>l2dsubtitles</i>{' '}
                                </b>{' '}
                                - Inter - medium 16px
                            </Typography>
                        </Box>
                    </Box>
                </Card>
            </Grid>
            <Grid
                item
                md={6}
            >
                <Card>
                    <Box
                        flexDirection="column"
                        display="flex"
                        gap="20px"
                    >
                        <Box>
                            <Typography variant="l2dh6">
                                Paragraphe - Large -{' '}
                                <b>
                                    variant : <i>l2dbodyl</i>{' '}
                                </b>{' '}
                            </Typography>
                            <Typography variant="l2dbodyl">
                                Lorem Ipsum is simply dummy text of the printing and typesetting
                                industry. Lorem Ipsum has been the industry&apos;s standard dummy
                                text ever since the when an unknown printer took a galley of type
                                and scrambled it to make a type specimen book.
                            </Typography>
                        </Box>
                        <Box>
                            <Typography variant="l2dh6">
                                Paragraphe - Medium -{' '}
                                <b>
                                    variant : <i>l2dbodym</i>{' '}
                                </b>{' '}
                            </Typography>
                            <Typography variant="l2dbodym">
                                Lorem Ipsum is simply dummy text of the printing and typesetting
                                industry. Lorem Ipsum has been the industry&apos;s standard.
                            </Typography>
                        </Box>
                        <Box>
                            <Typography variant="l2dh6">
                                Paragraphe - small -{' '}
                                <b>
                                    variant : <i>l2dbodys</i>{' '}
                                </b>{' '}
                            </Typography>
                            <Typography variant="l2dbodys">
                                Lorem Ipsum is simply dummy text of the printing and typesetting
                                industry. Lorem Ipsum has been the industry&apos;s standard.
                            </Typography>
                        </Box>
                        <Box>
                            <Typography variant="l2dh6">
                                Paragraphe - extrasmall -{' '}
                                <b>
                                    variant : <i>l2dbodyxs</i>{' '}
                                </b>{' '}
                            </Typography>
                            <Typography variant="l2dbodyxs">
                                Lorem Ipsum is simply dummy text of the printing and typesetting
                                industry. Lorem Ipsum has been the industry&apos;s standard.
                            </Typography>
                        </Box>
                        <Box>
                            <Typography variant="l2dh6">
                                caption -{' '}
                                <b>
                                    variant : <i>l2dcaption</i>{' '}
                                </b>{' '}
                            </Typography>
                            <Typography variant="l2dcaption">
                                Lorem Ipsum is simply dummy text.
                            </Typography>
                        </Box>
                        <Box>
                            <Typography variant="l2dh6">
                                caption -{' '}
                                <b>
                                    variant : <i>l2dlabel</i>{' '}
                                </b>{' '}
                            </Typography>
                            <Typography variant="l2dlabel">
                                Lorem Ipsum is simply dummy text.
                            </Typography>
                        </Box>

                        <Box>
                            <Typography variant="l2dh6">
                                Text secondaire -{' '}
                                <b>
                                    variant : <i>l2dbodym</i> color : <i>text.secondary</i>{' '}
                                </b>{' '}
                            </Typography>
                            <Typography
                                variant="l2dbodym"
                                color="text.secondary"
                            >
                                Lorem Ipsum is simply dummy text of the printing and typesetting
                                industry.
                            </Typography>
                        </Box>
                    </Box>
                </Card>
            </Grid>

            <Grid
                item
                md={12}
            >
                <Card>
                    <Typography variant="h1">Typo h1</Typography>
                    <br />
                    <Typography variant="h2">Typo h2</Typography>
                    <br />
                    <Typography variant="h3">Typo h3</Typography>
                    <br />
                    <Typography variant="h4">Typo h4</Typography>
                    <br />
                    <Typography variant="h5">Typo h5</Typography>
                    <br />
                    <Typography variant="h6">Typo h6</Typography>
                    <br />
                    <Typography variant="body1">
                        Body 1 Cupidatat id aute eu irure ut sit nostrud adipisicing sint nisi anim.
                        Nisi reprehenderit et eu duis. Cillum voluptate dolor magna ipsum. Eu velit
                        fugiat ea proident dolor qui nisi irure deserunt nisi sit sint dolor.
                    </Typography>
                    <br />
                    <Typography variant="body2">
                        Body 2 Cupidatat id aute eu irure ut sit nostrud adipisicing sint nisi anim.
                        Nisi reprehenderit et eu duis. Cillum voluptate dolor magna ipsum. Eu velit
                        fugiat ea proident dolor qui nisi irure deserunt nisi sit sint dolor.
                    </Typography>
                    <br />
                    <Typography variant="subtitle1">
                        Subtitle 1 Cupidatat id aute eu irure ut sit nostrud
                    </Typography>
                    <br />
                    <Typography variant="subtitle2">
                        Subtitle 2 Cupidatat id aute eu irure ut sit nostrud
                    </Typography>
                </Card>
            </Grid>
        </Grid>
    );
};

export default PanelTypography;
