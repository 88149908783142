import React from 'react';
import { Button, SortDirection, Typography, useTheme } from '@mui/material';
import { FaOptionIcon } from '@europrocurement/l2d-icons';
import { faCheck } from '@fortawesome/pro-duotone-svg-icons';

export type FlexyDatatableMobileSortItemProps = {
    current: boolean;
    label: string;
    field: string;
    direction: SortDirection;
    onClick: (field: string) => void;
};

const FlexyDatatableMobileSortItem: React.FunctionComponent<FlexyDatatableMobileSortItemProps> =
    function (props) {
        const { current, label, field, direction, onClick } = props;
        const theme = useTheme();
        const { palette } = theme;
        const iconName = direction === 'asc' ? 'arrow-up' : 'arrow-down';

        const handleClick = () => {
            onClick(field);
        };

        return (
            <Button
                sx={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    gap: '12px',
                    width: '100%',
                    padding: '0 14px',
                    height: '44px',
                    backgroundColor: current ? palette.primary.light : 'transparent',
                    '&:hover': {
                        backgroundColor: palette.primary.light,
                    },
                }}
                onClick={handleClick}
            >
                <FaOptionIcon
                    color={palette.grey[700]}
                    icon={['fasds', iconName]}
                />
                <Typography
                    sx={{
                        color: palette.text.primary,
                        fontWeight: '500',
                        fontSize: '15px',
                        lineHeight: '18px',
                        marginRight: 'auto',
                    }}
                    component="span"
                >
                    {label}
                </Typography>
                {current && <FaOptionIcon icon={faCheck} />}
            </Button>
        );
    };

export default FlexyDatatableMobileSortItem;
