import {
    CustomItemFormStructure,
    CustomItemRenderFieldProps,
    ExtandedFormContext,
} from '@europrocurement/flexy-form';
import { Box, Typography } from '@mui/material';
import React from 'react';

import { PercentWidget } from '@europrocurement/flexy-components';
// eslint-disable-next-line import/no-cycle
import DirigeantView from './DirigeantView/DirigeantView';
// eslint-disable-next-line import/no-cycle
import BeneficiaireView from './BeneficiaireView/BeneficiaireView';

export type customItemJsonProps = {
    formContext: ExtandedFormContext;
    props: CustomItemRenderFieldProps;
};

export const customItemRenderer: CustomItemFormStructure['renderField'] = (
    formContext: ExtandedFormContext,
    props: CustomItemRenderFieldProps,
) => {
    switch (props.name) {
        case 'dirigeants':
            return (
                <DirigeantView
                    formContext={formContext}
                    props={props}
                />
            );
            break;
        case 'beneficiaires_effectif':
            return (
                <BeneficiaireView
                    formContext={formContext}
                    props={props}
                />
            );
            break;
        case 'total_detention_directe_capital':
        case 'total_detention_indirecte_capital':
        case 'total_detention_directe_vote':
        case 'total_detention_indirecte_vote': {
            const values = [];
            if (props.rules?.renderTotalDetention) {
                for (let i = 0; i < props.rules?.renderTotalDetention?.length; i++) {
                    const valueWatched = formContext.watch(
                        props.rules.renderTotalDetention[i] as string,
                    );
                    values.push(parseFloat(valueWatched === '' ? 0 : valueWatched));
                }
            }
            return (
                <Box sx={{ margin: '25px 0' }}>
                    <PercentWidget
                        label={props.inputlabel as string}
                        values={values}
                    />
                </Box>
            );
            break;
        }
        default:
            return (
                <Typography variant="l2dh7">
                    Impossible d&apos;afficher le customItem le type type {props.name} n&apos;est
                    pas pris en charge
                </Typography>
            );
    }
};
