import * as React from 'react';
import { Box, Typography, Grid } from '@mui/material';
import { Link } from 'react-router-dom';
// import { B2D404Icon } from '@europrocurement/flexy-components/components';
import { L2DErrorImage, TextIconLink } from '@europrocurement/flexy-components/components/atoms';
import { arrowLeftIcon, L2DFLogoIcon } from '@europrocurement/l2d-icons';
import { PageContainer } from '../PageContainer';

/**
 *
 * [X] ErrorPage : ajouter title et description en parametre
 * [X] NotFound : ajouter message en parametre
 * [ ] DossierSignatureFiche :
 *      [X] ajouter un state isError404
 *      [X] a la place de navigateTo404 faire un setIsError404(true)
 *      [X] avant le return ligne 185 :
 *          si isError404 return un composant <NotFound>
 *
 * si ca marche pas :
 *      reprendre toute la box de ErrorPage dans un nouveau compo error
 *
 */

export type ErrorPageType = {
    statusCode: number;
    message: string;
    children?: React.ReactNode;
    details?: string;
    title?: string;
    description?: string;
};

export const ErrorPage = function ({
    statusCode,
    message,
    children,
    title = 'Error',
    details = 'Il semble que quelque chose n’a pas fonctionné correctement. Merci de réessayer plus tard.',
    description = 'this is Error page',
}: ErrorPageType) {
    return (
        <PageContainer
            title={title}
            description={description}
            data-testid="test-id-ErrorPage"
        >
            <Grid container>
                <Grid
                    item
                    sm={6}
                >
                    <Box height="100px">
                        <L2DFLogoIcon
                            style={{
                                marginTop: '32px',
                                height: '64px',
                            }}
                        />
                    </Box>
                    <Box
                        display="flex"
                        flexDirection="column"
                        height="100vh"
                        sx={{ padding: '0 64px' }}
                        textAlign="center"
                        justifyContent="center"
                        alignItems="flex-end"
                        padding="auto"
                    >
                        <L2DErrorImage />
                    </Box>
                </Grid>
                <Grid
                    item
                    sm={4}
                >
                    <Box
                        display="flex"
                        flexDirection="column"
                        height="100vh"
                        justifyContent="center"
                        sx={{ padding: '0 64px' }}
                    >
                        <Typography
                            variant="l2dh7"
                            sx={{
                                pt: 2,
                            }}
                            data-testid="error-status"
                        >
                            Erreur {statusCode}
                        </Typography>
                        <Typography
                            variant="l2dh2"
                            sx={{
                                pt: 1,
                                pb: 3,
                            }}
                            data-testid="error-content"
                        >
                            {message}
                        </Typography>
                        <Typography
                            variant="l2dbodym"
                            sx={{
                                pt: 1,
                                pb: 3,
                            }}
                        >
                            {details}
                        </Typography>
                        <Box
                            sx={{
                                marginTop: '32px',
                            }}
                        >
                            <TextIconLink
                                icon={arrowLeftIcon}
                                to="/"
                                btnProps={{
                                    component: Link,
                                }}
                            >
                                Retour à l&apos;accueil
                            </TextIconLink>
                            {children}
                        </Box>
                    </Box>
                </Grid>
            </Grid>
        </PageContainer>
    );
};

export default ErrorPage;
