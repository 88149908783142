import React, { PropsWithChildren, useMemo, useState } from 'react';
import { FlexyFilterContext } from './FlexyFilterContext';

export const FlexyFilterProvider = function (props: PropsWithChildren) {
    const { children } = props;
    const [rawValue, setRawValue] = useState<unknown | null>(null);
    const contextValue = useMemo(() => ({ rawValue, setRawValue }), [rawValue, setRawValue]);

    return (
        <FlexyFilterContext.Provider value={contextValue}>{children}</FlexyFilterContext.Provider>
    );
};
