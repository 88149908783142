import React, { ReactNode, useCallback, useMemo, useState } from 'react';
import ActionsContext, { ContextActionType } from './ActionsContext';

export type ActionsProviderType = {
    defaultActions: ContextActionType[];
    children: ReactNode;
};

const ActionsProvider: React.FunctionComponent<ActionsProviderType> = function ({
    defaultActions,
    children,
}) {
    const [contextActions, setContextActions] = useState<ContextActionType[]>([...defaultActions]);

    const setContextActionsWraper = useCallback((actions: ContextActionType[]) => {
        setContextActions((prev) => {
            if (prev.length !== actions.length) {
                return actions;
            }

            for (let i = 0; i < prev.length; i++) {
                if (prev[i].name !== actions[i].name) {
                    return actions;
                }
                if (prev[i].resetHash !== actions[i].resetHash) {
                    return actions;
                }
            }

            return prev;
        });
    }, []);

    const defineContextActions = useCallback(
        (actions: ContextActionType[]) => {
            setContextActionsWraper([...defaultActions, ...actions]);
        },
        [defaultActions, setContextActionsWraper],
    );

    const resetContextActions = useCallback(() => {
        setContextActionsWraper([...defaultActions]);
    }, [defaultActions, setContextActionsWraper]);

    const actionContextProviderValue = useMemo(
        () => ({
            defaultActions,
            contextActions,
            defineContextActions,
            resetContextActions,
        }),
        [contextActions, defaultActions, defineContextActions, resetContextActions],
    );

    return (
        <ActionsContext.Provider value={actionContextProviderValue}>
            {children}
        </ActionsContext.Provider>
    );
};

export default ActionsProvider;
