import React from 'react';

import {
    Divider,
    SxProps,
    Typography,
    TypographyProps,
    FormControlLabel,
    Button,
    ButtonProps,
} from '@mui/material';
import { Box } from '@mui/system';
import Switch, { type SwitchProps } from '../../atoms/Switch';
import FlexyIconButton, { type FlexyIconButtonProps } from '../../atoms/FlexyIconButton';

export type HeaderButton = ButtonProps & { iconButton?: Omit<FlexyIconButtonProps, 'onClick'> };
export type HeaderSwitch = SwitchProps & { label: string };

export type FlexyHeaderFormProps = {
    label: React.ReactNode;
    color?: TypographyProps['color'];
    outlined?: boolean;
    buttons?: Array<HeaderButton>;
    switches?: Array<HeaderSwitch>;
    sx?: SxProps;
    variant?: TypographyProps['variant'];
};

const FlexyHeaderForm: React.FunctionComponent<FlexyHeaderFormProps> = function ({
    label,
    color = 'secondary.main',
    outlined = true,
    buttons,
    switches,
    sx,
    variant = 'h4',
}: FlexyHeaderFormProps) {
    return (
        <>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'end',
                }}
            >
                <Typography
                    display="flex"
                    alignItems="center"
                    width="100%"
                    color={color}
                    padding={1}
                    variant={variant}
                    sx={{ marginTop: '15px', ...sx }}
                >
                    {label}
                    {buttons &&
                        buttons.map(({ iconButton, ...buttonProps }: HeaderButton) => {
                            const { content: buttonLabel } = buttonProps;
                            return iconButton ? (
                                <FlexyIconButton
                                    key={`formHeaderButton-${buttonLabel}`}
                                    displayTooltip={iconButton.displayTooltip}
                                    icon={iconButton.icon}
                                    onClick={buttonProps.onClick}
                                    tooltipOverwriteProps={iconButton.tooltipOverwriteProps}
                                    iconOverwriteProps={{
                                        color: 'primary',
                                        ...iconButton.iconOverwriteProps,
                                    }}
                                />
                            ) : (
                                <Button
                                    key={`formHeaderButton-${buttonLabel}`}
                                    variant="outlined"
                                    {...buttonProps}
                                >
                                    {buttonLabel}
                                </Button>
                            );
                        })}
                    {switches &&
                        switches.map(({ label: switchLabel, ...switchProps }: HeaderSwitch) => (
                            <FormControlLabel
                                sx={{
                                    marginLeft: 'auto',
                                    gap: '10px',
                                    '&:last-child': {
                                        marginRight: 0,
                                    },
                                }}
                                label={switchLabel}
                                key={`formHeaderSwitch-${switchLabel}`}
                                control={
                                    <Switch
                                        size="small"
                                        aria-label="switch"
                                        {...switchProps}
                                    />
                                }
                            />
                        ))}
                </Typography>
            </Box>
            {outlined ? (
                <Divider
                    sx={{
                        backgroundColor: color,
                        borderWidth: '1px',
                    }}
                />
            ) : null}
        </>
    );
};

export default FlexyHeaderForm;
