import React, { useState } from 'react';
import { CustomOptionResolver, CustomOptionResolvers } from '@europrocurement/flexy-form';
import { jsonForm, visibilityType } from '@europrocurement/flexy-form/utils/types/formTypes';
import { JsonForm } from '@europrocurement/l2d-modules/modules/JsonForms';
import { Box, useTheme } from '@mui/material';

const PanelFormulaires = function () {
    const startPage = 0;
    const defaultValues = {};

    const [visibility, setVisibility] = useState('PARTIAL');
    const [formjson, setFormjson] = useState('');
    const [jsonvalide, setJsonvalide] = useState(false);
    const [, setCurrentPage] = useState(startPage);
    const theme = useTheme();

    const createFakeListResolvers = () => {
        const listeSelectionDataResolver: CustomOptionResolver = () => [
            { label: 'Option 1', value: 1 },
            { label: 'Option 2', value: 2 },
            { label: 'Option 3', value: 3 },
            { label: 'Option 4', value: 4 },
            { label: 'Option 5', value: 5 },
            { label: 'Option 6', value: 6 },
            { label: 'Option 7', value: 7 },
            { label: 'Option 8', value: 8 },
            { label: 'Option 9', value: 9 },
            { label: 'Option 10', value: 10 },
        ];
        return listeSelectionDataResolver;
    };

    const customOptionResolvers: CustomOptionResolvers = {
        listeSelectionDataResolver: createFakeListResolvers(),
    };

    const onFinish = (data: Record<string, unknown> | undefined) =>
        console.log('fin de parcours : ', data);

    const visibilityChange = (e: React.FormEvent<HTMLSelectElement>) => {
        console.log('changement visibility', e.currentTarget.value);
        setVisibility(e.currentTarget.value);
    };

    const jsonChange = (e: React.FormEvent<HTMLTextAreaElement>) => {
        console.log('changement json', e.currentTarget.value);
        let json = '';
        try {
            json = JSON.parse(e.currentTarget.value);
            setJsonvalide(true);
        } catch (error) {
            console.log('error validation json', error);
            setJsonvalide(false);
        }
        console.log('json', json);
        setFormjson(json);
    };

    return (
        <Box sx={{ display: 'grid', gridTemplateColumns: '2fr 5fr' }}>
            <Box
                sx={{
                    gridRow: '1',
                }}
            >
                Choix de la visibilité :<br />
                <select
                    name="visibility"
                    defaultValue={visibility}
                    onChange={visibilityChange}
                >
                    <option value="PARTIAL">PARTIAL (Essentiel)</option>
                    <option value="FULL">FULL (Premium)</option>
                </select>
                <br />
                <br />
                Json du formulaire :
                <textarea
                    style={{ width: '90%', height: '50%' }}
                    onChange={jsonChange}
                />
                {!jsonvalide ? <div>Json non valide</div> : null}
            </Box>
            <Box
                sx={{
                    gridRow: '1',
                }}
            >
                <JsonForm
                    onChange={async (curr, pagelenth, data) => {
                        console.log(curr, pagelenth, JSON.stringify(data));
                        if (curr === pagelenth) {
                            onFinish(data);
                        } else {
                            setCurrentPage(curr);
                        }
                    }}
                    visibility={visibility as visibilityType}
                    debugMode
                    json={formjson as unknown as jsonForm}
                    startPage={startPage}
                    defaultValues={defaultValues}
                    customOptionResolvers={customOptionResolvers}
                    submitStyle={{
                        backgroundColor: theme.palette.primary.dark,
                        width: '100%',
                        marginTop: '30px',
                        padding: '15px',
                        fontSize: '1rem',
                        borderRadius: '15px',
                    }}
                />
            </Box>
        </Box>
    );
};

export default PanelFormulaires;
