import React from 'react';

import { Divider, TypographyProps } from '@mui/material';

export type FlexySeparatorFormProps = { color?: TypographyProps['color'] };

const FlexySeparatorForm: React.FunctionComponent<FlexySeparatorFormProps> = function ({
    color = 'secondary.main',
}: FlexySeparatorFormProps) {
    return (
        <>
            &nbsp;
            <Divider
                sx={{
                    backgroundColor: color,
                    borderWidth: '1px',
                    opacity: '0.1',
                }}
            />
        </>
    );
};

export default FlexySeparatorForm;
