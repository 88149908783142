import { NamedIconsType } from '@europrocurement/l2d-icons/types';
import {
    faArrowUp,
    faArrowDown,
    faArrowLeft,
    faArrowRight,
    faArrowRotateLeft,
    faArrowRotateRight,
    faArrowsUpDown,
    faArrowRightFromBracket,
    faArrowLeftFromBracket,
} from '@fortawesome/pro-regular-svg-icons';

export const orderAscIcon: NamedIconsType = {
    props: { icon: faArrowUp },
    name: 'order-asc',
    displayName: 'Tri croissant',
};

export const orderDescIcon: NamedIconsType = {
    props: { icon: faArrowDown },
    name: 'order-desc',
    displayName: 'Tri décroissant',
};

export const orderNoIcon: NamedIconsType = {
    props: { icon: faArrowsUpDown },
    name: 'order-no',
    displayName: 'sans Tri',
};

export const arrowLeftIcon: NamedIconsType = {
    props: { icon: faArrowLeft },
    name: 'arrow-left',
    displayName: 'Fleche gauche',
};

export const arrowRightIcon: NamedIconsType = {
    props: { icon: faArrowRight },
    name: 'arrow-right',
    displayName: 'Fleche droite',
};

export const arrowRotateLeftIcon: NamedIconsType = {
    props: { icon: faArrowRotateLeft },
    name: 'arrowRotate-left',
    displayName: 'Fleche rotate left',
};

export const arrowRotateRightIcon: NamedIconsType = {
    props: { icon: faArrowRotateRight },
    name: 'arrowRotate-right',
    displayName: 'Fleche rotate right',
};

export const logoutIcon: NamedIconsType = {
    props: { icon: faArrowRightFromBracket },
    name: 'arrowRotate-right',
    displayName: 'Fleche rotate right',
};

export const loginIcon: NamedIconsType = {
    props: { icon: faArrowLeftFromBracket },
    name: 'arrowRotate-left',
    displayName: 'Fleche rotate left',
};

export const tableNamedIcons: NamedIconsType[] = [
    orderNoIcon,
    orderAscIcon,
    orderDescIcon,
    arrowLeftIcon,
    arrowRightIcon,
    arrowRotateLeftIcon,
    arrowRotateRightIcon,
    logoutIcon,
    loginIcon,
];
