import { Button } from '@mui/material';
import React from 'react';
import { FaOptionIcon } from '@europrocurement/l2d-icons';
import { faTimes } from '@fortawesome/pro-duotone-svg-icons';

export type FlexyFilterCleanupProps = {
    onClick: () => void;
};

const FlexyFilterCleanup: React.FunctionComponent<FlexyFilterCleanupProps> = function (props) {
    const { onClick } = props;

    return (
        <Button
            color="inherit"
            sx={{
                fontSize: '14px',
                fontWeight: '500',
                lineHeight: '25.2px',
                textAlign: 'left',
                display: 'flex',
                alignItems: 'center',
                padding: '4px 8px 4px 8px',
            }}
            onClick={onClick}
        >
            <FaOptionIcon icon={faTimes} />
            Tout supprimer
        </Button>
    );
};

export default FlexyFilterCleanup;
