import React, { useEffect, useState } from 'react';
import { Box, Button, SortDirection, Typography, useTheme } from '@mui/material';
import FlexyMobilePopover from '../FlexyMobilePopover';
import { ColumnDatatable } from '../ColumnDatatable';
import { OrderDatatable, OrdersOpts } from '../DatatableHeader';
import FlexyDatatableMobileSortItem from '../FlexyDatatableMobileSortItem';

export type FlexyDatatableMobileSortProps = {
    anchorEl: null | HTMLElement;
    columns: ColumnDatatable[];
    orderOpts?: OrdersOpts;
    onClose: () => void;
};

const FlexyDatatableMobileSort: React.FunctionComponent<FlexyDatatableMobileSortProps> = function (
    props,
) {
    const { anchorEl, columns, orderOpts, onClose } = props;
    const [preSelected, setPreSelected] = useState<OrderDatatable | undefined>();
    const theme = useTheme();
    const { palette } = theme;
    const btnStyle = {
        padding: '8px 24px',
        borderRadius: '12px',
        fontFamily: 'Inter',
        fontSize: '15px',
        fontWeight: '500',
        lineHeight: '24px',
        textAlign: 'center',
    };

    useEffect(() => {
        if (anchorEl) {
            setPreSelected(orderOpts?.orders[0] || undefined);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [anchorEl]);

    const handleCancel = () => {
        onClose();
    };

    const handleConfirm = () => {
        if (preSelected) {
            orderOpts?.onOrdersChange(preSelected.field, preSelected.value);
        }

        onClose();
    };

    const handleSelectItem = (field: string) => {
        let direction: SortDirection = 'asc';

        if (preSelected && preSelected.field === field) {
            direction = preSelected.value === 'asc' ? 'desc' : 'asc';
        }

        setPreSelected({ field, value: direction });
    };

    const displayedColumns = columns.filter((column) => column.sortable && column.field);

    const heading = (
        <Typography
            variant="l2dcaption"
            component="h2"
            sx={{
                color: palette.text.light,
                padding: '10px 14px 0 14px',
                lineHeight: '22px',
            }}
        >
            Trier par
        </Typography>
    );

    const controls = (
        <Box
            sx={{
                width: '100%',
                padding: '10px 24px',
                display: 'flex',
                justifyContent: 'center',
                gap: '0 16px',
                boxShadow: '1px 1px 15px 0px rgba(0, 0, 0, 0.1)',
                marginTop: 'auto',
            }}
        >
            <Button
                variant="text"
                onClick={handleCancel}
                color="secondary"
                sx={btnStyle}
            >
                Fermer
            </Button>
            <Button
                variant="contained"
                onClick={handleConfirm}
                color="secondary"
                sx={btnStyle}
            >
                Voir les résultats
            </Button>
        </Box>
    );

    return (
        <FlexyMobilePopover
            open={Boolean(anchorEl)}
            anchorEl={anchorEl}
            heading={heading}
            controls={controls}
        >
            <Box sx={{ padding: '10px' }}>
                {displayedColumns.map((column) => {
                    const current = (preSelected?.field &&
                        preSelected.field === column.field) as boolean;
                    const direction = current ? preSelected?.value || 'asc' : 'asc';

                    return (
                        <FlexyDatatableMobileSortItem
                            key={column.field}
                            current={current}
                            label={column.label}
                            field={column.field as string}
                            direction={direction}
                            onClick={handleSelectItem}
                        />
                    );
                })}
            </Box>
        </FlexyMobilePopover>
    );
};

export default FlexyDatatableMobileSort;
