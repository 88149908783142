/* istanbul ignore file */

import * as React from 'react';
import { ThemeProvider, CssBaseline } from '@mui/material';
import { CenterCircularProgress, ModalProvider } from '@europrocurement/flexy-components';
import { UseKeycloakService } from '@europrocurement/l2d-keycloak';
import { useRoutes } from 'react-router-dom';
// import { ThemeSettings } from '@europrocurement/flexy-components/src/hooks';
import { useSelector } from 'react-redux';
import { customizerSelector } from '@europrocurement/l2d-modules';
import { setXIdSociete } from '@europrocurement/l2d-domain';
import Router from './router/router';
import L2DTheme from './Theme';
import { useAppDispatch } from './redux/types';

const App = function () {
    const routing = useRoutes(Router);
    const theme = L2DTheme();
    const kc = UseKeycloakService();
    const { xIdSociete } = useSelector(customizerSelector);

    const isApplicationReady = () => kc.isInitialized(); // && allOptionsAreLoaded;

    const dispatch = useAppDispatch();

    React.useEffect(() => {
        if (xIdSociete !== 12) {
            dispatch(setXIdSociete(12));
        }
    });

    return (
        <ModalProvider>
            <ThemeProvider theme={theme}>
                {isApplicationReady() ? routing : <CenterCircularProgress />}
                <CssBaseline />
            </ThemeProvider>
        </ModalProvider>
    );
};

export default App;
