import React from 'react';
import { LazyBreadcrumb } from '@europrocurement/flexy-components';
import { Container } from '@mui/material';

const Accueil = function () {
    return (
        <Container maxWidth="xl">
            <LazyBreadcrumb path={[{ path: 'Accueil', link: '/accueil' }, 'Dashboard']} />
        </Container>
    );
};
export default Accueil;
