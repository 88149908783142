import React from 'react';
import { FormControlLabel, useTheme } from '@mui/material';
import { Checkbox } from '@europrocurement/flexy-components';

export type SelectFormalitySearchResultProps = {
    label: string;
    checked: boolean;
    onChange: (checked: boolean) => void;
};

const SelectFormalitySearchResult: React.FunctionComponent<SelectFormalitySearchResultProps> =
    function (props) {
        const { label, checked, onChange } = props;
        const theme = useTheme();
        const { palette } = theme;

        const handleChange = () => {
            onChange(!checked);
        };

        return (
            <FormControlLabel
                sx={{
                    padding: '10px 14px',
                    display: 'block',
                    width: '100%',
                    margin: 0,
                    backgroundColor: checked ? palette.primary.light : 'transparent',
                    borderRadius: '12px',
                    '&:hover': { backgroundColor: palette.primary.light },
                    fontFamily: 'Inter',
                    fontSize: '15px',
                    fontWeight: '500',
                    lineHeight: '24px',
                }}
                control={
                    <Checkbox
                        sx={{ padding: 0, marginRight: '10px' }}
                        onChange={handleChange}
                        checked={checked}
                    />
                }
                label={label}
            />
        );
    };

export default SelectFormalitySearchResult;
