import React from 'react';
import { Tooltip, Typography, useTheme } from '@mui/material';
import { NestedDossierFormaliteOpenSearch } from '@europrocurement/l2d-domain';

export type DossierFormaliteLabelProps = {
    formalites: Array<NestedDossierFormaliteOpenSearch>;
};

const DossierFormaliteLabel: React.FunctionComponent<DossierFormaliteLabelProps> = function (
    props,
) {
    const { formalites } = props;
    const theme = useTheme();
    const { palette } = theme;
    const formaliteLabel =
        formalites?.slice(0, 1).map((formalite) => formalite?.libelle as string) || '';
    const formaliteOthersCount = formalites?.slice(1).length || 0;
    const formaliteOthersLabels =
        formalites
            ?.slice(1)
            .map((formalite) => formalite?.libelle as string)
            .join(', ') || '';

    return (
        <Typography
            variant="l2dtableCell"
            color="text.gris70"
        >
            {formaliteLabel}
            {formaliteOthersCount > 0 && (
                <Tooltip title={formaliteOthersLabels}>
                    <Typography
                        sx={{ fontSize: 'inherit', color: palette.grey[500] }}
                        component="span"
                    >
                        {formaliteOthersCount === 1 && ' + 1 autre'}
                        {formaliteOthersCount > 1 && ` + ${formaliteOthersCount} autres`}
                    </Typography>
                </Tooltip>
            )}
        </Typography>
    );
};

export default DossierFormaliteLabel;
