import React, { useCallback, useMemo, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { Box, Button, Card, CardContent, CardHeader, Grid, Stack, Typography } from '@mui/material';

import {
    APIENV,
    APIS_ENUM,
    APIS_ENV_SELECTED,
    APIS_KC_ENV_SELECTED,
    ENV,
    ENV_API,
    SET_ALL_ENVS,
} from '@europrocurement/l2d-domain';
import { BooleanBadge, CustomDivider, DataCard } from '@europrocurement/flexy-components';

import { FaOptionIcon, devIcon } from '@europrocurement/l2d-icons';
import { customizerSelector } from '../../../../redux/Customizer';

export type PanelEnvironmentType = {
    version: string;
};

const PanelEnvironment: React.FunctionComponent<PanelEnvironmentType> = function ({ version }) {
    const customizer = useSelector(customizerSelector);

    const dispatch = useDispatch();

    const initialEnvsState = useMemo(
        () => ({
            apis: customizer.api,
            keycloak: customizer.env,
        }),
        [customizer.api, customizer.env],
    );
    const [envs, setEnvs] = useState<APIS_KC_ENV_SELECTED>(initialEnvsState);

    const setAllEnvs = useCallback(
        (newEnvs?: APIS_KC_ENV_SELECTED) => {
            dispatch({
                type: SET_ALL_ENVS,
                payload: newEnvs || envs,
            });
        },
        [dispatch, envs],
    );

    return (
        <Grid
            container
            spacing={1}
        >
            <Grid
                item
                lg={3}
                md={3}
                xs={12}
            >
                <DataCard
                    title="Version de l'application"
                    color="primary"
                    icon={<FaOptionIcon {...devIcon.props} />}
                >
                    <h2>{version}</h2>
                </DataCard>
            </Grid>

            <Grid
                item
                lg={9}
                md={9}
                xs={12}
            >
                <Card>
                    <CardHeader title={<Typography fontWeight="bold">Environnements</Typography>} />
                    <CustomDivider />
                    <Typography
                        fontWeight="bold"
                        variant="h3"
                    >
                        Apis
                    </Typography>
                    <CardContent>
                        <Grid
                            container
                            spacing={2}
                        >
                            <Grid
                                item
                                xs={12}
                                sm={12}
                                md={6}
                                lg={4}
                            >
                                <Typography fontWeight="bold">Keycloak</Typography>
                                <Box display="flex">
                                    {Object.keys(APIENV.keycloak).map((env) => (
                                        <BooleanBadge
                                            key={env}
                                            label={env}
                                            icon
                                            toggleAction={() => {
                                                setEnvs((oldEnvs: APIS_KC_ENV_SELECTED) => ({
                                                    apis: {
                                                        ...oldEnvs.apis,
                                                    },
                                                    keycloak: env as ENV,
                                                }));
                                            }}
                                            value={envs.keycloak === env}
                                        />
                                    ))}
                                </Box>
                            </Grid>

                            {Object.values(APIS_ENUM).map((api) => (
                                <Grid
                                    item
                                    xs={12}
                                    sm={12}
                                    md={6}
                                    lg={4}
                                    key={api}
                                >
                                    <Box>
                                        <Typography fontWeight="bold">Api {api}</Typography>
                                        <Box display="flex">
                                            {Object.keys(APIENV.apis[api]).map((env) => (
                                                <BooleanBadge
                                                    key={env}
                                                    label={env}
                                                    icon
                                                    toggleAction={() => {
                                                        setEnvs(
                                                            (oldEnvs: APIS_KC_ENV_SELECTED) => ({
                                                                apis: {
                                                                    ...oldEnvs.apis,
                                                                    [api as APIS_ENUM]:
                                                                        env as ENV_API,
                                                                },
                                                                keycloak: oldEnvs.keycloak,
                                                            }),
                                                        );
                                                    }}
                                                    value={
                                                        envs.apis &&
                                                        envs.apis[api] &&
                                                        envs.apis[api] === env
                                                    }
                                                />
                                            ))}
                                        </Box>
                                    </Box>
                                </Grid>
                            ))}
                        </Grid>
                        <CustomDivider />

                        <Typography
                            fontWeight="bold"
                            variant="h3"
                        >
                            Tous
                        </Typography>

                        <Box display="flex">
                            {Object.keys(APIENV.keycloak).map((env: ENV) => (
                                <BooleanBadge
                                    sx={{ lineHeight: '1.5' }}
                                    key={env}
                                    label={env}
                                    icon
                                    toggleAction={() => {
                                        const values = Object.values(APIS_ENUM);

                                        const newApis: APIS_ENV_SELECTED = { ...envs.apis };

                                        values.forEach((key: APIS_ENUM) => {
                                            newApis[key] = env;
                                        });

                                        setAllEnvs({
                                            apis: {
                                                ...newApis,
                                            },
                                            keycloak: env as ENV,
                                        });
                                    }}
                                    value={envs.keycloak === env}
                                />
                            ))}
                        </Box>

                        <Stack
                            display="flex"
                            flexDirection="row"
                            justifyContent="start"
                            alignItems="center"
                            gap={1}
                            sx={{ marginTop: '15px' }}
                        >
                            <Button
                                variant="contained"
                                color="error"
                                onClick={() => {
                                    setEnvs(initialEnvsState);
                                }}
                            >
                                Annuler
                            </Button>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={() => {
                                    setAllEnvs();
                                }}
                            >
                                Appliquer
                            </Button>
                        </Stack>
                    </CardContent>
                </Card>
            </Grid>
        </Grid>
    );
};

export default PanelEnvironment;
