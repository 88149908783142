import React from 'react';

import {
    Card,
    Dialog,
    DialogActions,
    DialogActionsProps,
    DialogProps,
    DialogTitle,
    DialogTitleProps,
    IconButton,
    PaperProps,
} from '@mui/material';

// eslint-disable-next-line import/no-named-default
import { default as CloseIcon } from '@mui/icons-material/Close';
// eslint-disable-next-line import/no-named-default
import { default as MoreHorizIcon } from '@mui/icons-material/MoreHoriz';
import { Box } from '@mui/system';
import Draggable from 'react-draggable';
import Scrollbar from '../Scrollbar';

export type ModalizerProps = DialogProps & {
    open: DialogProps['open'];
    onClose: () => void;
    children: DialogProps['children'];
    modalTitle?: DialogTitleProps['children'];
    modalActions?: DialogActionsProps['children'];
    isDraggable?: boolean;
};

const PaperComponent = function ({ sx, ...props }: PaperProps) {
    return (
        <Card
            {...props}
            sx={{ padding: '25px 50px 25px 50px', ...sx }}
        />
    );
};

const DraggablePaperComponent = function (props: PaperProps) {
    return (
        <Draggable
            handle="#draggable-dialog-title"
            cancel='[class*="MuiDialogContent-root"]'
        >
            <PaperComponent {...props} />
        </Draggable>
    );
};

const Modalizer: React.FunctionComponent<ModalizerProps> = function ({
    open,
    onClose,
    modalActions,
    modalTitle,
    children,
    isDraggable = false,
    ...props
}: ModalizerProps) {
    return (
        <Dialog
            open={open}
            onClose={onClose}
            PaperComponent={isDraggable ? DraggablePaperComponent : PaperComponent}
            sx={{ margin: 'auto', maxWidth: '3000px' }}
            {...props}
        >
            {isDraggable ? (
                <Box
                    id="draggable-dialog-title"
                    sx={{
                        position: 'absolute',
                        width: '100%',
                        backgroundColor: 'rgba(0, 24, 56, 0.1)',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        cursor: 'move',
                    }}
                >
                    <MoreHorizIcon
                        sx={{
                            fontSize: '15px',
                            color: 'rgba(0, 24, 56, 0.5)',
                        }}
                    />
                </Box>
            ) : null}

            {onClose && (
                <Box sx={{ position: 'absolute', right: '10px', top: '0px' }}>
                    <IconButton
                        color="inherit"
                        onClick={onClose}
                        aria-label="close"
                        sx={{ margin: '10px' }}
                    >
                        <CloseIcon />
                    </IconButton>
                </Box>
            )}

            {modalTitle ? (
                <DialogTitle sx={{ textAlign: 'center', fontWeight: 'bold' }}>
                    {' '}
                    {modalTitle}{' '}
                </DialogTitle>
            ) : null}
            <Scrollbar style={{ paddingRight: '50px', marginRight: '-50px', marginTop: '30px' }}>
                {children}
            </Scrollbar>

            {modalActions && <DialogActions> {modalActions} </DialogActions>}
        </Dialog>
    );
};

export default Modalizer;
