import {
    SliceFactory,
    FetchCollectionData,
    FetchItemDataType,
    ApiCollectionResponse,
} from '@europrocurement/l2d-redux-utils';
import {
    ConfigurationParameters as TiersConfigurationParameters,
    Configuration,
    CategorieSocioProfessionnelleApi,
} from '../../../openApi/ApiTiers';
import { CSP_OPTIONS_SLICE_NAME, TIERS_REDUCER_NAME } from '../constants';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type CspApiObject = any & Record<string, unknown>;

export type CspOptions = CspApiObject;

const mapperCspOptions = function (cspOptions: CspApiObject) {
    return {
        ...cspOptions,
    } as CspOptions;
};

export function createCspOptionsSlice(configuration: TiersConfigurationParameters) {
    const conf = new Configuration(configuration);
    const api = new CategorieSocioProfessionnelleApi(conf);

    const fetchCspOptionsCollection: FetchCollectionData<CspApiObject> = ({
        pagination,
        xIdSociete,
    }) =>
        api
            .getListeCspCategorieSocioProfessionnelleCollection({
                xIdSociete,
                page: pagination.page + 1,
            })
            .then((res) => res.data)
            .catch((error) => {
                console.error(error);
            }) as Promise<ApiCollectionResponse<CspApiObject>>;

    const fetchCspOptionsItem: FetchItemDataType<CspApiObject> = ({ idItem, xIdSociete }) =>
        api
            .getCspCategorieSocioProfessionnelleItem({
                id: String(idItem),
                xIdSociete,
            })
            .then((res) => res.data) as Promise<CspApiObject>;

    const { slice: cspOptionsSlice, dataSourcesThunks: cspOptionsDataSourcesThunks } =
        SliceFactory.createSlice<CspApiObject, CspOptions>(
            CSP_OPTIONS_SLICE_NAME,
            TIERS_REDUCER_NAME,
            [],
            fetchCspOptionsCollection,
            fetchCspOptionsItem,
            mapperCspOptions,
        );

    return { cspOptionsSlice, cspOptionsDataSourcesThunks };
}
