import React, { MouseEventHandler, useCallback, useMemo, useState } from 'react';

import { useSnackbar } from 'notistack';
import { faQuestion } from '@fortawesome/pro-solid-svg-icons';
import { IconButton, IconButtonTypeMap, Tooltip, TooltipProps } from '@mui/material';
import { FaOptionIcon, FaOptionIconProps, NamedIconsType } from '@europrocurement/l2d-icons';
import { handleError } from '@europrocurement/l2d-utils';

export type FlexyIconButtonProps = {
    icon?: NamedIconsType;
    iconOverwriteProps?: Omit<FaOptionIconProps, 'icon'>;
    buttonOverwriteProps?: IconButtonTypeMap<object, 'button'>['props'];
    tooltipOverwriteProps?: Omit<TooltipProps, 'children'>;
    displayTooltip?: boolean;
    onClick?: MouseEventHandler<HTMLButtonElement>;
};

const FlexyIconButton: React.FunctionComponent<FlexyIconButtonProps> = function ({
    iconOverwriteProps,
    buttonOverwriteProps,
    tooltipOverwriteProps,
    icon = {
        name: 'undefined',
        displayName: 'Non défini',
        props: { icon: faQuestion },
    },
    displayTooltip = false,
    onClick = () => {},
}: FlexyIconButtonProps) {
    const { enqueueSnackbar } = useSnackbar();

    const [errorTriggered, setErrorTriggered] = useState<boolean>(false);

    const propsForError = useMemo<Omit<FaOptionIconProps, 'icon'>>(
        () =>
            errorTriggered
                ? {
                      iconProps: {
                          shake: true,
                          fade: true,
                      },
                      color: 'error',
                  }
                : {},
        [errorTriggered],
    );

    const triggerShortError = useCallback(() => {
        setErrorTriggered(true);
        setTimeout(() => {
            setErrorTriggered(false);
        }, 500);
    }, []);

    const errorFeedback = useCallback(
        (error: unknown) => {
            const uniformError = handleError({ error });

            enqueueSnackbar(uniformError.message, {
                variant: 'error',
            });

            triggerShortError();
        },
        [enqueueSnackbar, triggerShortError],
    );

    return (
        <Tooltip
            title={displayTooltip ? icon.displayName : undefined}
            {...tooltipOverwriteProps}
        >
            <IconButton
                data-testid="test-id-FlexyIconButton"
                aria-label={`icon-button-${icon.name}`}
                size="small"
                onClick={(event) => {
                    try {
                        onClick(event);
                    } catch (error) {
                        errorFeedback(error);
                    }
                }}
                {...buttonOverwriteProps}
            >
                <FaOptionIcon
                    {...icon?.props}
                    {...iconOverwriteProps}
                    {...propsForError}
                />
            </IconButton>
        </Tooltip>
    );
};

export default FlexyIconButton;
