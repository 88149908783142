import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { TIERS_REDUCER_NAME } from './constants';
import { buildTiersRedux } from './tiersReducer';

const { reducer } = buildTiersRedux({});

export const tiersReducer = combineReducers({
    [TIERS_REDUCER_NAME]: reducer,
});

export const tiersStore = configureStore({
    reducer: tiersReducer,
});

export type TiersReducerType = typeof tiersReducer;
export type TiersStateType = ReturnType<typeof tiersStore.getState>;
export type TiersAppDispatch = typeof tiersStore.dispatch;
