import {
    CustomItemFormStructure,
    FormObject,
    FormStructure,
} from '@europrocurement/flexy-form/components';
import { FieldValues, UseFormReturn } from 'react-hook-form';
import { jsonSubForm } from '../types/formTypes';
import jsonSubformArrayToStructure from './jsonSubformArrayToStructure';
// eslint-disable-next-line import/no-cycle
import jsonPageSectionToStructure from './jsonPageSectionToStructure';
import { checkFieldValue } from '../functions';

const jsonSubformToStructure: (
    subForm: jsonSubForm,
    customItemRenderer: CustomItemFormStructure['renderField'],
) => FormStructure = (subform, customItemRenderer) => {
    const { minlength = 0, maxlength = Infinity, rules, sections, ...base } = subform;

    if (!(minlength === 1 && maxlength === 1)) {
        return jsonSubformArrayToStructure(subform);
    }

    const subForm: FormStructure = {
        ...base,
        type: 'subform',
        defaultLength: 1,
        minLength: 1,
        maxLength: 1,
        structure: sections.map((section) =>
            jsonPageSectionToStructure(section, customItemRenderer),
        ),
    };

    // comportement sur rules display
    if (rules && rules.display) {
        if (rules.display.fieldIsEqualTo) {
            const fieldNameToWatch = rules.display.fieldIsEqualTo.field;
            const valueToWatch = rules.display.fieldIsEqualTo.value;

            subForm.display = (formContext: UseFormReturn<FieldValues, FormObject>) => {
                const valueWatch: number = parseInt(
                    formContext.watch(fieldNameToWatch as string)?.value,
                    10,
                );
                return Array.isArray(valueToWatch)
                    ? valueToWatch.includes(valueWatch)
                    : valueWatch === valueToWatch;
            };
        }
        if (rules.display.fieldIsChecked) {
            const fieldNameToWatch = rules.display.fieldIsChecked;

            subForm.display = (formContext: UseFormReturn<FieldValues, FormObject>) =>
                checkFieldValue(formContext, true, fieldNameToWatch);
        }
        if (rules.display.fieldIsNotChecked) {
            const fieldNameToWatch = rules.display.fieldIsNotChecked;

            subForm.display = (formContext: UseFormReturn<FieldValues, FormObject>) =>
                checkFieldValue(formContext, false, fieldNameToWatch);
        }
    }

    return subForm;
};

export default jsonSubformToStructure;
