import React, { forwardRef, useState, useEffect } from 'react';
import { Box, useTheme } from '@mui/material';
import {
    DateRange,
    DateRangePicker,
} from '@europrocurement/flexy-components/components/molecules/DateRangePicker';
import { defaultsRange } from '@europrocurement/flexy-components';
import { fr } from 'date-fns/locale';
import { FlexyFilterValue, FlexyFilterWidgetProps } from '../DatatableHeader';
import './FlexyDateRangeFilter.css';

const FlexyDateRangeFilter: React.FunctionComponent = forwardRef(
    (props: FlexyFilterWidgetProps) => {
        const { value, onChange } = props;
        const [inputValue, setInputValue] = useState<FlexyFilterValue>(value);
        useEffect(() => {
            setInputValue(value);
        }, [value]);

        const handleChange = (dr: DateRange) => {
            setInputValue(dr);
            onChange(dr);
        };

        const theme = useTheme();

        return (
            <Box className="flexy-date-range-filter">
                <DateRangePicker
                    open
                    toggle={() => {}}
                    definedRanges={defaultsRange}
                    onChange={handleChange}
                    initialDateRange={inputValue as DateRange}
                    defaultColor={theme.palette.text.primary}
                    selectedColor={theme.palette.text.primary}
                    defaultBgColor={theme.palette.background.default}
                    selectedBgColor={theme.palette.primary.light}
                    locale={fr}
                />
            </Box>
        );
    },
);

export default FlexyDateRangeFilter;
