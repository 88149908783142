import {
    selectedPrescripteursSelector,
    useTiersServiceSelector,
} from '@europrocurement/l2d-domain';
import { ParcoursFormaliteProvider } from '@europrocurement/l2d-modules/modules/ParcoursFormalite/provider';
import StepRecap from '@europrocurement/l2d-modules/modules/ParcoursFormalite/steps/StepRecap';
import React from 'react';

const PanelRecap: React.FunctionComponent = function () {
    const prescripteur = useTiersServiceSelector(selectedPrescripteursSelector);
    console.log(prescripteur);

    // const prescriber = useAppSelector(selectedPrescripteursSelector);
    // console.log(prescriber);

    return prescripteur ? (
        <ParcoursFormaliteProvider
            prescripteur={prescripteur}
            defaultCollectionId={3}
            defaultDossierId={3}
            defaultCurrentCatalogId={2}
        >
            <StepRecap />
        </ParcoursFormaliteProvider>
    ) : null;
};

export default PanelRecap;
