import {
    SliceFactory,
    FetchCollectionData,
    FetchItemDataType,
    ApiCollectionResponse,
} from '@europrocurement/l2d-redux-utils';
import {
    ConfigurationParameters as OffreConfigurationParameters,
    Configuration,
    OptionValueApi,
    OptionValueOptionValueRead,
} from '../../../openApi/ApiOffre';
import { OFFER_OPTION_VALUES_SLICE_NAME, OFFRE_REDUCER_NAME } from '../constants';

export type OfferOptionValueApiObject = OptionValueOptionValueRead & Record<string, unknown>;

export type OfferOptionValues = OfferOptionValueApiObject;

const mapperOfferOptions = function (offerOptionValues: OfferOptionValueApiObject) {
    return {
        ...offerOptionValues,
    } as OfferOptionValues;
};

export function createOfferOptionValuesSlice(configuration: OffreConfigurationParameters) {
    const conf = new Configuration(configuration);
    const api = new OptionValueApi(conf);

    const fetchOfferOptionsCollection: FetchCollectionData<OfferOptionValueApiObject> = ({
        pagination: { page, itemsPerPage },
        xIdSociete,
    }) =>
        api
            .apiOptionValuesGetCollection({
                xIdSociete,
                page: page + 1,
                itemsPerPage,
            })
            .then((res) => res.data)
            .catch((error) => {
                console.error(error);
            }) as Promise<ApiCollectionResponse<OfferOptionValueApiObject>>;

    const fetchOfferOptionsItem: FetchItemDataType<OfferOptionValueApiObject> = ({
        idItem,
        // pagination,
        xIdSociete,
    }) =>
        api
            .apiOptionValuesIdGet({ id: String(idItem), xIdSociete })
            .then((res) => res.data) as Promise<OfferOptionValueApiObject>;

    const { slice: offerOptionValuesSlice, dataSourcesThunks: offerOptionValuesDataSourcesThunks } =
        SliceFactory.createSlice<OfferOptionValueApiObject, OfferOptionValues>(
            OFFER_OPTION_VALUES_SLICE_NAME,
            OFFRE_REDUCER_NAME,
            [],
            fetchOfferOptionsCollection,
            fetchOfferOptionsItem,
            mapperOfferOptions,
        );

    return { offerOptionValuesSlice, offerOptionValuesDataSourcesThunks };
}
