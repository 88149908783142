import React, { forwardRef, useEffect, useState } from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { Button, useTheme } from '@mui/material';
import usePlaceAutocomplete, {
    AddressFormatted,
    PlaceType,
} from '../../hooks/usePlaceAutocomplete';

export type GoogleMapAutocompleteProps = {
    inputValue: string;
    placeholder: string;
    onChange: (formattedAddress: AddressFormatted) => void;
    onKeyUp: (e: Event) => void;
    minLengthDebounce?: number;
    hasError?: boolean;
};

const GoogleMapAutocomplete: React.FunctionComponent<GoogleMapAutocompleteProps> = forwardRef(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    (props: GoogleMapAutocompleteProps, ref) => {
        const {
            inputValue,
            placeholder,
            onChange,
            onKeyUp,
            minLengthDebounce = 3,
            hasError = false,
        } = props;
        const [value, setValue] = useState<PlaceType | string | null>(null);
        const [searchTerm, setSearchTerm] = useState('');
        const [options, setOptions] = useState<readonly PlaceType[]>([]);
        const [loading, setLoading] = useState<boolean>(false);
        const { getPlaceDetail, loadScript, fetchSuggestions, formatAddress } =
            usePlaceAutocomplete();
        const theme = useTheme();
        const { palette } = theme;

        const handleSelectPlace = async (option: PlaceType) => {
            const details = await getPlaceDetail(option.place_id);

            if (onChange) {
                onChange(formatAddress(details));
            }
        };

        if (typeof window !== 'undefined') {
            loadScript();
        }

        useEffect(() => {
            setValue(inputValue);
        }, [inputValue]);

        useEffect(() => {
            if (searchTerm.length < minLengthDebounce) {
                return () => {};
            }

            let active = true;
            setLoading(true);

            fetchSuggestions(
                {
                    input: searchTerm,
                    componentRestrictions: { country: 'fr' },
                },
                (results?: readonly PlaceType[]) => {
                    if (active) {
                        let newOptions: readonly PlaceType[] = [];

                        if (results) {
                            newOptions = [...newOptions, ...results];
                        }

                        setOptions(newOptions);
                        setLoading(false);
                    }
                },
            );

            return () => {
                active = false;
            };
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [value, searchTerm, fetchSuggestions]);

        return (
            <Autocomplete
                freeSolo
                getOptionLabel={(option) =>
                    typeof option === 'string' ? option : option.description
                }
                filterOptions={(x) => x}
                options={options}
                autoComplete
                includeInputInList
                filterSelectedOptions
                value={value}
                loading={loading}
                loadingText="Chargement..."
                clearIcon={null}
                noOptionsText="Aucun résultat pour votre recherche"
                onChange={(
                    event: React.FormEvent<HTMLInputElement>,
                    newValue: PlaceType | null,
                ) => {
                    if (newValue) {
                        setOptions(newValue ? [newValue, ...options] : options);
                        setValue(newValue);
                    } else {
                        setOptions([]);
                        setValue(null);
                    }
                }}
                onInputChange={(event, newSearchTerm) => {
                    setSearchTerm(newSearchTerm);
                }}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        placeholder={placeholder}
                        fullWidth
                        error={hasError}
                        onKeyUp={onKeyUp}
                    />
                )}
                renderOption={(childProps, option) => {
                    const { key, ...optionProps } = childProps;
                    const label = option.description;

                    return (
                        <li
                            key={key}
                            {...optionProps}
                        >
                            <Button
                                sx={{ textAlign: 'left', color: palette.text.primary }}
                                onClick={() => handleSelectPlace(option)}
                            >
                                {label}
                            </Button>
                        </li>
                    );
                }}
            />
        );
    },
);

export default GoogleMapAutocomplete;
