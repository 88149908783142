import React from 'react';
import { Box } from '@mui/system';
import { jsonForm } from '@europrocurement/flexy-form/utils/types/formTypes';
import { CustomOptionResolvers } from '@europrocurement/flexy-form';

import { useTheme } from '@mui/material';
// eslint-disable-next-line import/no-cycle
import JsonForm from '../../JsonForm';

export type dirigeantModalProps = {
    onChange: (currentPage: number, pageLength: number, data?: Record<string, unknown>) => void;
    json: jsonForm;
    defaultValues: Record<string, unknown>;
    customOptionResolvers: CustomOptionResolvers;
};

const DirigeantModal: React.FunctionComponent<dirigeantModalProps> = function ({
    onChange,
    json,
    defaultValues,
    customOptionResolvers,
}: dirigeantModalProps) {
    const theme = useTheme();

    return (
        <Box sx={{ width: '60%', margin: 'auto' }}>
            <JsonForm
                onChange={onChange}
                visibility="FULL"
                debugMode={false}
                json={json as jsonForm}
                startPage={0}
                defaultValues={defaultValues}
                customOptionResolvers={customOptionResolvers}
                submitLabel="Enregistrer les informations"
                submitStyle={{
                    backgroundColor: theme.palette.primary.dark,
                    marginTop: '30px',
                    padding: '20px 25px',
                    fontSize: '1rem',
                    borderRadius: '15px',
                    marginLeft: 'auto',
                    marginRight: 'auto',
                }}
            />
        </Box>
    );
};

export default DirigeantModal;
