import * as React from 'react';
import { SelectSociete } from '@europrocurement/l2d-modules';
import { Container } from '@mui/system';
import SelectSocieteRoot from './views/SelectSocieteRoot';

export const SelectSocieteRouter = {
    path: 'societe',
    element: <SelectSocieteRoot />,
    children: [
        {
            path: 'selection',
            children: [
                {
                    path: '',
                    index: true,
                    element: (
                        <Container maxWidth="md">
                            <SelectSociete onSelect={console.log} />
                        </Container>
                    ),
                },
            ],
        },
    ],
};
