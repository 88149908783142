import React, { useCallback, useEffect } from 'react';
import RecapFormalityShow from '@europrocurement/l2d-modules/modules/ParcoursFormalite/components/RecapFormalityShow';
import { Box, Container } from '@mui/system';
import { useFullLayout } from '@europrocurement/flexy-components/components/templates/FullLayout/FullLayoutContext';
import { Stepper } from '@europrocurement/flexy-components';
import { FichiersDossierJsonldDocumentfileRead } from '@europrocurement/l2d-domain/openApi/ApiFormalite';
import { DossierFormalite, useFormaliteService } from '@europrocurement/l2d-domain';
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { useParcoursFormalite } from '../provider';
import { loadDossier } from './StepDocuments';
import { parcoursFormalitePathResolver } from '../parcoursFormalitePathResolver';

const StepRecap: React.FunctionComponent = function () {
    const snackbar = useSnackbar();

    const { getCurrentDossier, getCurrentCollection } = useParcoursFormalite();
    const { fichiersDossierApi, dossierApi, collectionApi } = useFormaliteService();

    const { setCustomHeader } = useFullLayout();

    const collection = getCurrentCollection();
    const dossier = getCurrentDossier();
    const navigate = useNavigate();

    const [currentDocs, setCurrentDocs] =
        React.useState<Array<FichiersDossierJsonldDocumentfileRead> | null>(null);

    const reloadDossierAndDocs = useCallback(async () => {
        if (dossier) {
            const prom = loadDossier(dossier, fichiersDossierApi);
            return await prom.then((res) => {
                const fichiers = res.data['hydra:member'];
                setCurrentDocs(fichiers);
            });
        }
        return Promise.resolve();
    }, [dossier, fichiersDossierApi]);

    useEffect(() => {
        if (dossier && dossier.id && !currentDocs) {
            reloadDossierAndDocs();
        }
    }, [currentDocs, dossier, fichiersDossierApi, reloadDossierAndDocs]);

    useEffect(() => {
        setCustomHeader(
            <Box sx={{ width: '100%', padding: 'auto' }}>
                <Container maxWidth="md">
                    <Stepper
                        steps={[
                            {
                                name: 'step_company',
                                text: 'Société',
                                progress: 100,
                            },
                            {
                                name: 'step_formality',
                                text: 'Formalité',
                                progress: 100,
                            },
                            {
                                name: 'step_documents',
                                text: 'Documents',
                                progress: 100,
                            },
                            {
                                name: 'step_summary',
                                text: 'Récapitulatif',
                                progress: 15,
                            },
                        ]}
                    />
                </Container>
            </Box>,
        );
        return () => {
            setCustomHeader(null);
        };
    }, [setCustomHeader]);

    const onSubmitHandler = useCallback(
        async (innerDossier: DossierFormalite, refInterne: string | null) => {
            const freshDossier = (await dossierApi.apiDossiersIdGet({ id: `${innerDossier.id}` }))
                .data;
            await dossierApi.apiDossiersIdPut({
                id: `${innerDossier.id}`,
                dossierJsonldDossierUpdate: {
                    ...freshDossier,
                    societeRef: refInterne,
                },
            });

            if (!collection || !collection.id) {
                throw new Error('error in Collection');
            }

            const freshCollection = (
                await collectionApi.apiCollectionsDossiersIdGet({ id: `${collection.id}` })
            ).data;

            if (!freshCollection || !freshCollection.id) {
                throw new Error('missing freshCollection');
            }

            if (!freshCollection.client) {
                throw new Error('missing client');
            }
            if (!freshCollection.prescripteur) {
                throw new Error('missing prescripteur');
            }

            await collectionApi.apiCollectionsDossiersIdValidatePut({
                id: `${freshCollection?.id}`,
                collectionDeDossierJsonldCollectiondossierUpdate: {
                    prescripteur: freshCollection.prescripteur?.replace(
                        '/tiers/tiers',
                        '/formalite/tiers',
                    ),
                    client: freshCollection.client?.replace('/tiers/tiers', '/formalite/tiers'),
                    infoComp: freshCollection.infoComp,
                    libelle: freshCollection.libelle,
                },
            });
            snackbar.enqueueSnackbar('Dossier envoyé !');
            navigate('/dossiers/');
        },
        [collection, collectionApi, dossierApi, navigate, snackbar],
    );

    if (!dossier || !currentDocs) {
        return null;
    }

    return (
        <Container maxWidth="lg">
            <RecapFormalityShow
                onPrevious={() => {
                    navigate(parcoursFormalitePathResolver('stepDocs'));
                }}
                dossier={dossier}
                fichiers={currentDocs}
                onSubmit={onSubmitHandler}
            />
        </Container>
    );
};

export default StepRecap;
