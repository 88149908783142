import { useContext } from 'react';
import {
    FormulaireFormaliteApi,
    FormulaireListeSelectionApi,
} from '@europrocurement/l2d-domain/openApi/ApiFormulaire';
import { FormulaireReducerType } from '@europrocurement/l2d-domain/reducers/formulaires/types';
import L2DApiContext from '../L2DApiContext';

export const useFormulaireService = () => {
    const context = useContext(L2DApiContext);
    if (!context) {
        throw new Error('useL2DApi must be used within a L2DApiProvider');
    }

    if (!context.formulaire) {
        throw new Error('Formulaire service is not registred in L2DApiProvider');
    }
    const { apis, slices, thunks, fetchItems, getItems, reducer } = context.formulaire;

    return {
        formulairesApi: apis.formulairesApi as FormulaireFormaliteApi,
        formulaireListeSelectionApi:
            apis.formulaireListeSelectionApi as FormulaireListeSelectionApi,

        formulaireFormaliteSlice: slices.formulaireFormaliteSlice,
        formulaireFormaliteDataSourcesThunks: thunks.formulaireFormaliteDataSourcesThunks,

        getFormulaireFormalites: fetchItems.getFormulaires,
        selectFormulaires: getItems.selectFormulaires,
        reducer: reducer as FormulaireReducerType,
    };
};
