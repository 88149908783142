import React, { useEffect, useState } from 'react';
import { Box, Button, IconButton, InputAdornment, Typography } from '@mui/material';
import { FaOptionIcon, help } from '@europrocurement/l2d-icons';
import { useTheme } from '@mui/system';
import SearchIcon from '@mui/icons-material/Search';
import FlexyTextField from '../../atoms/FlexyTextField';
import ChoiceItemList, { type ChoiceItemListProps } from '../../molecules/ChoiceItemList';

type FormData = {
    namegroup: string;
    idsSociete: string[];
};
export type ChoiceListProps = {
    title: string;
    description: string;
    submitButtonLabel: string;
    placeholderLabelSearch?: string;
    listChoice: Array<ChoiceItemListProps>;
    multiple: boolean;
    defaultValues: string[];
    componentAdd?: React.ReactNode;
    onSubmit: (data: FormData) => void;
    search: (string: string) => void;
    onChange?: (selectedValues: Array<string>) => void;
};

const ChoiceList: React.FunctionComponent<ChoiceListProps> = function ({
    title,
    description,
    submitButtonLabel,
    placeholderLabelSearch,
    listChoice,
    multiple,
    defaultValues = [],
    componentAdd = null,
    onSubmit,
    search,
    onChange,
}) {
    const [searchValue, setSearchValue] = React.useState<string>('');
    const searchWrapper: React.ChangeEventHandler<HTMLInputElement> = (searchString) => {
        search(searchString.target.value);
        setSearchValue(searchString.target.value);
    };

    const [selectedValues, setSelectedValues] = useState<Array<string>>(defaultValues);

    const handleChange = (value: string) => {
        setSelectedValues((currentUpdatedValues) => {
            let updatedValues: Array<string>;
            if (currentUpdatedValues.includes(value)) {
                updatedValues = currentUpdatedValues.filter((v) => v !== value); // Déselectionne si déjà sélectionné
            } else if (multiple) {
                updatedValues = [...currentUpdatedValues, value]; // ajout multiple
            } else {
                updatedValues = [value]; // ajout simple
            }
            if (onChange) {
                onChange(updatedValues);
            }
            return updatedValues;
        });
    };
    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        onSubmit({ namegroup: 'selectionsociete', idsSociete: selectedValues });
    };
    const theme = useTheme();

    useEffect(() => {
        setSelectedValues(defaultValues);
    }, [defaultValues]);

    return (
        <Box
            sx={{
                width: '100%',
                height: '100%',
            }}
        >
            <form onSubmit={handleSubmit}>
                <Box
                    sx={{
                        width: '100%',
                    }}
                >
                    <Typography
                        className="title"
                        variant="l2dh4"
                        sx={{
                            color: theme.palette.primary.text,
                        }}
                    >
                        {title}
                    </Typography>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            fontSize: '18px',
                            marginTop: '15px',
                            marginBottom: '15px',
                        }}
                    >
                        <Typography
                            variant="l2dlabel"
                            color="text.secondary"
                        >
                            <FaOptionIcon
                                {...help.props}
                                color="text.secondary"
                            />
                        </Typography>

                        <Box
                            fontSize="14px"
                            marginLeft="8px"
                        >
                            <Typography
                                variant="l2dlabel"
                                color="text.secondary"
                            >
                                {description}
                            </Typography>
                        </Box>
                    </Box>
                    <Box sx={{ width: '100%', marginTop: '20px', marginBottom: '10px' }}>
                        <FlexyTextField
                            className="search"
                            variant="outlined"
                            value={searchValue}
                            onChange={searchWrapper}
                            placeholder={placeholderLabelSearch || 'Rechercher'}
                            sx={{ width: '100%' }}
                            inputMode="search"
                            InputProps={{
                                sx: { borderRadius: '12px', backgroundColor: '#ffff' },
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <IconButton>
                                            <SearchIcon />
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </Box>
                </Box>
                <Box />
                <Box
                    className="all-list"
                    sx={[
                        {
                            width: '100%',
                            marginBottom: '8px',
                        },
                    ]}
                >
                    {listChoice.map((choiceList) => (
                        <ChoiceItemList
                            {...choiceList}
                            sx={{ paddingRight: '25px' }}
                            key={choiceList.label}
                            listGroupement={
                                choiceList.listGroupement ? choiceList.listGroupement : []
                            }
                            multiple={multiple}
                            onChange={handleChange}
                            selectedValues={selectedValues}
                        />
                    ))}
                </Box>
                {componentAdd}
                {selectedValues.length !== 0 ? (
                    <Box
                        className="actions"
                        sx={{
                            width: '100%',
                            marginTop: '30px',
                        }}
                    >
                        <Button
                            type="submit"
                            className="submit-btn"
                            variant="contained"
                            color="primary"
                            data-test-id="submit-create-group"
                            disabled={selectedValues.length === 0}
                            sx={{
                                backgroundColor: theme.palette.primary.dark,
                                width: '100%',
                                marginTop: '30px',
                                padding: '15px',
                                fontSize: '1rem',
                                borderRadius: '15px',
                            }}
                        >
                            {submitButtonLabel || 'Continuer'}
                        </Button>
                    </Box>
                ) : null}
            </form>
        </Box>
    );
};

export default ChoiceList;
