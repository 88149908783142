import React from 'react';
import { Box } from '@mui/material';
import {
    FilterDatatable,
    FiltersOpts,
    FlexyFilterValue,
    SerializedFlexyFilterValue,
} from '../DatatableHeader';
import FlexyFilterCleanup from '../FlexyFilterCleanup/FlexyFilterCleanup';
import FlexyFilter from '../FlexyFilter/FlexyFilter';
import useFilter from '../FlexyFilter/useFilter';
import { FlexyFilterProvider } from '../FlexyFilter/FlexyFilterProvider';

export type FieldAndValueFilter = {
    label: string;
    field: string;
    value: string;
};

export type FlexyFiltersListProps = {
    filtersOpts?: FiltersOpts;
};

const FlexyFiltersList: React.FunctionComponent<FlexyFiltersListProps> = function (props) {
    const { filtersOpts } = props;
    const { serializeValue, unserializeValue } = useFilter();
    const activeFilters = filtersOpts?.activeFilters || {};
    let cleanAllBtn = null;

    const handleChange = (filter: FilterDatatable, value: FlexyFilterValue) => {
        if ((value === null || value === '') && filtersOpts?.onFilterDelete) {
            filtersOpts?.onFilterDelete(filter.field);

            return;
        }

        if (filtersOpts?.onFilterChange) {
            filtersOpts.onFilterChange(filter.field, serializeValue(filter, value));
        }
    };

    const handleClear = (filter: FilterDatatable) => {
        filtersOpts?.onFilterDelete(filter.field);
    };

    const handleCleanup = () => {
        filtersOpts?.onFiltersClear();
    };

    const filters = filtersOpts?.filters.map((filter: FilterDatatable) => {
        const value =
            activeFilters[filter.field] !== undefined ? activeFilters[filter.field] : null;

        return (
            <FlexyFilterProvider key={filter.label}>
                <FlexyFilter
                    filter={filter}
                    value={unserializeValue(filter, value as SerializedFlexyFilterValue)}
                    onChange={handleChange}
                    onClear={handleClear}
                />
            </FlexyFilterProvider>
        );
    });

    if (Object.keys(activeFilters).length > 0) {
        cleanAllBtn = <FlexyFilterCleanup onClick={handleCleanup} />;
    }

    return (
        <Box
            sx={{
                display: 'flex',
                gap: '10px 8px',
                alignItems: 'center',
                flexWrap: 'wrap',
                marginBottom: '1em',
            }}
        >
            {filters}
            {cleanAllBtn}
        </Box>
    );
};

export default FlexyFiltersList;
