import * as React from 'react';
import { Grid, GridProps } from '@mui/material';
/* eslint-disable import/no-cycle */
import { FormItemRenderer } from './FormItemRenderer';
import {
    FormStructure,
    FlexyFormInputProps,
    FlexySubFormProps,
    CustomOptionResolvers,
} from '../FlexyForm/FlexyFormTypes';
/* eslint-enable import/no-cycle */

export type FormStructureRendererProps = FlexySubFormProps & {
    basePath: string;
    shouldUnregister?: boolean;
    inputsProps?: FlexyFormInputProps;
    gridProps?: GridProps;
    customOptionResolvers?: CustomOptionResolvers;
};

export const FormStructureRenderer: React.FunctionComponent<FormStructureRendererProps> =
    function ({
        structure,
        basePath,
        shouldUnregister = false,
        inputsProps,
        gridProps,
        customOptionResolvers,
    }: FormStructureRendererProps) {
        return (
            <Grid
                container
                {...gridProps}
            >
                {structure.map((input: FormStructure, index: number) => {
                    const { isDisplayable = true } = input;

                    if (!isDisplayable) {
                        return null;
                    }

                    return (
                        <Grid
                            item
                            key={`kfg-${input.name}-${String(index)}`}
                            xs={input.xs || 12}
                            sm={input.sm}
                            md={input.md}
                            lg={input.lg}
                            sx={input.type === 'hidden' ? { display: 'none' } : {}}
                        >
                            <FormItemRenderer
                                input={input}
                                basePath={basePath}
                                shouldUnregister={shouldUnregister}
                                inputsProps={inputsProps}
                                gridProps={gridProps}
                                customOptionResolvers={customOptionResolvers}
                            />
                        </Grid>
                    );
                })}
            </Grid>
        );
    };

export default FormStructureRenderer;
