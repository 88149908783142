import React from 'react';

import { JsonView, allExpanded, darkStyles } from 'react-json-view-lite';
import { Grid, Card, Button, CardHeader, CardContent, Typography } from '@mui/material';

import { UseKeycloakService } from '@europrocurement/l2d-keycloak';

const PanelKeycloak = function () {
    const kc = UseKeycloakService();

    // eslint-disable-next-line prefer-destructuring
    let tokenParsed: object | undefined = kc.getKc().tokenParsed;
    // https://eu-west-3.console.aws.amazon.com/cloudwatch/home?region=eu-west-3#logsV2:log-groups/log-group/apideploy-qual-107d38c2$3FlogStreamNameFilter$3D

    if (tokenParsed === undefined) {
        tokenParsed = {};
    }

    return (
        <Grid
            container
            spacing={1}
        >
            <Grid
                item
                lg={12}
                md={12}
                xs={12}
            >
                <Card>
                    <CardHeader title={<Typography fontWeight="bold">Logs</Typography>} />
                    <CardContent>
                        <Button
                            sx={{ marginRight: '10px' }}
                            variant="contained"
                            target="_blank"
                            href="https://eu-west-3.console.aws.amazon.com/cloudwatch/home?region=eu-west-3#logsV2:log-groups/log-group/apideploy-qual-107d38c2"
                        >
                            Logs aws
                        </Button>
                        {[
                            'apitiers-qual1',
                            'apiachats-qual1',
                            'apidossiers-qual1',
                            'apial-qual1',
                            'apisoceiete-qual1',
                            'apimail-qual1',
                            'apisiren-qual1',
                            'apiutilisateur-qual1',
                        ].map((str) => (
                            <Button
                                key={str}
                                sx={{ marginRight: '10px' }}
                                variant="contained"
                                target="_blank"
                                href={`https://eu-west-3.console.aws.amazon.com/cloudwatch/home?region=eu-west-3#logsV2:log-groups/log-group/apideploy-qual-107d38c2$3FlogStreamNameFilter$3Dstreaming$252F${str}`}
                            >
                                {str}
                            </Button>
                        ))}
                    </CardContent>
                </Card>
            </Grid>
            <Grid
                item
                lg={7}
                md={6}
                xs={12}
            >
                <Card>
                    <CardHeader
                        title={<Typography fontWeight="bold">Token Keycloak Parsé</Typography>}
                    />
                    <JsonView
                        data={tokenParsed}
                        shouldInitiallyExpand={allExpanded}
                        style={darkStyles}
                    />
                </Card>
            </Grid>
            <Grid
                item
                lg={5}
                md={6}
                xs={12}
            >
                <Card>
                    <CardHeader
                        title={<Typography fontWeight="bold">Token Keycloak JWT</Typography>}
                    />
                    <p style={{ wordWrap: 'break-word' }}>{kc.getKc().token}</p>
                </Card>
            </Grid>
        </Grid>
    );
};

export default PanelKeycloak;
