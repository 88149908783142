import { useFormaliteService } from '@europrocurement/l2d-domain';
import { DossierOpenSearchJsonldOpensearchDossierObjectGroupRead } from '@europrocurement/l2d-domain/openApi/ApiFormalite';

export type DossierObjectGroup = DossierOpenSearchJsonldOpensearchDossierObjectGroupRead &
    Record<string, unknown>;

const useDossierObjectGroup = () => {
    const { dossierOpenSearchApi } = useFormaliteService();
    let searchObjectGroupTimeout: number | undefined;

    const searchDossierObjectGroup = (term: string): Promise<Array<DossierObjectGroup>> =>
        new Promise((resolve, reject) => {
            clearTimeout(searchObjectGroupTimeout);
            const requestParameters = { q: term || undefined };

            searchObjectGroupTimeout = setTimeout(() => {
                dossierOpenSearchApi
                    .apiOpensearchObjectGroupsGetCollection(requestParameters)
                    .then((res) => resolve(res.data['hydra:member'] as Array<DossierObjectGroup>))
                    .catch(() => reject());
            }, 300);
        });

    return { searchDossierObjectGroup };
};

export default useDossierObjectGroup;
