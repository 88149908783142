import { useContext } from 'react';
import { SirenApi } from '@europrocurement/l2d-domain/openApi/ApiSiren';
import L2DApiContext from '../L2DApiContext';

export const useSirenService = () => {
    const context = useContext(L2DApiContext);
    if (!context) {
        throw new Error('useL2DApi must be used within a L2DApiProvider');
    }

    if (!context.siren) {
        throw new Error('Siren service is not registred in L2DApiProvider');
    }
    const { apis } = context.siren;

    return {
        sirenApi: apis.sirenApi as SirenApi,
    };
};
