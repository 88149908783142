import {
    SliceFactory,
    FetchCollectionData,
    FetchItemDataType,
    ApiCollectionResponse,
} from '@europrocurement/l2d-redux-utils';
import {
    ConfigurationParameters as TiersConfigurationParameters,
    Configuration,
    PaysApi,
    // TblPaysJsonldPaysRead,
    TblPaysPaysRead,
} from '../../../openApi/ApiTiers';
import { PAYS_SLICE_NAME, TIERS_REDUCER_NAME } from '../constants';

export type PaysApiObject = TblPaysPaysRead & Record<string, unknown>;

export type Pays = PaysApiObject;

const mapperPays = function (pays: PaysApiObject) {
    return {
        ...pays,
    } as Pays;
};

export function createPaysSlice(configuration: TiersConfigurationParameters) {
    const conf = new Configuration(configuration);
    const api = new PaysApi(conf);

    const fetchPaysCollection: FetchCollectionData<PaysApiObject> = ({ pagination, xIdSociete }) =>
        api
            .getListePaysTblPaysCollection({
                xIdSociete,
                page: pagination.page + 1,
                itemsPerPage: 500,
            })
            .then((res) => res.data)
            .catch((error) => {
                console.error(error);
            }) as Promise<ApiCollectionResponse<PaysApiObject>>;

    const fetchPaysItem: FetchItemDataType<PaysApiObject> = ({ idItem, xIdSociete }) =>
        api
            .getPaysTblPaysItem({
                codeIso2: String(idItem),
                xIdSociete,
            })
            .then((res) => res.data) as Promise<PaysApiObject>;

    const { slice: paysSlice, dataSourcesThunks: paysDataSourcesThunks } = SliceFactory.createSlice<
        PaysApiObject,
        Pays
    >(PAYS_SLICE_NAME, TIERS_REDUCER_NAME, [], fetchPaysCollection, fetchPaysItem, mapperPays);

    return { paysSlice, paysDataSourcesThunks };
}
