import React from 'react';

import { FaOptionIcon } from '@europrocurement/l2d-icons';
import { useTheme } from '@mui/system';
import { Box, Button, Grid, Typography, TypographyProps } from '@mui/material';
import { faArrowLeft } from '@fortawesome/pro-solid-svg-icons';

export type PreviousType = {
    label?: string;
    onClick: () => void;
    color?: TypographyProps['color'];
};

const Previous: React.FunctionComponent<PreviousType> = function ({
    label = 'Précédent',
    onClick,
    color,
}: PreviousType) {
    const theme = useTheme();
    return (
        <Button
            data-testid="test-id-button-previous"
            onClick={onClick}
            sx={{
                marginLeft: '0px',
                paddingLeft: '0px',
                '&:hover': {
                    textDecoration: 'underline',
                    textDecorationColor: color || theme.palette.primary.dark,
                },
            }}
        >
            <Grid
                container
                sx={{
                    marginLeft: '0px',
                }}
            >
                <Grid
                    item
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        width: '100%',
                        paddingLeft: '0px',
                    }}
                >
                    <Box>
                        <FaOptionIcon
                            data-testid="test-id-previous-icone"
                            icon={faArrowLeft}
                            color={color || theme.palette.primary.dark}
                            sx={{
                                fontSize: '13px',
                                position: 'relative',
                                top: '1px',
                                marginRight: '5px',
                            }}
                        />
                    </Box>
                    <Typography
                        className="previous-label"
                        color={color || theme.palette.primary.dark}
                        sx={{
                            fontWeight: 500,
                        }}
                    >
                        {label}
                    </Typography>
                </Grid>
            </Grid>
        </Button>
    );
};

export default Previous;
