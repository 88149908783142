import { forwardRef, useImperativeHandle } from 'react';
import { FlexyFilterWidgetProps } from '../DatatableHeader';

const FlexyBooleanFilter: React.FunctionComponent = forwardRef(
    (props: FlexyFilterWidgetProps, ref) => {
        const { value } = props;

        const getValueOnFocus = () => (value === null ? true : !value);

        useImperativeHandle(ref, () => ({
            getValueOnFocus,
        }));

        return null;
    },
);

export default FlexyBooleanFilter;
