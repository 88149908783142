import {
    SliceFactory,
    FetchCollectionData,
    FetchItemDataType,
    ApiCollectionResponse,
    filtersToElasticQuery,
} from '@europrocurement/l2d-redux-utils';
import {
    ConfigurationParameters,
    Configuration,
    PrescripteursApi,
    TiersTiersJsonldTiersRead,
} from '../../../openApi/ApiTiers';
import { PRESCRIPTEUR_SLICE_NAME, TIERS_REDUCER_NAME } from '../constants';

export type PrescripteurApi = TiersTiersJsonldTiersRead & Record<string, unknown>;

export type Prescripteur = PrescripteurApi;

const mapperPrescripteur = function (prescripteur: PrescripteurApi) {
    return {
        nomDentreprise: prescripteur.raisonSociale,
        ...prescripteur,
    } as Prescripteur;
};

export function createPrescripteurSlice(configuration: ConfigurationParameters) {
    const conf = new Configuration(configuration);
    const api = new PrescripteursApi(conf);

    const fetchPrescripteursCollection: FetchCollectionData<PrescripteurApi> = ({
        search,
        pagination,
        filters,
        orders,
        xIdSociete,
        // rootstate,
        options,
    }) => {
        let aditionalMatch;

        if (search && search !== '') {
            aditionalMatch = [
                {
                    query_string: {
                        query: `**${search}**`,
                        fields: [
                            'relationsSocietes.codeClient', // Tie-code
                            'raisonSociale',
                            'adresses.codePostal',
                            'adresses.ville',
                            'siren',
                            'siret',
                            'contacts.emailsContact.email',
                            'contacts.telephonesContact.telephone',
                            'contacts.nomContact',
                            'contacts.prenomContact',
                        ],
                    },
                },
            ];
        }

        const mesdatas = api
            .getListTiersTiersCollection(
                {
                    xIdSociete,
                    page: pagination.page + 1,
                    itemsPerPage: pagination.itemsPerPage,
                    search: filtersToElasticQuery(
                        {
                            'relationsSocietes.societe.id': xIdSociete,
                            ...filters,
                        },
                        aditionalMatch,
                        orders,
                    ),
                },
                options,
            )
            .then((res) => res.data) as Promise<ApiCollectionResponse<PrescripteurApi>>;

        return mesdatas;
    };

    const fetchPrescripteursItem: FetchItemDataType<PrescripteurApi> = ({
        idItem,
        // search,
        // pagination,
        // filters,
        // orders,
        xIdSociete,
        // rootstate,
        options,
    }) =>
        api
            .getTiersTiersItem(
                {
                    id: `${idItem}`,
                    xIdSociete,
                },
                options,
            )
            .then((res) => res.data) as Promise<PrescripteurApi>;

    const { slice: prescripteurSlice, dataSourcesThunks: prescripteurDataSourcesThunks } =
        SliceFactory.createSlice<PrescripteurApi, Prescripteur>(
            PRESCRIPTEUR_SLICE_NAME,
            TIERS_REDUCER_NAME,
            ['export'],
            fetchPrescripteursCollection,
            fetchPrescripteursItem,
            mapperPrescripteur,
            {
                isPrescripteur: true,
                demo: false,
                deleted: false,
            },
            {},
            [
                // {
                //     field : 'raisonSociale',
                //     value : 'asc'
                // }
            ],
        );

    return { prescripteurSlice, prescripteurDataSourcesThunks };
}
