import React from 'react';

import { Box, Typography } from '@mui/material';

export type PercentWidgetType = {
    label: string;
    values: Array<number>;
};

const PercentWidget: React.FunctionComponent<PercentWidgetType> = function ({
    label,
    values,
}: PercentWidgetType) {
    const total = values.reduce((accumulator, currentValue) => accumulator + currentValue, 0);

    return (
        <Box
            sx={{
                width: '100%',
                backgroundColor: '#0A1A530D',
                padding: '20px 40px 20px 60px',
                border: '1px solid #0A1A5359',
                borderLeftWidth: '6px',
                borderRadius: '4px',
                color: '#0A1A53B2',
                borderColor: 'lime',
            }}
        >
            <Typography>
                {label} : {Number.isNaN(total) ? '0' : total} %
            </Typography>
        </Box>
    );
};

export default PercentWidget;
