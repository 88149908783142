import React from 'react';
import { RouteObject } from 'react-router-dom';
import { dossiersBasePath, listeDossiersPath } from './constants';
import DossiersRoot from './dossiersRoot';
import ListeDossiers from './pages/liste/ListeDossiers';

export const DossiersRouter: RouteObject = {
    path: dossiersBasePath,
    element: <DossiersRoot />,
    children: [
        {
            path: listeDossiersPath,
            element: <ListeDossiers />,
        },
    ],
};
