import * as React from 'react';
import { useNavigate } from 'react-router';

import { UseKeycloakService } from '@europrocurement/l2d-keycloak';
import { TextIconButton } from '@europrocurement/flexy-components/components/atoms';
import { Box } from '@mui/system';

import { loginIcon } from '@europrocurement/l2d-icons';
import { ErrorPage } from '../ErrorPage';

export type UnauthorizedProps = {
    redirect?: boolean;
};

export const Unauthorized: React.FunctionComponent<UnauthorizedProps> = function ({
    redirect = true,
}) {
    const kc = UseKeycloakService();
    const navigate = useNavigate();

    React.useEffect(() => {
        if (kc.isAutenticated() && redirect) {
            navigate('/');
        }
    }, [kc, navigate, redirect]);

    return (
        <ErrorPage
            statusCode={401}
            message="Oups, vous ne semblez pas etre authentifié"
            details="pas Vous devez etre authentifié pour acceder a cette page"
        >
            <Box sx={{ display: 'inline-block', marginLeft: '30px' }}>
                <TextIconButton
                    icon={loginIcon}
                    btnProps={{
                        onClick: () => {
                            kc.getKc().login();
                        },
                    }}
                >
                    Se connecter
                </TextIconButton>
            </Box>
        </ErrorPage>
    );
};
export default Unauthorized;
