import { Box, Card, Grid, Typography } from '@mui/material';
import React from 'react';

import { FaOptionIcon } from '@europrocurement/l2d-icons';
import { faUserTie } from '@fortawesome/pro-duotone-svg-icons';
import { useTheme } from '@mui/system';
import { OptionType } from '../../atoms';

export type BeneficiaireEffectif = {
    fonction: OptionType;
    nom: string;
    prenom: string;
    detention_capital_vote?: {
        check_determination_capital?: boolean;
        check_determination_vote?: boolean;
    };
};

export type BeneficiaireEffectifWidgetCallbackArgs = (
    beneficiaire: BeneficiaireEffectif,
    index: number,
) => void;

export type BeneficiaireEffectifWidgetType = {
    beneficiaire: BeneficiaireEffectif;
    actions?: Array<{
        name: string;
        icon?: React.ReactNode | React.FunctionComponent;
        tooltip?: string;
        text?: string;
        onClick: BeneficiaireEffectifWidgetCallbackArgs;
    }>;
    index?: number;
};

// exemple de style sur DisplayFile

const BeneficiaireEffectifWidget: React.FunctionComponent<BeneficiaireEffectifWidgetType> =
    function ({ beneficiaire, actions, index = -1 }: BeneficiaireEffectifWidgetType) {
        const theme = useTheme();
        return (
            <Card sx={{ padding: '10px', cursor: 'pointer', margin: '15px 0' }}>
                <Grid
                    container
                    sx={{
                        marginLeft: '0px',
                    }}
                >
                    <Grid
                        item
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            width: '100%',
                            paddingLeft: '0px',
                        }}
                    >
                        <Box
                            display="flex"
                            flexDirection="row"
                            gap="10px"
                            sx={{
                                margin: '0 12px 0',
                            }}
                        >
                            <Box
                                data-testid="test-id-status-color-box"
                                sx={{
                                    margin: '0px',
                                    padding: '10px',
                                    borderRadius: '10px',
                                    fontSize: '25px',
                                    maxHeight: '55px',
                                    backgroundColor: 'rgba(66,221,245,0.2)',
                                }}
                            >
                                <FaOptionIcon
                                    data-testid="test-id-mimetype-icone"
                                    icon={faUserTie}
                                    color={theme.palette.primary.main}
                                    sx={{ fontSize: '20px' }}
                                />
                            </Box>
                            <Box sx={{ padding: '0 10px', minWidth: '150px' }}>
                                <Typography
                                    variant="h5"
                                    sx={{ fontWeight: 'bold' }}
                                >
                                    {`${beneficiaire.prenom} ${beneficiaire.nom}`}
                                    <Typography
                                        component="span"
                                        sx={{
                                            color: '#0A1A5380',
                                            fontSize: '0.9em',
                                            fontWeight: '400',
                                        }}
                                    >
                                        &nbsp;{beneficiaire.fonction?.label}
                                    </Typography>
                                </Typography>
                                <Typography
                                    variant="h6"
                                    sx={{
                                        color: '#0A1A5380',
                                        fontWeight: '500',
                                    }}
                                >
                                    {beneficiaire.detention_capital_vote
                                        ?.check_determination_capital
                                        ? 'Capital 25%'
                                        : ''}
                                    {beneficiaire.detention_capital_vote
                                        ?.check_determination_capital &&
                                    beneficiaire.detention_capital_vote?.check_determination_vote
                                        ? ', '
                                        : ''}
                                    {beneficiaire.detention_capital_vote?.check_determination_vote
                                        ? 'Droit de vote 25%'
                                        : ''}
                                </Typography>
                            </Box>
                        </Box>
                        <Box
                            display="flex"
                            flexDirection="row"
                            gap="10px"
                            sx={{
                                margin: '0 0 0 auto',
                            }}
                        >
                            {actions
                                ? actions.map((action, key) => (
                                      <Box
                                          title={action.tooltip}
                                          data-testid={`test-id-${key}-handleClick-button`}
                                          onClick={() => action.onClick(beneficiaire, index)}
                                          role="button"
                                          key={action.name}
                                      >
                                          {action.icon || action.text}{' '}
                                      </Box>
                                  ))
                                : null}
                        </Box>
                    </Grid>
                </Grid>
            </Card>
        );
    };

export default BeneficiaireEffectifWidget;
