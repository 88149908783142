/* tslint:disable */
/* eslint-disable */
/**
 * Europrocurement API Utilisateur
 * API de gestion des utilisateurs
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError, operationServerMap } from './base';

/**
 * 
 * @export
 * @interface ApiDroitGetCollection200Response
 */
export interface ApiDroitGetCollection200Response {
    /**
     * 
     * @type {Array<DroitsJsonldDroitReadCollection>}
     * @memberof ApiDroitGetCollection200Response
     */
    'hydra:member': Array<DroitsJsonldDroitReadCollection>;
    /**
     * 
     * @type {number}
     * @memberof ApiDroitGetCollection200Response
     */
    'hydra:totalItems'?: number;
    /**
     * 
     * @type {ApiDroitGetCollection200ResponseHydraView}
     * @memberof ApiDroitGetCollection200Response
     */
    'hydra:view'?: ApiDroitGetCollection200ResponseHydraView;
    /**
     * 
     * @type {ApiDroitGetCollection200ResponseHydraSearch}
     * @memberof ApiDroitGetCollection200Response
     */
    'hydra:search'?: ApiDroitGetCollection200ResponseHydraSearch;
}
/**
 * 
 * @export
 * @interface ApiDroitGetCollection200ResponseHydraSearch
 */
export interface ApiDroitGetCollection200ResponseHydraSearch {
    /**
     * 
     * @type {string}
     * @memberof ApiDroitGetCollection200ResponseHydraSearch
     */
    '@type'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiDroitGetCollection200ResponseHydraSearch
     */
    'hydra:template'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiDroitGetCollection200ResponseHydraSearch
     */
    'hydra:variableRepresentation'?: string;
    /**
     * 
     * @type {Array<ApiDroitGetCollection200ResponseHydraSearchHydraMappingInner>}
     * @memberof ApiDroitGetCollection200ResponseHydraSearch
     */
    'hydra:mapping'?: Array<ApiDroitGetCollection200ResponseHydraSearchHydraMappingInner>;
}
/**
 * 
 * @export
 * @interface ApiDroitGetCollection200ResponseHydraSearchHydraMappingInner
 */
export interface ApiDroitGetCollection200ResponseHydraSearchHydraMappingInner {
    /**
     * 
     * @type {string}
     * @memberof ApiDroitGetCollection200ResponseHydraSearchHydraMappingInner
     */
    '@type'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiDroitGetCollection200ResponseHydraSearchHydraMappingInner
     */
    'variable'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiDroitGetCollection200ResponseHydraSearchHydraMappingInner
     */
    'property'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ApiDroitGetCollection200ResponseHydraSearchHydraMappingInner
     */
    'required'?: boolean;
}
/**
 * 
 * @export
 * @interface ApiDroitGetCollection200ResponseHydraView
 */
export interface ApiDroitGetCollection200ResponseHydraView {
    /**
     * 
     * @type {string}
     * @memberof ApiDroitGetCollection200ResponseHydraView
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiDroitGetCollection200ResponseHydraView
     */
    '@type'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiDroitGetCollection200ResponseHydraView
     */
    'hydra:first'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiDroitGetCollection200ResponseHydraView
     */
    'hydra:last'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiDroitGetCollection200ResponseHydraView
     */
    'hydra:previous'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiDroitGetCollection200ResponseHydraView
     */
    'hydra:next'?: string;
}
/**
 * 
 * @export
 * @interface ApiDroitGetCollection401Response
 */
export interface ApiDroitGetCollection401Response {
    /**
     * 
     * @type {string}
     * @memberof ApiDroitGetCollection401Response
     */
    'message'?: string;
}
/**
 * 
 * @export
 * @interface ApiDroitGetCollection403Response
 */
export interface ApiDroitGetCollection403Response {
    /**
     * 
     * @type {string}
     * @memberof ApiDroitGetCollection403Response
     */
    'error'?: string;
    /**
     * 
     * @type {ApiDroitGetCollection403ResponseException}
     * @memberof ApiDroitGetCollection403Response
     */
    'exception'?: ApiDroitGetCollection403ResponseException;
}
/**
 * 
 * @export
 * @interface ApiDroitGetCollection403ResponseException
 */
export interface ApiDroitGetCollection403ResponseException {
    /**
     * 
     * @type {string}
     * @memberof ApiDroitGetCollection403ResponseException
     */
    'message'?: string;
    /**
     * 
     * @type {number}
     * @memberof ApiDroitGetCollection403ResponseException
     */
    'code'?: number;
}
/**
 * 
 * @export
 * @interface ApiDroitGetCollection404Response
 */
export interface ApiDroitGetCollection404Response {
    /**
     * 
     * @type {string}
     * @memberof ApiDroitGetCollection404Response
     */
    'error'?: string;
    /**
     * 
     * @type {ApiDroitGetCollection404ResponseException}
     * @memberof ApiDroitGetCollection404Response
     */
    'exception'?: ApiDroitGetCollection404ResponseException;
}
/**
 * 
 * @export
 * @interface ApiDroitGetCollection404ResponseException
 */
export interface ApiDroitGetCollection404ResponseException {
    /**
     * 
     * @type {string}
     * @memberof ApiDroitGetCollection404ResponseException
     */
    'message'?: string;
    /**
     * 
     * @type {number}
     * @memberof ApiDroitGetCollection404ResponseException
     */
    'code'?: number;
}
/**
 * 
 * @export
 * @interface ApiGroupesGetCollection200Response
 */
export interface ApiGroupesGetCollection200Response {
    /**
     * 
     * @type {Array<GroupesJsonldGroupReadCollection>}
     * @memberof ApiGroupesGetCollection200Response
     */
    'hydra:member': Array<GroupesJsonldGroupReadCollection>;
    /**
     * 
     * @type {number}
     * @memberof ApiGroupesGetCollection200Response
     */
    'hydra:totalItems'?: number;
    /**
     * 
     * @type {ApiDroitGetCollection200ResponseHydraView}
     * @memberof ApiGroupesGetCollection200Response
     */
    'hydra:view'?: ApiDroitGetCollection200ResponseHydraView;
    /**
     * 
     * @type {ApiDroitGetCollection200ResponseHydraSearch}
     * @memberof ApiGroupesGetCollection200Response
     */
    'hydra:search'?: ApiDroitGetCollection200ResponseHydraSearch;
}
/**
 * 
 * @export
 * @interface ApiJournauxGetCollection200Response
 */
export interface ApiJournauxGetCollection200Response {
    /**
     * 
     * @type {Array<JournauxJsonldJournauxReadCollection>}
     * @memberof ApiJournauxGetCollection200Response
     */
    'hydra:member': Array<JournauxJsonldJournauxReadCollection>;
    /**
     * 
     * @type {number}
     * @memberof ApiJournauxGetCollection200Response
     */
    'hydra:totalItems'?: number;
    /**
     * 
     * @type {ApiDroitGetCollection200ResponseHydraView}
     * @memberof ApiJournauxGetCollection200Response
     */
    'hydra:view'?: ApiDroitGetCollection200ResponseHydraView;
    /**
     * 
     * @type {ApiDroitGetCollection200ResponseHydraSearch}
     * @memberof ApiJournauxGetCollection200Response
     */
    'hydra:search'?: ApiDroitGetCollection200ResponseHydraSearch;
}
/**
 * 
 * @export
 * @interface ApiKeycloakUtilisateurPost400Response
 */
export interface ApiKeycloakUtilisateurPost400Response {
    /**
     * 
     * @type {string}
     * @memberof ApiKeycloakUtilisateurPost400Response
     */
    'error'?: string;
    /**
     * 
     * @type {ApiKeycloakUtilisateurPost400ResponseException}
     * @memberof ApiKeycloakUtilisateurPost400Response
     */
    'exception'?: ApiKeycloakUtilisateurPost400ResponseException;
}
/**
 * 
 * @export
 * @interface ApiKeycloakUtilisateurPost400ResponseException
 */
export interface ApiKeycloakUtilisateurPost400ResponseException {
    /**
     * 
     * @type {string}
     * @memberof ApiKeycloakUtilisateurPost400ResponseException
     */
    'message'?: string;
    /**
     * 
     * @type {number}
     * @memberof ApiKeycloakUtilisateurPost400ResponseException
     */
    'code'?: number;
}
/**
 * 
 * @export
 * @interface ApiKeycloakUtilisateursPrescripteurIdGetCollection200Response
 */
export interface ApiKeycloakUtilisateursPrescripteurIdGetCollection200Response {
    /**
     * 
     * @type {Array<UserJsonld>}
     * @memberof ApiKeycloakUtilisateursPrescripteurIdGetCollection200Response
     */
    'hydra:member': Array<UserJsonld>;
    /**
     * 
     * @type {number}
     * @memberof ApiKeycloakUtilisateursPrescripteurIdGetCollection200Response
     */
    'hydra:totalItems'?: number;
    /**
     * 
     * @type {ApiDroitGetCollection200ResponseHydraView}
     * @memberof ApiKeycloakUtilisateursPrescripteurIdGetCollection200Response
     */
    'hydra:view'?: ApiDroitGetCollection200ResponseHydraView;
    /**
     * 
     * @type {ApiDroitGetCollection200ResponseHydraSearch}
     * @memberof ApiKeycloakUtilisateursPrescripteurIdGetCollection200Response
     */
    'hydra:search'?: ApiDroitGetCollection200ResponseHydraSearch;
}
/**
 * 
 * @export
 * @interface ApiMarquesGetCollection200Response
 */
export interface ApiMarquesGetCollection200Response {
    /**
     * 
     * @type {Array<MarquesJsonldSocieteMarqueReadCollection>}
     * @memberof ApiMarquesGetCollection200Response
     */
    'hydra:member': Array<MarquesJsonldSocieteMarqueReadCollection>;
    /**
     * 
     * @type {number}
     * @memberof ApiMarquesGetCollection200Response
     */
    'hydra:totalItems'?: number;
    /**
     * 
     * @type {ApiDroitGetCollection200ResponseHydraView}
     * @memberof ApiMarquesGetCollection200Response
     */
    'hydra:view'?: ApiDroitGetCollection200ResponseHydraView;
    /**
     * 
     * @type {ApiDroitGetCollection200ResponseHydraSearch}
     * @memberof ApiMarquesGetCollection200Response
     */
    'hydra:search'?: ApiDroitGetCollection200ResponseHydraSearch;
}
/**
 * 
 * @export
 * @interface ApiPolesGetCollection200Response
 */
export interface ApiPolesGetCollection200Response {
    /**
     * 
     * @type {Array<PolesJsonldDefPoleReadCollection>}
     * @memberof ApiPolesGetCollection200Response
     */
    'hydra:member': Array<PolesJsonldDefPoleReadCollection>;
    /**
     * 
     * @type {number}
     * @memberof ApiPolesGetCollection200Response
     */
    'hydra:totalItems'?: number;
    /**
     * 
     * @type {ApiDroitGetCollection200ResponseHydraView}
     * @memberof ApiPolesGetCollection200Response
     */
    'hydra:view'?: ApiDroitGetCollection200ResponseHydraView;
    /**
     * 
     * @type {ApiDroitGetCollection200ResponseHydraSearch}
     * @memberof ApiPolesGetCollection200Response
     */
    'hydra:search'?: ApiDroitGetCollection200ResponseHydraSearch;
}
/**
 * 
 * @export
 * @interface ApiServicesGetCollection200Response
 */
export interface ApiServicesGetCollection200Response {
    /**
     * 
     * @type {Array<ServicesJsonldServiceReadCollection>}
     * @memberof ApiServicesGetCollection200Response
     */
    'hydra:member': Array<ServicesJsonldServiceReadCollection>;
    /**
     * 
     * @type {number}
     * @memberof ApiServicesGetCollection200Response
     */
    'hydra:totalItems'?: number;
    /**
     * 
     * @type {ApiDroitGetCollection200ResponseHydraView}
     * @memberof ApiServicesGetCollection200Response
     */
    'hydra:view'?: ApiDroitGetCollection200ResponseHydraView;
    /**
     * 
     * @type {ApiDroitGetCollection200ResponseHydraSearch}
     * @memberof ApiServicesGetCollection200Response
     */
    'hydra:search'?: ApiDroitGetCollection200ResponseHydraSearch;
}
/**
 * 
 * @export
 * @interface ApiUtilisateursGetCollection200Response
 */
export interface ApiUtilisateursGetCollection200Response {
    /**
     * 
     * @type {Array<UtilisateursJsonldUtilisateurRead>}
     * @memberof ApiUtilisateursGetCollection200Response
     */
    'hydra:member': Array<UtilisateursJsonldUtilisateurRead>;
    /**
     * 
     * @type {number}
     * @memberof ApiUtilisateursGetCollection200Response
     */
    'hydra:totalItems'?: number;
    /**
     * 
     * @type {ApiDroitGetCollection200ResponseHydraView}
     * @memberof ApiUtilisateursGetCollection200Response
     */
    'hydra:view'?: ApiDroitGetCollection200ResponseHydraView;
    /**
     * 
     * @type {ApiDroitGetCollection200ResponseHydraSearch}
     * @memberof ApiUtilisateursGetCollection200Response
     */
    'hydra:search'?: ApiDroitGetCollection200ResponseHydraSearch;
}
/**
 * Returns all resources from Tbl_Utilisateur_Droit
 * @export
 * @interface DroitsDroitReadCollection
 */
export interface DroitsDroitReadCollection {
    /**
     * 
     * @type {number}
     * @memberof DroitsDroitReadCollection
     */
    'usdId'?: number;
    /**
     * 
     * @type {UtilisateursDroitReadCollection}
     * @memberof DroitsDroitReadCollection
     */
    'iduser'?: UtilisateursDroitReadCollection | null;
    /**
     * 
     * @type {boolean}
     * @memberof DroitsDroitReadCollection
     */
    'usdAppsJalgest'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof DroitsDroitReadCollection
     */
    'usdAppsJalgestOption'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof DroitsDroitReadCollection
     */
    'usdAppsXy'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof DroitsDroitReadCollection
     */
    'usdAppsXyOption'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof DroitsDroitReadCollection
     */
    'usdAppsMdc'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof DroitsDroitReadCollection
     */
    'usdAppsMdcOption'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof DroitsDroitReadCollection
     */
    'usdAppsMail'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof DroitsDroitReadCollection
     */
    'usdAppsMailOption'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof DroitsDroitReadCollection
     */
    'usdAppsAnalytics'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DroitsDroitReadCollection
     */
    'usdAppsAnalyticsOption'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DroitsDroitReadCollection
     */
    'usdAppsAnnuaire'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DroitsDroitReadCollection
     */
    'usdAppsAnnuaireOption'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DroitsDroitReadCollection
     */
    'usdAppsSupport'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DroitsDroitReadCollection
     */
    'usdAppsSupportOption'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DroitsDroitReadCollection
     */
    'usdAppsMdcSpel'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof DroitsDroitReadCollection
     */
    'usdAppsMdcSpelOption'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof DroitsDroitReadCollection
     */
    'usdAppsDocutech'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof DroitsDroitReadCollection
     */
    'usdAppsDocutechOption'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof DroitsDroitReadCollection
     */
    'usdAppsDocutechBackoffice'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DroitsDroitReadCollection
     */
    'usdAppsGestAbonnes'?: boolean;
}
/**
 * Returns all resources from Tbl_Utilisateur_Droit
 * @export
 * @interface DroitsJsonldDroitReadCollection
 */
export interface DroitsJsonldDroitReadCollection {
    /**
     * 
     * @type {string}
     * @memberof DroitsJsonldDroitReadCollection
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof DroitsJsonldDroitReadCollection
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof DroitsJsonldDroitReadCollection
     */
    'usdId'?: number;
    /**
     * 
     * @type {UtilisateursJsonldDroitReadCollection}
     * @memberof DroitsJsonldDroitReadCollection
     */
    'iduser'?: UtilisateursJsonldDroitReadCollection | null;
    /**
     * 
     * @type {boolean}
     * @memberof DroitsJsonldDroitReadCollection
     */
    'usdAppsJalgest'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof DroitsJsonldDroitReadCollection
     */
    'usdAppsJalgestOption'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof DroitsJsonldDroitReadCollection
     */
    'usdAppsXy'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof DroitsJsonldDroitReadCollection
     */
    'usdAppsXyOption'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof DroitsJsonldDroitReadCollection
     */
    'usdAppsMdc'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof DroitsJsonldDroitReadCollection
     */
    'usdAppsMdcOption'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof DroitsJsonldDroitReadCollection
     */
    'usdAppsMail'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof DroitsJsonldDroitReadCollection
     */
    'usdAppsMailOption'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof DroitsJsonldDroitReadCollection
     */
    'usdAppsAnalytics'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DroitsJsonldDroitReadCollection
     */
    'usdAppsAnalyticsOption'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DroitsJsonldDroitReadCollection
     */
    'usdAppsAnnuaire'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DroitsJsonldDroitReadCollection
     */
    'usdAppsAnnuaireOption'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DroitsJsonldDroitReadCollection
     */
    'usdAppsSupport'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DroitsJsonldDroitReadCollection
     */
    'usdAppsSupportOption'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DroitsJsonldDroitReadCollection
     */
    'usdAppsMdcSpel'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof DroitsJsonldDroitReadCollection
     */
    'usdAppsMdcSpelOption'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof DroitsJsonldDroitReadCollection
     */
    'usdAppsDocutech'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof DroitsJsonldDroitReadCollection
     */
    'usdAppsDocutechOption'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof DroitsJsonldDroitReadCollection
     */
    'usdAppsDocutechBackoffice'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DroitsJsonldDroitReadCollection
     */
    'usdAppsGestAbonnes'?: boolean;
}
/**
 * Returns a specific id resource from Tbl_Utilisateur_Droit
 * @export
 * @interface DroitsJsonldUtilisateurRead
 */
export interface DroitsJsonldUtilisateurRead {
    /**
     * 
     * @type {DroitsJsonldUtilisateurReadContext}
     * @memberof DroitsJsonldUtilisateurRead
     */
    '@context'?: DroitsJsonldUtilisateurReadContext;
    /**
     * 
     * @type {string}
     * @memberof DroitsJsonldUtilisateurRead
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof DroitsJsonldUtilisateurRead
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof DroitsJsonldUtilisateurRead
     */
    'usdId'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof DroitsJsonldUtilisateurRead
     */
    'usdAppsJalgest'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof DroitsJsonldUtilisateurRead
     */
    'usdAppsJalgestOption'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof DroitsJsonldUtilisateurRead
     */
    'usdAppsXy'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof DroitsJsonldUtilisateurRead
     */
    'usdAppsXyOption'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof DroitsJsonldUtilisateurRead
     */
    'usdAppsMdc'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof DroitsJsonldUtilisateurRead
     */
    'usdAppsMdcOption'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof DroitsJsonldUtilisateurRead
     */
    'usdAppsMail'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof DroitsJsonldUtilisateurRead
     */
    'usdAppsMailOption'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof DroitsJsonldUtilisateurRead
     */
    'usdAppsAnalytics'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DroitsJsonldUtilisateurRead
     */
    'usdAppsAnalyticsOption'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DroitsJsonldUtilisateurRead
     */
    'usdAppsAnnuaire'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DroitsJsonldUtilisateurRead
     */
    'usdAppsAnnuaireOption'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DroitsJsonldUtilisateurRead
     */
    'usdAppsSupport'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DroitsJsonldUtilisateurRead
     */
    'usdAppsSupportOption'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DroitsJsonldUtilisateurRead
     */
    'usdAppsMdcSpel'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof DroitsJsonldUtilisateurRead
     */
    'usdAppsMdcSpelOption'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof DroitsJsonldUtilisateurRead
     */
    'usdAppsDocutech'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof DroitsJsonldUtilisateurRead
     */
    'usdAppsDocutechOption'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof DroitsJsonldUtilisateurRead
     */
    'usdAppsDocutechBackoffice'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DroitsJsonldUtilisateurRead
     */
    'usdAppsGestAbonnes'?: boolean;
}
/**
 * @type DroitsJsonldUtilisateurReadContext
 * @export
 */
export type DroitsJsonldUtilisateurReadContext = DroitsJsonldUtilisateurReadContextOneOf | string;

/**
 * 
 * @export
 * @interface DroitsJsonldUtilisateurReadContextOneOf
 */
export interface DroitsJsonldUtilisateurReadContextOneOf {
    [key: string]: any;

    /**
     * 
     * @type {string}
     * @memberof DroitsJsonldUtilisateurReadContextOneOf
     */
    '@vocab': string;
    /**
     * 
     * @type {string}
     * @memberof DroitsJsonldUtilisateurReadContextOneOf
     */
    'hydra': DroitsJsonldUtilisateurReadContextOneOfHydraEnum;
}

export const DroitsJsonldUtilisateurReadContextOneOfHydraEnum = {
    HttpWwwW3OrgNsHydraCore: 'http://www.w3.org/ns/hydra/core#'
} as const;

export type DroitsJsonldUtilisateurReadContextOneOfHydraEnum = typeof DroitsJsonldUtilisateurReadContextOneOfHydraEnum[keyof typeof DroitsJsonldUtilisateurReadContextOneOfHydraEnum];

/**
 * Returns a specific id resource from Tbl_Utilisateur_Droit
 * @export
 * @interface DroitsUtilisateurRead
 */
export interface DroitsUtilisateurRead {
    /**
     * 
     * @type {number}
     * @memberof DroitsUtilisateurRead
     */
    'usdId'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof DroitsUtilisateurRead
     */
    'usdAppsJalgest'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof DroitsUtilisateurRead
     */
    'usdAppsJalgestOption'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof DroitsUtilisateurRead
     */
    'usdAppsXy'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof DroitsUtilisateurRead
     */
    'usdAppsXyOption'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof DroitsUtilisateurRead
     */
    'usdAppsMdc'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof DroitsUtilisateurRead
     */
    'usdAppsMdcOption'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof DroitsUtilisateurRead
     */
    'usdAppsMail'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof DroitsUtilisateurRead
     */
    'usdAppsMailOption'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof DroitsUtilisateurRead
     */
    'usdAppsAnalytics'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DroitsUtilisateurRead
     */
    'usdAppsAnalyticsOption'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DroitsUtilisateurRead
     */
    'usdAppsAnnuaire'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DroitsUtilisateurRead
     */
    'usdAppsAnnuaireOption'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DroitsUtilisateurRead
     */
    'usdAppsSupport'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DroitsUtilisateurRead
     */
    'usdAppsSupportOption'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DroitsUtilisateurRead
     */
    'usdAppsMdcSpel'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof DroitsUtilisateurRead
     */
    'usdAppsMdcSpelOption'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof DroitsUtilisateurRead
     */
    'usdAppsDocutech'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof DroitsUtilisateurRead
     */
    'usdAppsDocutechOption'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof DroitsUtilisateurRead
     */
    'usdAppsDocutechBackoffice'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DroitsUtilisateurRead
     */
    'usdAppsGestAbonnes'?: boolean;
}
/**
 * Returns the groups collection
 * @export
 * @interface GroupesGroupReadCollection
 */
export interface GroupesGroupReadCollection {
    /**
     * 
     * @type {number}
     * @memberof GroupesGroupReadCollection
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof GroupesGroupReadCollection
     */
    'groupName'?: string;
    /**
     * 
     * @type {string}
     * @memberof GroupesGroupReadCollection
     */
    'note'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof GroupesGroupReadCollection
     */
    'flagDelete'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof GroupesGroupReadCollection
     */
    'userMaj'?: number;
    /**
     * 
     * @type {string}
     * @memberof GroupesGroupReadCollection
     */
    'dateMaj'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof GroupesGroupReadCollection
     */
    'group'?: number;
}
/**
 * Returns the groups collection
 * @export
 * @interface GroupesJsonldGroupReadCollection
 */
export interface GroupesJsonldGroupReadCollection {
    /**
     * 
     * @type {string}
     * @memberof GroupesJsonldGroupReadCollection
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof GroupesJsonldGroupReadCollection
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof GroupesJsonldGroupReadCollection
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof GroupesJsonldGroupReadCollection
     */
    'groupName'?: string;
    /**
     * 
     * @type {string}
     * @memberof GroupesJsonldGroupReadCollection
     */
    'note'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof GroupesJsonldGroupReadCollection
     */
    'flagDelete'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof GroupesJsonldGroupReadCollection
     */
    'userMaj'?: number;
    /**
     * 
     * @type {string}
     * @memberof GroupesJsonldGroupReadCollection
     */
    'dateMaj'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof GroupesJsonldGroupReadCollection
     */
    'group'?: number;
}
/**
 * 
 * @export
 * @interface GroupesJsonldUtilisateurRead
 */
export interface GroupesJsonldUtilisateurRead {
    /**
     * 
     * @type {DroitsJsonldUtilisateurReadContext}
     * @memberof GroupesJsonldUtilisateurRead
     */
    '@context'?: DroitsJsonldUtilisateurReadContext;
    /**
     * 
     * @type {string}
     * @memberof GroupesJsonldUtilisateurRead
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof GroupesJsonldUtilisateurRead
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof GroupesJsonldUtilisateurRead
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof GroupesJsonldUtilisateurRead
     */
    'groupName'?: string;
    /**
     * 
     * @type {string}
     * @memberof GroupesJsonldUtilisateurRead
     */
    'note'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof GroupesJsonldUtilisateurRead
     */
    'flagDelete'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof GroupesJsonldUtilisateurRead
     */
    'userMaj'?: number;
    /**
     * 
     * @type {string}
     * @memberof GroupesJsonldUtilisateurRead
     */
    'dateMaj'?: string | null;
}
/**
 * 
 * @export
 * @interface GroupesUtilisateurRead
 */
export interface GroupesUtilisateurRead {
    /**
     * 
     * @type {number}
     * @memberof GroupesUtilisateurRead
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof GroupesUtilisateurRead
     */
    'groupName'?: string;
    /**
     * 
     * @type {string}
     * @memberof GroupesUtilisateurRead
     */
    'note'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof GroupesUtilisateurRead
     */
    'flagDelete'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof GroupesUtilisateurRead
     */
    'userMaj'?: number;
    /**
     * 
     * @type {string}
     * @memberof GroupesUtilisateurRead
     */
    'dateMaj'?: string | null;
}
/**
 * Returns all resources from Tbl_Utilisateur_Journaux
 * @export
 * @interface JournauxJournauxReadCollection
 */
export interface JournauxJournauxReadCollection {
    /**
     * 
     * @type {number}
     * @memberof JournauxJournauxReadCollection
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof JournauxJournauxReadCollection
     */
    'name'?: string | null;
}
/**
 * Returns all resources from Tbl_Utilisateur_Journaux
 * @export
 * @interface JournauxJsonldJournauxReadCollection
 */
export interface JournauxJsonldJournauxReadCollection {
    /**
     * 
     * @type {string}
     * @memberof JournauxJsonldJournauxReadCollection
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof JournauxJsonldJournauxReadCollection
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof JournauxJsonldJournauxReadCollection
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof JournauxJsonldJournauxReadCollection
     */
    'name'?: string | null;
}
/**
 * Returns the society\'s brands list
 * @export
 * @interface MarquesJsonldSocieteMarqueReadCollection
 */
export interface MarquesJsonldSocieteMarqueReadCollection {
    /**
     * 
     * @type {string}
     * @memberof MarquesJsonldSocieteMarqueReadCollection
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof MarquesJsonldSocieteMarqueReadCollection
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof MarquesJsonldSocieteMarqueReadCollection
     */
    'idMarque'?: number;
    /**
     * 
     * @type {number}
     * @memberof MarquesJsonldSocieteMarqueReadCollection
     */
    'idSociete'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof MarquesJsonldSocieteMarqueReadCollection
     */
    'libelle'?: string;
    /**
     * 
     * @type {string}
     * @memberof MarquesJsonldSocieteMarqueReadCollection
     */
    'codeMarque'?: string;
    /**
     * 
     * @type {number}
     * @memberof MarquesJsonldSocieteMarqueReadCollection
     */
    'userCreation'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof MarquesJsonldSocieteMarqueReadCollection
     */
    'dateCreation'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof MarquesJsonldSocieteMarqueReadCollection
     */
    'flagDelete'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof MarquesJsonldSocieteMarqueReadCollection
     */
    'dateDelete'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof MarquesJsonldSocieteMarqueReadCollection
     */
    'userDelete'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof MarquesJsonldSocieteMarqueReadCollection
     */
    'piedDePage'?: string | null;
}
/**
 * 
 * @export
 * @interface MarquesJsonldUtilisateurRead
 */
export interface MarquesJsonldUtilisateurRead {
    /**
     * 
     * @type {DroitsJsonldUtilisateurReadContext}
     * @memberof MarquesJsonldUtilisateurRead
     */
    '@context'?: DroitsJsonldUtilisateurReadContext;
    /**
     * 
     * @type {string}
     * @memberof MarquesJsonldUtilisateurRead
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof MarquesJsonldUtilisateurRead
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof MarquesJsonldUtilisateurRead
     */
    'idMarque'?: number;
    /**
     * 
     * @type {number}
     * @memberof MarquesJsonldUtilisateurRead
     */
    'idSociete'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof MarquesJsonldUtilisateurRead
     */
    'libelle'?: string;
    /**
     * 
     * @type {string}
     * @memberof MarquesJsonldUtilisateurRead
     */
    'codeMarque'?: string;
    /**
     * 
     * @type {number}
     * @memberof MarquesJsonldUtilisateurRead
     */
    'userCreation'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof MarquesJsonldUtilisateurRead
     */
    'dateCreation'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof MarquesJsonldUtilisateurRead
     */
    'flagDelete'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof MarquesJsonldUtilisateurRead
     */
    'dateDelete'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof MarquesJsonldUtilisateurRead
     */
    'userDelete'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof MarquesJsonldUtilisateurRead
     */
    'piedDePage'?: string | null;
}
/**
 * Returns the society\'s brands list
 * @export
 * @interface MarquesSocieteMarqueReadCollection
 */
export interface MarquesSocieteMarqueReadCollection {
    /**
     * 
     * @type {number}
     * @memberof MarquesSocieteMarqueReadCollection
     */
    'idMarque'?: number;
    /**
     * 
     * @type {number}
     * @memberof MarquesSocieteMarqueReadCollection
     */
    'idSociete'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof MarquesSocieteMarqueReadCollection
     */
    'libelle'?: string;
    /**
     * 
     * @type {string}
     * @memberof MarquesSocieteMarqueReadCollection
     */
    'codeMarque'?: string;
    /**
     * 
     * @type {number}
     * @memberof MarquesSocieteMarqueReadCollection
     */
    'userCreation'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof MarquesSocieteMarqueReadCollection
     */
    'dateCreation'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof MarquesSocieteMarqueReadCollection
     */
    'flagDelete'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof MarquesSocieteMarqueReadCollection
     */
    'dateDelete'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof MarquesSocieteMarqueReadCollection
     */
    'userDelete'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof MarquesSocieteMarqueReadCollection
     */
    'piedDePage'?: string | null;
}
/**
 * 
 * @export
 * @interface MarquesUtilisateurRead
 */
export interface MarquesUtilisateurRead {
    /**
     * 
     * @type {number}
     * @memberof MarquesUtilisateurRead
     */
    'idMarque'?: number;
    /**
     * 
     * @type {number}
     * @memberof MarquesUtilisateurRead
     */
    'idSociete'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof MarquesUtilisateurRead
     */
    'libelle'?: string;
    /**
     * 
     * @type {string}
     * @memberof MarquesUtilisateurRead
     */
    'codeMarque'?: string;
    /**
     * 
     * @type {number}
     * @memberof MarquesUtilisateurRead
     */
    'userCreation'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof MarquesUtilisateurRead
     */
    'dateCreation'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof MarquesUtilisateurRead
     */
    'flagDelete'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof MarquesUtilisateurRead
     */
    'dateDelete'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof MarquesUtilisateurRead
     */
    'userDelete'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof MarquesUtilisateurRead
     */
    'piedDePage'?: string | null;
}
/**
 * Returns the TBL poles collection
 * @export
 * @interface PolesDefPoleReadCollection
 */
export interface PolesDefPoleReadCollection {
    /**
     * 
     * @type {number}
     * @memberof PolesDefPoleReadCollection
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof PolesDefPoleReadCollection
     */
    'libelle'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PolesDefPoleReadCollection
     */
    'info'?: string | null;
}
/**
 * Returns the TBL poles collection
 * @export
 * @interface PolesJsonldDefPoleReadCollection
 */
export interface PolesJsonldDefPoleReadCollection {
    /**
     * 
     * @type {string}
     * @memberof PolesJsonldDefPoleReadCollection
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof PolesJsonldDefPoleReadCollection
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof PolesJsonldDefPoleReadCollection
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof PolesJsonldDefPoleReadCollection
     */
    'libelle'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PolesJsonldDefPoleReadCollection
     */
    'info'?: string | null;
}
/**
 * 
 * @export
 * @interface PolesJsonldUtilisateurRead
 */
export interface PolesJsonldUtilisateurRead {
    /**
     * 
     * @type {DroitsJsonldUtilisateurReadContext}
     * @memberof PolesJsonldUtilisateurRead
     */
    '@context'?: DroitsJsonldUtilisateurReadContext;
    /**
     * 
     * @type {string}
     * @memberof PolesJsonldUtilisateurRead
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof PolesJsonldUtilisateurRead
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof PolesJsonldUtilisateurRead
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof PolesJsonldUtilisateurRead
     */
    'libelle'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PolesJsonldUtilisateurRead
     */
    'info'?: string | null;
}
/**
 * 
 * @export
 * @interface PolesUtilisateurRead
 */
export interface PolesUtilisateurRead {
    /**
     * 
     * @type {number}
     * @memberof PolesUtilisateurRead
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof PolesUtilisateurRead
     */
    'libelle'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PolesUtilisateurRead
     */
    'info'?: string | null;
}
/**
 * Returns all resources from Tbl_Def_Service
 * @export
 * @interface ServicesJsonldServiceReadCollection
 */
export interface ServicesJsonldServiceReadCollection {
    /**
     * 
     * @type {string}
     * @memberof ServicesJsonldServiceReadCollection
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ServicesJsonldServiceReadCollection
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof ServicesJsonldServiceReadCollection
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof ServicesJsonldServiceReadCollection
     */
    'libelle'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ServicesJsonldServiceReadCollection
     */
    'info'?: string | null;
}
/**
 * Resources from Tbl_Def_Service
 * @export
 * @interface ServicesJsonldUtilisateurRead
 */
export interface ServicesJsonldUtilisateurRead {
    /**
     * 
     * @type {DroitsJsonldUtilisateurReadContext}
     * @memberof ServicesJsonldUtilisateurRead
     */
    '@context'?: DroitsJsonldUtilisateurReadContext;
    /**
     * 
     * @type {string}
     * @memberof ServicesJsonldUtilisateurRead
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ServicesJsonldUtilisateurRead
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof ServicesJsonldUtilisateurRead
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof ServicesJsonldUtilisateurRead
     */
    'libelle'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ServicesJsonldUtilisateurRead
     */
    'info'?: string | null;
}
/**
 * Returns all resources from Tbl_Def_Service
 * @export
 * @interface ServicesServiceReadCollection
 */
export interface ServicesServiceReadCollection {
    /**
     * 
     * @type {number}
     * @memberof ServicesServiceReadCollection
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof ServicesServiceReadCollection
     */
    'libelle'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ServicesServiceReadCollection
     */
    'info'?: string | null;
}
/**
 * Resources from Tbl_Def_Service
 * @export
 * @interface ServicesUtilisateurRead
 */
export interface ServicesUtilisateurRead {
    /**
     * 
     * @type {number}
     * @memberof ServicesUtilisateurRead
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof ServicesUtilisateurRead
     */
    'libelle'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ServicesUtilisateurRead
     */
    'info'?: string | null;
}
/**
 * 
 * @export
 * @interface User
 */
export interface User {
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'id'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'username'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof User
     */
    'enabled'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof User
     */
    'emailVerified'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'firstName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'lastName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'email'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof User
     */
    'idPrescripteur'?: Array<string> | null;
}
/**
 * 
 * @export
 * @interface UserCreateUser
 */
export interface UserCreateUser {
    /**
     * 
     * @type {string}
     * @memberof UserCreateUser
     */
    'username': string | null;
    /**
     * 
     * @type {boolean}
     * @memberof UserCreateUser
     */
    'enabled': boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof UserCreateUser
     */
    'emailVerified': boolean | null;
    /**
     * 
     * @type {string}
     * @memberof UserCreateUser
     */
    'firstName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserCreateUser
     */
    'lastName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserCreateUser
     */
    'email': string | null;
    /**
     * 
     * @type {string}
     * @memberof UserCreateUser
     */
    'password': string | null;
    /**
     * 
     * @type {number}
     * @memberof UserCreateUser
     */
    'idPrescripteur': number | null;
}
/**
 * 
 * @export
 * @interface UserCreateUserJsonld
 */
export interface UserCreateUserJsonld {
    /**
     * 
     * @type {string}
     * @memberof UserCreateUserJsonld
     */
    'username': string | null;
    /**
     * 
     * @type {boolean}
     * @memberof UserCreateUserJsonld
     */
    'enabled': boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof UserCreateUserJsonld
     */
    'emailVerified': boolean | null;
    /**
     * 
     * @type {string}
     * @memberof UserCreateUserJsonld
     */
    'firstName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserCreateUserJsonld
     */
    'lastName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserCreateUserJsonld
     */
    'email': string | null;
    /**
     * 
     * @type {string}
     * @memberof UserCreateUserJsonld
     */
    'password': string | null;
    /**
     * 
     * @type {number}
     * @memberof UserCreateUserJsonld
     */
    'idPrescripteur': number | null;
}
/**
 * 
 * @export
 * @interface UserJsonld
 */
export interface UserJsonld {
    /**
     * 
     * @type {DroitsJsonldUtilisateurReadContext}
     * @memberof UserJsonld
     */
    '@context'?: DroitsJsonldUtilisateurReadContext;
    /**
     * 
     * @type {string}
     * @memberof UserJsonld
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserJsonld
     */
    '@type'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserJsonld
     */
    'id'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserJsonld
     */
    'username'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof UserJsonld
     */
    'enabled'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof UserJsonld
     */
    'emailVerified'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof UserJsonld
     */
    'firstName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserJsonld
     */
    'lastName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserJsonld
     */
    'email'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof UserJsonld
     */
    'idPrescripteur'?: Array<string> | null;
}
/**
 * 
 * @export
 * @interface UserUpdateUser
 */
export interface UserUpdateUser {
    /**
     * 
     * @type {boolean}
     * @memberof UserUpdateUser
     */
    'enabled'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof UserUpdateUser
     */
    'emailVerified'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof UserUpdateUser
     */
    'firstName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserUpdateUser
     */
    'lastName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserUpdateUser
     */
    'email'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof UserUpdateUser
     */
    'idPrescripteur'?: Array<string> | null;
}
/**
 * Returns the selected user informations
 * @export
 * @interface UtilisateursDroitReadCollection
 */
export interface UtilisateursDroitReadCollection {
    /**
     * 
     * @type {number}
     * @memberof UtilisateursDroitReadCollection
     */
    'id'?: number;
}
/**
 * Returns the selected user informations
 * @export
 * @interface UtilisateursJsonldDroitReadCollection
 */
export interface UtilisateursJsonldDroitReadCollection {
    /**
     * 
     * @type {DroitsJsonldUtilisateurReadContext}
     * @memberof UtilisateursJsonldDroitReadCollection
     */
    '@context'?: DroitsJsonldUtilisateurReadContext;
    /**
     * 
     * @type {string}
     * @memberof UtilisateursJsonldDroitReadCollection
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof UtilisateursJsonldDroitReadCollection
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof UtilisateursJsonldDroitReadCollection
     */
    'id'?: number;
}
/**
 * Delete the user
 * @export
 * @interface UtilisateursJsonldLock
 */
export interface UtilisateursJsonldLock {
    /**
     * 
     * @type {DroitsJsonldUtilisateurReadContext}
     * @memberof UtilisateursJsonldLock
     */
    '@context'?: DroitsJsonldUtilisateurReadContext;
    /**
     * 
     * @type {string}
     * @memberof UtilisateursJsonldLock
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof UtilisateursJsonldLock
     */
    '@type'?: string;
}
/**
 * Returns the selected user informations
 * @export
 * @interface UtilisateursJsonldUtilisateurRead
 */
export interface UtilisateursJsonldUtilisateurRead {
    /**
     * 
     * @type {string}
     * @memberof UtilisateursJsonldUtilisateurRead
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof UtilisateursJsonldUtilisateurRead
     */
    '@type'?: string;
    /**
     * 
     * @type {DroitsJsonldUtilisateurReadContext}
     * @memberof UtilisateursJsonldUtilisateurRead
     */
    '@context'?: DroitsJsonldUtilisateurReadContext;
    /**
     * 
     * @type {number}
     * @memberof UtilisateursJsonldUtilisateurRead
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof UtilisateursJsonldUtilisateurRead
     */
    'nom': string;
    /**
     * 
     * @type {string}
     * @memberof UtilisateursJsonldUtilisateurRead
     */
    'prenom': string;
    /**
     * 
     * @type {string}
     * @memberof UtilisateursJsonldUtilisateurRead
     */
    'login'?: string | null;
    /**
     * 
     * @type {GroupesJsonldUtilisateurRead}
     * @memberof UtilisateursJsonldUtilisateurRead
     */
    'userGroupe': GroupesJsonldUtilisateurRead;
    /**
     * 
     * @type {string}
     * @memberof UtilisateursJsonldUtilisateurRead
     */
    'initiales'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof UtilisateursJsonldUtilisateurRead
     */
    'isDeleted'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UtilisateursJsonldUtilisateurRead
     */
    'user'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof UtilisateursJsonldUtilisateurRead
     */
    'userKeycloakId'?: number;
    /**
     * 
     * @type {string}
     * @memberof UtilisateursJsonldUtilisateurRead
     */
    'dateMaj'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UtilisateursJsonldUtilisateurRead
     */
    'poste'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UtilisateursJsonldUtilisateurRead
     */
    'ligneDirecte'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UtilisateursJsonldUtilisateurRead
     */
    'mail'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UtilisateursJsonldUtilisateurRead
     */
    'pc'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof UtilisateursJsonldUtilisateurRead
     */
    'isEleveur'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof UtilisateursJsonldUtilisateurRead
     */
    'hasG3Access'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UtilisateursJsonldUtilisateurRead
     */
    'formalistDemo'?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof UtilisateursJsonldUtilisateurRead
     */
    'formalistAdmin'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof UtilisateursJsonldUtilisateurRead
     */
    'formalistDroitModifDossier'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof UtilisateursJsonldUtilisateurRead
     */
    'formalistDroitLectureDossier'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof UtilisateursJsonldUtilisateurRead
     */
    'formalistDroitModifGestion'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof UtilisateursJsonldUtilisateurRead
     */
    'formalistDroitLectureGestion'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof UtilisateursJsonldUtilisateurRead
     */
    'formalistDroitSupprDossier'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof UtilisateursJsonldUtilisateurRead
     */
    'isFormalist'?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof UtilisateursJsonldUtilisateurRead
     */
    'idSociete'?: number | null;
    /**
     * 
     * @type {PolesJsonldUtilisateurRead}
     * @memberof UtilisateursJsonldUtilisateurRead
     */
    'pole'?: PolesJsonldUtilisateurRead | null;
    /**
     * 
     * @type {ServicesJsonldUtilisateurRead}
     * @memberof UtilisateursJsonldUtilisateurRead
     */
    'service'?: ServicesJsonldUtilisateurRead | null;
    /**
     * 
     * @type {number}
     * @memberof UtilisateursJsonldUtilisateurRead
     */
    'raccourciG3Id'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof UtilisateursJsonldUtilisateurRead
     */
    'portable'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UtilisateursJsonldUtilisateurRead
     */
    'fonction'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof UtilisateursJsonldUtilisateurRead
     */
    'hasVpn'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UtilisateursJsonldUtilisateurRead
     */
    'isG3Recetteur'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UtilisateursJsonldUtilisateurRead
     */
    'formalistDroitMultiSociete'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof UtilisateursJsonldUtilisateurRead
     */
    'idUserG3': number;
    /**
     * 
     * @type {boolean}
     * @memberof UtilisateursJsonldUtilisateurRead
     */
    'hasComptaSoftware': boolean;
    /**
     * 
     * @type {string}
     * @memberof UtilisateursJsonldUtilisateurRead
     */
    'dematFacile'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof UtilisateursJsonldUtilisateurRead
     */
    'idUserDematfacile': number;
    /**
     * 
     * @type {MarquesJsonldUtilisateurRead}
     * @memberof UtilisateursJsonldUtilisateurRead
     */
    'marque': MarquesJsonldUtilisateurRead;
    /**
     * 
     * @type {boolean}
     * @memberof UtilisateursJsonldUtilisateurRead
     */
    'hasAccessL2DF': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UtilisateursJsonldUtilisateurRead
     */
    'hasAnnuaire': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UtilisateursJsonldUtilisateurRead
     */
    'isAdminApps': boolean;
    /**
     * 
     * @type {number}
     * @memberof UtilisateursJsonldUtilisateurRead
     */
    'idGroupeGie'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof UtilisateursJsonldUtilisateurRead
     */
    'binome'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof UtilisateursJsonldUtilisateurRead
     */
    'idSocieteEmployeur'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof UtilisateursJsonldUtilisateurRead
     */
    'idAntenne'?: number | null;
    /**
     * 
     * @type {DroitsJsonldUtilisateurRead}
     * @memberof UtilisateursJsonldUtilisateurRead
     */
    'droitUtilisateur'?: DroitsJsonldUtilisateurRead | null;
    /**
     * 
     * @type {string}
     * @memberof UtilisateursJsonldUtilisateurRead
     */
    'libelleSociete'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UtilisateursJsonldUtilisateurRead
     */
    'libelleSocieteEmployeur'?: string | null;
}
/**
 * Updates the selected user informations
 * @export
 * @interface UtilisateursJsonldUtilisateurUpdateUtilisateur
 */
export interface UtilisateursJsonldUtilisateurUpdateUtilisateur {
    /**
     * 
     * @type {DroitsJsonldUtilisateurReadContext}
     * @memberof UtilisateursJsonldUtilisateurUpdateUtilisateur
     */
    '@context'?: DroitsJsonldUtilisateurReadContext;
    /**
     * 
     * @type {string}
     * @memberof UtilisateursJsonldUtilisateurUpdateUtilisateur
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof UtilisateursJsonldUtilisateurUpdateUtilisateur
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof UtilisateursJsonldUtilisateurUpdateUtilisateur
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof UtilisateursJsonldUtilisateurUpdateUtilisateur
     */
    'nom': string;
    /**
     * 
     * @type {string}
     * @memberof UtilisateursJsonldUtilisateurUpdateUtilisateur
     */
    'prenom': string;
    /**
     * 
     * @type {string}
     * @memberof UtilisateursJsonldUtilisateurUpdateUtilisateur
     */
    'password': string;
    /**
     * 
     * @type {string}
     * @memberof UtilisateursJsonldUtilisateurUpdateUtilisateur
     */
    'userGroupe': string;
    /**
     * 
     * @type {string}
     * @memberof UtilisateursJsonldUtilisateurUpdateUtilisateur
     */
    'initiales'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UtilisateursJsonldUtilisateurUpdateUtilisateur
     */
    'user'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UtilisateursJsonldUtilisateurUpdateUtilisateur
     */
    'poste'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UtilisateursJsonldUtilisateurUpdateUtilisateur
     */
    'ligneDirecte'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UtilisateursJsonldUtilisateurUpdateUtilisateur
     */
    'mail'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UtilisateursJsonldUtilisateurUpdateUtilisateur
     */
    'pc'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof UtilisateursJsonldUtilisateurUpdateUtilisateur
     */
    'isEleveur'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof UtilisateursJsonldUtilisateurUpdateUtilisateur
     */
    'hasG3Access'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UtilisateursJsonldUtilisateurUpdateUtilisateur
     */
    'formalistDemo'?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof UtilisateursJsonldUtilisateurUpdateUtilisateur
     */
    'formalistAdmin'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof UtilisateursJsonldUtilisateurUpdateUtilisateur
     */
    'formalistDroitModifDossier'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof UtilisateursJsonldUtilisateurUpdateUtilisateur
     */
    'formalistDroitLectureDossier'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof UtilisateursJsonldUtilisateurUpdateUtilisateur
     */
    'formalistDroitModifGestion'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof UtilisateursJsonldUtilisateurUpdateUtilisateur
     */
    'formalistDroitLectureGestion'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof UtilisateursJsonldUtilisateurUpdateUtilisateur
     */
    'formalistDroitSupprDossier'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof UtilisateursJsonldUtilisateurUpdateUtilisateur
     */
    'isFormalist'?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof UtilisateursJsonldUtilisateurUpdateUtilisateur
     */
    'idSociete'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof UtilisateursJsonldUtilisateurUpdateUtilisateur
     */
    'pole'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UtilisateursJsonldUtilisateurUpdateUtilisateur
     */
    'service'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof UtilisateursJsonldUtilisateurUpdateUtilisateur
     */
    'raccourciG3Id'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof UtilisateursJsonldUtilisateurUpdateUtilisateur
     */
    'portable'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UtilisateursJsonldUtilisateurUpdateUtilisateur
     */
    'fonction'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof UtilisateursJsonldUtilisateurUpdateUtilisateur
     */
    'hasVpn'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UtilisateursJsonldUtilisateurUpdateUtilisateur
     */
    'isG3Recetteur'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UtilisateursJsonldUtilisateurUpdateUtilisateur
     */
    'formalistDroitMultiSociete'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof UtilisateursJsonldUtilisateurUpdateUtilisateur
     */
    'idUserG3': number;
    /**
     * 
     * @type {boolean}
     * @memberof UtilisateursJsonldUtilisateurUpdateUtilisateur
     */
    'hasComptaSoftware': boolean;
    /**
     * 
     * @type {string}
     * @memberof UtilisateursJsonldUtilisateurUpdateUtilisateur
     */
    'dematFacile'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof UtilisateursJsonldUtilisateurUpdateUtilisateur
     */
    'idUserDematfacile': number;
    /**
     * 
     * @type {string}
     * @memberof UtilisateursJsonldUtilisateurUpdateUtilisateur
     */
    'marque': string;
    /**
     * 
     * @type {boolean}
     * @memberof UtilisateursJsonldUtilisateurUpdateUtilisateur
     */
    'hasAccessL2DF': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UtilisateursJsonldUtilisateurUpdateUtilisateur
     */
    'hasAnnuaire': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UtilisateursJsonldUtilisateurUpdateUtilisateur
     */
    'isAdminApps': boolean;
    /**
     * 
     * @type {number}
     * @memberof UtilisateursJsonldUtilisateurUpdateUtilisateur
     */
    'idGroupeGie'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof UtilisateursJsonldUtilisateurUpdateUtilisateur
     */
    'binome'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof UtilisateursJsonldUtilisateurUpdateUtilisateur
     */
    'idSocieteEmployeur'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof UtilisateursJsonldUtilisateurUpdateUtilisateur
     */
    'idAntenne'?: number | null;
}
/**
 * Create a user
 * @export
 * @interface UtilisateursJsonldUtilisateurWrite
 */
export interface UtilisateursJsonldUtilisateurWrite {
    /**
     * 
     * @type {DroitsJsonldUtilisateurReadContext}
     * @memberof UtilisateursJsonldUtilisateurWrite
     */
    '@context'?: DroitsJsonldUtilisateurReadContext;
    /**
     * 
     * @type {string}
     * @memberof UtilisateursJsonldUtilisateurWrite
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof UtilisateursJsonldUtilisateurWrite
     */
    '@type'?: string;
    /**
     * 
     * @type {string}
     * @memberof UtilisateursJsonldUtilisateurWrite
     */
    'nom': string;
    /**
     * 
     * @type {string}
     * @memberof UtilisateursJsonldUtilisateurWrite
     */
    'prenom': string;
    /**
     * 
     * @type {string}
     * @memberof UtilisateursJsonldUtilisateurWrite
     */
    'password': string;
    /**
     * 
     * @type {string}
     * @memberof UtilisateursJsonldUtilisateurWrite
     */
    'userGroupe': string;
    /**
     * 
     * @type {string}
     * @memberof UtilisateursJsonldUtilisateurWrite
     */
    'initiales'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UtilisateursJsonldUtilisateurWrite
     */
    'user'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UtilisateursJsonldUtilisateurWrite
     */
    'poste'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UtilisateursJsonldUtilisateurWrite
     */
    'ligneDirecte'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UtilisateursJsonldUtilisateurWrite
     */
    'mail'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UtilisateursJsonldUtilisateurWrite
     */
    'pc'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof UtilisateursJsonldUtilisateurWrite
     */
    'isEleveur'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof UtilisateursJsonldUtilisateurWrite
     */
    'hasG3Access'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UtilisateursJsonldUtilisateurWrite
     */
    'formalistDemo'?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof UtilisateursJsonldUtilisateurWrite
     */
    'formalistAdmin'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof UtilisateursJsonldUtilisateurWrite
     */
    'formalistDroitModifDossier'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof UtilisateursJsonldUtilisateurWrite
     */
    'formalistDroitLectureDossier'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof UtilisateursJsonldUtilisateurWrite
     */
    'formalistDroitModifGestion'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof UtilisateursJsonldUtilisateurWrite
     */
    'formalistDroitLectureGestion'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof UtilisateursJsonldUtilisateurWrite
     */
    'formalistDroitSupprDossier'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof UtilisateursJsonldUtilisateurWrite
     */
    'isFormalist'?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof UtilisateursJsonldUtilisateurWrite
     */
    'idSociete'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof UtilisateursJsonldUtilisateurWrite
     */
    'pole'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UtilisateursJsonldUtilisateurWrite
     */
    'service'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof UtilisateursJsonldUtilisateurWrite
     */
    'raccourciG3Id'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof UtilisateursJsonldUtilisateurWrite
     */
    'portable'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UtilisateursJsonldUtilisateurWrite
     */
    'fonction'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof UtilisateursJsonldUtilisateurWrite
     */
    'hasVpn'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UtilisateursJsonldUtilisateurWrite
     */
    'isG3Recetteur'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UtilisateursJsonldUtilisateurWrite
     */
    'formalistDroitMultiSociete'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof UtilisateursJsonldUtilisateurWrite
     */
    'idUserG3': number;
    /**
     * 
     * @type {boolean}
     * @memberof UtilisateursJsonldUtilisateurWrite
     */
    'hasComptaSoftware': boolean;
    /**
     * 
     * @type {string}
     * @memberof UtilisateursJsonldUtilisateurWrite
     */
    'dematFacile'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof UtilisateursJsonldUtilisateurWrite
     */
    'idUserDematfacile': number;
    /**
     * 
     * @type {string}
     * @memberof UtilisateursJsonldUtilisateurWrite
     */
    'marque': string;
    /**
     * 
     * @type {boolean}
     * @memberof UtilisateursJsonldUtilisateurWrite
     */
    'hasAccessL2DF': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UtilisateursJsonldUtilisateurWrite
     */
    'hasAnnuaire': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UtilisateursJsonldUtilisateurWrite
     */
    'isAdminApps': boolean;
    /**
     * 
     * @type {number}
     * @memberof UtilisateursJsonldUtilisateurWrite
     */
    'idGroupeGie'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof UtilisateursJsonldUtilisateurWrite
     */
    'binome'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof UtilisateursJsonldUtilisateurWrite
     */
    'idSocieteEmployeur'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof UtilisateursJsonldUtilisateurWrite
     */
    'idAntenne'?: number | null;
}
/**
 * Returns the selected user informations
 * @export
 * @interface UtilisateursUtilisateurRead
 */
export interface UtilisateursUtilisateurRead {
    /**
     * 
     * @type {number}
     * @memberof UtilisateursUtilisateurRead
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof UtilisateursUtilisateurRead
     */
    'nom': string;
    /**
     * 
     * @type {string}
     * @memberof UtilisateursUtilisateurRead
     */
    'prenom': string;
    /**
     * 
     * @type {string}
     * @memberof UtilisateursUtilisateurRead
     */
    'login'?: string | null;
    /**
     * 
     * @type {GroupesUtilisateurRead}
     * @memberof UtilisateursUtilisateurRead
     */
    'userGroupe': GroupesUtilisateurRead;
    /**
     * 
     * @type {string}
     * @memberof UtilisateursUtilisateurRead
     */
    'initiales'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof UtilisateursUtilisateurRead
     */
    'isDeleted'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UtilisateursUtilisateurRead
     */
    'user'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof UtilisateursUtilisateurRead
     */
    'userKeycloakId'?: number;
    /**
     * 
     * @type {string}
     * @memberof UtilisateursUtilisateurRead
     */
    'dateMaj'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UtilisateursUtilisateurRead
     */
    'poste'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UtilisateursUtilisateurRead
     */
    'ligneDirecte'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UtilisateursUtilisateurRead
     */
    'mail'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UtilisateursUtilisateurRead
     */
    'pc'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof UtilisateursUtilisateurRead
     */
    'isEleveur'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof UtilisateursUtilisateurRead
     */
    'hasG3Access'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UtilisateursUtilisateurRead
     */
    'formalistDemo'?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof UtilisateursUtilisateurRead
     */
    'formalistAdmin'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof UtilisateursUtilisateurRead
     */
    'formalistDroitModifDossier'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof UtilisateursUtilisateurRead
     */
    'formalistDroitLectureDossier'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof UtilisateursUtilisateurRead
     */
    'formalistDroitModifGestion'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof UtilisateursUtilisateurRead
     */
    'formalistDroitLectureGestion'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof UtilisateursUtilisateurRead
     */
    'formalistDroitSupprDossier'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof UtilisateursUtilisateurRead
     */
    'isFormalist'?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof UtilisateursUtilisateurRead
     */
    'idSociete'?: number | null;
    /**
     * 
     * @type {PolesUtilisateurRead}
     * @memberof UtilisateursUtilisateurRead
     */
    'pole'?: PolesUtilisateurRead | null;
    /**
     * 
     * @type {ServicesUtilisateurRead}
     * @memberof UtilisateursUtilisateurRead
     */
    'service'?: ServicesUtilisateurRead | null;
    /**
     * 
     * @type {number}
     * @memberof UtilisateursUtilisateurRead
     */
    'raccourciG3Id'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof UtilisateursUtilisateurRead
     */
    'portable'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UtilisateursUtilisateurRead
     */
    'fonction'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof UtilisateursUtilisateurRead
     */
    'hasVpn'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UtilisateursUtilisateurRead
     */
    'isG3Recetteur'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UtilisateursUtilisateurRead
     */
    'formalistDroitMultiSociete'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof UtilisateursUtilisateurRead
     */
    'idUserG3': number;
    /**
     * 
     * @type {boolean}
     * @memberof UtilisateursUtilisateurRead
     */
    'hasComptaSoftware': boolean;
    /**
     * 
     * @type {string}
     * @memberof UtilisateursUtilisateurRead
     */
    'dematFacile'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof UtilisateursUtilisateurRead
     */
    'idUserDematfacile': number;
    /**
     * 
     * @type {MarquesUtilisateurRead}
     * @memberof UtilisateursUtilisateurRead
     */
    'marque': MarquesUtilisateurRead;
    /**
     * 
     * @type {boolean}
     * @memberof UtilisateursUtilisateurRead
     */
    'hasAccessL2DF': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UtilisateursUtilisateurRead
     */
    'hasAnnuaire': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UtilisateursUtilisateurRead
     */
    'isAdminApps': boolean;
    /**
     * 
     * @type {number}
     * @memberof UtilisateursUtilisateurRead
     */
    'idGroupeGie'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof UtilisateursUtilisateurRead
     */
    'binome'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof UtilisateursUtilisateurRead
     */
    'idSocieteEmployeur'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof UtilisateursUtilisateurRead
     */
    'idAntenne'?: number | null;
    /**
     * 
     * @type {DroitsUtilisateurRead}
     * @memberof UtilisateursUtilisateurRead
     */
    'droitUtilisateur'?: DroitsUtilisateurRead | null;
    /**
     * 
     * @type {string}
     * @memberof UtilisateursUtilisateurRead
     */
    'libelleSociete'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UtilisateursUtilisateurRead
     */
    'libelleSocieteEmployeur'?: string | null;
}
/**
 * Updates the selected user informations
 * @export
 * @interface UtilisateursUtilisateurUpdateUtilisateur
 */
export interface UtilisateursUtilisateurUpdateUtilisateur {
    /**
     * 
     * @type {number}
     * @memberof UtilisateursUtilisateurUpdateUtilisateur
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof UtilisateursUtilisateurUpdateUtilisateur
     */
    'nom': string;
    /**
     * 
     * @type {string}
     * @memberof UtilisateursUtilisateurUpdateUtilisateur
     */
    'prenom': string;
    /**
     * 
     * @type {string}
     * @memberof UtilisateursUtilisateurUpdateUtilisateur
     */
    'password': string;
    /**
     * 
     * @type {string}
     * @memberof UtilisateursUtilisateurUpdateUtilisateur
     */
    'userGroupe': string;
    /**
     * 
     * @type {string}
     * @memberof UtilisateursUtilisateurUpdateUtilisateur
     */
    'initiales'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UtilisateursUtilisateurUpdateUtilisateur
     */
    'user'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UtilisateursUtilisateurUpdateUtilisateur
     */
    'poste'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UtilisateursUtilisateurUpdateUtilisateur
     */
    'ligneDirecte'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UtilisateursUtilisateurUpdateUtilisateur
     */
    'mail'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UtilisateursUtilisateurUpdateUtilisateur
     */
    'pc'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof UtilisateursUtilisateurUpdateUtilisateur
     */
    'isEleveur'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof UtilisateursUtilisateurUpdateUtilisateur
     */
    'hasG3Access'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UtilisateursUtilisateurUpdateUtilisateur
     */
    'formalistDemo'?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof UtilisateursUtilisateurUpdateUtilisateur
     */
    'formalistAdmin'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof UtilisateursUtilisateurUpdateUtilisateur
     */
    'formalistDroitModifDossier'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof UtilisateursUtilisateurUpdateUtilisateur
     */
    'formalistDroitLectureDossier'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof UtilisateursUtilisateurUpdateUtilisateur
     */
    'formalistDroitModifGestion'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof UtilisateursUtilisateurUpdateUtilisateur
     */
    'formalistDroitLectureGestion'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof UtilisateursUtilisateurUpdateUtilisateur
     */
    'formalistDroitSupprDossier'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof UtilisateursUtilisateurUpdateUtilisateur
     */
    'isFormalist'?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof UtilisateursUtilisateurUpdateUtilisateur
     */
    'idSociete'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof UtilisateursUtilisateurUpdateUtilisateur
     */
    'pole'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UtilisateursUtilisateurUpdateUtilisateur
     */
    'service'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof UtilisateursUtilisateurUpdateUtilisateur
     */
    'raccourciG3Id'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof UtilisateursUtilisateurUpdateUtilisateur
     */
    'portable'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UtilisateursUtilisateurUpdateUtilisateur
     */
    'fonction'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof UtilisateursUtilisateurUpdateUtilisateur
     */
    'hasVpn'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UtilisateursUtilisateurUpdateUtilisateur
     */
    'isG3Recetteur'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UtilisateursUtilisateurUpdateUtilisateur
     */
    'formalistDroitMultiSociete'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof UtilisateursUtilisateurUpdateUtilisateur
     */
    'idUserG3': number;
    /**
     * 
     * @type {boolean}
     * @memberof UtilisateursUtilisateurUpdateUtilisateur
     */
    'hasComptaSoftware': boolean;
    /**
     * 
     * @type {string}
     * @memberof UtilisateursUtilisateurUpdateUtilisateur
     */
    'dematFacile'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof UtilisateursUtilisateurUpdateUtilisateur
     */
    'idUserDematfacile': number;
    /**
     * 
     * @type {string}
     * @memberof UtilisateursUtilisateurUpdateUtilisateur
     */
    'marque': string;
    /**
     * 
     * @type {boolean}
     * @memberof UtilisateursUtilisateurUpdateUtilisateur
     */
    'hasAccessL2DF': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UtilisateursUtilisateurUpdateUtilisateur
     */
    'hasAnnuaire': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UtilisateursUtilisateurUpdateUtilisateur
     */
    'isAdminApps': boolean;
    /**
     * 
     * @type {number}
     * @memberof UtilisateursUtilisateurUpdateUtilisateur
     */
    'idGroupeGie'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof UtilisateursUtilisateurUpdateUtilisateur
     */
    'binome'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof UtilisateursUtilisateurUpdateUtilisateur
     */
    'idSocieteEmployeur'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof UtilisateursUtilisateurUpdateUtilisateur
     */
    'idAntenne'?: number | null;
}
/**
 * Create a user
 * @export
 * @interface UtilisateursUtilisateurWrite
 */
export interface UtilisateursUtilisateurWrite {
    /**
     * 
     * @type {string}
     * @memberof UtilisateursUtilisateurWrite
     */
    'nom': string;
    /**
     * 
     * @type {string}
     * @memberof UtilisateursUtilisateurWrite
     */
    'prenom': string;
    /**
     * 
     * @type {string}
     * @memberof UtilisateursUtilisateurWrite
     */
    'password': string;
    /**
     * 
     * @type {string}
     * @memberof UtilisateursUtilisateurWrite
     */
    'userGroupe': string;
    /**
     * 
     * @type {string}
     * @memberof UtilisateursUtilisateurWrite
     */
    'initiales'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UtilisateursUtilisateurWrite
     */
    'user'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UtilisateursUtilisateurWrite
     */
    'poste'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UtilisateursUtilisateurWrite
     */
    'ligneDirecte'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UtilisateursUtilisateurWrite
     */
    'mail'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UtilisateursUtilisateurWrite
     */
    'pc'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof UtilisateursUtilisateurWrite
     */
    'isEleveur'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof UtilisateursUtilisateurWrite
     */
    'hasG3Access'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UtilisateursUtilisateurWrite
     */
    'formalistDemo'?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof UtilisateursUtilisateurWrite
     */
    'formalistAdmin'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof UtilisateursUtilisateurWrite
     */
    'formalistDroitModifDossier'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof UtilisateursUtilisateurWrite
     */
    'formalistDroitLectureDossier'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof UtilisateursUtilisateurWrite
     */
    'formalistDroitModifGestion'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof UtilisateursUtilisateurWrite
     */
    'formalistDroitLectureGestion'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof UtilisateursUtilisateurWrite
     */
    'formalistDroitSupprDossier'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof UtilisateursUtilisateurWrite
     */
    'isFormalist'?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof UtilisateursUtilisateurWrite
     */
    'idSociete'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof UtilisateursUtilisateurWrite
     */
    'pole'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UtilisateursUtilisateurWrite
     */
    'service'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof UtilisateursUtilisateurWrite
     */
    'raccourciG3Id'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof UtilisateursUtilisateurWrite
     */
    'portable'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UtilisateursUtilisateurWrite
     */
    'fonction'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof UtilisateursUtilisateurWrite
     */
    'hasVpn'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UtilisateursUtilisateurWrite
     */
    'isG3Recetteur'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UtilisateursUtilisateurWrite
     */
    'formalistDroitMultiSociete'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof UtilisateursUtilisateurWrite
     */
    'idUserG3': number;
    /**
     * 
     * @type {boolean}
     * @memberof UtilisateursUtilisateurWrite
     */
    'hasComptaSoftware': boolean;
    /**
     * 
     * @type {string}
     * @memberof UtilisateursUtilisateurWrite
     */
    'dematFacile'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof UtilisateursUtilisateurWrite
     */
    'idUserDematfacile': number;
    /**
     * 
     * @type {string}
     * @memberof UtilisateursUtilisateurWrite
     */
    'marque': string;
    /**
     * 
     * @type {boolean}
     * @memberof UtilisateursUtilisateurWrite
     */
    'hasAccessL2DF': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UtilisateursUtilisateurWrite
     */
    'hasAnnuaire': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UtilisateursUtilisateurWrite
     */
    'isAdminApps': boolean;
    /**
     * 
     * @type {number}
     * @memberof UtilisateursUtilisateurWrite
     */
    'idGroupeGie'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof UtilisateursUtilisateurWrite
     */
    'binome'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof UtilisateursUtilisateurWrite
     */
    'idSocieteEmployeur'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof UtilisateursUtilisateurWrite
     */
    'idAntenne'?: number | null;
}

/**
 * DroitsApi - axios parameter creator
 * @export
 */
export const DroitsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Returns all resources from Tbl_Utilisateur_Droit
         * @summary Returns all resources from Tbl_Utilisateur_Droit
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {boolean} [pagination] Enable or disable pagination
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDroitGetCollection: async (page?: number, itemsPerPage?: number, pagination?: boolean, xIdSociete?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/utilisateur/droit`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (itemsPerPage !== undefined) {
                localVarQueryParameter['itemsPerPage'] = itemsPerPage;
            }

            if (pagination !== undefined) {
                localVarQueryParameter['pagination'] = pagination;
            }

            if (xIdSociete != null) {
                localVarHeaderParameter['X-Id-Societe'] = typeof xIdSociete === 'string'
                    ? xIdSociete
                    : JSON.stringify(xIdSociete);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DroitsApi - functional programming interface
 * @export
 */
export const DroitsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DroitsApiAxiosParamCreator(configuration)
    return {
        /**
         * Returns all resources from Tbl_Utilisateur_Droit
         * @summary Returns all resources from Tbl_Utilisateur_Droit
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {boolean} [pagination] Enable or disable pagination
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiDroitGetCollection(page?: number, itemsPerPage?: number, pagination?: boolean, xIdSociete?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiDroitGetCollection200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiDroitGetCollection(page, itemsPerPage, pagination, xIdSociete, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DroitsApi.apiDroitGetCollection']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * DroitsApi - factory interface
 * @export
 */
export const DroitsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DroitsApiFp(configuration)
    return {
        /**
         * Returns all resources from Tbl_Utilisateur_Droit
         * @summary Returns all resources from Tbl_Utilisateur_Droit
         * @param {DroitsApiApiDroitGetCollectionRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDroitGetCollection(requestParameters: DroitsApiApiDroitGetCollectionRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<ApiDroitGetCollection200Response> {
            return localVarFp.apiDroitGetCollection(requestParameters.page, requestParameters.itemsPerPage, requestParameters.pagination, requestParameters.xIdSociete, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for apiDroitGetCollection operation in DroitsApi.
 * @export
 * @interface DroitsApiApiDroitGetCollectionRequest
 */
export interface DroitsApiApiDroitGetCollectionRequest {
    /**
     * The collection page number
     * @type {number}
     * @memberof DroitsApiApiDroitGetCollection
     */
    readonly page?: number

    /**
     * The number of items per page
     * @type {number}
     * @memberof DroitsApiApiDroitGetCollection
     */
    readonly itemsPerPage?: number

    /**
     * Enable or disable pagination
     * @type {boolean}
     * @memberof DroitsApiApiDroitGetCollection
     */
    readonly pagination?: boolean

    /**
     * Identifiant de la société
     * @type {number}
     * @memberof DroitsApiApiDroitGetCollection
     */
    readonly xIdSociete?: number
}

/**
 * DroitsApi - object-oriented interface
 * @export
 * @class DroitsApi
 * @extends {BaseAPI}
 */
export class DroitsApi extends BaseAPI {
    /**
     * Returns all resources from Tbl_Utilisateur_Droit
     * @summary Returns all resources from Tbl_Utilisateur_Droit
     * @param {DroitsApiApiDroitGetCollectionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DroitsApi
     */
    public apiDroitGetCollection(requestParameters: DroitsApiApiDroitGetCollectionRequest = {}, options?: RawAxiosRequestConfig) {
        return DroitsApiFp(this.configuration).apiDroitGetCollection(requestParameters.page, requestParameters.itemsPerPage, requestParameters.pagination, requestParameters.xIdSociete, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * GroupesApi - axios parameter creator
 * @export
 */
export const GroupesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Returns the groups collection.
         * @summary Returns the groups collection.
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {boolean} [flagDelete] 
         * @param {boolean} [gieGroup] Filter by group id
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiGroupesGetCollection: async (page?: number, itemsPerPage?: number, flagDelete?: boolean, gieGroup?: boolean, xIdSociete?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/utilisateur/groupes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (itemsPerPage !== undefined) {
                localVarQueryParameter['itemsPerPage'] = itemsPerPage;
            }

            if (flagDelete !== undefined) {
                localVarQueryParameter['flagDelete'] = flagDelete;
            }

            if (gieGroup !== undefined) {
                localVarQueryParameter['gieGroup'] = gieGroup;
            }

            if (xIdSociete != null) {
                localVarHeaderParameter['X-Id-Societe'] = typeof xIdSociete === 'string'
                    ? xIdSociete
                    : JSON.stringify(xIdSociete);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * GroupesApi - functional programming interface
 * @export
 */
export const GroupesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = GroupesApiAxiosParamCreator(configuration)
    return {
        /**
         * Returns the groups collection.
         * @summary Returns the groups collection.
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {boolean} [flagDelete] 
         * @param {boolean} [gieGroup] Filter by group id
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiGroupesGetCollection(page?: number, itemsPerPage?: number, flagDelete?: boolean, gieGroup?: boolean, xIdSociete?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiGroupesGetCollection200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiGroupesGetCollection(page, itemsPerPage, flagDelete, gieGroup, xIdSociete, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['GroupesApi.apiGroupesGetCollection']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * GroupesApi - factory interface
 * @export
 */
export const GroupesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = GroupesApiFp(configuration)
    return {
        /**
         * Returns the groups collection.
         * @summary Returns the groups collection.
         * @param {GroupesApiApiGroupesGetCollectionRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiGroupesGetCollection(requestParameters: GroupesApiApiGroupesGetCollectionRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<ApiGroupesGetCollection200Response> {
            return localVarFp.apiGroupesGetCollection(requestParameters.page, requestParameters.itemsPerPage, requestParameters.flagDelete, requestParameters.gieGroup, requestParameters.xIdSociete, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for apiGroupesGetCollection operation in GroupesApi.
 * @export
 * @interface GroupesApiApiGroupesGetCollectionRequest
 */
export interface GroupesApiApiGroupesGetCollectionRequest {
    /**
     * The collection page number
     * @type {number}
     * @memberof GroupesApiApiGroupesGetCollection
     */
    readonly page?: number

    /**
     * The number of items per page
     * @type {number}
     * @memberof GroupesApiApiGroupesGetCollection
     */
    readonly itemsPerPage?: number

    /**
     * 
     * @type {boolean}
     * @memberof GroupesApiApiGroupesGetCollection
     */
    readonly flagDelete?: boolean

    /**
     * Filter by group id
     * @type {boolean}
     * @memberof GroupesApiApiGroupesGetCollection
     */
    readonly gieGroup?: boolean

    /**
     * Identifiant de la société
     * @type {number}
     * @memberof GroupesApiApiGroupesGetCollection
     */
    readonly xIdSociete?: number
}

/**
 * GroupesApi - object-oriented interface
 * @export
 * @class GroupesApi
 * @extends {BaseAPI}
 */
export class GroupesApi extends BaseAPI {
    /**
     * Returns the groups collection.
     * @summary Returns the groups collection.
     * @param {GroupesApiApiGroupesGetCollectionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GroupesApi
     */
    public apiGroupesGetCollection(requestParameters: GroupesApiApiGroupesGetCollectionRequest = {}, options?: RawAxiosRequestConfig) {
        return GroupesApiFp(this.configuration).apiGroupesGetCollection(requestParameters.page, requestParameters.itemsPerPage, requestParameters.flagDelete, requestParameters.gieGroup, requestParameters.xIdSociete, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * JournauxApi - axios parameter creator
 * @export
 */
export const JournauxApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Returns all resources from Tbl_Utilisateur_Journaux
         * @summary Returns all resources from Tbl_Utilisateur_Journaux
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {boolean} [pagination] Enable or disable pagination
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiJournauxGetCollection: async (page?: number, itemsPerPage?: number, pagination?: boolean, xIdSociete?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/utilisateur/journaux`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (itemsPerPage !== undefined) {
                localVarQueryParameter['itemsPerPage'] = itemsPerPage;
            }

            if (pagination !== undefined) {
                localVarQueryParameter['pagination'] = pagination;
            }

            if (xIdSociete != null) {
                localVarHeaderParameter['X-Id-Societe'] = typeof xIdSociete === 'string'
                    ? xIdSociete
                    : JSON.stringify(xIdSociete);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * JournauxApi - functional programming interface
 * @export
 */
export const JournauxApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = JournauxApiAxiosParamCreator(configuration)
    return {
        /**
         * Returns all resources from Tbl_Utilisateur_Journaux
         * @summary Returns all resources from Tbl_Utilisateur_Journaux
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {boolean} [pagination] Enable or disable pagination
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiJournauxGetCollection(page?: number, itemsPerPage?: number, pagination?: boolean, xIdSociete?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiJournauxGetCollection200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiJournauxGetCollection(page, itemsPerPage, pagination, xIdSociete, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['JournauxApi.apiJournauxGetCollection']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * JournauxApi - factory interface
 * @export
 */
export const JournauxApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = JournauxApiFp(configuration)
    return {
        /**
         * Returns all resources from Tbl_Utilisateur_Journaux
         * @summary Returns all resources from Tbl_Utilisateur_Journaux
         * @param {JournauxApiApiJournauxGetCollectionRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiJournauxGetCollection(requestParameters: JournauxApiApiJournauxGetCollectionRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<ApiJournauxGetCollection200Response> {
            return localVarFp.apiJournauxGetCollection(requestParameters.page, requestParameters.itemsPerPage, requestParameters.pagination, requestParameters.xIdSociete, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for apiJournauxGetCollection operation in JournauxApi.
 * @export
 * @interface JournauxApiApiJournauxGetCollectionRequest
 */
export interface JournauxApiApiJournauxGetCollectionRequest {
    /**
     * The collection page number
     * @type {number}
     * @memberof JournauxApiApiJournauxGetCollection
     */
    readonly page?: number

    /**
     * The number of items per page
     * @type {number}
     * @memberof JournauxApiApiJournauxGetCollection
     */
    readonly itemsPerPage?: number

    /**
     * Enable or disable pagination
     * @type {boolean}
     * @memberof JournauxApiApiJournauxGetCollection
     */
    readonly pagination?: boolean

    /**
     * Identifiant de la société
     * @type {number}
     * @memberof JournauxApiApiJournauxGetCollection
     */
    readonly xIdSociete?: number
}

/**
 * JournauxApi - object-oriented interface
 * @export
 * @class JournauxApi
 * @extends {BaseAPI}
 */
export class JournauxApi extends BaseAPI {
    /**
     * Returns all resources from Tbl_Utilisateur_Journaux
     * @summary Returns all resources from Tbl_Utilisateur_Journaux
     * @param {JournauxApiApiJournauxGetCollectionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof JournauxApi
     */
    public apiJournauxGetCollection(requestParameters: JournauxApiApiJournauxGetCollectionRequest = {}, options?: RawAxiosRequestConfig) {
        return JournauxApiFp(this.configuration).apiJournauxGetCollection(requestParameters.page, requestParameters.itemsPerPage, requestParameters.pagination, requestParameters.xIdSociete, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * MarquesApi - axios parameter creator
 * @export
 */
export const MarquesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Returns the society\'s brands list.
         * @summary Returns the society\'s brands list.
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {boolean} [flagDelete] 
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMarquesGetCollection: async (page?: number, itemsPerPage?: number, flagDelete?: boolean, xIdSociete?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/utilisateur/marques`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (itemsPerPage !== undefined) {
                localVarQueryParameter['itemsPerPage'] = itemsPerPage;
            }

            if (flagDelete !== undefined) {
                localVarQueryParameter['flagDelete'] = flagDelete;
            }

            if (xIdSociete != null) {
                localVarHeaderParameter['X-Id-Societe'] = typeof xIdSociete === 'string'
                    ? xIdSociete
                    : JSON.stringify(xIdSociete);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MarquesApi - functional programming interface
 * @export
 */
export const MarquesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MarquesApiAxiosParamCreator(configuration)
    return {
        /**
         * Returns the society\'s brands list.
         * @summary Returns the society\'s brands list.
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {boolean} [flagDelete] 
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiMarquesGetCollection(page?: number, itemsPerPage?: number, flagDelete?: boolean, xIdSociete?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiMarquesGetCollection200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiMarquesGetCollection(page, itemsPerPage, flagDelete, xIdSociete, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MarquesApi.apiMarquesGetCollection']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * MarquesApi - factory interface
 * @export
 */
export const MarquesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MarquesApiFp(configuration)
    return {
        /**
         * Returns the society\'s brands list.
         * @summary Returns the society\'s brands list.
         * @param {MarquesApiApiMarquesGetCollectionRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMarquesGetCollection(requestParameters: MarquesApiApiMarquesGetCollectionRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<ApiMarquesGetCollection200Response> {
            return localVarFp.apiMarquesGetCollection(requestParameters.page, requestParameters.itemsPerPage, requestParameters.flagDelete, requestParameters.xIdSociete, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for apiMarquesGetCollection operation in MarquesApi.
 * @export
 * @interface MarquesApiApiMarquesGetCollectionRequest
 */
export interface MarquesApiApiMarquesGetCollectionRequest {
    /**
     * The collection page number
     * @type {number}
     * @memberof MarquesApiApiMarquesGetCollection
     */
    readonly page?: number

    /**
     * The number of items per page
     * @type {number}
     * @memberof MarquesApiApiMarquesGetCollection
     */
    readonly itemsPerPage?: number

    /**
     * 
     * @type {boolean}
     * @memberof MarquesApiApiMarquesGetCollection
     */
    readonly flagDelete?: boolean

    /**
     * Identifiant de la société
     * @type {number}
     * @memberof MarquesApiApiMarquesGetCollection
     */
    readonly xIdSociete?: number
}

/**
 * MarquesApi - object-oriented interface
 * @export
 * @class MarquesApi
 * @extends {BaseAPI}
 */
export class MarquesApi extends BaseAPI {
    /**
     * Returns the society\'s brands list.
     * @summary Returns the society\'s brands list.
     * @param {MarquesApiApiMarquesGetCollectionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MarquesApi
     */
    public apiMarquesGetCollection(requestParameters: MarquesApiApiMarquesGetCollectionRequest = {}, options?: RawAxiosRequestConfig) {
        return MarquesApiFp(this.configuration).apiMarquesGetCollection(requestParameters.page, requestParameters.itemsPerPage, requestParameters.flagDelete, requestParameters.xIdSociete, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * PolesApi - axios parameter creator
 * @export
 */
export const PolesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Returns the TBL poles collection.
         * @summary Returns the TBL poles collection.
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPolesGetCollection: async (page?: number, itemsPerPage?: number, xIdSociete?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/utilisateur/poles`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (itemsPerPage !== undefined) {
                localVarQueryParameter['itemsPerPage'] = itemsPerPage;
            }

            if (xIdSociete != null) {
                localVarHeaderParameter['X-Id-Societe'] = typeof xIdSociete === 'string'
                    ? xIdSociete
                    : JSON.stringify(xIdSociete);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PolesApi - functional programming interface
 * @export
 */
export const PolesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PolesApiAxiosParamCreator(configuration)
    return {
        /**
         * Returns the TBL poles collection.
         * @summary Returns the TBL poles collection.
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiPolesGetCollection(page?: number, itemsPerPage?: number, xIdSociete?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiPolesGetCollection200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiPolesGetCollection(page, itemsPerPage, xIdSociete, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PolesApi.apiPolesGetCollection']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * PolesApi - factory interface
 * @export
 */
export const PolesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PolesApiFp(configuration)
    return {
        /**
         * Returns the TBL poles collection.
         * @summary Returns the TBL poles collection.
         * @param {PolesApiApiPolesGetCollectionRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPolesGetCollection(requestParameters: PolesApiApiPolesGetCollectionRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<ApiPolesGetCollection200Response> {
            return localVarFp.apiPolesGetCollection(requestParameters.page, requestParameters.itemsPerPage, requestParameters.xIdSociete, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for apiPolesGetCollection operation in PolesApi.
 * @export
 * @interface PolesApiApiPolesGetCollectionRequest
 */
export interface PolesApiApiPolesGetCollectionRequest {
    /**
     * The collection page number
     * @type {number}
     * @memberof PolesApiApiPolesGetCollection
     */
    readonly page?: number

    /**
     * The number of items per page
     * @type {number}
     * @memberof PolesApiApiPolesGetCollection
     */
    readonly itemsPerPage?: number

    /**
     * Identifiant de la société
     * @type {number}
     * @memberof PolesApiApiPolesGetCollection
     */
    readonly xIdSociete?: number
}

/**
 * PolesApi - object-oriented interface
 * @export
 * @class PolesApi
 * @extends {BaseAPI}
 */
export class PolesApi extends BaseAPI {
    /**
     * Returns the TBL poles collection.
     * @summary Returns the TBL poles collection.
     * @param {PolesApiApiPolesGetCollectionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PolesApi
     */
    public apiPolesGetCollection(requestParameters: PolesApiApiPolesGetCollectionRequest = {}, options?: RawAxiosRequestConfig) {
        return PolesApiFp(this.configuration).apiPolesGetCollection(requestParameters.page, requestParameters.itemsPerPage, requestParameters.xIdSociete, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * ServicesApi - axios parameter creator
 * @export
 */
export const ServicesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Returns all resources from Tbl_Def_Service
         * @summary Returns all resources from Tbl_Def_Service
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {boolean} [pagination] Enable or disable pagination
         * @param {boolean} [assignable] dsv_id 0 and 999 filter
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesGetCollection: async (page?: number, itemsPerPage?: number, pagination?: boolean, assignable?: boolean, xIdSociete?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/utilisateur/services`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (itemsPerPage !== undefined) {
                localVarQueryParameter['itemsPerPage'] = itemsPerPage;
            }

            if (pagination !== undefined) {
                localVarQueryParameter['pagination'] = pagination;
            }

            if (assignable !== undefined) {
                localVarQueryParameter['assignable'] = assignable;
            }

            if (xIdSociete != null) {
                localVarHeaderParameter['X-Id-Societe'] = typeof xIdSociete === 'string'
                    ? xIdSociete
                    : JSON.stringify(xIdSociete);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ServicesApi - functional programming interface
 * @export
 */
export const ServicesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ServicesApiAxiosParamCreator(configuration)
    return {
        /**
         * Returns all resources from Tbl_Def_Service
         * @summary Returns all resources from Tbl_Def_Service
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {boolean} [pagination] Enable or disable pagination
         * @param {boolean} [assignable] dsv_id 0 and 999 filter
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiServicesGetCollection(page?: number, itemsPerPage?: number, pagination?: boolean, assignable?: boolean, xIdSociete?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiServicesGetCollection200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiServicesGetCollection(page, itemsPerPage, pagination, assignable, xIdSociete, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ServicesApi.apiServicesGetCollection']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * ServicesApi - factory interface
 * @export
 */
export const ServicesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ServicesApiFp(configuration)
    return {
        /**
         * Returns all resources from Tbl_Def_Service
         * @summary Returns all resources from Tbl_Def_Service
         * @param {ServicesApiApiServicesGetCollectionRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServicesGetCollection(requestParameters: ServicesApiApiServicesGetCollectionRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<ApiServicesGetCollection200Response> {
            return localVarFp.apiServicesGetCollection(requestParameters.page, requestParameters.itemsPerPage, requestParameters.pagination, requestParameters.assignable, requestParameters.xIdSociete, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for apiServicesGetCollection operation in ServicesApi.
 * @export
 * @interface ServicesApiApiServicesGetCollectionRequest
 */
export interface ServicesApiApiServicesGetCollectionRequest {
    /**
     * The collection page number
     * @type {number}
     * @memberof ServicesApiApiServicesGetCollection
     */
    readonly page?: number

    /**
     * The number of items per page
     * @type {number}
     * @memberof ServicesApiApiServicesGetCollection
     */
    readonly itemsPerPage?: number

    /**
     * Enable or disable pagination
     * @type {boolean}
     * @memberof ServicesApiApiServicesGetCollection
     */
    readonly pagination?: boolean

    /**
     * dsv_id 0 and 999 filter
     * @type {boolean}
     * @memberof ServicesApiApiServicesGetCollection
     */
    readonly assignable?: boolean

    /**
     * Identifiant de la société
     * @type {number}
     * @memberof ServicesApiApiServicesGetCollection
     */
    readonly xIdSociete?: number
}

/**
 * ServicesApi - object-oriented interface
 * @export
 * @class ServicesApi
 * @extends {BaseAPI}
 */
export class ServicesApi extends BaseAPI {
    /**
     * Returns all resources from Tbl_Def_Service
     * @summary Returns all resources from Tbl_Def_Service
     * @param {ServicesApiApiServicesGetCollectionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServicesApi
     */
    public apiServicesGetCollection(requestParameters: ServicesApiApiServicesGetCollectionRequest = {}, options?: RawAxiosRequestConfig) {
        return ServicesApiFp(this.configuration).apiServicesGetCollection(requestParameters.page, requestParameters.itemsPerPage, requestParameters.pagination, requestParameters.assignable, requestParameters.xIdSociete, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * UserApi - axios parameter creator
 * @export
 */
export const UserApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Updates the User resource.
         * @summary Updates the User resource.
         * @param {string} id User identifier
         * @param {UserUpdateUser} userUpdateUser The updated User resource
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiKeycloakUtilisateurIdPatch: async (id: string, userUpdateUser: UserUpdateUser, xIdSociete?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiKeycloakUtilisateurIdPatch', 'id', id)
            // verify required parameter 'userUpdateUser' is not null or undefined
            assertParamExists('apiKeycloakUtilisateurIdPatch', 'userUpdateUser', userUpdateUser)
            const localVarPath = `/utilisateur/keycloak-utilisateur/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (xIdSociete != null) {
                localVarHeaderParameter['X-Id-Societe'] = typeof xIdSociete === 'string'
                    ? xIdSociete
                    : JSON.stringify(xIdSociete);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/merge-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userUpdateUser, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Creates a User resource.
         * @summary Creates a User resource.
         * @param {UserCreateUserJsonld} userCreateUserJsonld The new User resource
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiKeycloakUtilisateurPost: async (userCreateUserJsonld: UserCreateUserJsonld, xIdSociete?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userCreateUserJsonld' is not null or undefined
            assertParamExists('apiKeycloakUtilisateurPost', 'userCreateUserJsonld', userCreateUserJsonld)
            const localVarPath = `/utilisateur/keycloak-utilisateur`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (xIdSociete != null) {
                localVarHeaderParameter['X-Id-Societe'] = typeof xIdSociete === 'string'
                    ? xIdSociete
                    : JSON.stringify(xIdSociete);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/ld+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userCreateUserJsonld, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves the collection of User resources.
         * @summary Retrieves the collection of User resources.
         * @param {string} prescripteurId User identifier
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiKeycloakUtilisateursPrescripteurIdGetCollection: async (prescripteurId: string, page?: number, itemsPerPage?: number, xIdSociete?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'prescripteurId' is not null or undefined
            assertParamExists('apiKeycloakUtilisateursPrescripteurIdGetCollection', 'prescripteurId', prescripteurId)
            const localVarPath = `/utilisateur/keycloak-utilisateurs/{prescripteurId}`
                .replace(`{${"prescripteurId"}}`, encodeURIComponent(String(prescripteurId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (itemsPerPage !== undefined) {
                localVarQueryParameter['itemsPerPage'] = itemsPerPage;
            }

            if (xIdSociete != null) {
                localVarHeaderParameter['X-Id-Societe'] = typeof xIdSociete === 'string'
                    ? xIdSociete
                    : JSON.stringify(xIdSociete);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UserApi - functional programming interface
 * @export
 */
export const UserApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UserApiAxiosParamCreator(configuration)
    return {
        /**
         * Updates the User resource.
         * @summary Updates the User resource.
         * @param {string} id User identifier
         * @param {UserUpdateUser} userUpdateUser The updated User resource
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiKeycloakUtilisateurIdPatch(id: string, userUpdateUser: UserUpdateUser, xIdSociete?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserJsonld>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiKeycloakUtilisateurIdPatch(id, userUpdateUser, xIdSociete, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserApi.apiKeycloakUtilisateurIdPatch']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Creates a User resource.
         * @summary Creates a User resource.
         * @param {UserCreateUserJsonld} userCreateUserJsonld The new User resource
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiKeycloakUtilisateurPost(userCreateUserJsonld: UserCreateUserJsonld, xIdSociete?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserJsonld>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiKeycloakUtilisateurPost(userCreateUserJsonld, xIdSociete, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserApi.apiKeycloakUtilisateurPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Retrieves the collection of User resources.
         * @summary Retrieves the collection of User resources.
         * @param {string} prescripteurId User identifier
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiKeycloakUtilisateursPrescripteurIdGetCollection(prescripteurId: string, page?: number, itemsPerPage?: number, xIdSociete?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiKeycloakUtilisateursPrescripteurIdGetCollection200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiKeycloakUtilisateursPrescripteurIdGetCollection(prescripteurId, page, itemsPerPage, xIdSociete, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserApi.apiKeycloakUtilisateursPrescripteurIdGetCollection']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * UserApi - factory interface
 * @export
 */
export const UserApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UserApiFp(configuration)
    return {
        /**
         * Updates the User resource.
         * @summary Updates the User resource.
         * @param {UserApiApiKeycloakUtilisateurIdPatchRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiKeycloakUtilisateurIdPatch(requestParameters: UserApiApiKeycloakUtilisateurIdPatchRequest, options?: RawAxiosRequestConfig): AxiosPromise<UserJsonld> {
            return localVarFp.apiKeycloakUtilisateurIdPatch(requestParameters.id, requestParameters.userUpdateUser, requestParameters.xIdSociete, options).then((request) => request(axios, basePath));
        },
        /**
         * Creates a User resource.
         * @summary Creates a User resource.
         * @param {UserApiApiKeycloakUtilisateurPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiKeycloakUtilisateurPost(requestParameters: UserApiApiKeycloakUtilisateurPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<UserJsonld> {
            return localVarFp.apiKeycloakUtilisateurPost(requestParameters.userCreateUserJsonld, requestParameters.xIdSociete, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieves the collection of User resources.
         * @summary Retrieves the collection of User resources.
         * @param {UserApiApiKeycloakUtilisateursPrescripteurIdGetCollectionRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiKeycloakUtilisateursPrescripteurIdGetCollection(requestParameters: UserApiApiKeycloakUtilisateursPrescripteurIdGetCollectionRequest, options?: RawAxiosRequestConfig): AxiosPromise<ApiKeycloakUtilisateursPrescripteurIdGetCollection200Response> {
            return localVarFp.apiKeycloakUtilisateursPrescripteurIdGetCollection(requestParameters.prescripteurId, requestParameters.page, requestParameters.itemsPerPage, requestParameters.xIdSociete, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for apiKeycloakUtilisateurIdPatch operation in UserApi.
 * @export
 * @interface UserApiApiKeycloakUtilisateurIdPatchRequest
 */
export interface UserApiApiKeycloakUtilisateurIdPatchRequest {
    /**
     * User identifier
     * @type {string}
     * @memberof UserApiApiKeycloakUtilisateurIdPatch
     */
    readonly id: string

    /**
     * The updated User resource
     * @type {UserUpdateUser}
     * @memberof UserApiApiKeycloakUtilisateurIdPatch
     */
    readonly userUpdateUser: UserUpdateUser

    /**
     * Identifiant de la société
     * @type {number}
     * @memberof UserApiApiKeycloakUtilisateurIdPatch
     */
    readonly xIdSociete?: number
}

/**
 * Request parameters for apiKeycloakUtilisateurPost operation in UserApi.
 * @export
 * @interface UserApiApiKeycloakUtilisateurPostRequest
 */
export interface UserApiApiKeycloakUtilisateurPostRequest {
    /**
     * The new User resource
     * @type {UserCreateUserJsonld}
     * @memberof UserApiApiKeycloakUtilisateurPost
     */
    readonly userCreateUserJsonld: UserCreateUserJsonld

    /**
     * Identifiant de la société
     * @type {number}
     * @memberof UserApiApiKeycloakUtilisateurPost
     */
    readonly xIdSociete?: number
}

/**
 * Request parameters for apiKeycloakUtilisateursPrescripteurIdGetCollection operation in UserApi.
 * @export
 * @interface UserApiApiKeycloakUtilisateursPrescripteurIdGetCollectionRequest
 */
export interface UserApiApiKeycloakUtilisateursPrescripteurIdGetCollectionRequest {
    /**
     * User identifier
     * @type {string}
     * @memberof UserApiApiKeycloakUtilisateursPrescripteurIdGetCollection
     */
    readonly prescripteurId: string

    /**
     * The collection page number
     * @type {number}
     * @memberof UserApiApiKeycloakUtilisateursPrescripteurIdGetCollection
     */
    readonly page?: number

    /**
     * The number of items per page
     * @type {number}
     * @memberof UserApiApiKeycloakUtilisateursPrescripteurIdGetCollection
     */
    readonly itemsPerPage?: number

    /**
     * Identifiant de la société
     * @type {number}
     * @memberof UserApiApiKeycloakUtilisateursPrescripteurIdGetCollection
     */
    readonly xIdSociete?: number
}

/**
 * UserApi - object-oriented interface
 * @export
 * @class UserApi
 * @extends {BaseAPI}
 */
export class UserApi extends BaseAPI {
    /**
     * Updates the User resource.
     * @summary Updates the User resource.
     * @param {UserApiApiKeycloakUtilisateurIdPatchRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public apiKeycloakUtilisateurIdPatch(requestParameters: UserApiApiKeycloakUtilisateurIdPatchRequest, options?: RawAxiosRequestConfig) {
        return UserApiFp(this.configuration).apiKeycloakUtilisateurIdPatch(requestParameters.id, requestParameters.userUpdateUser, requestParameters.xIdSociete, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Creates a User resource.
     * @summary Creates a User resource.
     * @param {UserApiApiKeycloakUtilisateurPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public apiKeycloakUtilisateurPost(requestParameters: UserApiApiKeycloakUtilisateurPostRequest, options?: RawAxiosRequestConfig) {
        return UserApiFp(this.configuration).apiKeycloakUtilisateurPost(requestParameters.userCreateUserJsonld, requestParameters.xIdSociete, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves the collection of User resources.
     * @summary Retrieves the collection of User resources.
     * @param {UserApiApiKeycloakUtilisateursPrescripteurIdGetCollectionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public apiKeycloakUtilisateursPrescripteurIdGetCollection(requestParameters: UserApiApiKeycloakUtilisateursPrescripteurIdGetCollectionRequest, options?: RawAxiosRequestConfig) {
        return UserApiFp(this.configuration).apiKeycloakUtilisateursPrescripteurIdGetCollection(requestParameters.prescripteurId, requestParameters.page, requestParameters.itemsPerPage, requestParameters.xIdSociete, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * UtilisateursApi - axios parameter creator
 * @export
 */
export const UtilisateursApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Returns the users collection
         * @summary Returns the users collection
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {boolean} [isDeleted] 
         * @param {boolean} [isFormalist] 
         * @param {boolean} [hasG3Access] 
         * @param {boolean} [hasComptaSoftware] 
         * @param {boolean} [hasAccessL2DF] 
         * @param {boolean} [isAdminApps] 
         * @param {string} [mail] 
         * @param {Array<string>} [mail2] 
         * @param {string} [user] 
         * @param {Array<string>} [user2] 
         * @param {ApiUtilisateursGetCollectionOrderIdEnum} [orderId] 
         * @param {ApiUtilisateursGetCollectionOrderNomEnum} [orderNom] 
         * @param {ApiUtilisateursGetCollectionOrderUserEnum} [orderUser] 
         * @param {ApiUtilisateursGetCollectionOrderMailEnum} [orderMail] 
         * @param {ApiUtilisateursGetCollectionOrderPoleIdEnum} [orderPoleId] 
         * @param {string} [multiFieldSearch] Partial search in Nom, Prenom, Login, Mail and Initiales
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUtilisateursGetCollection: async (page?: number, itemsPerPage?: number, isDeleted?: boolean, isFormalist?: boolean, hasG3Access?: boolean, hasComptaSoftware?: boolean, hasAccessL2DF?: boolean, isAdminApps?: boolean, mail?: string, mail2?: Array<string>, user?: string, user2?: Array<string>, orderId?: ApiUtilisateursGetCollectionOrderIdEnum, orderNom?: ApiUtilisateursGetCollectionOrderNomEnum, orderUser?: ApiUtilisateursGetCollectionOrderUserEnum, orderMail?: ApiUtilisateursGetCollectionOrderMailEnum, orderPoleId?: ApiUtilisateursGetCollectionOrderPoleIdEnum, multiFieldSearch?: string, xIdSociete?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/utilisateur/utilisateurs`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (itemsPerPage !== undefined) {
                localVarQueryParameter['itemsPerPage'] = itemsPerPage;
            }

            if (isDeleted !== undefined) {
                localVarQueryParameter['isDeleted'] = isDeleted;
            }

            if (isFormalist !== undefined) {
                localVarQueryParameter['isFormalist'] = isFormalist;
            }

            if (hasG3Access !== undefined) {
                localVarQueryParameter['hasG3Access'] = hasG3Access;
            }

            if (hasComptaSoftware !== undefined) {
                localVarQueryParameter['hasComptaSoftware'] = hasComptaSoftware;
            }

            if (hasAccessL2DF !== undefined) {
                localVarQueryParameter['hasAccessL2DF'] = hasAccessL2DF;
            }

            if (isAdminApps !== undefined) {
                localVarQueryParameter['isAdminApps'] = isAdminApps;
            }

            if (mail !== undefined) {
                localVarQueryParameter['mail'] = mail;
            }

            if (mail2) {
                localVarQueryParameter['mail[]'] = mail2;
            }

            if (user !== undefined) {
                localVarQueryParameter['user'] = user;
            }

            if (user2) {
                localVarQueryParameter['user[]'] = user2;
            }

            if (orderId !== undefined) {
                localVarQueryParameter['order[id]'] = orderId;
            }

            if (orderNom !== undefined) {
                localVarQueryParameter['order[nom]'] = orderNom;
            }

            if (orderUser !== undefined) {
                localVarQueryParameter['order[user]'] = orderUser;
            }

            if (orderMail !== undefined) {
                localVarQueryParameter['order[mail]'] = orderMail;
            }

            if (orderPoleId !== undefined) {
                localVarQueryParameter['order[pole.id]'] = orderPoleId;
            }

            if (multiFieldSearch !== undefined) {
                localVarQueryParameter['MultiFieldSearch'] = multiFieldSearch;
            }

            if (xIdSociete != null) {
                localVarHeaderParameter['X-Id-Societe'] = typeof xIdSociete === 'string'
                    ? xIdSociete
                    : JSON.stringify(xIdSociete);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete the user
         * @summary Delete the user
         * @param {string} id Utilisateurs identifier
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUtilisateursIdDelete: async (id: string, xIdSociete?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiUtilisateursIdDelete', 'id', id)
            const localVarPath = `/utilisateur/utilisateurs/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (xIdSociete != null) {
                localVarHeaderParameter['X-Id-Societe'] = typeof xIdSociete === 'string'
                    ? xIdSociete
                    : JSON.stringify(xIdSociete);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns the selected user informations
         * @summary Returns the selected user informations
         * @param {string} id Utilisateurs identifier
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUtilisateursIdGet: async (id: string, xIdSociete?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiUtilisateursIdGet', 'id', id)
            const localVarPath = `/utilisateur/utilisateurs/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (xIdSociete != null) {
                localVarHeaderParameter['X-Id-Societe'] = typeof xIdSociete === 'string'
                    ? xIdSociete
                    : JSON.stringify(xIdSociete);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Updates the selected user informations
         * @summary Updates the selected user informations
         * @param {string} id Utilisateurs identifier
         * @param {UtilisateursJsonldUtilisateurUpdateUtilisateur} utilisateursJsonldUtilisateurUpdateUtilisateur The updated Utilisateurs resource
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUtilisateursIdPut: async (id: string, utilisateursJsonldUtilisateurUpdateUtilisateur: UtilisateursJsonldUtilisateurUpdateUtilisateur, xIdSociete?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiUtilisateursIdPut', 'id', id)
            // verify required parameter 'utilisateursJsonldUtilisateurUpdateUtilisateur' is not null or undefined
            assertParamExists('apiUtilisateursIdPut', 'utilisateursJsonldUtilisateurUpdateUtilisateur', utilisateursJsonldUtilisateurUpdateUtilisateur)
            const localVarPath = `/utilisateur/utilisateurs/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (xIdSociete != null) {
                localVarHeaderParameter['X-Id-Societe'] = typeof xIdSociete === 'string'
                    ? xIdSociete
                    : JSON.stringify(xIdSociete);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/ld+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(utilisateursJsonldUtilisateurUpdateUtilisateur, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Create a user
         * @summary Create a user
         * @param {UtilisateursJsonldUtilisateurWrite} utilisateursJsonldUtilisateurWrite The new Utilisateurs resource
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUtilisateursPost: async (utilisateursJsonldUtilisateurWrite: UtilisateursJsonldUtilisateurWrite, xIdSociete?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'utilisateursJsonldUtilisateurWrite' is not null or undefined
            assertParamExists('apiUtilisateursPost', 'utilisateursJsonldUtilisateurWrite', utilisateursJsonldUtilisateurWrite)
            const localVarPath = `/utilisateur/utilisateurs`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (xIdSociete != null) {
                localVarHeaderParameter['X-Id-Societe'] = typeof xIdSociete === 'string'
                    ? xIdSociete
                    : JSON.stringify(xIdSociete);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/ld+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(utilisateursJsonldUtilisateurWrite, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UtilisateursApi - functional programming interface
 * @export
 */
export const UtilisateursApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UtilisateursApiAxiosParamCreator(configuration)
    return {
        /**
         * Returns the users collection
         * @summary Returns the users collection
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {boolean} [isDeleted] 
         * @param {boolean} [isFormalist] 
         * @param {boolean} [hasG3Access] 
         * @param {boolean} [hasComptaSoftware] 
         * @param {boolean} [hasAccessL2DF] 
         * @param {boolean} [isAdminApps] 
         * @param {string} [mail] 
         * @param {Array<string>} [mail2] 
         * @param {string} [user] 
         * @param {Array<string>} [user2] 
         * @param {ApiUtilisateursGetCollectionOrderIdEnum} [orderId] 
         * @param {ApiUtilisateursGetCollectionOrderNomEnum} [orderNom] 
         * @param {ApiUtilisateursGetCollectionOrderUserEnum} [orderUser] 
         * @param {ApiUtilisateursGetCollectionOrderMailEnum} [orderMail] 
         * @param {ApiUtilisateursGetCollectionOrderPoleIdEnum} [orderPoleId] 
         * @param {string} [multiFieldSearch] Partial search in Nom, Prenom, Login, Mail and Initiales
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiUtilisateursGetCollection(page?: number, itemsPerPage?: number, isDeleted?: boolean, isFormalist?: boolean, hasG3Access?: boolean, hasComptaSoftware?: boolean, hasAccessL2DF?: boolean, isAdminApps?: boolean, mail?: string, mail2?: Array<string>, user?: string, user2?: Array<string>, orderId?: ApiUtilisateursGetCollectionOrderIdEnum, orderNom?: ApiUtilisateursGetCollectionOrderNomEnum, orderUser?: ApiUtilisateursGetCollectionOrderUserEnum, orderMail?: ApiUtilisateursGetCollectionOrderMailEnum, orderPoleId?: ApiUtilisateursGetCollectionOrderPoleIdEnum, multiFieldSearch?: string, xIdSociete?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiUtilisateursGetCollection200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiUtilisateursGetCollection(page, itemsPerPage, isDeleted, isFormalist, hasG3Access, hasComptaSoftware, hasAccessL2DF, isAdminApps, mail, mail2, user, user2, orderId, orderNom, orderUser, orderMail, orderPoleId, multiFieldSearch, xIdSociete, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UtilisateursApi.apiUtilisateursGetCollection']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Delete the user
         * @summary Delete the user
         * @param {string} id Utilisateurs identifier
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiUtilisateursIdDelete(id: string, xIdSociete?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiUtilisateursIdDelete(id, xIdSociete, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UtilisateursApi.apiUtilisateursIdDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Returns the selected user informations
         * @summary Returns the selected user informations
         * @param {string} id Utilisateurs identifier
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiUtilisateursIdGet(id: string, xIdSociete?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UtilisateursJsonldUtilisateurRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiUtilisateursIdGet(id, xIdSociete, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UtilisateursApi.apiUtilisateursIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Updates the selected user informations
         * @summary Updates the selected user informations
         * @param {string} id Utilisateurs identifier
         * @param {UtilisateursJsonldUtilisateurUpdateUtilisateur} utilisateursJsonldUtilisateurUpdateUtilisateur The updated Utilisateurs resource
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiUtilisateursIdPut(id: string, utilisateursJsonldUtilisateurUpdateUtilisateur: UtilisateursJsonldUtilisateurUpdateUtilisateur, xIdSociete?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UtilisateursJsonldUtilisateurUpdateUtilisateur>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiUtilisateursIdPut(id, utilisateursJsonldUtilisateurUpdateUtilisateur, xIdSociete, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UtilisateursApi.apiUtilisateursIdPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Create a user
         * @summary Create a user
         * @param {UtilisateursJsonldUtilisateurWrite} utilisateursJsonldUtilisateurWrite The new Utilisateurs resource
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiUtilisateursPost(utilisateursJsonldUtilisateurWrite: UtilisateursJsonldUtilisateurWrite, xIdSociete?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UtilisateursJsonldUtilisateurWrite>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiUtilisateursPost(utilisateursJsonldUtilisateurWrite, xIdSociete, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UtilisateursApi.apiUtilisateursPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * UtilisateursApi - factory interface
 * @export
 */
export const UtilisateursApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UtilisateursApiFp(configuration)
    return {
        /**
         * Returns the users collection
         * @summary Returns the users collection
         * @param {UtilisateursApiApiUtilisateursGetCollectionRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUtilisateursGetCollection(requestParameters: UtilisateursApiApiUtilisateursGetCollectionRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<ApiUtilisateursGetCollection200Response> {
            return localVarFp.apiUtilisateursGetCollection(requestParameters.page, requestParameters.itemsPerPage, requestParameters.isDeleted, requestParameters.isFormalist, requestParameters.hasG3Access, requestParameters.hasComptaSoftware, requestParameters.hasAccessL2DF, requestParameters.isAdminApps, requestParameters.mail, requestParameters.mail2, requestParameters.user, requestParameters.user2, requestParameters.orderId, requestParameters.orderNom, requestParameters.orderUser, requestParameters.orderMail, requestParameters.orderPoleId, requestParameters.multiFieldSearch, requestParameters.xIdSociete, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete the user
         * @summary Delete the user
         * @param {UtilisateursApiApiUtilisateursIdDeleteRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUtilisateursIdDelete(requestParameters: UtilisateursApiApiUtilisateursIdDeleteRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiUtilisateursIdDelete(requestParameters.id, requestParameters.xIdSociete, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns the selected user informations
         * @summary Returns the selected user informations
         * @param {UtilisateursApiApiUtilisateursIdGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUtilisateursIdGet(requestParameters: UtilisateursApiApiUtilisateursIdGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<UtilisateursJsonldUtilisateurRead> {
            return localVarFp.apiUtilisateursIdGet(requestParameters.id, requestParameters.xIdSociete, options).then((request) => request(axios, basePath));
        },
        /**
         * Updates the selected user informations
         * @summary Updates the selected user informations
         * @param {UtilisateursApiApiUtilisateursIdPutRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUtilisateursIdPut(requestParameters: UtilisateursApiApiUtilisateursIdPutRequest, options?: RawAxiosRequestConfig): AxiosPromise<UtilisateursJsonldUtilisateurUpdateUtilisateur> {
            return localVarFp.apiUtilisateursIdPut(requestParameters.id, requestParameters.utilisateursJsonldUtilisateurUpdateUtilisateur, requestParameters.xIdSociete, options).then((request) => request(axios, basePath));
        },
        /**
         * Create a user
         * @summary Create a user
         * @param {UtilisateursApiApiUtilisateursPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUtilisateursPost(requestParameters: UtilisateursApiApiUtilisateursPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<UtilisateursJsonldUtilisateurWrite> {
            return localVarFp.apiUtilisateursPost(requestParameters.utilisateursJsonldUtilisateurWrite, requestParameters.xIdSociete, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for apiUtilisateursGetCollection operation in UtilisateursApi.
 * @export
 * @interface UtilisateursApiApiUtilisateursGetCollectionRequest
 */
export interface UtilisateursApiApiUtilisateursGetCollectionRequest {
    /**
     * The collection page number
     * @type {number}
     * @memberof UtilisateursApiApiUtilisateursGetCollection
     */
    readonly page?: number

    /**
     * The number of items per page
     * @type {number}
     * @memberof UtilisateursApiApiUtilisateursGetCollection
     */
    readonly itemsPerPage?: number

    /**
     * 
     * @type {boolean}
     * @memberof UtilisateursApiApiUtilisateursGetCollection
     */
    readonly isDeleted?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof UtilisateursApiApiUtilisateursGetCollection
     */
    readonly isFormalist?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof UtilisateursApiApiUtilisateursGetCollection
     */
    readonly hasG3Access?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof UtilisateursApiApiUtilisateursGetCollection
     */
    readonly hasComptaSoftware?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof UtilisateursApiApiUtilisateursGetCollection
     */
    readonly hasAccessL2DF?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof UtilisateursApiApiUtilisateursGetCollection
     */
    readonly isAdminApps?: boolean

    /**
     * 
     * @type {string}
     * @memberof UtilisateursApiApiUtilisateursGetCollection
     */
    readonly mail?: string

    /**
     * 
     * @type {Array<string>}
     * @memberof UtilisateursApiApiUtilisateursGetCollection
     */
    readonly mail2?: Array<string>

    /**
     * 
     * @type {string}
     * @memberof UtilisateursApiApiUtilisateursGetCollection
     */
    readonly user?: string

    /**
     * 
     * @type {Array<string>}
     * @memberof UtilisateursApiApiUtilisateursGetCollection
     */
    readonly user2?: Array<string>

    /**
     * 
     * @type {'asc' | 'desc'}
     * @memberof UtilisateursApiApiUtilisateursGetCollection
     */
    readonly orderId?: ApiUtilisateursGetCollectionOrderIdEnum

    /**
     * 
     * @type {'asc' | 'desc'}
     * @memberof UtilisateursApiApiUtilisateursGetCollection
     */
    readonly orderNom?: ApiUtilisateursGetCollectionOrderNomEnum

    /**
     * 
     * @type {'asc' | 'desc'}
     * @memberof UtilisateursApiApiUtilisateursGetCollection
     */
    readonly orderUser?: ApiUtilisateursGetCollectionOrderUserEnum

    /**
     * 
     * @type {'asc' | 'desc'}
     * @memberof UtilisateursApiApiUtilisateursGetCollection
     */
    readonly orderMail?: ApiUtilisateursGetCollectionOrderMailEnum

    /**
     * 
     * @type {'asc' | 'desc'}
     * @memberof UtilisateursApiApiUtilisateursGetCollection
     */
    readonly orderPoleId?: ApiUtilisateursGetCollectionOrderPoleIdEnum

    /**
     * Partial search in Nom, Prenom, Login, Mail and Initiales
     * @type {string}
     * @memberof UtilisateursApiApiUtilisateursGetCollection
     */
    readonly multiFieldSearch?: string

    /**
     * Identifiant de la société
     * @type {number}
     * @memberof UtilisateursApiApiUtilisateursGetCollection
     */
    readonly xIdSociete?: number
}

/**
 * Request parameters for apiUtilisateursIdDelete operation in UtilisateursApi.
 * @export
 * @interface UtilisateursApiApiUtilisateursIdDeleteRequest
 */
export interface UtilisateursApiApiUtilisateursIdDeleteRequest {
    /**
     * Utilisateurs identifier
     * @type {string}
     * @memberof UtilisateursApiApiUtilisateursIdDelete
     */
    readonly id: string

    /**
     * Identifiant de la société
     * @type {number}
     * @memberof UtilisateursApiApiUtilisateursIdDelete
     */
    readonly xIdSociete?: number
}

/**
 * Request parameters for apiUtilisateursIdGet operation in UtilisateursApi.
 * @export
 * @interface UtilisateursApiApiUtilisateursIdGetRequest
 */
export interface UtilisateursApiApiUtilisateursIdGetRequest {
    /**
     * Utilisateurs identifier
     * @type {string}
     * @memberof UtilisateursApiApiUtilisateursIdGet
     */
    readonly id: string

    /**
     * Identifiant de la société
     * @type {number}
     * @memberof UtilisateursApiApiUtilisateursIdGet
     */
    readonly xIdSociete?: number
}

/**
 * Request parameters for apiUtilisateursIdPut operation in UtilisateursApi.
 * @export
 * @interface UtilisateursApiApiUtilisateursIdPutRequest
 */
export interface UtilisateursApiApiUtilisateursIdPutRequest {
    /**
     * Utilisateurs identifier
     * @type {string}
     * @memberof UtilisateursApiApiUtilisateursIdPut
     */
    readonly id: string

    /**
     * The updated Utilisateurs resource
     * @type {UtilisateursJsonldUtilisateurUpdateUtilisateur}
     * @memberof UtilisateursApiApiUtilisateursIdPut
     */
    readonly utilisateursJsonldUtilisateurUpdateUtilisateur: UtilisateursJsonldUtilisateurUpdateUtilisateur

    /**
     * Identifiant de la société
     * @type {number}
     * @memberof UtilisateursApiApiUtilisateursIdPut
     */
    readonly xIdSociete?: number
}

/**
 * Request parameters for apiUtilisateursPost operation in UtilisateursApi.
 * @export
 * @interface UtilisateursApiApiUtilisateursPostRequest
 */
export interface UtilisateursApiApiUtilisateursPostRequest {
    /**
     * The new Utilisateurs resource
     * @type {UtilisateursJsonldUtilisateurWrite}
     * @memberof UtilisateursApiApiUtilisateursPost
     */
    readonly utilisateursJsonldUtilisateurWrite: UtilisateursJsonldUtilisateurWrite

    /**
     * Identifiant de la société
     * @type {number}
     * @memberof UtilisateursApiApiUtilisateursPost
     */
    readonly xIdSociete?: number
}

/**
 * UtilisateursApi - object-oriented interface
 * @export
 * @class UtilisateursApi
 * @extends {BaseAPI}
 */
export class UtilisateursApi extends BaseAPI {
    /**
     * Returns the users collection
     * @summary Returns the users collection
     * @param {UtilisateursApiApiUtilisateursGetCollectionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UtilisateursApi
     */
    public apiUtilisateursGetCollection(requestParameters: UtilisateursApiApiUtilisateursGetCollectionRequest = {}, options?: RawAxiosRequestConfig) {
        return UtilisateursApiFp(this.configuration).apiUtilisateursGetCollection(requestParameters.page, requestParameters.itemsPerPage, requestParameters.isDeleted, requestParameters.isFormalist, requestParameters.hasG3Access, requestParameters.hasComptaSoftware, requestParameters.hasAccessL2DF, requestParameters.isAdminApps, requestParameters.mail, requestParameters.mail2, requestParameters.user, requestParameters.user2, requestParameters.orderId, requestParameters.orderNom, requestParameters.orderUser, requestParameters.orderMail, requestParameters.orderPoleId, requestParameters.multiFieldSearch, requestParameters.xIdSociete, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete the user
     * @summary Delete the user
     * @param {UtilisateursApiApiUtilisateursIdDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UtilisateursApi
     */
    public apiUtilisateursIdDelete(requestParameters: UtilisateursApiApiUtilisateursIdDeleteRequest, options?: RawAxiosRequestConfig) {
        return UtilisateursApiFp(this.configuration).apiUtilisateursIdDelete(requestParameters.id, requestParameters.xIdSociete, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns the selected user informations
     * @summary Returns the selected user informations
     * @param {UtilisateursApiApiUtilisateursIdGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UtilisateursApi
     */
    public apiUtilisateursIdGet(requestParameters: UtilisateursApiApiUtilisateursIdGetRequest, options?: RawAxiosRequestConfig) {
        return UtilisateursApiFp(this.configuration).apiUtilisateursIdGet(requestParameters.id, requestParameters.xIdSociete, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Updates the selected user informations
     * @summary Updates the selected user informations
     * @param {UtilisateursApiApiUtilisateursIdPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UtilisateursApi
     */
    public apiUtilisateursIdPut(requestParameters: UtilisateursApiApiUtilisateursIdPutRequest, options?: RawAxiosRequestConfig) {
        return UtilisateursApiFp(this.configuration).apiUtilisateursIdPut(requestParameters.id, requestParameters.utilisateursJsonldUtilisateurUpdateUtilisateur, requestParameters.xIdSociete, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Create a user
     * @summary Create a user
     * @param {UtilisateursApiApiUtilisateursPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UtilisateursApi
     */
    public apiUtilisateursPost(requestParameters: UtilisateursApiApiUtilisateursPostRequest, options?: RawAxiosRequestConfig) {
        return UtilisateursApiFp(this.configuration).apiUtilisateursPost(requestParameters.utilisateursJsonldUtilisateurWrite, requestParameters.xIdSociete, options).then((request) => request(this.axios, this.basePath));
    }
}

/**
 * @export
 */
export const ApiUtilisateursGetCollectionOrderIdEnum = {
    Asc: 'asc',
    Desc: 'desc'
} as const;
export type ApiUtilisateursGetCollectionOrderIdEnum = typeof ApiUtilisateursGetCollectionOrderIdEnum[keyof typeof ApiUtilisateursGetCollectionOrderIdEnum];
/**
 * @export
 */
export const ApiUtilisateursGetCollectionOrderNomEnum = {
    Asc: 'asc',
    Desc: 'desc'
} as const;
export type ApiUtilisateursGetCollectionOrderNomEnum = typeof ApiUtilisateursGetCollectionOrderNomEnum[keyof typeof ApiUtilisateursGetCollectionOrderNomEnum];
/**
 * @export
 */
export const ApiUtilisateursGetCollectionOrderUserEnum = {
    Asc: 'asc',
    Desc: 'desc'
} as const;
export type ApiUtilisateursGetCollectionOrderUserEnum = typeof ApiUtilisateursGetCollectionOrderUserEnum[keyof typeof ApiUtilisateursGetCollectionOrderUserEnum];
/**
 * @export
 */
export const ApiUtilisateursGetCollectionOrderMailEnum = {
    Asc: 'asc',
    Desc: 'desc'
} as const;
export type ApiUtilisateursGetCollectionOrderMailEnum = typeof ApiUtilisateursGetCollectionOrderMailEnum[keyof typeof ApiUtilisateursGetCollectionOrderMailEnum];
/**
 * @export
 */
export const ApiUtilisateursGetCollectionOrderPoleIdEnum = {
    Asc: 'asc',
    Desc: 'desc'
} as const;
export type ApiUtilisateursGetCollectionOrderPoleIdEnum = typeof ApiUtilisateursGetCollectionOrderPoleIdEnum[keyof typeof ApiUtilisateursGetCollectionOrderPoleIdEnum];


