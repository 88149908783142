import { TypedUseSelectorHook, useSelector } from 'react-redux';
import {
    CIVILITIES_SLICE_NAME,
    CSP_OPTIONS_SLICE_NAME,
    FOURNISSEUR_SLICE_NAME,
    PAYS_SLICE_NAME,
    PRESCRIPTEUR_SLICE_NAME,
    SOUS_CLIENT_SLICE_NAME,
    TIERS_FOURNISSEUR_SLICE_NAME,
    TIERS_REDUCER_NAME,
    WALLET_OPTIONS_SLICE_NAME,
} from './constants';
import { type TiersStateType } from './types';

export const FournisseurSelector = (s: TiersStateType) =>
    s[TIERS_REDUCER_NAME][FOURNISSEUR_SLICE_NAME];
export const PrescripteursSelector = (s: TiersStateType) =>
    s[TIERS_REDUCER_NAME][PRESCRIPTEUR_SLICE_NAME];
export const SousClientsSelector = (s: TiersStateType) =>
    s[TIERS_REDUCER_NAME][SOUS_CLIENT_SLICE_NAME];
export const TiersFournisseurSelector = (s: TiersStateType) =>
    s[TIERS_REDUCER_NAME][TIERS_FOURNISSEUR_SLICE_NAME];
export const PaysSelector = (s: TiersStateType) => s[TIERS_REDUCER_NAME][PAYS_SLICE_NAME];
export const CivilitiesSelector = (s: TiersStateType) =>
    s[TIERS_REDUCER_NAME][CIVILITIES_SLICE_NAME];
export const WalletOptionsSelector = (s: TiersStateType) =>
    s[TIERS_REDUCER_NAME][WALLET_OPTIONS_SLICE_NAME];
export const CspOptionsSelector = (s: TiersStateType) =>
    s[TIERS_REDUCER_NAME][CSP_OPTIONS_SLICE_NAME];

export const selectedPrescripteursSelector = (s: TiersStateType) =>
    s[TIERS_REDUCER_NAME][PRESCRIPTEUR_SLICE_NAME].main.selected;

export const selectedStatusPrescripteursSelector = (s: TiersStateType) =>
    s[TIERS_REDUCER_NAME][PRESCRIPTEUR_SLICE_NAME].main.selectedStatus;

export const selectedSousClientSelector = (s: TiersStateType) =>
    s[TIERS_REDUCER_NAME][SOUS_CLIENT_SLICE_NAME].main.selected;

export const SousClientSelector = (s: TiersStateType) =>
    s[TIERS_REDUCER_NAME][SOUS_CLIENT_SLICE_NAME];

export const SousClientMainSelector = (s: TiersStateType) =>
    s[TIERS_REDUCER_NAME][SOUS_CLIENT_SLICE_NAME].main;

export const useTiersServiceSelector: TypedUseSelectorHook<TiersStateType> = useSelector;
