/* istanbul ignore file */

import { ThemeSettings } from '@europrocurement/flexy-components';
import { PaletteOptions, createTheme } from '@mui/material';

import _ from 'lodash';

import {
    red,
    pink,
    purple,
    deepPurple,
    lightGreen,
    teal,
    deepOrange,
    brown,
    blueGrey,
    amber,
    cyan,
    green,
    indigo,
    lightBlue,
    lime,
    orange,
    yellow,
    blue,
} from '@mui/material/colors';

declare module '@mui/material/styles' {
    interface Palette {
        ERDN?: string;
        ERCA?: string;
        ERDO?: string;
        EREN?: string;
        ERJA?: string;
        ERJU?: string;
        ERLO?: string;
        ERPF?: string;
        ERPU?: string;
        ERRC?: string;
        ERRE?: string;
        ERSO?: string;
        ERNF?: string;
        VEAA?: string;
        VEADJ?: string;
        VEMP?: string;

        lightGreen?: string;
        red?: string;
        pink?: string;
        purple?: string;
        deepPurple?: string;
        blueGrey?: string;
        amber?: string;
        teal?: string;
        deepOrange?: string;
        brown?: string;
        cyan?: string;
        green?: string;
        indigo?: string;
        lightBlue?: string;
        lime?: string;
        orange?: string;
        yellow?: string;
        blue?: string;
    }
    interface PaletteOptions {
        ERDN?: string;
        ERCA?: string;
        ERDO?: string;
        EREN?: string;
        ERJA?: string;
        ERJU?: string;
        ERLO?: string;
        ERPF?: string;
        ERPU?: string;
        ERRC?: string;
        ERRE?: string;
        ERSO?: string;
        ERNF?: string;
        VEAA?: string;
        VEADJ?: string;
        VEMP?: string;

        lightGreen?: string;
        red?: string;
        pink?: string;
        purple?: string;
        deepPurple?: string;
        amber?: string;
        blueGrey?: string;
        teal?: string;
        deepOrange?: string;
        brown?: string;
        cyan?: string;
        green?: string;
        indigo?: string;
        lightBlue?: string;
        lime?: string;
        orange?: string;
        yellow?: string;
        blue?: string;
    }
}

declare module '@mui/material/Button' {
    interface ButtonPropsColorOverrides {
        ERDN?: true;
        ERCA?: true;
        ERDO?: true;
        EREN?: true;
        ERJA?: true;
        ERJU?: true;
        ERLO?: true;
        ERPF?: true;
        ERPU?: true;
        ERRC?: true;
        ERRE?: true;
        ERSO?: true;
        ERNF?: true;
        VEAA?: true;
        VEADJ?: true;
        VEMP?: true;

        lightGreen?: true;
        red?: true;
        pink?: true;
        purple?: true;
        deepPurple?: true;
        amber?: true;
        blueGrey?: true;
        teal?: true;
        deepOrange?: true;
        brown?: true;
        cyan?: true;
        green?: true;
        indigo?: true;
        lightBlue?: true;
        lime?: true;
        orange?: true;
        yellow?: true;
        blue?: true;
    }
}

const L2DTheme = function () {
    const baseTheme = ThemeSettings();

    const customTheme = createTheme({
        palette: {
            ...baseTheme.palette,
            ERDN: baseTheme.palette.augmentColor({
                color: baseTheme.palette.primary as PaletteOptions,
            }),
            ERCA: baseTheme.palette.augmentColor({
                color: baseTheme.palette.secondary as PaletteOptions,
            }),
            ERDO: baseTheme.palette.augmentColor({ color: lightGreen }),
            EREN: baseTheme.palette.augmentColor({ color: red }),
            ERJA: baseTheme.palette.augmentColor({ color: pink }),
            ERJU: baseTheme.palette.augmentColor({ color: purple }),
            ERLO: baseTheme.palette.augmentColor({ color: deepPurple }),
            ERPF: baseTheme.palette.augmentColor({ color: amber }),
            ERPU: baseTheme.palette.augmentColor({ color: blueGrey }),
            ERRC: baseTheme.palette.augmentColor({ color: teal }),
            ERRE: baseTheme.palette.augmentColor({ color: deepOrange }),
            ERSO: baseTheme.palette.augmentColor({ color: brown }),
            ERNF: baseTheme.palette.augmentColor({ color: orange }),
            VEAA: baseTheme.palette.augmentColor({ color: lime }),
            VEADJ: baseTheme.palette.augmentColor({ color: indigo }),
            VEMP: baseTheme.palette.augmentColor({ color: blue }),

            lightGreen: baseTheme.palette.augmentColor({ color: lightGreen }),
            red: baseTheme.palette.augmentColor({ color: red }),
            pink: baseTheme.palette.augmentColor({ color: pink }),
            purple: baseTheme.palette.augmentColor({ color: purple }),
            deepPurple: baseTheme.palette.augmentColor({ color: deepPurple }),
            amber: baseTheme.palette.augmentColor({ color: amber }),
            blueGrey: baseTheme.palette.augmentColor({ color: blueGrey }),
            teal: baseTheme.palette.augmentColor({ color: teal }),
            deepOrange: baseTheme.palette.augmentColor({ color: deepOrange }),
            brown: baseTheme.palette.augmentColor({ color: brown }),

            cyan: baseTheme.palette.augmentColor({ color: cyan }),
            green: baseTheme.palette.augmentColor({ color: green }),
            indigo: baseTheme.palette.augmentColor({ color: indigo }),
            lightBlue: baseTheme.palette.augmentColor({ color: lightBlue }),
            lime: baseTheme.palette.augmentColor({ color: lime }),
            orange: baseTheme.palette.augmentColor({ color: orange }),
            yellow: baseTheme.palette.augmentColor({ color: yellow }),
            blue: baseTheme.palette.augmentColor({ color: blue }),
        },
    });

    return createTheme(_.merge({}, customTheme, baseTheme, {}));
};
export default L2DTheme;
