import * as React from 'react';

import { TopbarHeight, breadcrumbHeight, footerHeight } from '@europrocurement/flexy-components';
import FlexyDatatableTable, {
    FlexyDatatableProps,
} from '../FlexyDatatableTable/FlexyDatatableTable';
import { FlexyDatatableTableProvider } from '../FlexyDatatableTable/FlexyDatatableTableContext';

export const FlexyDatatableMaxHeight = `calc(100vh - ${footerHeight}px - ${TopbarHeight}px - ${breadcrumbHeight}px -48px  )`;

export const FlexyDatatable = function <T extends Record<string, unknown>>({
    sx,
    columnLabelVariant = 'l2dtableHeader',
    textCellVariant = 'l2dtableCell',
    ...props
}: FlexyDatatableProps<T>) {
    return (
        <FlexyDatatableTableProvider>
            <FlexyDatatableTable
                columnLabelVariant={columnLabelVariant}
                textCellVariant={textCellVariant}
                sx={{
                    width: '100%',
                    height: FlexyDatatableMaxHeight,
                    ...sx,
                }}
                {...props}
            />
        </FlexyDatatableTableProvider>
    );
};

export default FlexyDatatable;
