import { useContext } from 'react';
import { FlexyDatatableTableContext } from './FlexyDatatableTableContext';

const useSelectItem = <T extends Record<string, unknown>>() => {
    const { selectedItems, setSelectedItems, currentData, setCurrentData } = useContext(
        FlexyDatatableTableContext,
    );

    const countSelectedItems = (): number => selectedItems.length;

    const hasSelectedItems = (): boolean => selectedItems.length > 0;

    const isItemSelected = (item: T): boolean =>
        selectedItems.find((dataItem: T) => dataItem.id === item.id) !== undefined;

    const handleSelectItem = (item: T) => {
        let tmp = [...selectedItems, item];

        if (isItemSelected(item)) {
            tmp = selectedItems.filter((dataItem: T) => dataItem.id !== item.id);
        }

        setSelectedItems(tmp);
    };

    const countUnselectedItems = (): number =>
        currentData.reduce(
            (accumulator, item: T) => accumulator + (!isItemSelected(item) ? 1 : 0),
            0,
        );

    const handleSelectAllItems = () => {
        let tmp: Array<T> = [...selectedItems];
        const countUnselected = countUnselectedItems();

        currentData.forEach((item: T) => {
            if (countUnselected > 0 && !isItemSelected(item)) {
                tmp.push(item);
            } else if (countUnselected === 0 && isItemSelected(item)) {
                tmp = tmp.filter((dataItem: T) => dataItem.id !== item.id);
            }
        });

        setSelectedItems(tmp);
    };

    return {
        selectedItems,
        countSelectedItems,
        hasSelectedItems,
        isItemSelected,
        handleSelectItem,
        handleSelectAllItems,
        countUnselectedItems,
        setCurrentData,
    };
};

export default useSelectItem;
