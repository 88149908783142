import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Container } from '@mui/system';
import Previous from '@europrocurement/flexy-components/components/atoms/Previous';
import { Stepper, useUploader } from '@europrocurement/flexy-components';
import { useFullLayout } from '@europrocurement/flexy-components/components/templates/FullLayout/FullLayoutContext';
import SelectSociete from '../components/SelectSociete';
import { useParcoursFormalite } from '../provider';
import { parcoursFormalitePathResolver } from '../parcoursFormalitePathResolver';
import CurrentFormalityAndClient from '../components/currentFormalityAndClient';

const StepSelectionClient: React.FunctionComponent = function () {
    const { idFormalites, create, containerWidth } = useParcoursFormalite();

    const { clearFiles } = useUploader();

    const navigate = useNavigate();

    const onPrevious = () => {
        navigate(parcoursFormalitePathResolver('stepFormalite'));
    };

    const { setCustomHeader } = useFullLayout();

    React.useEffect(() => {
        setCustomHeader(
            <Box sx={{ width: '100%', padding: 'auto' }}>
                <Container maxWidth="md">
                    <Stepper
                        steps={[
                            {
                                name: 'step_company',
                                text: 'Société',
                                progress: 15,
                            },
                            {
                                name: 'step_formality',
                                text: 'Formalité',
                                progress: 0,
                            },
                            {
                                name: 'step_documents',
                                text: 'Documents',
                                progress: 0,
                            },
                            {
                                name: 'step_summary',
                                text: 'Récapitulatif',
                                progress: 0,
                            },
                        ]}
                    />
                </Container>
            </Box>,
        );
        return () => {
            setCustomHeader(null);
        };
    }, [setCustomHeader]);

    return (
        <Container maxWidth={containerWidth}>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '32px',
                }}
            >
                <CurrentFormalityAndClient />
                <Previous onClick={onPrevious} />
            </Box>
            <SelectSociete
                multiple
                onSelect={async (ids, sousClients) => {
                    await create(ids, idFormalites, sousClients[0]);
                    clearFiles();
                    navigate(parcoursFormalitePathResolver('stepPremiumDoc'));
                }}
            />
        </Container>
    );
};

export default StepSelectionClient;
