/**
 *
 * Notre composant affiche un composant de téléchargement de documents
 *
 */
import React from 'react';

import { Box, Collapse, List, SxProps, Typography } from '@mui/material';
import { TransitionGroup } from 'react-transition-group';
import FileUpload, { type FileUploadProps } from '../../molecules/FileUpload';

import UploadedFile, { type UploadedFileProps } from '../../molecules/UploadedFile';

import FlexyHeaderForm from '../../molecules/FlexyHeaderForm';

import { useUploader } from './useUploader';
import { FilesBase64Cache } from './UploaderContext';
import TypedRequiredFile from '../../atoms/TypedRequiredFile';

export type RequiredUploadFileType = {
    libelle: string;
    id: number;
};

export type UploaderProps = {
    accept?: FileUploadProps['accept'];
    formatInfo?: FileUploadProps['formatInfo'];
    sx?: SxProps;
    maxMbSize?: FileUploadProps['maxMbSize'];
    requiredTypes?: Array<RequiredUploadFileType>;
    fileUlpoadOverides?: Partial<FileUploadProps>;
    // onChange: (event: ChangeEvent<HTMLInputElement>) => void;
    // onDrop: (event: DragEvent<HTMLElement>) => void;
};

export const getBase64Statut = (item: FilesBase64Cache): UploadedFileProps['status'] => {
    switch (item) {
        case false:
            return 'pending';
        case 'error':
            return 'error';
        default:
            return 'done';
    }
};

const Uploader: React.FunctionComponent<UploaderProps> = function ({
    accept = 'application/pdf',
    formatInfo = '.pdf',
    maxMbSize = undefined,
    sx = {
        margin: '20px',
    },
    requiredTypes,
    fileUlpoadOverides,
}: UploaderProps) {
    const { files, addFiles, removeFile, filesBase64 } = useUploader();

    const fileUploadProp: FileUploadProps = {
        accept,
        formatInfo,
        maxMbSize,
        onChange: (event: React.ChangeEvent<HTMLInputElement>) => {
            if (event.target.files !== null && event.target.files?.length > 0) {
                addFiles(event.target.files);
            }
        },
        onDrop: (event: React.DragEvent<HTMLElement>) => {
            addFiles(event.dataTransfer.files);
        },
        ...fileUlpoadOverides,
    };

    return (
        <Box sx={{ ...sx }}>
            <Box>
                <FileUpload {...fileUploadProp} />

                <FlexyHeaderForm
                    outlined
                    label="Fichier(s) transmis"
                />
            </Box>
            <List>
                <TransitionGroup>
                    {files.map((file: File, index) => (
                        <Collapse
                            timeout={300}
                            key={`fichier-${file.name}-${file.size}-${file.lastModified}`}
                        >
                            <UploadedFile
                                key={`${file.name}-${file.size}-${file.lastModified}`}
                                file={file}
                                status={getBase64Statut(filesBase64[index])}
                                onDelete={() => {
                                    removeFile(index);
                                }}
                                // TODO gérer le edit
                                // onEdit={() => {
                                //     replaceFile(index, file);
                                // }}
                            />
                        </Collapse>
                    ))}
                </TransitionGroup>
            </List>
            {files.length === 0 ? (
                <Typography
                    sx={{
                        fontSize: '18px',
                        textAlign: 'center',
                    }}
                >
                    Aucun fichier ajouté
                </Typography>
            ) : (
                ''
            )}

            {requiredTypes ? (
                <Box
                    sx={{
                        margin: '20px',
                    }}
                >
                    <FlexyHeaderForm
                        outlined
                        label="Fichier(s) transmis"
                    />

                    {requiredTypes?.map((requiredType) => <TypedRequiredFile {...requiredType} />)}
                </Box>
            ) : null}
        </Box>
    );
};

export default Uploader;
