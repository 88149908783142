import React, { useCallback, useState, useEffect, MouseEvent, useRef, Fragment } from 'react';

import md5 from 'md5';
import { useSnackbar } from 'notistack';
import { useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/pro-regular-svg-icons';
import { faChevronDown } from '@fortawesome/pro-duotone-svg-icons';

import {
    AppBar,
    IconButton,
    Box,
    Toolbar,
    Button,
    Menu,
    Avatar,
    SxProps,
    Typography,
    Stack,
} from '@mui/material';

import {
    CustomizerReducerType,
    BDD_SWITCH_SOCIETES_FULLNAME_FROM_ID,
} from '@europrocurement/l2d-domain';
import { UseKeycloakService } from '@europrocurement/l2d-keycloak';

import ProfileDropdown from '../../molecules/ProfileDropdown';
import { FlexyLayoutState } from '../../../redux/storeConfig/store';
import { DropdownPrescriber, DropdownSociete } from '../../atoms';
import { useFullLayout } from '../../templates/FullLayout/FullLayoutContext';
import ExitButton from '../../atoms/ExitButton';

export type HeaderProps = {
    toggleSidebar?: (event: React.MouseEvent<HTMLButtonElement>) => void;
    sx?: SxProps;
    drawerAvailability?: boolean;
    showSociete?: boolean;
    showPrescripteur?: boolean;
    handleClickExitButton?: (event: MouseEvent<HTMLButtonElement>) => void;
};

const Header: React.FunctionComponent<HeaderProps> = function ({
    toggleSidebar,
    sx,
    drawerAvailability,
    showSociete = true,
    showPrescripteur = false,
    handleClickExitButton,
}: HeaderProps) {
    const kc = UseKeycloakService();
    const xIdSociete = useSelector((state: FlexyLayoutState) => state.customizer.xIdSociete);

    const { jobName } = useFullLayout();

    // const kc = {
    //     isAutenticated : ()=>{return false;}
    // }

    // Action de redirection utilisée lorsque
    // le bouton menu est remplacé par la flèche de retour

    const userimg = useCallback(
        () => `https://www.gravatar.com/avatar/${md5(kc.getEmail())}`,
        [kc],
    );

    // userimg = "https://www.gravatar.com/avatar/"

    // const login = kc.getKc().login();
    const logout = useCallback(() => kc.getKc().logout(), [kc]);

    const [profilDropdownOpened, setProfilDropdwnOpened] = useState<Element | null>(null);

    const handleClose4 = () => setProfilDropdwnOpened(null);
    const openProfilDropdown = (event: MouseEvent<HTMLButtonElement>) => {
        if (event.target instanceof Element) setProfilDropdwnOpened(event.currentTarget);
    };
    const { enqueueSnackbar } = useSnackbar();

    const prevXIdSocieteRef = useRef<CustomizerReducerType['xIdSociete']>();

    const { toggleCustomizerDrawer, customHeader } = useFullLayout();

    const oppenCustomizeFromProfile = () => {
        setProfilDropdwnOpened(null);
        toggleCustomizerDrawer();
    };

    useEffect(() => {
        const prevXIdSociete = prevXIdSocieteRef.current;

        if (prevXIdSociete !== undefined && prevXIdSociete !== xIdSociete) {
            enqueueSnackbar(
                <Stack
                    display="flex"
                    flexDirection="row"
                    alignItems="baseline"
                    gap={1}
                >
                    <Typography variant="caption">Nouvelle société sélectionnée :</Typography>
                    <Typography
                        variant="caption"
                        fontWeight="bold"
                    >
                        {BDD_SWITCH_SOCIETES_FULLNAME_FROM_ID[xIdSociete]}
                    </Typography>
                </Stack>,
                {
                    variant: 'info',
                    autoHideDuration: 5000,
                },
            );
        }

        prevXIdSocieteRef.current = xIdSociete;
    }, [xIdSociete, enqueueSnackbar]);

    return (
        <AppBar
            sx={{
                backgroundColor: 'background.paper',
                backdropFilter: 'blur(8px)',
                ...sx,
            }}
            elevation={0}
        >
            <Toolbar>
                {drawerAvailability ? (
                    <>
                        {/* Pour le mode Desktop */}
                        <IconButton
                            edge="start"
                            color="inherit"
                            aria-label="menu"
                            onClick={toggleSidebar}
                            size="small"
                            sx={{
                                display: {
                                    lg: 'flex',
                                    xs: 'none',
                                },
                            }}
                        >
                            <FontAwesomeIcon
                                icon={faBars}
                                size="lg"
                            />
                        </IconButton>

                        {/* Pour le Mobile */}
                        <IconButton
                            size="small"
                            color="inherit"
                            aria-label="menu"
                            onClick={toggleSidebar}
                            sx={{
                                display: {
                                    lg: 'none',
                                    xs: 'flex',
                                },
                            }}
                        >
                            <FontAwesomeIcon
                                icon={faBars}
                                size="lg"
                                width="24"
                                height="24"
                            />
                        </IconButton>
                    </>
                ) : (
                    <ExitButton handleClickExitButton={handleClickExitButton} />
                )}
                {/* ------------ End Menu icon ------------- */}
                {customHeader === null ? (
                    <>
                        <Box flexGrow={1} />
                        {showSociete ? <DropdownSociete /> : null}
                        {showPrescripteur ? <DropdownPrescriber /> : null}

                        <Box
                            sx={{
                                width: '1px',
                                backgroundColor: 'text.gris005',
                                height: '32px',
                                ml: 1,
                                mr: 1,
                            }}
                        />

                        {/* Profile Dropdown */}
                        <Button
                            aria-label="menu"
                            color="inherit"
                            aria-controls="profile-menu"
                            aria-haspopup="true"
                            onClick={openProfilDropdown}
                        >
                            <Stack
                                alignItems="center"
                                display="flex"
                                flexDirection="row"
                                gap={1}
                            >
                                <Avatar
                                    src={userimg()}
                                    alt={userimg()}
                                    sx={{
                                        marginRight: '15px',
                                        width: '32px',
                                        height: '32px',
                                    }}
                                />
                                <Typography
                                    variant="l2dbodys"
                                    color="text.gris50"
                                >
                                    Bonjour,{' '}
                                    <Typography
                                        variant="l2dbodys"
                                        color="text.primary"
                                        fontWeight={700}
                                    >
                                        {kc.getName()}
                                    </Typography>
                                </Typography>
                                <Typography
                                    variant="l2dbodys"
                                    color="text.gris70"
                                    fontWeight={900}
                                >
                                    <FontAwesomeIcon
                                        width={16}
                                        height={16}
                                        icon={faChevronDown}
                                        flip={profilDropdownOpened ? 'vertical' : undefined}
                                    />
                                </Typography>
                            </Stack>
                        </Button>

                        <Menu
                            id="profile-menu"
                            anchorEl={profilDropdownOpened}
                            open={!!profilDropdownOpened}
                            onClose={handleClose4}
                        >
                            <Box width="385px">
                                <ProfileDropdown
                                    jobName={jobName}
                                    avatarSrc={userimg()}
                                    logout={logout}
                                    openSettings={oppenCustomizeFromProfile}
                                />
                            </Box>
                        </Menu>
                    </>
                ) : (
                    customHeader
                )}
            </Toolbar>
        </AppBar>
    );
};

export default Header;
