import {
    FormaliteApi,
    FormaliteFormaliteRead,
} from '@europrocurement/l2d-domain/openApi/ApiFormalite';
import { ModelConfiguration, ModelItem, ModelMethods } from '@europrocurement/l2d-domain/types';
import { DataSourcesThunksType } from '@europrocurement/l2d-redux-utils';
import { formalityIcon } from '@europrocurement/l2d-icons';
import { FORMALITES_SLICE_NAME } from '../constants';

const modelKey = 'formality';
const modelName = 'Formalite';
const sliceName = FORMALITES_SLICE_NAME;

type ModelStructure = FormaliteFormaliteRead;

const methods: ModelMethods<ModelStructure> = {
    getItemDefaultLabel: (model: ModelStructure) => `${model.libelle}`,
};

export type FormalityEndpoints = {
    list: InstanceType<typeof FormaliteApi>['apiFormalitesGetCollection'];
    update: InstanceType<typeof FormaliteApi>['apiFormalitesIdPut'];
};

export type FormalityModel = ModelItem<ModelStructure> & FormalityEndpoints;

export const buildFormalityModel = (
    api: FormaliteApi,
    datasourcesThunks: DataSourcesThunksType<FormaliteFormaliteRead>,
) => {
    const configuration: ModelConfiguration = {
        key: modelKey,
        name: modelName,
        type: 'Actions',
        sliceName,
        api,
        datasourcesThunks,
        icon: formalityIcon,
    };

    const endpoints = {
        list: api.apiFormalitesGetCollection.bind(api),
        update: api.apiFormalitesIdPut.bind(api),
    };

    const formalityModel: FormalityModel = {
        ...configuration,
        ...methods,
        ...endpoints,
    };

    return formalityModel;
};
