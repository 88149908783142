import * as React from 'react';
import { Box, Container, useTheme } from '@mui/system';
import { FaOptionIcon } from '@europrocurement/l2d-icons';
import { Button, Typography } from '@mui/material';
import { IconName } from '@fortawesome/pro-duotone-svg-icons';
import _ from 'lodash';
import { TreeItem, TreeItemProps } from '../../atoms';

type title = { title?: string };
export type TreeItemBaseProps = Omit<TreeItemProps, 'design' | ' onChange'> & title;

export type TreeItemsProps = {
    items: TreeItemBaseProps[];
    onChange: (values: Array<TreeItemProps['value']>) => void;
    value?: Array<TreeItemProps['value']>;
    multiple?: boolean;
};

function onlyUnique<T>(value: T, index: number, array: Array<T>) {
    return array.indexOf(value) === index;
}

function linearisationTreeItems(
    items: TreeItemsProps['items'],
    value: TreeItemsProps['value'] = [],
) {
    let innerItems: TreeItemsProps['items'] = [];

    const tmpInnerItemsNoTile: TreeItemsProps['items'] = [];

    const tmp = items.reduce(
        (acc, curr) => {
            if (curr.title) {
                if (!acc[curr.title]) {
                    acc[curr.title] = [];
                }
                acc[curr.title].push({
                    ...curr,
                    isChecked: curr.isChecked || !(value.indexOf(curr.value) === -1),
                });
            } else {
                tmpInnerItemsNoTile.push({
                    ...curr,
                    isChecked: curr.isChecked || !(value.indexOf(curr.value) === -1),
                });
            }
            return acc;
        },
        {} as Record<string, TreeItemsProps['items']>,
    );

    Object.values(tmp).forEach((tabloDeTablo) => {
        innerItems = [...innerItems, ...tabloDeTablo];
    });

    innerItems.push(...tmpInnerItemsNoTile);

    return innerItems;
}

const TreeItems: React.FunctionComponent<TreeItemsProps> = function ({
    items = [],
    onChange,
    value,
    multiple = true,
}: TreeItemsProps) {
    let baseInnerItems: TreeItemsProps['items'];
    if (value) {
        baseInnerItems = items.map((item) => {
            if (value.indexOf(item.value) !== -1) {
                return { ...item, isChecked: true };
            }
            return { ...item, isChecked: false };
        });
    } else {
        baseInnerItems = items;
    }

    const innerItems = React.useMemo(
        () => linearisationTreeItems(baseInnerItems, value),
        [baseInnerItems, value],
    );

    const allValues = innerItems.map((i) => i.value).filter(onlyUnique);
    if (allValues.length !== innerItems.length) {
        console.error('Two items or more with the same value found.');
    }

    const [values, setValues] = React.useState(
        innerItems.filter((i) => i.isChecked).map((i) => i.value),
    );

    const onChangeWrapper: TreeItemProps['onChange'] = React.useCallback(
        (newValue: TreeItemProps['value'], checked: boolean) => {
            let tmpNewValues: (string | number)[];
            if (multiple && checked) {
                tmpNewValues = [...values, newValue];
            } else if (checked) {
                tmpNewValues = [newValue];
            } else {
                tmpNewValues = [...values.filter((v) => v !== newValue)];
            }
            if (!_.isEqual(tmpNewValues, values)) {
                setValues(tmpNewValues);
            }
        },
        [multiple, values, setValues],
    );

    React.useEffect(() => {
        if (onChange) {
            onChange(values);
        }
    }, [onChange, values]);

    const itemsTitles = innerItems.reduce(
        (acc: Record<string, boolean>, curr) => {
            const newAcc = { ...acc };
            if (curr.title) {
                newAcc[curr.title] = true;
            }
            return newAcc;
        },
        {} as Record<string, boolean>,
    );

    const [hide, setHide] = React.useState(itemsTitles);

    const updateHideStatus = (titleSelected: string) => {
        const newHideState = { ...hide };
        newHideState[titleSelected] = !hide[titleSelected];
        setHide(newHideState);
    };

    const theme = useTheme();
    return (
        <Container
            maxWidth="md"
            style={{ margin: '5px' }}
            data-testid="test-id-treeItems"
        >
            <>
                {Object.keys(itemsTitles).map((title) => (
                    <Box
                        key={title}
                        sx={{
                            marginBottom: '20px',
                            marginTop: '20px',
                        }}
                    >
                        {' '}
                        <Typography
                            component="h2"
                            sx={{
                                fontSize: '18px',
                                width: '100%',
                            }}
                            style={{
                                position: 'relative',
                            }}
                            onClick={() => {
                                updateHideStatus(title);
                            }}
                        >
                            {title}
                            <Button
                                style={{
                                    position: 'absolute',
                                    right: 0,
                                }}
                            >
                                {hide[title] ? (
                                    <FaOptionIcon
                                        icon={['fasl', 'chevron-up' as IconName]}
                                        sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            color: theme.palette.grey[600],
                                        }}
                                    />
                                ) : (
                                    <FaOptionIcon
                                        icon={['fasl', 'chevron-down' as IconName]}
                                        sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            color: theme.palette.grey[600],
                                        }}
                                    />
                                )}
                            </Button>
                        </Typography>
                        {hide[title] ? null : (
                            <Box
                                style={{ padding: '0px 15px', margin: '5px 0px 0px 0px' }}
                                data-testid="test-id-treeItem-box"
                            >
                                {innerItems
                                    .filter((item) => item.title === title)
                                    .map((item) => (
                                        <TreeItem
                                            label={item.label}
                                            value={item.value}
                                            onChange={onChangeWrapper}
                                            // item.isChecked
                                            isChecked={values.indexOf(item.value as string) > -1}
                                            isDisabled={item.isDisabled}
                                        />
                                    ))}
                            </Box>
                        )}
                    </Box>
                ))}
                {innerItems
                    .filter((item) => item.title === undefined)
                    .map((item) => (
                        <TreeItem
                            label={item.label}
                            value={item.value}
                            onChange={onChangeWrapper}
                            isChecked={values.indexOf(item.value as string) > -1}
                            isDisabled={item.isDisabled}
                        />
                    ))}
            </>
        </Container>
    );
};

export default TreeItems;
