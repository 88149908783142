import React, { ReactNode, useId } from 'react';
import { Box, Popover, PopoverProps, useMediaQuery, useTheme } from '@mui/material';

export type FlexyMobilePopoverProps = {
    anchorEl?: Element | null;
    open: boolean;
    heading?: ReactNode;
    controls: ReactNode;
    children: ReactNode;
};

const FlexyMobilePopover: React.FunctionComponent<FlexyMobilePopoverProps> = function (props) {
    const { anchorEl = null, open, heading = null, controls, children } = props;
    const theme = useTheme();
    const { breakpoints } = theme;
    const downSmBreakpoint = useMediaQuery(breakpoints.down('sm'));
    let popoverProps: PopoverProps = {
        open,
        anchorReference: 'anchorEl',
        anchorPosition: {
            left: 0,
            top: 0,
        },
        slotProps: {},
        anchorEl,
        anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
        },
        sx: {
            marginTop: '10px',
            zIndex: 10000,
        },
    };

    if (downSmBreakpoint) {
        popoverProps = {
            ...popoverProps,
            anchorReference: 'anchorPosition',
            anchorOrigin: {
                vertical: 'top',
                horizontal: 'left',
            },
            slotProps: {
                paper: {
                    sx: { width: '100%', height: '100%' },
                },
            },
            sx: {
                zIndex: 10000,
            },
        };
    }

    const id = useId();

    return (
        <Popover
            id={id}
            {...popoverProps}
        >
            <Box
                sx={{
                    borderRadius: downSmBreakpoint ? 0 : '12px',
                    minWidth: downSmBreakpoint ? '100%' : '372px',
                    height: downSmBreakpoint ? '100%' : 'auto',
                    background: 'primary.main',
                    display: downSmBreakpoint ? 'flex' : 'block',
                    flexDirection: 'column',
                }}
            >
                {heading}
                {children}
                {controls}
            </Box>
        </Popover>
    );
};

export default FlexyMobilePopover;
