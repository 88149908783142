import React, { useEffect } from 'react';
import { ObjectGroup, ObjectGroupProps, ObjectGroupType } from '../../atoms';

export type ObjectGroupListProps = {
    objectGroups: Array<ObjectGroupType>;
    selectedId?: string | number | null;
    onClick?: (objectGroup: ObjectGroupType) => void;
};

const ObjectGroupList: React.FunctionComponent<ObjectGroupListProps> = ({
    objectGroups,
    selectedId,
    onClick,
}) => {
    const [selected, setSelected] = React.useState<ObjectGroupType>();

    useEffect(() => {
        const item = objectGroups.find((group) => String(group.id) === String(selectedId));

        if (item) {
            setSelected(item);
        }
    }, [objectGroups, selectedId]);

    const onClickWrapper: ObjectGroupProps['onClick'] = (objectGroup) => {
        // condition pour déselectionner
        if (selected !== undefined && objectGroup.id === selected.id) {
            setSelected(undefined);
        } else {
            setSelected(objectGroup);
        }
        // appliquer le bon style a l'enfant séléctionné
        if (onClick) {
            onClick(objectGroup);
        }
    };

    return objectGroups.map((objectGroup) => (
        <ObjectGroup
            isSelected={selected && selected.id === objectGroup.id}
            key={objectGroup.id}
            {...objectGroup}
            onClick={onClickWrapper}
        />
    ));
};

export default ObjectGroupList;
