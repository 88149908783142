import React from 'react';
import { Button, useTheme, Typography } from '@mui/material';
import { FaOptionIcon } from '@europrocurement/l2d-icons';
import { faPlus } from '@fortawesome/pro-solid-svg-icons';

export type AddPrescriberClientButtonProps = {
    onClick: () => void;
};

const AddPrescriberClientButton: React.FunctionComponent<AddPrescriberClientButtonProps> =
    function (props) {
        const { onClick } = props;
        const theme = useTheme();

        const buttonStyle = {
            display: 'flex',
            alignItems: 'center',
            padding: '8px 14px',
            gap: '12px',
            borderRadius: '12px',
            width: '100%',
            justifyContent: 'flex-start',
            fontFamily: 'Inter',
            fontSize: '15px',
            fontWeight: '600',
            lineHeight: '24px',
            color: theme.palette.text.primary,
        };

        return (
            <Button
                onClick={onClick}
                sx={buttonStyle}
            >
                <Typography
                    variant="l2dbodys"
                    color="text.secondary"
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'flex-start',
                        alignItems: 'flex-start',
                        width: '100%',
                        gap: '12px',
                        fontWeight: '600',
                    }}
                >
                    <FaOptionIcon icon={faPlus} />
                    Ajouter une société
                </Typography>
            </Button>
        );
    };

export default AddPrescriberClientButton;
