import React from 'react';
import { Button } from '@mui/material';
import { FaOptionIcon } from '@europrocurement/l2d-icons';
import { IconName } from '@fortawesome/fontawesome-svg-core';
import useSelectItem from '../FlexyDatatableTable/useSelectItem';

export type FlexyDatatableSelectionActionProps = {
    label: string;
    icon: string;
    onClick: (items: Array<unknown>) => void;
};

const FlexyDatatableSelectionAction: React.FunctionComponent<FlexyDatatableSelectionActionProps> =
    function (props) {
        const { label, icon = null, onClick } = props;
        const { selectedItems } = useSelectItem();
        const handleOnClick = () => {
            onClick(selectedItems);
        };

        return (
            <Button
                color="inherit"
                onClick={handleOnClick}
                sx={{
                    color: 'primary.dark.contrastText',
                    display: 'flex',
                    gap: '5px',
                    alignItems: 'center',
                    fontSize: '13px',
                    fontWeight: '500;',
                    lineHeight: '20.8px',
                    textAlign: 'left',
                }}
            >
                {icon && <FaOptionIcon icon={['fad', icon as IconName]} />}
                {label}
            </Button>
        );
    };

export default FlexyDatatableSelectionAction;
