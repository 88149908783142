import { NamedIconsGroupType } from '../types/types';
import {
    formalitiesIcons,
    modelIcons,
    invoiceNamedIcons,
    generalNamedIcons,
    signNamedIcons,
    logtimelineNamedIcons,
    fileNamedIcons,
    inputIcons,
    userActionIcons,
    statusIcons,
    alertNamedIcons,
    formNamedIcons,
    publicationNamedIcons,
    tableNamedIcons,
} from './icons';

export const namedIconsGroups: NamedIconsGroupType[] = [
    {
        name: 'general',
        displayName: 'Icones générales',
        icons: generalNamedIcons,
    },
    {
        name: 'status',
        displayName: 'Icônes de statut',
        icons: statusIcons,
    },
    {
        name: 'alert',
        displayName: 'Icones des alertes',
        icons: alertNamedIcons,
    },
    {
        name: 'formalities',
        displayName: 'Icones Formalités',
        icons: formalitiesIcons,
    },
    {
        name: 'publications',
        displayName: 'Icones Publications',
        icons: publicationNamedIcons,
    },
    {
        name: 'files',
        displayName: 'Icones fichiers',
        icons: fileNamedIcons,
    },
    {
        name: 'invoices',
        displayName: 'Icones factures',
        icons: invoiceNamedIcons,
    },
    {
        name: 'sign',
        displayName: 'Icones signatures',
        icons: signNamedIcons,
    },
    {
        name: 'timeline',
        displayName: 'Icones timelines',
        icons: logtimelineNamedIcons,
    },
    {
        name: 'inputs',
        displayName: 'Icônes champs utilsateur',
        icons: inputIcons,
    },
    {
        name: 'models',
        displayName: 'Modèles API',
        icons: modelIcons,
    },
    {
        name: 'userActions',
        displayName: 'Icônes actions utilsateur',
        icons: userActionIcons,
    },
    {
        name: 'form',
        displayName: 'Icones des formulaires',
        icons: formNamedIcons,
    },
    {
        name: 'table',
        displayName: 'Icones des tableaux',
        icons: tableNamedIcons,
    },
];

export const namedIconsTree: unknown = namedIconsGroups.reduce(
    (acc: Record<string, unknown>, current) => {
        acc[current.name] = current.icons.reduce((acc2: Record<string, unknown>, current2) => {
            acc2[current2.name] = current2;
            return acc2;
        }, {});
        return acc;
    },
    {},
);

export type NamedIconsTreeType = typeof namedIconsTree;
