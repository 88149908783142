import React, { ReactNode, useCallback, useState } from 'react';
import { Outlet } from 'react-router-dom';
import { useLocation, useParams } from 'react-router';
import { experimentalStyled, Container, Box, Theme, useMediaQuery, useTheme } from '@mui/material';

import { PageContainer } from '@europrocurement/flexy-components/pages';
import {
    ActionsProvider,
    ContextActionType,
    useModal,
} from '@europrocurement/flexy-components/providers';
import drawerClosePaths from '@europrocurement/flexy-components/constants/drawerClosePaths';
import {
    SidebarWidth,
    SidebarFoldedWidth,
    TopbarHeight,
    paddingContent,
    footerHeight,
} from '../../../assets/global/Theme-variable';
import Customizer from '../../organisms/Customizer';
import {
    Sidebar,
    Header,
    Footer,
    type MenuEntry,
    HeaderProps,
    FooterProps,
    SidebarProps,
} from '../../organisms';
import { Modalizer, Scrollbar } from '../../molecules';
import ContextActionsMenu from '../../organisms/ContextActionsMenu/ContextActionsMenu';
import { FullLayoutProvider } from './FullLayoutContext';

export type FullLayoutProps = {
    menuEntries: MenuEntry[];
    footerProps: FooterProps;
    headerProps?: HeaderProps;
    contextActionMainIcon?: ReactNode;
    defaultContextActions?: ContextActionType[];
    logo: SidebarProps['logo'];
    logoFolded: SidebarProps['logoFolded'];
    sidebarActions?: SidebarProps['actions'];
    pageTitle?: string;
};

const MainWrapper = experimentalStyled('div')(() => ({
    display: 'flex',
    overflow: 'hidden',
    width: '100%',
}));

const PageWrapper = experimentalStyled('div')(({ theme }: { theme: Theme }) => ({
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden',

    backgroundColor: theme.palette.background.default,
    [theme.breakpoints.up('lg')]: {
        paddingTop: TopbarHeight,
    },
    [theme.breakpoints.down('lg')]: {
        paddingTop: '64px',
    },
}));

// Fermeture du menu latéral forcé + icône de retour en haut à gauche pour les routes suivantes
function isDrawerRoute(pathname: string) {
    return !drawerClosePaths.find((path) => pathname.includes(path));
}

const FullLayout = function (props: FullLayoutProps) {
    const {
        menuEntries,
        footerProps,
        headerProps,
        contextActionMainIcon,
        defaultContextActions = [],
        logo,
        logoFolded,
        sidebarActions = [],
        pageTitle = 'Back2Digital',
    } = props;
    const theme = useTheme();
    const smBreakpoint = useMediaQuery(theme.breakpoints.up('sm'));
    const hiddenSidebarContentPaddingLeft = smBreakpoint
        ? SidebarFoldedWidth + paddingContent
        : paddingContent;
    const hiddenSidebarHeaderPaddingLeft = smBreakpoint ? SidebarFoldedWidth : 0;
    const [isSidebarOpen, setSidebarOpen] = useState(smBreakpoint);
    const [jobName, setJobName] = useState<string | undefined>(undefined);
    const [customHeader, setCustomHeader] = useState<React.ReactNode>(null);
    const [isCustomizerDrawerOpen, setCustomizerDrawerOpen] = React.useState(false);

    const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent,
    );

    const toggleCustomizerDrawer = useCallback(() => {
        setCustomizerDrawerOpen(!isCustomizerDrawerOpen);
    }, [setCustomizerDrawerOpen, isCustomizerDrawerOpen]);

    const { pathname } = useLocation();

    const useBasePath = () => {
        const location = useLocation();
        const params = useParams<Record<string, string>>();

        return Object.values(params).reduce(
            (path, param) => (path && param ? path.replace(`/${param}`, '') : null),
            location.pathname,
        );
    };

    const basePath = useBasePath();

    const pathWithoutLastPart = typeof basePath === 'string' ? basePath : pathname;

    const { modalStatus, modalActions } = useModal();

    return (
        <PageContainer
            title={pageTitle}
            description="Backoffice Legal2digital"
        >
            <FullLayoutProvider
                value={{
                    jobName,
                    setJobName,
                    isSidebarOpen,
                    setSidebarOpen,
                    isCustomizerDrawerOpen,
                    setCustomizerDrawerOpen,
                    toggleCustomizerDrawer,
                    customHeader,
                    setCustomHeader,
                }}
            >
                <ActionsProvider defaultActions={defaultContextActions}>
                    <MainWrapper
                        style={{
                            height: isMobile ? 'auto' : '100vh',
                        }}
                        data-testid="test-id-FullLayout"
                    >
                        <Header
                            sx={{
                                paddingLeft:
                                    isSidebarOpen && isDrawerRoute(pathWithoutLastPart)
                                        ? `${SidebarWidth}px !important`
                                        : `${hiddenSidebarHeaderPaddingLeft}px !important`,
                            }}
                            toggleSidebar={() => setSidebarOpen(!isSidebarOpen)}
                            drawerAvailability={isDrawerRoute(pathWithoutLastPart)}
                            {...headerProps}
                        />

                        <Sidebar
                            menuEntries={menuEntries}
                            isSidebarOpen={isSidebarOpen && isDrawerRoute(pathWithoutLastPart)}
                            onSidebarClose={() => setSidebarOpen(false)}
                            logo={logo}
                            logoFolded={logoFolded}
                            actions={sidebarActions}
                        />

                        <PageWrapper>
                            <Container
                                maxWidth={false}
                                sx={{
                                    height: isMobile
                                        ? 'auto'
                                        : `calc(100vh - ${footerHeight}px - ${TopbarHeight}px)`,
                                }}
                            >
                                <Scrollbar>
                                    <Box
                                        sx={{
                                            paddingLeft:
                                                isSidebarOpen && isDrawerRoute(pathWithoutLastPart)
                                                    ? `${SidebarWidth + paddingContent}px !important`
                                                    : `${hiddenSidebarContentPaddingLeft}px !important`,
                                            paddingRight: `${paddingContent}px !important`,
                                        }}
                                    >
                                        <Outlet />
                                    </Box>
                                </Scrollbar>
                                <Footer {...footerProps} />
                            </Container>
                        </PageWrapper>
                        <ContextActionsMenu mainIcon={contextActionMainIcon} />
                        <Customizer
                            showDrawer={isCustomizerDrawerOpen}
                            setShowDrawer={setCustomizerDrawerOpen}
                            showButton={false}
                        />
                    </MainWrapper>
                </ActionsProvider>
            </FullLayoutProvider>
            <Modalizer
                open={modalStatus.isOpen}
                fullWidth={modalStatus.isFullWidth}
                isDraggable={modalStatus.isDraggable}
                onClose={() => modalActions.close()}
                maxWidth="lg"
            >
                <Box>{modalStatus.content}</Box>
            </Modalizer>
        </PageContainer>
    );
};

export default FullLayout;
