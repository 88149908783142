import React, { useEffect, useState } from 'react';
import { Autocomplete, FormHelperText, TextField, Tooltip, Typography } from '@mui/material';
import { Controller, RegisterOptions, useFormContext } from 'react-hook-form';
import { FlexyFormLabel } from '@europrocurement/flexy-components';
import { Box } from '@mui/system';
import usePlaceAutocomplete from '../../hooks/usePlaceAutocomplete';

export type PostalCodeChoice = {
    label: string;
    value: string;
    metadata: {
        city: string;
        postalCode: string;
        countryCode: string;
    };
};

export type ControlledPostalCodeInputProps = {
    name: string;
    rules: RegisterOptions;
    label?: string;
    onSelectChoice: (choice: PostalCodeChoice | null) => void;
};

const ControlledPostalCodeInput: React.FunctionComponent<ControlledPostalCodeInputProps> =
    function (props) {
        const { name, rules, label, onSelectChoice } = props;
        const { control } = useFormContext();
        const [searchTerm, setSearchTerm] = useState<string>('');
        const [choices, setChoices] = useState<Array<PostalCodeChoice>>([]);
        const { loadScript, geocodePostalCode } = usePlaceAutocomplete();

        useEffect(() => {
            loadScript();
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, []);

        useEffect(() => {
            if (searchTerm === '') {
                setChoices([]);

                return;
            }

            geocodePostalCode(searchTerm)
                .then((results) => {
                    setChoices(
                        results.map((result) => ({
                            label: `${result.postalCode} ${result.city}`,
                            value: result.postalCode,
                            metadata: {
                                city: result.city,
                                postalCode: result.postalCode,
                                countryCode: result.countryCode,
                            },
                        })),
                    );
                })
                .catch(() => {
                    setChoices([]);
                });
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [searchTerm]);

        return (
            <Controller
                control={control}
                name={name}
                rules={rules}
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                    <>
                        {label && label !== '' ? (
                            <FlexyFormLabel>
                                <Typography component="span">
                                    {label}
                                    {rules.required && (
                                        <Tooltip title={rules.required as string}>
                                            <Typography
                                                component="span"
                                                color="danger.main"
                                            >
                                                &nbsp;&nbsp;*
                                            </Typography>
                                        </Tooltip>
                                    )}
                                </Typography>
                            </FlexyFormLabel>
                        ) : null}
                        <Autocomplete
                            getOptionLabel={() => value}
                            options={choices}
                            renderInput={(params) => (
                                <TextField
                                    error={error !== undefined}
                                    {...params}
                                    placeholder="Code postal"
                                />
                            )}
                            clearIcon={null}
                            freeSolo
                            onChange={(
                                event: React.FormEvent<HTMLInputElement>,
                                newValue: PostalCodeChoice | null,
                            ) => {
                                onChange(newValue?.value || '');
                                onSelectChoice(newValue);
                            }}
                            onInputChange={(event, newSearchTerm) => {
                                setSearchTerm(newSearchTerm);
                                onChange(newSearchTerm);
                            }}
                            value={value}
                            renderOption={(optionProps, option) => (
                                <Box
                                    {...optionProps}
                                    key={option.label}
                                    component="li"
                                >
                                    {option.label}
                                </Box>
                            )}
                        />
                        {error ? (
                            <FormHelperText
                                sx={{
                                    margin: '4px 14px 0px 14px',
                                }}
                                error
                            >
                                {error?.message}
                            </FormHelperText>
                        ) : null}
                    </>
                )}
            />
        );
    };

export default ControlledPostalCodeInput;
