import React from 'react';
import { Box, Tooltip } from '@mui/material';
import { CustomAvatar } from '@europrocurement/flexy-components';

type PeoplePreviewProps = {
    firstName?: string;
    lastName?: string;
};

const PeoplePreview: React.FunctionComponent<PeoplePreviewProps> = function (props) {
    const { firstName, lastName } = props;

    if (!firstName && !lastName) {
        return null;
    }

    const boxStyle = {
        borderRadius: '16px',
        width: '32px',
        height: '32px',
        margin: '0px 16px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    };

    return (
        <Tooltip title={`${firstName} ${lastName}`}>
            <Box sx={boxStyle}>
                <CustomAvatar
                    sx={{
                        width: '32px',
                        height: '32px',
                    }}
                    firstName={firstName}
                    lastName={lastName}
                />
            </Box>
        </Tooltip>
    );
};

export default PeoplePreview;
