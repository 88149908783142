import { ConfigurationParameters, Configuration, DpartementApi } from '../../openApi/ApiGeoloc';

/**
 * Configuration
 */

export function buildGeolocRedux(configGeoloc: ConfigurationParameters) {
    const geolocConfiguration = new Configuration(configGeoloc);

    const departmentApi = new DpartementApi(geolocConfiguration);

    return {
        serviceConfiguration: geolocConfiguration,
        serviceConfigurationParameters: configGeoloc,
        apis: {
            departmentApi,
        },
        thunks: {},
        slices: {},
        fetchItems: {},
        getItems: {},
        models: {},
    };
}
