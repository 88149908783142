import React from 'react';
import { Outlet } from 'react-router';
import { KeycloakRoute } from '@europrocurement/l2d-keycloak';

const SelectSocieteRoot = function () {
    return (
        <KeycloakRoute>
            <Outlet />
        </KeycloakRoute>
    );
};

export default SelectSocieteRoot;
