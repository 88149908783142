import {
    SliceFactory,
    FetchCollectionData,
    ApiCollectionResponse,
    FetchItemDataType,
} from '@europrocurement/l2d-redux-utils';
import { Order } from '@europrocurement/l2d-domain/types/apiGenericTypes';
import { castIriToId } from '@europrocurement/l2d-utils';
import {
    ConfigurationParameters as DossiersConfigurationParameters,
    Configuration,
    FormaliteFormaliteRead,
    FormaliteApi,
    FormaliteApiApiFormalitesIdGetRequest,
    FormaliteApiApiFormalitesGetCollectionRequest,
} from '../../../openApi/ApiFormalite';
import { FORMALITES_REDUCER_NAME, FORMALITES_SLICE_NAME } from '../constants';

export type FormaliteApiObject = FormaliteFormaliteRead & Record<string, unknown>;

export type Formalite = FormaliteApiObject;

export const mapperFormalites = function (formality: Formalite) {
    return {
        ...formality,
        legalStatuses: formality.legalStatuses?.map(castIriToId),
        incompatibilities: formality.incompatibilities?.map(castIriToId),
        documents: formality.documents.map(castIriToId),
        formulaire: formality.formulaire ? castIriToId(formality.formulaire) : null,
    } as unknown as Formalite;
};

export function createFormaliteSlice(configuration: DossiersConfigurationParameters) {
    const conf = new Configuration(configuration);
    const api = new FormaliteApi(conf);

    const fetchFormaliteCollection: FetchCollectionData<Formalite> = ({
        pagination,
        filters,
        orders,
        // rootstate,
    }) => {
        const options = undefined;
        let orderLibelle: Order;
        let orderCategorieLibelle: Order;
        let categorieId: number | undefined;

        if (filters.categorie) {
            categorieId = filters.categorie as number;
        }

        orders.forEach((item) => {
            if (item.field === 'libelle') {
                orderLibelle = item.value;
            }
            if (item.field === 'categorie') {
                orderCategorieLibelle = item.value;
            }
        });

        const payload: Record<string, number | string | undefined | boolean> = {
            page: pagination.page + 1,
            itemsPerPage: pagination.itemsPerPage,
            categorieId,
            orderLibelle,
            orderCategorieLibelle,
        };

        if (typeof filters.deleted !== 'undefined') {
            payload.deleted = !!filters.deleted;
        }

        const requestParameters: FormaliteApiApiFormalitesGetCollectionRequest = { ...payload };

        return api
            .apiFormalitesGetCollection(requestParameters, options)
            .then((res) => res.data) as Promise<ApiCollectionResponse<Formalite>>;
    };

    const fetchFormaliteItem: FetchItemDataType<Formalite> = ({ idItem }) => {
        const requestParameters: FormaliteApiApiFormalitesIdGetRequest = {
            id: String(idItem),
        };

        return api
            .apiFormalitesIdGet(requestParameters)
            .then((res) => res.data) as Promise<Formalite>;
    };

    const { slice: formaliteSlice, dataSourcesThunks: formaliteDataSourcesThunks } =
        SliceFactory.createSlice<Formalite, Formalite>(
            FORMALITES_SLICE_NAME,
            FORMALITES_REDUCER_NAME,
            [],
            fetchFormaliteCollection,
            fetchFormaliteItem,
            mapperFormalites,
            {
                deleted: false,
            },
            {},
        );

    return { formaliteSlice, formaliteDataSourcesThunks };
}
