import React from 'react';
import { Button, Typography, useMediaQuery, useTheme } from '@mui/material';
import { FaOptionIcon } from '@europrocurement/l2d-icons';
import { faCheck } from '@fortawesome/pro-solid-svg-icons';

export type FlexyPreFilterProps = {
    label: string;
    current: boolean;
    count?: number;
    onClick: () => void;
};

const FlexyPreFilter: React.FunctionComponent<FlexyPreFilterProps> = function (props) {
    const { label, current, count, onClick } = props;
    const theme = useTheme();
    const { palette, breakpoints } = theme;
    const downSmBreakpoint = useMediaQuery(breakpoints.down('sm'));

    const desktopWrapperStyle = {
        borderBottomStyle: 'solid',
        borderBottomWidth: '2px',
        borderBottomColor: current ? 'primary.main' : 'transparent',
        borderRadius: 0,
        padding: '8px 0',
        minWidth: 0,
        display: 'flex',
        gap: '8px',
    };
    const desktopLabelStyle = {
        fontSize: '15px',
        fontWeight: current ? '600' : '500',
        lineHeight: '24px',
        color: current ? 'rgba(10, 26, 83, 1)' : 'rgba(10, 26, 83, 0.5)',
    };
    const desktopCounterStyle = {
        fontSize: '13px',
        fontWeight: '500',
        lineHeight: '24px',
        color: palette.text.light,
    };
    const mobileWrapperStyle = {
        display: 'flex',
        justifyContent: 'flex-end',
        gap: '12px',
        width: '100%',
        padding: '0 14px',
        height: '44px',
        backgroundColor: current ? palette.primary.light : 'transparent',
        '&:hover': {
            backgroundColor: palette.primary.light,
        },
    };
    const mobileLabelStyle = {
        color: palette.text.primary,
        fontWeight: '500',
        fontSize: '15px',
        lineHeight: '18px',
        marginRight: 'auto',
    };
    const mobileCounterStyle = {
        fontSize: '13px',
        fontWeight: '500',
        lineHeight: '24px',
        color: palette.text.light,
    };

    return (
        <Button
            sx={downSmBreakpoint ? mobileWrapperStyle : desktopWrapperStyle}
            onClick={onClick}
        >
            <Typography
                sx={downSmBreakpoint ? mobileLabelStyle : desktopLabelStyle}
                component="span"
            >
                {label}
            </Typography>
            {count !== undefined && (
                <Typography
                    sx={downSmBreakpoint ? mobileCounterStyle : desktopCounterStyle}
                    component="span"
                >
                    {count}
                </Typography>
            )}
            {current && downSmBreakpoint && <FaOptionIcon icon={faCheck} />}
        </Button>
    );
};

export default FlexyPreFilter;
