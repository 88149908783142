import { APIENV, APIS_ENUM } from '../constants';
import { type ConfigurationParameters } from '../openApi/ApiTiers';
import { getCustomiserState } from './customizerLocalSorageState';

export const getBasePathFromApiName = (name: APIS_ENUM) =>
    APIENV.apis[name][getCustomiserState().api[name]];

const getApiConfiguration: (
    name: APIS_ENUM,
    updateToken: () => Promise<string>,
) => ConfigurationParameters = (name, updateToken) => ({
    accessToken: updateToken,
    basePath: getBasePathFromApiName(name),
});

export default getApiConfiguration;
