import React from 'react';
import { Box } from '@mui/material';
import { BeneficiaireEffectifWidget, BeneficiaireEffectifWidgetType } from '../../molecules';

export type BeneficiaireEffectifListProps = {
    items?: Array<BeneficiaireEffectifWidgetType>;
};

const BeneficiaireEffectifList: React.FunctionComponent<BeneficiaireEffectifListProps> = function (
    props: BeneficiaireEffectifListProps,
) {
    const { items } = props;
    return (
        <Box>
            {items
                ? items.map((itemProps: BeneficiaireEffectifWidgetType, index) => (
                      <BeneficiaireEffectifWidget
                          key={`beneficiaire-${itemProps.beneficiaire.nom}-${itemProps.beneficiaire.prenom}}`}
                          {...itemProps}
                          index={index}
                      />
                  ))
                : null}
        </Box>
    );
};

export default BeneficiaireEffectifList;
