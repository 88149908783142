import { combineReducers } from '@reduxjs/toolkit';
import {
    AdressesApi,
    CategorieSocioProfessionnelleApi,
    CivilitesApi,
    Configuration,
    ConfigurationParameters,
    ContactsApi,
    CoordonneesApi,
    LogoApi,
    PortefeuilleApi,
    PrescripteursApi,
    SousClientsApi,
} from '../../openApi/ApiTiers';
import { createFournisseurSlice } from './slices/fournisseurSlice';
import { createPrescripteurSlice } from './slices/prescripteurSlice';
import { createTiersFournisseurSlice } from './slices/tiersFournisseurSlice';
import { createPaysSlice } from './slices/paysSlice';
import { createWalletOptionsSlice } from './slices/walletOptionsSlice';
import { createCspOptionsSlice } from './slices/cspOptionsSlice';
import { createCivilitiesSlice } from './slices/civilitiesSlice';
import { createSousClientSlice } from './slices/sousClientSlice';
import {
    CIVILITIES_SLICE_NAME,
    CSP_OPTIONS_SLICE_NAME,
    FOURNISSEUR_SLICE_NAME,
    PAYS_SLICE_NAME,
    PRESCRIPTEUR_SLICE_NAME,
    SOUS_CLIENT_SLICE_NAME,
    TIERS_FOURNISSEUR_SLICE_NAME,
    WALLET_OPTIONS_SLICE_NAME,
} from './constants';

export { type ConfigurationParameters };

export function createTiersReducer(configuration: ConfigurationParameters) {
    const { prescripteurSlice, prescripteurDataSourcesThunks } =
        createPrescripteurSlice(configuration);
    const { sousClientSlice, sousClientDataSourcesThunks } = createSousClientSlice(configuration);
    const { fournisseurSlice, fournisseurDataSourcesThunks } =
        createFournisseurSlice(configuration);
    const { tiersFournisseurSlice, tiersFournisseurDataSourcesThunks } =
        createTiersFournisseurSlice(configuration);
    const { paysSlice, paysDataSourcesThunks } = createPaysSlice(configuration);

    const { walletOptionsSlice, walletOptionsDataSourcesThunks } =
        createWalletOptionsSlice(configuration);
    const { cspOptionsSlice, cspOptionsDataSourcesThunks } = createCspOptionsSlice(configuration);
    const { civilitiesSlice, civilitiesDataSourcesThunks } = createCivilitiesSlice(configuration);

    return {
        prescripteurSlice,
        prescripteurDataSourcesThunks,
        sousClientSlice,
        sousClientDataSourcesThunks,
        fournisseurSlice,
        fournisseurDataSourcesThunks,
        tiersFournisseurSlice,
        tiersFournisseurDataSourcesThunks,
        paysSlice,
        walletOptionsSlice,
        cspOptionsSlice,
        paysDataSourcesThunks,
        walletOptionsDataSourcesThunks,
        cspOptionsDataSourcesThunks,
        civilitiesSlice,
        civilitiesDataSourcesThunks,
    };
}

export function buildTiersRedux(configparams: ConfigurationParameters) {
    const tiersConfiguration = new Configuration(configparams);

    const prescribersApi = new PrescripteursApi(tiersConfiguration);
    const coordonneesApi = new CoordonneesApi(tiersConfiguration);
    const contactsApi = new ContactsApi(tiersConfiguration);
    const adressesApi = new AdressesApi(tiersConfiguration);
    const categorieSocioProfessionnelleApi = new CategorieSocioProfessionnelleApi(
        tiersConfiguration,
    );
    const civilitesApi = new CivilitesApi(tiersConfiguration);
    const portefeuilleApi = new PortefeuilleApi(tiersConfiguration);
    const sousClientsApi = new SousClientsApi(tiersConfiguration);

    const logoSpecificationsApi = new LogoApi(tiersConfiguration);

    const {
        prescripteurSlice,
        prescripteurDataSourcesThunks,
        sousClientSlice,
        sousClientDataSourcesThunks,
        fournisseurSlice,
        fournisseurDataSourcesThunks,
        tiersFournisseurSlice,
        tiersFournisseurDataSourcesThunks,
        paysSlice,
        walletOptionsSlice,
        cspOptionsSlice,
        paysDataSourcesThunks,
        walletOptionsDataSourcesThunks,
        cspOptionsDataSourcesThunks,
        civilitiesSlice,
        civilitiesDataSourcesThunks,
    } = createTiersReducer(tiersConfiguration);

    const combinedTiersReducers = combineReducers({
        [FOURNISSEUR_SLICE_NAME]: fournisseurSlice.reducer,
        [PRESCRIPTEUR_SLICE_NAME]: prescripteurSlice.reducer,
        [SOUS_CLIENT_SLICE_NAME]: sousClientSlice.reducer,
        [TIERS_FOURNISSEUR_SLICE_NAME]: tiersFournisseurSlice.reducer,
        [PAYS_SLICE_NAME]: paysSlice.reducer,
        [CIVILITIES_SLICE_NAME]: civilitiesSlice.reducer,
        [CSP_OPTIONS_SLICE_NAME]: cspOptionsSlice.reducer,
        [WALLET_OPTIONS_SLICE_NAME]: walletOptionsSlice.reducer,
    });

    return {
        serviceConfiguration: tiersConfiguration,
        serviceConfigurationParameters: configparams,

        reducer: combinedTiersReducers,

        apis: {
            prescribersApi,
            coordonneesApi,
            contactsApi,
            adressesApi,
            categorieSocioProfessionnelleApi,
            civilitesApi,
            portefeuilleApi,
            logoSpecificationsApi,
            sousClientsApi,
        },
        slices: {
            prescripteurSlice,
            sousClientSlice,
            fournisseurSlice,
            tiersFournisseurSlice,
            paysSlice,
            walletOptionsSlice,
            cspOptionsSlice,
            civilitiesSlice,
        },
        thunks: {
            prescripteurDataSourcesThunks,
            sousClientDataSourcesThunks,
            fournisseurDataSourcesThunks,
            tiersFournisseurDataSourcesThunks,
            paysDataSourcesThunks,
            walletOptionsDataSourcesThunks,
            cspOptionsDataSourcesThunks,
            civilitiesDataSourcesThunks,
        },

        fetchItems: {
            getFournisseurs: fournisseurDataSourcesThunks.main.getData,
            getFournisseurAc: fournisseurDataSourcesThunks.autocompleteFactures.getData,
            getPrescripteurs: prescripteurDataSourcesThunks.main.getData,
            getExportPrescripteurs: prescripteurDataSourcesThunks.export.getData,
            getSousClients: sousClientDataSourcesThunks.main.getData,
            getTiersFournisseurs: tiersFournisseurDataSourcesThunks.main.getData,
            getPays: paysDataSourcesThunks.main.getData,
            getCivilities: civilitiesDataSourcesThunks.main.getData,
            getWalletOptions: walletOptionsDataSourcesThunks.main.getData,
            getCspOptions: cspOptionsDataSourcesThunks.main.getData,
        },

        getItems: {
            selectFournisseur: fournisseurDataSourcesThunks.main.getSelected,
            selectFournisseurAc: fournisseurDataSourcesThunks.autocompleteFactures.getSelected,
            selectPrescripteur: prescripteurDataSourcesThunks.main.getSelected,
            selectExportPrescripteurs: prescripteurDataSourcesThunks.export.getSelected,
            selectSousClients: sousClientDataSourcesThunks.main.getSelected,
            selectTiersFournisseur: tiersFournisseurDataSourcesThunks.main.getSelected,
            selectPays: paysDataSourcesThunks.main.getSelected,
            selectCivilities: civilitiesDataSourcesThunks.main.getSelected,
            selectWalletOptions: walletOptionsDataSourcesThunks.main.getSelected,
            selectCspOptions: cspOptionsDataSourcesThunks.main.getSelected,
        },
        models: {},
    };
}
