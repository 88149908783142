import { FormObject, FormStructure } from '@europrocurement/flexy-form/components';
import { FieldValues, UseFormReturn } from 'react-hook-form';
import { jsonBooleanInput } from '../types/formTypes';
import { checkFieldValue } from '../functions';

const jsonBooleanInputToFormElement: (input: jsonBooleanInput) => FormStructure = (input) => {
    const baseField: FormStructure = {
        type: input.type,
        name: input.name,
        inputlabel: input.inputlabel,
        placeholder: input.placeholder,
        defaultValue: input.defaultvalue,
        xs: input.xs,
        sm: input.sm,
        md: input.md,
        lg: input.lg,
        sx: {
            fontSize: '14px',
            marginTop: '15px',
            marginBottom: '15px',
            fontWeight: '500',
        },
    };

    // comportement sur rules display
    if (input.rules && input.rules.display) {
        if (input.rules.display.fieldIsEqualTo) {
            const fieldNameToWatch = input.rules.display.fieldIsEqualTo.field;
            const valueToWatch = input.rules.display.fieldIsEqualTo.value;

            baseField.display = (formContext: UseFormReturn<FieldValues, FormObject>) =>
                checkFieldValue(formContext, valueToWatch, fieldNameToWatch);
        }
        if (input.rules.display.isTrue) {
            const fieldNameToWatch = input.rules.display.isTrue;

            baseField.display = (formContext: UseFormReturn<FieldValues, FormObject>) =>
                checkFieldValue(formContext, true, fieldNameToWatch);
        }
    }

    return baseField;
};

export default jsonBooleanInputToFormElement;
