import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { CUSTOMIZER_REDUCER_NAME } from './constants';
import { CustomizerReducer } from './CustomizerReducer';

export const customizerReducer = combineReducers({
    [CUSTOMIZER_REDUCER_NAME]: CustomizerReducer,
});

export const customizerStore = configureStore({
    reducer: customizerReducer,
});

export type CustomizerStateType = ReturnType<typeof customizerStore.getState>;
export type CustomizerAppDispatch = typeof customizerStore.dispatch;
