import React from 'react';
import { Box } from '@mui/material';
import { DirigeantWidget, DirigeantWidgetType } from '../../molecules';

export type DirigeantListProps = {
    items?: Array<DirigeantWidgetType>;
};

const DirigeantList: React.FunctionComponent<DirigeantListProps> = function (
    props: DirigeantListProps,
) {
    const { items } = props;
    return (
        <Box>
            {items
                ? items.map((itemProps: DirigeantWidgetType, index) => (
                      <DirigeantWidget
                          key={`dirigeant-${itemProps.dirigeant.nom}-${itemProps.dirigeant.prenom}}`}
                          {...itemProps}
                          index={index}
                      />
                  ))
                : null}
        </Box>
    );
};

export default DirigeantList;
