// In L2DApiContext.tsx
import { APIS_ENUM } from '@europrocurement/l2d-domain/constants';
import {
    Configuration,
    ConfigurationParameters,
} from '@europrocurement/l2d-domain/openApi/ApiTiers';
import {
    DataSourcesThunksType,
    getDataThunkType,
    getItemThunkType,
} from '@europrocurement/l2d-redux-utils';
import { AnyAction, CombinedState, Slice, SliceCaseReducers } from '@reduxjs/toolkit';
import { createContext, Reducer } from 'react';
import { type ModelConfiguration } from '../../types';

export type ServiceStateType = {
    serviceConfigurationParameters: ConfigurationParameters;
    serviceConfiguration: Configuration;
    apis: {
        [x: string]: unknown;
    };
    slices: {
        [x: string]: Slice<unknown, SliceCaseReducers<unknown>, string>;
    };
    thunks: {
        [x: string]: DataSourcesThunksType<unknown>;
    };
    fetchItems: {
        [x: string]: getDataThunkType<unknown>;
    };
    getItems: {
        [x: string]: getItemThunkType<unknown>;
    };

    models: {
        [x: string]: ModelConfiguration;
    };
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    reducer?: Reducer<CombinedState<any>, AnyAction>;
};

export type StoreState = {
    [x in APIS_ENUM]?: ServiceStateType;
};

// Create the context
const L2DApiContext = createContext<StoreState | undefined>(undefined);

export default L2DApiContext;
