import { CustomItemFormStructure, FormStructure } from '@europrocurement/flexy-form/components';
import { jsonCustom } from '../types/formTypes';

const jsonCustomToFormElement: (
    input: jsonCustom,
    customItemRenderer: CustomItemFormStructure['renderField'],
) => FormStructure = (input, customItemRenderer) => {
    const baseField: CustomItemFormStructure = {
        type: 'customItem',
        name: input.name,
        renderField: customItemRenderer,
        inputlabel: input.inputlabel,
        placeholder: input.placeholder,
        rules: input.rules,
        visibility: input.visibility,
    };

    return baseField;
};

export default jsonCustomToFormElement;
