export const OFFRE_REDUCER_NAME = 'offre';
export const AUTOCOMPLETE_KEY = 'autocomplete';

export const specificsArticleLabels = {
    section: 'section-title',
    lineBreak: 'line-break',
};

export const OFFER_ACTIONS_SLICE_NAME = 'actions';
export const OFFER_ADDITIONAL_FIELDS_SLICE_NAME = 'offer_additional_fields';
export const REDUX_LIB_ARTICLE = 'articleSpecifics';
export const ARTICLES_SLICE_NAME = 'articles';
export const CATALOGS_SLICE_NAME = 'catalogs';
export const DOCUMENT_SLICE_NAME = 'documents';
export const DOMAINS_SLICE_NAME = 'domains';
export const INVOICING_CATEGORIES_SLICE_NAME = 'invoicingCategories';
export const OFFER_CACHED_KEY = 'cached';
export const OFFER_GROUP_SLICE_NAME = 'offer_group';
export const OFFER_OPTIONS_SLICE_NAME = 'offer_options';
export const OFFER_OPTION_VALUES_SLICE_NAME = 'offer_option_values';
export const OFFERS_SLICE_NAME = 'offers';
export const PACKAGES_SLICE_NAME = 'packages';
export const VATS_SLICE_NAME = 'vats';
