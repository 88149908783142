import { useFormaliteService } from '@europrocurement/l2d-domain';
import { DossierOpenSearchJsonldOpensearchDossierCollectionRead } from '@europrocurement/l2d-domain/openApi/ApiFormalite';

export type DossierCollection = DossierOpenSearchJsonldOpensearchDossierCollectionRead &
    Record<string, unknown>;

const useDossierCollection = () => {
    const { dossierOpenSearchApi } = useFormaliteService();
    let searchCollectionTimeout: number | undefined;

    const searchDossierCollection = (term: string): Promise<Array<DossierCollection>> =>
        new Promise((resolve, reject) => {
            clearTimeout(searchCollectionTimeout);
            const requestParameters = { q: term || undefined };

            searchCollectionTimeout = setTimeout(() => {
                dossierOpenSearchApi
                    .apiOpensearchCollectionsGetCollection(requestParameters)
                    .then((res) => resolve(res.data['hydra:member'] as Array<DossierCollection>))
                    .catch(() => reject());
            }, 300);
        });

    return { searchDossierCollection };
};

export default useDossierCollection;
