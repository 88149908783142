import {
    OptionValueApi,
    OptionValueOptionValueRead,
} from '@europrocurement/l2d-domain/openApi/ApiOffre';
import { offerOptionValueIcon } from '@europrocurement/l2d-icons';
import { DataSourcesThunksType } from '@europrocurement/l2d-redux-utils';

import { type ModelConfiguration, type ModelMethods, type ModelItem } from '../../..';
import { OFFER_OPTION_VALUES_SLICE_NAME } from '../constants';

const modelKey = 'offer_option_value';
const modelName = 'OfferOptionValue';
const sliceName = OFFER_OPTION_VALUES_SLICE_NAME;

type ModelStructure = OptionValueOptionValueRead;

const methods: ModelMethods<ModelStructure> = {
    getItemDefaultLabel: (model: ModelStructure) => `${model.libelle}`,
};

export type OptionValueEndpoints = {
    list: InstanceType<typeof OptionValueApi>['apiOptionValuesGetCollection'];
    dependencies: InstanceType<typeof OptionValueApi>['apiOptionValuesIdDependenciesGet'];
    read: InstanceType<typeof OptionValueApi>['apiOptionValuesIdGet'];
    update: InstanceType<typeof OptionValueApi>['apiOptionValuesIdPut'];
    create: InstanceType<typeof OptionValueApi>['apiOptionValuesPost'];
};

export type OfferOptionValueModel = ModelItem<ModelStructure> & OptionValueEndpoints;

export const buildOfferOptionValueModel = (
    api: OptionValueApi,
    datasourcesThunks: DataSourcesThunksType<OptionValueOptionValueRead>,
) => {
    const configuration: ModelConfiguration = {
        key: modelKey,
        name: modelName,
        type: 'Option value',
        sliceName,
        api,
        datasourcesThunks,
        icon: offerOptionValueIcon,
    };

    const endpoints = {
        list: api.apiOptionValuesGetCollection.bind(api),
        dependencies: api.apiOptionValuesIdDependenciesGet.bind(api),
        read: api.apiOptionValuesIdGet.bind(api),
        update: api.apiOptionValuesIdPut.bind(api),
        create: api.apiOptionValuesPost.bind(api),
    };

    const model: OfferOptionValueModel = {
        ...configuration,
        ...methods,
        ...endpoints,
    };

    return model;
};
