import React from 'react';
import CircleIcon from '@mui/icons-material/Circle';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { Button, Grid, SxProps, Typography } from '@mui/material';

export type BooleanBadgeProps = {
    label: string;
    value?: boolean;
    icon?: boolean;
    logo?: string;
    sx?: SxProps;
    toggleAction?: () => void;
    disabled?: boolean;
};

const BooleanBadge: React.FunctionComponent<BooleanBadgeProps> = function ({
    label,
    value = true,
    icon = false,
    logo = undefined,
    sx = {},
    toggleAction = undefined,
    disabled = false,
}: BooleanBadgeProps) {
    const deductBgColor = (): string => {
        if (!icon) return value ? 'success.main' : 'error.main';
        return value ? 'primary.main' : 'grey.500';
    };

    const buttonStyle = {
        ...sx,
        display: 'flex',
        padding: icon ? '3px 3px 3px 6px' : '6px 10px',
        borderRadius: '50px',
        alignItems: 'center',
        gap: '5px',
        width: 'fit-content',
    };

    return (
        <Button
            onClick={toggleAction}
            sx={buttonStyle}
            disabled={disabled}
        >
            <Grid
                component="div"
                bgcolor={deductBgColor()}
                sx={buttonStyle}
                color="primary.contrastText"
            >
                {logo && icon && (
                    <img
                        src={logo}
                        alt="Logo du booleanBadge"
                        height={9}
                    />
                )}

                <Typography
                    variant="body2"
                    component="span"
                    fontWeight="bold"
                >
                    {label}
                </Typography>

                {icon &&
                    (value ? (
                        <CheckCircleIcon fontSize="small" />
                    ) : (
                        <CircleIcon fontSize="small" />
                    ))}
            </Grid>
        </Button>
    );
};

export default BooleanBadge;
