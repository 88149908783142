import { useFormaliteService } from '@europrocurement/l2d-domain';
import { DossierOpenSearchJsonldOpensearchDossierSocieteRefRead } from '@europrocurement/l2d-domain/openApi/ApiFormalite';

export type DossierSocieteReference = DossierOpenSearchJsonldOpensearchDossierSocieteRefRead &
    Record<string, unknown>;

const useDossierSocieteReference = () => {
    const { dossierOpenSearchApi } = useFormaliteService();
    let searchSocieteReferenceTimeout: number | undefined;

    const searchDossierSocieteReference = (term: string): Promise<Array<DossierSocieteReference>> =>
        new Promise((resolve, reject) => {
            clearTimeout(searchSocieteReferenceTimeout);
            const requestParameters = { q: term || undefined };

            searchSocieteReferenceTimeout = setTimeout(() => {
                dossierOpenSearchApi
                    .apiOpensearchSocieteReferenceGetCollection(requestParameters)
                    .then((res) =>
                        resolve(res.data['hydra:member'] as Array<DossierSocieteReference>),
                    )
                    .catch(() => reject());
            }, 300);
        });

    return { searchDossierSocieteReference };
};

export default useDossierSocieteReference;
