import { ModelConfiguration, ModelItem, ModelMethods } from '@europrocurement/l2d-domain/types';
import { DevisApi } from '@europrocurement/l2d-domain/openApi/ApiFormalite';
import { DataSourcesThunksType } from '@europrocurement/l2d-redux-utils';
import { quoteIcon } from '@europrocurement/l2d-icons';
import { Devis, DEVIS_SLICE_NAME, DevisApiObject } from '../slices/devisSlice';

const modelKey = 'quote';
const modelName = 'Quote';
const sliceName = DEVIS_SLICE_NAME;

type ModelStructure = Devis;

const methods: ModelMethods<ModelStructure> = {
    getItemDefaultLabel: (model: ModelStructure) => `Numéro: ${model.numero}`,
    getItemSecondaryLabel: (model: ModelStructure) =>
        `Nombre de lignes: ${model.lignes?.length} - Actif: ${model.actif ? 'Oui' : 'Non'}`,
};

export type QuoteEndpoints = {
    list: InstanceType<typeof DevisApi>['apiDevisGetCollection'];
    read: InstanceType<typeof DevisApi>['apiDevisIdGet'];
};

export type QuoteModel = ModelItem<ModelStructure> & QuoteEndpoints;

export const buildQuoteModel = (
    api: DevisApi,
    datasourcesThunks: DataSourcesThunksType<DevisApiObject>,
) => {
    const configuration: ModelConfiguration = {
        key: modelKey,
        name: modelName,
        type: 'Quote',
        // selector: baseSelector,
        sliceName,
        api,
        datasourcesThunks,
        icon: quoteIcon,
        // color: 'blue',
    };
    const endpoints = {
        list: api.apiDevisGetCollection.bind(api),
        read: api.apiDevisIdGet.bind(api),
    };

    const model: QuoteModel = {
        ...configuration,
        ...methods,
        ...endpoints,
    };

    return model;
};
