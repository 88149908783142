import React from 'react';
import { useNavigate } from 'react-router-dom';
import { castIriToId } from '@europrocurement/l2d-utils';
import { Container } from '@mui/system';
import { useParcoursFormalite } from '../provider/useParcoursFormalite';
import { parcoursFormalitePathResolver } from '../parcoursFormalitePathResolver';
import { SelectionFormality } from '../components';
import { useDomaine, useOrigine } from '../hooks';

const StepSelectionFormalites: React.FunctionComponent = function () {
    const {
        prescripteurId,
        setIdFormalites,
        containerWidth,
        setCurrentCatalog,
        currentCatalogs,
        setCurrentCatalogs,
    } = useParcoursFormalite();
    const navigate = useNavigate();
    const domaineId = useDomaine();
    const origineId = useOrigine();

    return (
        <Container maxWidth={containerWidth}>
            <SelectionFormality
                prescripteurId={prescripteurId}
                origine={origineId}
                domaine={domaineId}
                defaultGroups={
                    (currentCatalogs || []).length > 0
                        ? (currentCatalogs || [])
                              ?.filter((catalog) => catalog?.groupement !== undefined)
                              .map((catalog) => catalog?.groupement?.id as number)
                        : []
                }
                defaultCatalogs={
                    (currentCatalogs || []).length > 0
                        ? (currentCatalogs || [])?.map((catalog) => catalog.id as number)
                        : []
                }
                onChange={(data) => {
                    if (data.catalogs.length > 0) {
                        setCurrentCatalog(data.catalogs[0]);
                        setCurrentCatalogs(data.catalogs);
                    }
                    setIdFormalites(
                        data.catalogs.map((catalog) => {
                            if (!catalog.package?.formalite) {
                                throw new Error('catalog not linked to formalite');
                            }
                            return castIriToId(catalog.package?.formalite);
                        }),
                    );
                    navigate(parcoursFormalitePathResolver('stepClient'));
                }}
            />
        </Container>
    );
};

export default StepSelectionFormalites;
