import React, { useState } from 'react';
import { FormHelperText, Tooltip, Typography } from '@mui/material';
import { Controller, RegisterOptions, useFormContext } from 'react-hook-form';
import { FlexyFormLabel, FlexyTextField } from '@europrocurement/flexy-components';
import parsePhoneNumber, { CountryCode } from 'libphonenumber-js';

export type ControlledNumberInputProps = {
    name: string;
    rules: RegisterOptions;
    label?: string;
    placeholder?: string;
    countryCode?: string;
};

const ControlledNumberInput: React.FunctionComponent<ControlledNumberInputProps> = function (
    props,
) {
    const { name, rules, label, placeholder, countryCode = 'FR' } = props;
    const { control } = useFormContext();
    const [currentValue, setCurrentValue] = useState<string>('');

    const handleChange = (
        e: React.ChangeEvent<HTMLInputElement>,
        onChange: (value: string) => void,
    ) => {
        const targetValue = e.target.value;
        const phoneNumber = parsePhoneNumber(targetValue, countryCode as CountryCode);

        if (phoneNumber && phoneNumber.isValid()) {
            if (targetValue.substring(0, 1) === '+') {
                setCurrentValue(phoneNumber.formatInternational());
                onChange(phoneNumber.formatInternational());
            } else {
                setCurrentValue(phoneNumber.formatNational());
                onChange(phoneNumber.formatNational());
            }
        } else {
            setCurrentValue(targetValue);
            onChange(targetValue);
        }
    };

    return (
        <Controller
            control={control}
            name={name}
            rules={{
                validate: (data, formData) => {
                    if (typeof rules.validate === 'function') {
                        const validateMessage = rules.validate(data, formData);

                        if (validateMessage !== true) {
                            return validateMessage;
                        }
                    }

                    if (data) {
                        const phoneNumber = parsePhoneNumber(data, countryCode as CountryCode);

                        if (!phoneNumber || !phoneNumber.isPossible() || !phoneNumber.isValid()) {
                            return "Ce numéro de téléphone n'est pas valide";
                        }
                    }

                    return true;
                },
            }}
            render={({ field: { onChange }, fieldState: { error } }) => (
                <>
                    {label && label !== '' ? (
                        <FlexyFormLabel>
                            {label}
                            {rules.required && (
                                <Tooltip title={rules.required as string}>
                                    <Typography
                                        component="span"
                                        color="danger.main"
                                    >
                                        &nbsp;&nbsp;*
                                    </Typography>
                                </Tooltip>
                            )}
                        </FlexyFormLabel>
                    ) : null}
                    <FlexyTextField
                        variant="outlined"
                        fullWidth
                        error={error !== undefined}
                        placeholder={placeholder || ''}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            handleChange(e, onChange)
                        }
                        value={currentValue}
                    />
                    {error ? (
                        <FormHelperText
                            sx={{
                                margin: '4px 14px 0px 14px',
                            }}
                            error
                        >
                            {error?.message}
                        </FormHelperText>
                    ) : null}
                </>
            )}
        />
    );
};

export default ControlledNumberInput;
