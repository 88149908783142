import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { REDUX_LIB_ARTICLE } from '../constants';

type StoredArticleLibSpecifics = Array<{ id: number; libelle: string }> | null;

const initialState: { result: StoredArticleLibSpecifics } = { result: null };

const articleLibSlice = createSlice({
    name: REDUX_LIB_ARTICLE,
    initialState,
    reducers: {
        updateSpecificsArticleLib(state, action: PayloadAction<StoredArticleLibSpecifics>) {
            // eslint-disable-next-line no-param-reassign
            state.result = action.payload;
        },
    },
});

export const { updateSpecificsArticleLib } = articleLibSlice.actions;

export default articleLibSlice;
