import { useFormaliteService } from '@europrocurement/l2d-domain';
import { DossierOpenSearchJsonldOpensearchDossierFormalisteRead } from '@europrocurement/l2d-domain/openApi/ApiFormalite';

export type DossierFormaliste = DossierOpenSearchJsonldOpensearchDossierFormalisteRead &
    Record<string, unknown>;

const useDossierFormaliste = () => {
    const { dossierOpenSearchApi } = useFormaliteService();
    let searchFormalisteTimeout: number | undefined;

    const searchDossierFormaliste = (term: string): Promise<Array<DossierFormaliste>> =>
        new Promise((resolve, reject) => {
            clearTimeout(searchFormalisteTimeout);
            const requestParameters = { q: term || undefined };

            searchFormalisteTimeout = setTimeout(() => {
                dossierOpenSearchApi
                    .apiOpensearchFormalistesGetCollection(requestParameters)
                    .then((res) => resolve(res.data['hydra:member'] as Array<DossierFormaliste>))
                    .catch(() => reject());
            }, 300);
        });

    return { searchDossierFormaliste };
};

export default useDossierFormaliste;
