import React from 'react';
import { Box, Button } from '@mui/material';
import { FlexyFilterWrapperProps } from '../DatatableHeader';
import FlexyMobilePopover from '../FlexyMobilePopover';

const FlexyFilterExpandable: React.FunctionComponent<FlexyFilterWrapperProps> = function (props) {
    const { anchorEl, open, onCancel, onSubmit, children } = props;

    const handleClose = () => {
        onCancel();
    };

    const handleSubmit = () => {
        onSubmit();
    };

    const btnStyles = {
        padding: '8px 24px',
        borderRadius: '12px',
        fontFamily: 'Inter',
        fontSize: '15px',
        fontWeight: '500',
        lineHeight: '24px',
        textAlign: 'center',
    };

    const controls = (
        <Box
            sx={{
                width: '100%',
                padding: '10px 24px',
                display: 'flex',
                justifyContent: 'center',
                gap: '0 16px',
                boxShadow: '1px 1px 15px 0px rgba(0, 0, 0, 0.1)',
                marginTop: 'auto',
            }}
        >
            <Button
                variant="text"
                onClick={handleClose}
                color="secondary"
                sx={btnStyles}
            >
                Fermer
            </Button>
            <Button
                variant="contained"
                onClick={handleSubmit}
                color="secondary"
                sx={btnStyles}
            >
                Voir les résultats
            </Button>
        </Box>
    );

    return (
        <FlexyMobilePopover
            open={open}
            anchorEl={anchorEl}
            controls={controls}
        >
            {children}
        </FlexyMobilePopover>
    );
};

export default FlexyFilterExpandable;
