import { OptionsApi, OptionsOptionRead } from '@europrocurement/l2d-domain/openApi/ApiOffre';
import { offerOptionIcon } from '@europrocurement/l2d-icons';
import { DataSourcesThunksType } from '@europrocurement/l2d-redux-utils';

import { type ModelConfiguration, type ModelMethods, type ModelItem } from '../../..';
import { OFFER_OPTIONS_SLICE_NAME } from '../constants';

const modelKey = 'offer_option';
const modelName = 'OfferOption';
const sliceName = OFFER_OPTIONS_SLICE_NAME;

type ModelStructure = OptionsOptionRead;

const methods: ModelMethods<ModelStructure> = {
    getItemDefaultLabel: (model: ModelStructure) => `${model.libelle}`,
    getItemSecondaryLabel: (model: ModelStructure) => `${model.description}`,
};

export type OfferOptionEndpoints = {
    list: InstanceType<typeof OptionsApi>['apiOptionsGetCollection'];
    dependencies: InstanceType<typeof OptionsApi>['apiOptionsIdDependenciesGet'];
    read: InstanceType<typeof OptionsApi>['apiOptionsIdGet'];
    update: InstanceType<typeof OptionsApi>['apiOptionsIdPut'];
    create: InstanceType<typeof OptionsApi>['apiOptionsPost'];
};

export type OfferOptionModel = ModelItem<ModelStructure> & OfferOptionEndpoints;

export const buildOfferOptionModel = (
    api: OptionsApi,
    datasourcesThunks: DataSourcesThunksType<OptionsOptionRead>,
) => {
    const configuration: ModelConfiguration = {
        key: modelKey,
        name: modelName,
        type: 'Options',
        sliceName,
        api,
        datasourcesThunks,
        icon: offerOptionIcon,
    };

    const endpoints = {
        list: api.apiOptionsGetCollection.bind(api),
        dependencies: api.apiOptionsIdDependenciesGet.bind(api),
        read: api.apiOptionsIdGet.bind(api),
        update: api.apiOptionsIdPut.bind(api),
        create: api.apiOptionsPost.bind(api),
    };

    const model: OfferOptionModel = {
        ...configuration,
        ...methods,
        ...endpoints,
    };

    return model;
};
