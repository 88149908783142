import { Box, SxProps } from '@mui/system';
import React from 'react';
import { Grow, Typography } from '@mui/material';
import { TransitionGroup } from 'react-transition-group';
import ChoiceItem, { ChoiceItemProps } from '../../atoms/ChoiceItem';
import ChoiceItemObjectGroup, { ChoiceItemGroupProps } from '../ChoiceItemObjectGroup';
import Scrollbar from '../Scrollbar';

export type ChoiceItemListProps = {
    label: string;
    listChoice: Array<ChoiceItemProps>;
    listGroupement?: Array<ChoiceItemGroupProps>;
    onChange?: (value: string) => void;
    multiple?: boolean;
    topSelected?: boolean;
    selectedValues?: string[];
    sx?: SxProps;
};

const ChoiceItemList: React.FunctionComponent<ChoiceItemListProps> = function ({
    label,
    listChoice,
    listGroupement,
    multiple = true,
    topSelected = false,
    selectedValues = [],
    onChange = () => {},
    sx = {},
}) {
    return (
        <Box
            sx={{
                marginBottom: '15px',
                ...sx,
            }}
        >
            <Typography
                sx={{
                    paddingLeft: '15px',
                    paddingBottom: '8px',
                }}
                variant="l2dcaption"
                color="text.secondary"
            >
                {label}
            </Typography>
            <Box
                sx={{
                    marginTop: '5px',
                }}
            >
                {topSelected ? (
                    <TransitionGroup>
                        {listChoice
                            .filter((choiceItem) =>
                                topSelected
                                    ? selectedValues.indexOf(choiceItem.value) !== -1
                                    : true,
                            )
                            .sort((a, b) =>
                                selectedValues.indexOf(a.value) > selectedValues.indexOf(b.value)
                                    ? 1
                                    : -1,
                            )
                            .map((choiceItem) => (
                                <Grow
                                    {...{ timeout: 300 }}
                                    key={choiceItem.value}
                                >
                                    <div>
                                        <ChoiceItem
                                            key={choiceItem.value}
                                            label={choiceItem.label}
                                            siren={choiceItem.siren}
                                            description={choiceItem.description}
                                            isChecked={selectedValues.includes(choiceItem.value)}
                                            value={choiceItem.value}
                                            onChange={onChange}
                                        />
                                    </div>
                                </Grow>
                            ))}
                    </TransitionGroup>
                ) : null}
                <Scrollbar
                    style={{
                        maxHeight: `calc(400px - 63px* ${selectedValues.length < 3 ? selectedValues.length : 3})`,
                    }}
                >
                    <TransitionGroup>
                        {listChoice
                            .filter((choiceItem) =>
                                topSelected
                                    ? selectedValues.indexOf(choiceItem.value) === -1
                                    : true,
                            )
                            .map((choiceItem) => (
                                <Grow
                                    {...{ timeout: 300 }}
                                    key={choiceItem.value}
                                >
                                    <div>
                                        <ChoiceItem
                                            label={choiceItem.label}
                                            siren={choiceItem.siren}
                                            description={choiceItem.description}
                                            isChecked={selectedValues.includes(choiceItem.value)}
                                            value={choiceItem.value}
                                            onChange={onChange}
                                        />
                                    </div>
                                </Grow>
                            ))}
                    </TransitionGroup>

                    {listGroupement?.map((choiceItemObjectGroup) => (
                        <ChoiceItemObjectGroup
                            key={choiceItemObjectGroup.label}
                            label={choiceItemObjectGroup.label}
                            selectedValues={selectedValues}
                            multiple={multiple}
                            onChange={onChange}
                            listObjectGroup={choiceItemObjectGroup.listObjectGroup}
                        />
                    ))}
                </Scrollbar>
            </Box>
        </Box>
    );
};

export default ChoiceItemList;
