import { ConfigurationParameters, Configuration, SirenApi } from '../../openApi/ApiSiren';

/**
 * Configuration
 */

export function buildSirenRedux(configSiren: ConfigurationParameters) {
    const sirenConfiguration = new Configuration(configSiren);

    const sirenApi = new SirenApi(sirenConfiguration);

    return {
        serviceConfiguration: sirenConfiguration,
        serviceConfigurationParameters: configSiren,
        apis: {
            sirenApi,
        },
        thunks: {},
        slices: {},
        fetchItems: {},
        getItems: {},
        models: {},
    };
}
