import { DevisApi } from '@europrocurement/l2d-domain/openApi/ApiFormalite';
import { combineReducers } from '@reduxjs/toolkit';

import {
    DevisLignesApi,
    Configuration as FacturationConfiguration,
    FactureApi,
    ConfigurationParameters as FactureFormaliteConfigurationParameters,
    FacturesMultiplesApi,
} from '../../openApi/ApiFacturation';
import { createDevisLigneSlice, DEVIS_LIGNE_SLICE_NAME } from './slices/devisLigneSlice';
import { createDevisSlice, DEVIS_SLICE_NAME } from './slices/devisSlice';
import {
    createFactureFormaliteSlice,
    FACTURE_FORMALITE_SLICE_NAME,
} from './slices/factureFormaliteSlice';
import { buildQuoteModel } from './Models';
import { buildQuoteLineModel } from './Models/quoteLine';

export function createFactureFormaliteReducer(
    configuration: FactureFormaliteConfigurationParameters,
) {
    const { factureFormaliteDataSourcesThunks, factureFormaliteSlice } =
        createFactureFormaliteSlice(configuration);
    return { factureFormaliteDataSourcesThunks, factureFormaliteSlice };
}

export function createDevisReducer(configuration: FactureFormaliteConfigurationParameters) {
    const { devisDataSourcesThunks, devisSlice } = createDevisSlice(configuration);
    return { devisDataSourcesThunks, devisSlice };
}

export function createDevisLigneReducer(configuration: FactureFormaliteConfigurationParameters) {
    const { devisLigneDataSourcesThunks, devisLigneSlice } = createDevisLigneSlice(configuration);
    return { devisLigneDataSourcesThunks, devisLigneSlice };
}

export function buildFacturationRedux(configuration: FactureFormaliteConfigurationParameters) {
    const facturationConfiguration = new FacturationConfiguration(configuration);

    const devisApi = new DevisApi(facturationConfiguration);
    const devisLignesApi = new DevisLignesApi(facturationConfiguration);
    const factureApi = new FactureApi(facturationConfiguration);
    const facturesMultiplesApi = new FacturesMultiplesApi(facturationConfiguration);

    const { factureFormaliteDataSourcesThunks, factureFormaliteSlice } =
        createFactureFormaliteReducer(facturationConfiguration);
    const { devisDataSourcesThunks, devisSlice } = createDevisReducer(facturationConfiguration);
    const { devisLigneDataSourcesThunks, devisLigneSlice } =
        createDevisLigneReducer(facturationConfiguration);

    const combinedFactReducers = combineReducers({
        [FACTURE_FORMALITE_SLICE_NAME]: factureFormaliteSlice.reducer,
        [DEVIS_SLICE_NAME]: devisSlice.reducer,
        [DEVIS_LIGNE_SLICE_NAME]: devisLigneSlice.reducer,
    });

    return {
        serviceConfigurationParameters: configuration,
        serviceConfiguration: facturationConfiguration,
        reducer: combinedFactReducers,
        apis: {
            devisApi,
            devisLignesApi,
            factureApi,
            facturesMultiplesApi,
        },
        slices: {
            factureFormaliteSlice,
            devisSlice,
            devisLigneSlice,
        },
        thunks: {
            factureFormaliteDataSourcesThunks,
            devisDataSourcesThunks,
            devisLigneDataSourcesThunks,
        },

        fetchItems: {
            getFacturesFormalite: factureFormaliteDataSourcesThunks.main.getData,
            getDevis: devisDataSourcesThunks.main.getData,
            getDevisLignes: devisLigneDataSourcesThunks.main.getData,
        },

        getItems: {
            selectFacturesFormalite: factureFormaliteDataSourcesThunks.main.getSelected,
            selectDevis: devisDataSourcesThunks.main.getSelected,
            selectDevisLignes: devisLigneDataSourcesThunks.main.getSelected,
        },

        models: {
            quote: buildQuoteModel(devisApi, devisDataSourcesThunks),
            quoteLine: buildQuoteLineModel(devisLignesApi, devisLigneDataSourcesThunks),
        },
    };
}
