import React, { useState, forwardRef } from 'react';
import { Box, Input, useTheme } from '@mui/material';
import { FlexyFilterWidgetProps } from '../DatatableHeader';
// import './FlexyTextFilter.css';

const FlexyTextFilter: React.FunctionComponent = forwardRef((props: FlexyFilterWidgetProps) => {
    const { filter, value, onChange } = props;
    const [localValue, setLocalValue] = useState<string | boolean | null>(value || '');
    const theme = useTheme();

    const handleChange = (inputValue: string) => {
        setLocalValue(inputValue);
        onChange(inputValue);
    };

    return (
        <Box
            sx={{
                width: '100%',
                padding: '10px',
            }}
        >
            <Input
                type={filter.type}
                placeholder={filter.label}
                onChange={(e) => handleChange(e.target.value)}
                value={localValue}
                fullWidth
                disableUnderline
                inputProps={{
                    sx: {
                        color: theme.palette.text.light,
                        borderColor: theme.palette.grey[400],
                        height: '48px',
                        boxSizing: 'border-box',
                        padding: '8px 16px',
                        borderRadius: '12px',
                        fontSize: '16px',
                        fontWeight: '400',
                        lineHeight: '32px',
                        textAlign: 'left',
                        borderWidth: '1px',
                        borderStyle: 'solid',
                    },
                }}
            />
        </Box>
    );
});

export default FlexyTextFilter;
