import { NamedIconsType } from '@europrocurement/l2d-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Typography, TypographyProps } from '@mui/material';
import { Box } from '@mui/system';
import React, { CSSProperties } from 'react';

export interface TextIconProps {
    children: React.ReactNode;
    icon: Pick<NamedIconsType, 'props'>;
    typoProps?: TypographyProps;
    gap?: CSSProperties['gap'];
}

const TextIcon: React.FunctionComponent<TextIconProps> = function ({
    children,
    icon,
    gap = '12px',
    typoProps = {},
}) {
    return (
        <Box
            sx={{
                display: 'flex',
                gap,
                alignItems: 'center',
            }}
        >
            <FontAwesomeIcon
                size="lg"
                icon={icon.props.icon}
            />
            <Typography
                variant="l2dbodys"
                {...typoProps}
            >
                {children}
            </Typography>
        </Box>
    );
};

export default TextIcon;
