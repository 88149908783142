import {
    selectedPrescripteursSelector,
    useTiersServiceSelector,
} from '@europrocurement/l2d-domain';

export const useCurrentPrescriber = () => {
    const prescripteur = useTiersServiceSelector(selectedPrescripteursSelector);

    if (!prescripteur) {
        throw new Error('missing prescripteur');
    }

    return prescripteur;
};
