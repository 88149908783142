import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { FORMULAIRE_REDUCER_NAME } from './constants';
import { buildFormulairesRedux } from './formulairesReducer';

const { reducer } = buildFormulairesRedux({});

export const formulaireReducer = combineReducers({
    [FORMULAIRE_REDUCER_NAME]: reducer,
});

export const formulaireStore = configureStore({
    reducer: formulaireReducer,
});

export type FormulaireReducerType = typeof formulaireReducer;
export type FormulaireStateType = ReturnType<typeof formulaireStore.getState>;
export type FormulaireAppDispatch = typeof formulaireStore.dispatch;
