import * as React from 'react';
import { Box, lighten, PaletteColor, SxProps, useTheme } from '@mui/material';
import './ProgressBar.css';

export type ProgressBarProps = {
    completion?: number;
    color?: PaletteColor;
    minCompletion?: number;
    sx?: SxProps;
};

const ProgressBar: React.FunctionComponent<ProgressBarProps> = function (props: ProgressBarProps) {
    const theme = useTheme();
    const { color, completion, minCompletion = 5, sx } = props;
    const completionPercentage = Math.min(
        Math.max(completion || minCompletion, minCompletion),
        100,
    );
    const mainColor = color || theme.palette.primary;

    return (
        <Box
            className="progress-bar-wrapper"
            sx={{
                backgroundColor: lighten(mainColor.main, 0.85),
                borderRadius: '3px',
                height: '2px',
                ...sx,
            }}
            data-testid="progress-bar"
        >
            <Box
                className="progress-bar-completion"
                sx={{
                    borderRadius: '3px',
                    opacity: '100%',
                    height: '2px',
                    backgroundColor: mainColor.main,
                    ...sx,
                    width: `${completionPercentage}%`,
                }}
                data-testid="progress-bar-completion"
            />
        </Box>
    );
};

export default ProgressBar;
