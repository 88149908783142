import React from 'react';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { type BoxProps, styled } from '@mui/material';
import { SxProps } from '@mui/system';

export type EmptyBoxProps = {
    text: string;
    sx?: SxProps;
} & BoxProps;

const EmptyBox: React.FunctionComponent<EmptyBoxProps> = styled((props: EmptyBoxProps) => {
    const { text } = props;

    return (
        <Box
            {...props}
            data-testid="emptybox"
        >
            <Typography
                variant="overline"
                color="GrayText"
                sx={{
                    marginLeft: '2%',
                    marginRight: '2%',
                }}
            >
                {text}
            </Typography>
        </Box>
    );
})(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderStyle: 'dashed',
    borderRadius: '10px',
    borderWidth: '1px',
    borderColor: `${theme.palette.grey[500]}`,
    width: '100%',
    height: '100%',
    minWidth: '200px',
    minHeight: '100px',
}));

export default EmptyBox;
