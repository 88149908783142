import {
    DARK_THEME,
    SET_ID_PRESCRIPTEUR,
    SET_ID_SOCIETE,
    SET_PRESCRIPTEURS,
    THEME_COLOR,
    THEME_LIST,
} from '../../constants';
import { type CustomizerReducerType } from './CustomizerReducer';

export const setTheme = (payload: THEME_LIST) => ({
    type: THEME_COLOR,
    payload,
});

export const setDarkMode = (payload: CustomizerReducerType['activeMode']) => ({
    type: DARK_THEME,
    payload,
});

export const setXIdSociete = (payload: CustomizerReducerType['xIdSociete']) => ({
    type: SET_ID_SOCIETE,
    payload,
});

export const setIdPrescripteur = (payload: CustomizerReducerType['idPrescripteur']) => ({
    type: SET_ID_PRESCRIPTEUR,
    payload,
});

export const setPrescripteurs = (payload: CustomizerReducerType['prescripteurs']) => ({
    type: SET_PRESCRIPTEURS,
    payload,
});
