import { FaOptionIcon } from '@europrocurement/l2d-icons';
import { Box, Typography, useTheme } from '@mui/material';
import React from 'react';
import { ProgressBar } from '@europrocurement/flexy-components';
import { NestedDossierFormaliteOpenSearch } from '@europrocurement/l2d-domain';
import PeoplePreview from '../PeoplePreview';
import DossierFormaliteLabel from './DossierFormaliteLabel';

type PreviewColumnProps = {
    contactPrenom?: string | null;
    contactNom?: string | null;
    formalites?: Array<NestedDossierFormaliteOpenSearch>;
    numero?: string | null;
    societeRef?: string | null;
    documentsCount?: number | null;
};

const PreviewColumn: React.FunctionComponent<PreviewColumnProps> = function (props) {
    const { contactPrenom, contactNom, formalites, numero, societeRef, documentsCount } = props;
    const theme = useTheme();
    const { palette } = theme;

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-start',
                width: '100%',
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    width: '64px',
                }}
            >
                <PeoplePreview
                    firstName={contactPrenom as string}
                    lastName={contactNom as string}
                />
            </Box>

            <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                <DossierFormaliteLabel formalites={formalites || []} />

                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'flex-start',
                    }}
                >
                    <Typography
                        variant="l2dtableCell"
                        color="text.gris70"
                    >
                        {numero}
                    </Typography>
                    {societeRef ? (
                        <Typography
                            color="text.gris50"
                            variant="l2dtableCell"
                        >
                            {societeRef}
                        </Typography>
                    ) : null}
                </Box>
                <Box sx={{ marginTop: '10px' }}>
                    <ProgressBar
                        sx={{ height: '5px', width: '100px' }}
                        completion={10}
                        color={palette.primary}
                        minCompletion={10}
                    />
                </Box>
            </Box>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    marginLeft: 'auto',
                    paddingRight: '8px',
                }}
            >
                <Box
                    sx={{
                        color: 'text.gris70',

                        display: 'flex',
                        flexDirection: 'row',
                        gap: '5px',
                    }}
                >
                    <FaOptionIcon icon={['fasl', 'paperclip']} />
                    <Typography
                        variant="l2dtableCell"
                        component="span"
                    >
                        {documentsCount}
                    </Typography>
                </Box>
                <Box
                    sx={{
                        color: 'text.gris70',

                        display: 'flex',
                        flexDirection: 'row',
                        gap: '5px',
                    }}
                >
                    <FaOptionIcon icon={['fasl', 'message']} />
                    <Typography
                        variant="l2dtableCell"
                        component="span"
                    >
                        0
                    </Typography>
                </Box>
            </Box>
        </Box>
    );
};

export default PreviewColumn;
