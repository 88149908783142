import {
    ControlledSelectItemsFormStructure,
    FormObject,
    FormStructure,
} from '@europrocurement/flexy-form/components';
import { SelectItemsProps } from '@europrocurement/flexy-components';
import { FieldValues, UseFormReturn } from 'react-hook-form';
import { jsonRadioInput } from '../types/formTypes';
import { checkFieldValue } from '../functions';

const jsonRadioInputToFormElement: (input: jsonRadioInput) => FormStructure = (input) => {
    const baseField: ControlledSelectItemsFormStructure = {
        type: 'selectItems',
        name: input.name,
        inputlabel: input.inputlabel,
        // placeholder: input.placeholder,
        mode: 'basic',
        // colInline: 6,
        xs: input.xs,
        sm: input.sm,
        md: input.md,
        lg: input.lg,
        items: [],
        rules: {},
        sx: {
            fontSize: '14px',
            marginTop: '15px',
            marginBottom: '15px',
            fontWeight: '500',
        },
    };

    if (input.valuesfrom.options) {
        const arrayLength = Object.entries(input.valuesfrom.options).length;

        baseField.colInline = 1;
        baseField.gridColLength = arrayLength;

        const optionsFlexySelect: SelectItemsProps['items'] = Object.entries(
            input.valuesfrom.options,
        ).map((option) => ({
            value: option[0] as string,
            label: option[1] as string,
        }));
        baseField.items = optionsFlexySelect;
    }

    if (input.valuesfrom.api) {
        baseField.optionsResolver = {
            name: 'listeSelectionDataResolver',
            params: {
                api: input.valuesfrom.api.name,
                endpoint: input.valuesfrom.api.endpoint,
                discriminant: input.valuesfrom.api.params.discriminant,
            },
        };
    }

    // comportement sur rules display
    if (input.rules && input.rules.display) {
        if (input.rules.display.fieldIsEqualTo) {
            const fieldNameToWatch = input.rules.display.fieldIsEqualTo.field;
            const valueToWatch = input.rules.display.fieldIsEqualTo.value;

            baseField.display = (formContext: UseFormReturn<FieldValues, FormObject>) =>
                checkFieldValue(formContext, valueToWatch, fieldNameToWatch);
        }
    }

    return baseField;
};

export default jsonRadioInputToFormElement;
