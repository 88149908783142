import {
    SliceFactory,
    FetchCollectionData,
    ApiCollectionResponse,
    FetchItemDataType,
} from '@europrocurement/l2d-redux-utils';
import {
    ConfigurationParameters as FormalisteApiConfigurationParameters,
    Configuration,
    FormalisteFormalisteRead,
    FormalisteApi,
    FormalisteApiApiFormalistesGetCollectionRequest,
    FormalisteApiApiFormalistesIdGetRequest,
} from '../../../openApi/ApiFormalite';
import { FORMALISTE_SLICE_NAME, FORMALITES_REDUCER_NAME } from '../constants';

export type FormalisteApiObject = FormalisteFormalisteRead;

export type Formaliste = FormalisteApiObject;

export const mapperFormaliste = function (formaliste: Formaliste) {
    return {
        ...formaliste,
    } as Formaliste;
};

export function createFormalisteSlice(configuration: FormalisteApiConfigurationParameters) {
    const conf = new Configuration(configuration);
    const api = new FormalisteApi(conf);

    const fetchFormalisteCollection: FetchCollectionData<Formaliste> = ({ pagination }) => {
        const options = undefined;
        const requestParameters: FormalisteApiApiFormalistesGetCollectionRequest = {
            page: pagination.page + 1,
            itemsPerPage: 100,
        };

        return api
            .apiFormalistesGetCollection(requestParameters, options)
            .then((res) => res.data) as Promise<ApiCollectionResponse<Formaliste>>;
    };

    const fetchFormalisteItem: FetchItemDataType<Formaliste> = ({ idItem }) => {
        const requestParameters: FormalisteApiApiFormalistesIdGetRequest = {
            id: String(idItem),
        };

        return api
            .apiFormalistesIdGet(requestParameters)
            .then((res) => res.data) as Promise<Formaliste>;
    };

    const { slice: formalisteSlice, dataSourcesThunks: formalisteDataSourcesThunks } =
        SliceFactory.createSlice<Formaliste, Formaliste>(
            FORMALISTE_SLICE_NAME,
            FORMALITES_REDUCER_NAME,
            [],
            fetchFormalisteCollection,
            fetchFormalisteItem,
            mapperFormaliste,
            {},
        );

    return { formalisteSlice, formalisteDataSourcesThunks };
}
