import {
    SliceFactory,
    FetchCollectionData,
    FetchItemDataType,
    ApiCollectionResponse,
} from '@europrocurement/l2d-redux-utils';
import { TypeDeDocumentTypedocumentUpdate } from '@europrocurement/l2d-domain/openApi/ApiFormalite';
import {
    ConfigurationParameters as OffreConfigurationParameters,
    Configuration,
    TypeDeDocumentApi,
} from '../../../openApi/ApiOffre';
import { AUTOCOMPLETE_KEY, DOCUMENT_SLICE_NAME, OFFRE_REDUCER_NAME } from '../constants';

export type DocumentApiObject = TypeDeDocumentTypedocumentUpdate & Record<string, unknown>;

export type Documents = DocumentApiObject;

const mapperDocuments = function (offers: DocumentApiObject) {
    return {
        ...offers,
    } as Documents;
};

export function createDocumentsSlice(configuration: OffreConfigurationParameters) {
    const conf = new Configuration(configuration);
    const api = new TypeDeDocumentApi(conf);

    const fetchDocumentsCollection: FetchCollectionData<DocumentApiObject> = ({
        pagination: { page, itemsPerPage },
        xIdSociete,
        search,
    }) => {
        let libelle: string | undefined;

        if (search && search !== '') {
            libelle = search;
        }

        return api
            .apiTypesDocumentsGetCollection({ xIdSociete, page: page + 1, itemsPerPage, libelle })
            .then((res) => res.data)
            .catch((error) => {
                console.error(error);
            }) as Promise<ApiCollectionResponse<DocumentApiObject>>;
    };

    const fetchDocumentsItem: FetchItemDataType<DocumentApiObject> = ({
        idItem,
        // pagination,
        xIdSociete,
    }) =>
        api
            .apiTypesDocumentsIdGet({ id: String(idItem), xIdSociete })
            .then((res) => res.data) as Promise<DocumentApiObject>;

    const { slice: documentsSlice, dataSourcesThunks: documentsDataSourcesThunks } =
        SliceFactory.createSlice<DocumentApiObject, Documents>(
            DOCUMENT_SLICE_NAME,
            OFFRE_REDUCER_NAME,
            [AUTOCOMPLETE_KEY],
            fetchDocumentsCollection,
            fetchDocumentsItem,
            mapperDocuments,
        );

    return { documentsSlice, documentsDataSourcesThunks };
}
