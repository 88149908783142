/* eslint-disable @typescript-eslint/no-unused-vars */

import {
    FORMALITES_REDUCER_NAME,
    OFFRE_REDUCER_NAME,
    TIERS_REDUCER_NAME,
    buildFormaliteRedux,
    buildOfferRedux,
    buildTiersRedux,
} from '@europrocurement/l2d-domain';
import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { standaloneReducerBase } from './RootStore';

const appReducer = combineReducers({
    [FORMALITES_REDUCER_NAME]: buildFormaliteRedux({}).reducer,
    [OFFRE_REDUCER_NAME]: buildOfferRedux({}).reducer,
    [TIERS_REDUCER_NAME]: buildTiersRedux({}).reducer,
    ...standaloneReducerBase,
});

const appStore = configureStore({
    reducer: appReducer,
});

export type RootStateType = ReturnType<typeof appStore.getState>;
export type AppDispatch = typeof appStore.dispatch;

export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootStateType> = useSelector;
