import * as React from 'react';
import { Checkbox as MuiCheckbox, CheckboxProps as MuiCheckboxProps } from '@mui/material';
import {
    checkboxCheckedIcon,
    checkboxUncheckedIcon,
    checkboxIndeterminateIcon,
    FaOptionIcon,
} from '@europrocurement/l2d-icons';
import { useTheme } from '@mui/system';

export type CheckboxProps = MuiCheckboxProps;

const Checkbox: React.FunctionComponent<CheckboxProps> = function (props: CheckboxProps) {
    const { color = 'primary' } = props;
    const theme = useTheme();
    const palette = theme.palette[color] || theme.palette.primary;
    const icon = (
        <FaOptionIcon
            {...checkboxUncheckedIcon.props}
            color={theme.palette.background.dark}
            optionProps={{
                style: {
                    ...checkboxUncheckedIcon?.props?.optionProps?.style,
                    color: theme.palette.grey[400],
                },
            }}
        />
    );
    const checkedIcon = (
        <FaOptionIcon
            {...checkboxCheckedIcon.props}
            color={palette.light}
            optionProps={{
                style: {
                    ...checkboxCheckedIcon?.props?.optionProps?.style,
                    color: palette.dark,
                },
            }}
        />
    );
    const indeterminateIcon = (
        <FaOptionIcon
            {...checkboxIndeterminateIcon.props}
            color={palette.light}
            optionProps={{
                style: {
                    ...checkboxCheckedIcon?.props?.optionProps?.style,
                    color: palette.dark,
                },
            }}
        />
    );

    return (
        <MuiCheckbox
            {...props}
            icon={icon}
            checkedIcon={checkedIcon}
            indeterminateIcon={indeterminateIcon}
            data-testid="checkbox"
        />
    );
};

export default Checkbox;
