import {
    SliceFactory,
    FetchCollectionData,
    FetchItemDataType,
    ApiCollectionResponse,
} from '@europrocurement/l2d-redux-utils';
import {
    ConfigurationParameters as OffreConfigurationParameters,
    Configuration,
    RubriqueFacturationApi,
    RubriqueFacturationJsonldRubriqueFacturationRead,
} from '../../../openApi/ApiOffre';
import { INVOICING_CATEGORIES_SLICE_NAME, OFFRE_REDUCER_NAME } from '../constants';

export type InvoicingCategoryApiObject = RubriqueFacturationJsonldRubriqueFacturationRead &
    Record<string, unknown>;

export type InvoicingCategories = InvoicingCategoryApiObject;

const mapperInvoicingCategory = function (invoicingCategories: InvoicingCategoryApiObject) {
    return {
        ...invoicingCategories,
    } as InvoicingCategories;
};

export function createInvoicingCategorySlice(configuration: OffreConfigurationParameters) {
    const conf = new Configuration(configuration);
    const api = new RubriqueFacturationApi(conf);

    const fetchInvoicingCategoriesCollection: FetchCollectionData<InvoicingCategoryApiObject> = ({
        pagination: { page, itemsPerPage },
        xIdSociete,
    }) =>
        api
            .apiRubriqueFacturationsGetCollection({
                xIdSociete,
                page: page + 1,
                itemsPerPage,
            })
            .then((res) => res.data)
            .catch((error) => {
                console.error(error);
            }) as Promise<ApiCollectionResponse<InvoicingCategoryApiObject>>;

    const fetchOffersItem: FetchItemDataType<InvoicingCategoryApiObject> = ({
        idItem,
        // pagination,
        xIdSociete,
    }) =>
        api
            .apiRubriqueFacturationsGetItem({ id: String(idItem), xIdSociete })
            .then((res) => res.data) as Promise<InvoicingCategoryApiObject>;

    const { slice: invoicingCategorySlice, dataSourcesThunks: invoicingCategoryDataSourcesThunks } =
        SliceFactory.createSlice<InvoicingCategoryApiObject, InvoicingCategories>(
            INVOICING_CATEGORIES_SLICE_NAME,
            OFFRE_REDUCER_NAME,
            [],
            fetchInvoicingCategoriesCollection,
            fetchOffersItem,
            mapperInvoicingCategory,
        );

    return { invoicingCategorySlice, invoicingCategoryDataSourcesThunks };
}
