import { combineReducers } from '@reduxjs/toolkit';

import { createUtilisateurSlice } from './slices/utilisateurSlice';
import { UTILISATEUR_SLICE_NAME } from './constants';
import {
    UtilisateursApi,
    Configuration as UtilisateursConfiguration,
} from '../../openApi/ApiUtilisateur';
import { type UtilisateursConfigurationParameters } from './types';

export function createUtilisateurReducer(configuration: UtilisateursConfigurationParameters) {
    const { utilisateurSlice, utilisateurDataSourcesThunks } =
        createUtilisateurSlice(configuration);

    return {
        utilisateurSlice,
        utilisateurDataSourcesThunks,
    };
}

export function buildUtilisateurRedux(configuration: UtilisateursConfigurationParameters) {
    const utilisateursConfiguration = new UtilisateursConfiguration(configuration);

    const utilisateursApi = new UtilisateursApi(utilisateursConfiguration);

    const { utilisateurSlice, utilisateurDataSourcesThunks } =
        createUtilisateurReducer(configuration);

    const combinedUtilisateursReducers = combineReducers({
        [UTILISATEUR_SLICE_NAME]: utilisateurSlice.reducer,
    });

    return {
        serviceConfigurationParameters: configuration,
        serviceConfiguration: utilisateursConfiguration,
        reducer: combinedUtilisateursReducers,

        apis: {
            utilisateursApi,
        },
        slices: {
            utilisateurSlice,
        },
        thunks: {
            utilisateurDataSourcesThunks,
        },

        fetchItems: {
            getUtilisateurs: utilisateurDataSourcesThunks.main.getData,
        },

        getItems: {
            selectUtilisateurs: utilisateurDataSourcesThunks.main.getSelected,
        },

        models: {},
    };
}
