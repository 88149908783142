import React, { ReactNode } from 'react';
import { FlexyFormLabel, SelectItems, SelectItemsProps } from '@europrocurement/flexy-components';
import { Controller, RegisterOptions, useFormContext } from 'react-hook-form';
import { FormHelperText, SxProps, Tooltip, Typography } from '@mui/material';
import _ from 'lodash';

export type ControlledSelectItemsProps = {
    name: string;
    rules: RegisterOptions;
    label?: string;
    inputlabel?: string | ReactNode;
    required?: boolean;
    items: SelectItemsProps['items'] | Promise<SelectItemsProps['items']>;
    sx?: SxProps;
} & Omit<SelectItemsProps, 'onChange' | 'items'>;

const ControlledSelectItems: React.FunctionComponent<ControlledSelectItemsProps> = function ({
    name,
    rules,
    showMore,
    label,
    inputlabel,
    items,
    required,
    sx: sxProps,
    ...inputProps
}) {
    const { control } = useFormContext();

    const [asyncItems, setAsyncItems] = React.useState<SelectItemsProps['items']>([]);

    let { colInline } = inputProps;
    let { gridColLength } = inputProps;

    if (Array.isArray(items)) {
        if (!_.isEqual(asyncItems, items)) {
            setAsyncItems(items);
        }
    } else {
        items.then(setAsyncItems);
    }

    if (inputProps.mode === 'singleLine') {
        colInline = 1;
        gridColLength = asyncItems.length;
    }

    return (
        <Controller
            control={control}
            name={name}
            rules={rules}
            render={({
                field: { onChange, value },
                fieldState: { error },
                // formState,
            }) => (
                <>
                    {inputlabel && inputlabel !== '' ? (
                        <FlexyFormLabel
                            sx={sxProps}
                            data-testid="test-id-FlexyInput-label"
                        >
                            {inputlabel}
                            {required && (
                                <Tooltip title="Le champ est requis">
                                    <Typography
                                        component="span"
                                        color="danger.main"
                                    >
                                        &nbsp;&nbsp;*
                                    </Typography>
                                </Tooltip>
                            )}
                        </FlexyFormLabel>
                    ) : null}

                    <SelectItems
                        onChange={onChange}
                        {...inputProps}
                        value={value}
                        items={asyncItems}
                        showMore={showMore}
                        gridColLength={gridColLength}
                        colInline={colInline}
                        showMoreLabel={label}
                    />
                    {error ? (
                        <FormHelperText
                            sx={{
                                margin: '4px 14px 0px 14px',
                            }}
                            error
                        >
                            {error?.message}
                        </FormHelperText>
                    ) : null}
                </>
            )}
        />
    );
};

export default ControlledSelectItems;
