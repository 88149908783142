import React, { useContext, useEffect, useId, useRef, useState } from 'react';
import { Box, Button, lighten, useTheme } from '@mui/material';
import { FaOptionIcon } from '@europrocurement/l2d-icons';
import { faTimes } from '@fortawesome/pro-duotone-svg-icons';
import { FilterDatatable, FlexyFilterValue } from '../DatatableHeader';
import useFilter from './useFilter';
import { FlexyFilterContext } from './FlexyFilterContext';

export type FlexyFilterProps = {
    filter: FilterDatatable;
    value: FlexyFilterValue;
    onChange: (filter: FilterDatatable, value: string | number | boolean | null) => void;
    onClear: (filter: FilterDatatable) => void;
};

const FlexyFilter: React.FunctionComponent<FlexyFilterProps> = function (props) {
    const { filter, value, onChange, onClear } = props;
    const { icon } = filter;
    const [hover, setHover] = useState<boolean>(false);

    const id = useId();
    const { getFilterLabel, getWrapperComponent, getWidgetComponent } = useFilter();
    const label = getFilterLabel(filter, value); // TODO value preview
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
    const [expanded, setExpanded] = useState<boolean>(false);
    const [inputValue, setInputValue] = useState<FlexyFilterValue>(value);
    const [inputRawValue, setRawInputValue] = useState<FlexyFilterValue>();
    const { setRawValue } = useContext(FlexyFilterContext);
    const widgetRef = useRef();
    const theme = useTheme();

    const wrapperFn = getWrapperComponent(filter);
    const widgetFn = getWidgetComponent(filter);

    useEffect(() => {
        setInputValue(value);
    }, [value]);

    const handleFocus = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
        setExpanded(true);

        const currentRef: { getValueOnFocus: unknown } = widgetRef.current;

        if (currentRef && currentRef.getValueOnFocus) {
            onChange(filter, currentRef.getValueOnFocus());
        }
    };
    const handleUnfocus = () => {
        setAnchorEl(null);
        setExpanded(false);
    };
    const handleClear = () => {
        setHover(false);
        onClear(filter);
    };
    const handleSubmit = () => {
        setAnchorEl(null);
        setExpanded(false);
        onChange(filter, inputValue);

        if (inputRawValue !== undefined) {
            setRawValue(inputRawValue);
        }
    };
    const handleChangeValue = (v: string | boolean | number | null, rawValue?: unknown) => {
        setInputValue(v);

        if (undefined !== rawValue) {
            setRawInputValue(rawValue);
        }
    };

    let wrapper = null;
    let widget = null;

    if (widgetFn) {
        widget = React.createElement(
            widgetFn,
            {
                key: filter.field,
                value,
                filter,
                onChange: handleChangeValue,
                ref: widgetRef,
            },
            [],
        );
    }

    if (wrapperFn) {
        wrapper = React.createElement(
            wrapperFn,
            {
                id,
                anchorEl,
                open: expanded,
                onSubmit: handleSubmit,
                onCancel: handleUnfocus,
                value,
            },
            widget ? [widget] : [],
        );
    }

    return (
        <>
            <Box
                onMouseEnter={() => setHover(true)}
                onMouseLeave={() => setHover(false)}
                className="flexy-filter"
                sx={{
                    padding: '4px 8px 4px 8px',
                    borderRadius: '12px',
                    display: 'flex',
                    gap: '0 4px',
                    alignItems: 'center',
                    flexWrap: 'nowrap',
                    background:
                        hover || value !== null
                            ? theme.palette.primary.light
                            : lighten(theme.palette.secondary.dark, 0.9),
                }}
            >
                <Button
                    color="inherit"
                    aria-describedby={id}
                    onClick={handleFocus}
                    sx={{
                        display: 'flex',
                        gap: '0 4px',
                        alignItems: 'center',
                        padding: '0',
                        minWidth: 'auto',
                        fontSize: '13px',
                        fontWeight: '500',
                        lineHeight: '20.8px',
                        textAlign: 'left',
                    }}
                >
                    {icon && (
                        <FaOptionIcon
                            sx={{ fontSize: '12px', lineHeight: '0' }}
                            icon={['fasl', icon]}
                        />
                    )}
                    {label}
                </Button>
                {value !== null && (
                    <Button
                        sx={{
                            padding: '0',
                            fontSize: '12px',
                            fontWeight: '900',
                            lineHeight: '12px',
                            textAlign: 'center',
                            minWidth: 'auto',
                        }}
                        onClick={handleClear}
                    >
                        <FaOptionIcon
                            color={theme.palette.primary.dark}
                            icon={faTimes}
                        />
                    </Button>
                )}
            </Box>
            {wrapper}
        </>
    );
};

export default FlexyFilter;
