import * as React from 'react';

import { Box, Typography, Avatar, Button } from '@mui/material';
import { KeycloakHasRole, KeycloakIsAuth, UseKeycloakService } from '@europrocurement/l2d-keycloak';
import { Link, useNavigate } from 'react-router-dom';
import {
    FaOptionIcon,
    codeIcon,
    companyIcon,
    errorIcon,
    mailIcon,
    preferencesIcon,
    quitIcon,
    userIcon,
    usersIcon,
} from '@europrocurement/l2d-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export type ProfileDropdownProps = {
    jobName?: string;
    avatarSrc?: string;
    logout: () => void;
    navigateToPrescripteur?: () => void;
    navigateToMembers?: () => void;
    openSettings?: () => void;
};
const ProfileDropdown: React.FunctionComponent<ProfileDropdownProps> = function ({
    jobName,
    avatarSrc,
    logout,
    navigateToPrescripteur,
    navigateToMembers,
    openSettings,
}: ProfileDropdownProps) {
    const navigate = useNavigate();

    const keycloak = UseKeycloakService();

    return (
        <>
            <Box
                sx={{
                    padding: '30px 30px 8px 30px',
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '15px',
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        verticalAlign: 'middle',
                        justifyContent: 'space-between',
                    }}
                >
                    <Typography variant="l2dh6">Mon Compte</Typography>
                    <FaOptionIcon
                        {...errorIcon.props}
                        sx={{ width: '24px', heigth: '24px', color: 'text.gris50' }}
                    />
                </Box>
                <Box
                    role="menu"
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        flexWrap: 'wrap',
                        paddingTop: '16px',
                        paddingBottom: '25px',
                        borderBottom: '1px solid rgba(10, 26, 83, 0.1)',
                    }}
                >
                    <Box>
                        <Avatar
                            alt="avatar"
                            src={avatarSrc}
                            sx={{ width: '90px', height: '90px' }}
                        />
                    </Box>

                    <Box
                        sx={{
                            marginLeft: '24px',
                            display: 'flex',
                            flexDirection: 'column',
                        }}
                    >
                        {keycloak.getName() ? (
                            <Typography
                                lineHeight="1.4"
                                variant="l2dh6"
                                sx={{
                                    fontSize: '1.125rem',
                                }}
                            >
                                {keycloak.getName()}
                            </Typography>
                        ) : null}
                        {jobName ? (
                            <Typography
                                color="text.gris50"
                                variant="l2dcaption"
                                lineHeight="1.4"
                            >
                                {jobName}
                            </Typography>
                        ) : null}
                        {keycloak.getEmail() ? (
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    gap: '4px',
                                    color: 'text.gris50',
                                }}
                            >
                                <FontAwesomeIcon
                                    icon={mailIcon.props.icon}
                                    size="xs"
                                />
                                <Typography
                                    lineHeight="1.4"
                                    color="text.gris50"
                                    variant="l2dcaption"
                                >
                                    {keycloak.getEmail()}
                                </Typography>
                            </Box>
                        ) : null}
                    </Box>
                </Box>
            </Box>
            <Box
                sx={{
                    padding: '0px 30px 30px 30px',
                }}
            >
                <Typography
                    variant="l2dcaption"
                    color="text.gris50"
                >
                    Mon profil
                </Typography>
                <Box sx={{ paddingTop: '4px', paddingBottom: '4px' }}>
                    <Button
                        variant="text"
                        onClick={() => {
                            navigate(keycloak.getKc().createAccountUrl());
                        }}
                        sx={{ color: 'text.gris70', alignItems: 'normal', fontWeight: 500 }}
                    >
                        <FaOptionIcon
                            {...userIcon.props}
                            size="xs"
                            sx={{ marginRight: '12px' }}
                        />
                        Mon compte
                    </Button>
                </Box>
                {openSettings ? (
                    <Box sx={{ paddingTop: '4px', paddingBottom: '4px' }}>
                        <Button
                            variant="text"
                            onClick={openSettings}
                            sx={{ color: 'text.gris70', alignItems: 'normal', fontWeight: 500 }}
                        >
                            <FaOptionIcon
                                {...preferencesIcon.props}
                                size="xs"
                                sx={{ marginRight: '12px' }}
                            />
                            Mes préférences
                        </Button>
                    </Box>
                ) : null}
                {navigateToPrescripteur || navigateToMembers ? (
                    <Typography
                        variant="l2dcaption"
                        color="text.gris50"
                    >
                        Paramètres de la société
                    </Typography>
                ) : null}
                {navigateToPrescripteur ? (
                    <Box sx={{ paddingTop: '4px', paddingBottom: '4px' }}>
                        <Button
                            onClick={navigateToPrescripteur}
                            variant="text"
                            sx={{ color: 'text.gris70', alignItems: 'normal', fontWeight: 500 }}
                        >
                            <FaOptionIcon
                                {...companyIcon.props}
                                size="xs"
                                sx={{ marginRight: '12px' }}
                            />
                            Ma structure
                        </Button>
                    </Box>
                ) : null}
                {navigateToMembers ? (
                    <Box sx={{ paddingTop: '4px', paddingBottom: '4px' }}>
                        <Button
                            onClick={navigateToMembers}
                            variant="text"
                            sx={{ color: 'text.gris70', alignItems: 'normal', fontWeight: 500 }}
                        >
                            <FaOptionIcon
                                {...usersIcon.props}
                                size="xs"
                                sx={{ marginRight: '12px' }}
                            />
                            Membres
                        </Button>
                    </Box>
                ) : null}
                <KeycloakIsAuth>
                    <>
                        <KeycloakHasRole kcRole="realm:debug">
                            <Typography
                                variant="l2dcaption"
                                color="text.gris50"
                            >
                                Administration
                            </Typography>

                            <Box sx={{ paddingTop: '4px', paddingBottom: '4px' }}>
                                <Button
                                    color="primary"
                                    variant="text"
                                    component={Link}
                                    to="/debug"
                                    disableElevation
                                    sx={{
                                        color: 'text.gris70',
                                        alignItems: 'normal',
                                        fontWeight: 500,
                                    }}
                                >
                                    <FaOptionIcon
                                        {...codeIcon.props}
                                        size="xs"
                                        sx={{ marginRight: '12px' }}
                                    />
                                    Outils de développement
                                </Button>
                            </Box>
                        </KeycloakHasRole>
                        <Box
                            sx={{
                                borderTop: '1px solid rgba(10, 26, 83, 0.1)',
                                paddingTop: '8px',
                            }}
                        >
                            <Button
                                onClick={logout}
                                variant="text"
                                title="Déconnexion"
                                sx={{ color: 'text.gris70', alignItems: 'normal', fontWeight: 500 }}
                            >
                                <FaOptionIcon
                                    {...quitIcon.props}
                                    size="xs"
                                    sx={{ marginRight: '12px' }}
                                />
                                Se déconnecter
                            </Button>
                        </Box>
                    </>
                </KeycloakIsAuth>
            </Box>
        </>
    );
};

export default ProfileDropdown;
