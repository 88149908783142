import React, { ReactNode, useCallback, useMemo, useState } from 'react';
import {
    ModalContext,
    ModalContextType,
    callModalType,
    closeModalType,
    modalActionsType,
    modalStatusType,
    openModalType,
    resetModalType,
} from './ModalContext';

export type ModalProviderType = {
    children: ReactNode;
};

const ModalProvider: React.FunctionComponent<ModalProviderType> = function ({ children }) {
    const [modalContent, setModalContent] = useState<ReactNode>();
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
    const [isModalDraggable, setIsModalDraggable] = useState<boolean>(false);
    const [isModalFullWidth, setIsModalFullWidth] = useState<boolean>(false);

    const closeModal: closeModalType = useCallback((onCloseEvent?: () => void) => {
        if (onCloseEvent !== undefined) {
            onCloseEvent();
        }
        setIsModalOpen(false);
    }, []);

    const callModal: callModalType = useCallback(
        (
            modalContentToCall: ReactNode,
            isModalToCallDraggable?: boolean,
            isModalToCallFullWidth?: boolean,
        ) => {
            setModalContent(modalContentToCall);
            setIsModalDraggable(!!isModalToCallDraggable);
            setIsModalFullWidth(!!isModalToCallFullWidth);
            setIsModalOpen(true);
        },
        [],
    );
    const openModal: openModalType = useCallback(() => setIsModalOpen(true), []);

    const resetModal: resetModalType = useCallback(() => {
        closeModal();
        setModalContent(null);
    }, [closeModal]);

    const modalActions: modalActionsType = useMemo<modalActionsType>(
        () => ({
            close: closeModal,
            call: callModal,
            open: openModal,
            reset: resetModal,
            setContent: setModalContent,
        }),
        [callModal, closeModal, openModal, resetModal],
    );

    const modalStatus: modalStatusType = useMemo<modalStatusType>(
        () => ({
            isOpen: isModalOpen,
            content: modalContent,
            isDraggable: isModalDraggable,
            isFullWidth: isModalFullWidth,
        }),
        [isModalDraggable, isModalFullWidth, isModalOpen, modalContent],
    );

    const modalContextProviderValue = useMemo<ModalContextType>(
        () => ({
            modalActions,
            modalStatus,
        }),
        [modalActions, modalStatus],
    );

    return (
        <ModalContext.Provider value={modalContextProviderValue}>{children}</ModalContext.Provider>
    );
};

export default ModalProvider;
