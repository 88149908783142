import {
    ConfigurationParameters,
    Configuration,
    AnnonceLegaleApi,
} from '../../openApi/ApiAnnoncesLegales';

/**
 * Configuration
 */

export function buildAnnonceLegalesRedux(configAl: ConfigurationParameters) {
    const annoncesLegalesConfiguration = new Configuration(configAl);

    const legalAdvertApi = new AnnonceLegaleApi(annoncesLegalesConfiguration);
    return {
        serviceConfiguration: annoncesLegalesConfiguration,
        serviceConfigurationParameters: configAl,
        apis: {
            legalAdvertApi,
        },
        thunks: {},
        slices: {},
        fetchItems: {},
        getItems: {},
        models: {},
    };
}
