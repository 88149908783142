import React from 'react';

import { ErrorPage } from '../ErrorPage';

export type NotFoundProps = {
    message?: string;
};

export const NotFound: React.FunctionComponent<NotFoundProps> = function ({
    message = 'Oups, cette page est introuvable !',
}) {
    return (
        <ErrorPage
            statusCode={404}
            message={message}
            details="Il semble que cette page n'existe plus ou que le lien soit incorrect."
        />
    );
};
export default NotFound;
