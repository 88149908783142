import { Box } from '@mui/material';
import * as React from 'react';
import { FaOptionIcon, orderAscIcon, orderDescIcon, orderNoIcon } from '@europrocurement/l2d-icons';
import { OrdersOpts } from '../DatatableHeader';
import { ColumnDatatable } from '../ColumnDatatable';

export type SortDirection = 'asc' | 'desc' | undefined;

type ColumnHeaderItemSortabledProps = {
    onOrdersChange: OrdersOpts['onOrdersChange'];
    field: ColumnDatatable['field'];
    defaultValue: SortDirection;
};

const ColumnHeaderItemSortabled: React.FunctionComponent<ColumnHeaderItemSortabledProps> =
    function ({ onOrdersChange, field, defaultValue }: ColumnHeaderItemSortabledProps) {
        const handleClick = () => {
            let newDirection: SortDirection;

            // solution 1
            if (!defaultValue) newDirection = 'asc';
            if (defaultValue === 'asc') newDirection = 'desc';
            if (defaultValue === 'desc') newDirection = undefined;
            onOrdersChange(`${field}`, newDirection);
        };

        const renderChevron = () => {
            switch (defaultValue) {
                case 'asc':
                    return (
                        <FaOptionIcon
                            {...orderAscIcon.props}
                            color="text.primary"
                        />
                    );
                case 'desc':
                    return (
                        <FaOptionIcon
                            {...orderDescIcon.props}
                            color="text.primary"
                        />
                    );
                case undefined:
                default:
                    return (
                        <FaOptionIcon
                            {...orderNoIcon.props}
                            color="text.primary"
                            iconProps={{
                                opacity: '20%',
                            }}
                        />
                    );
            }
        };

        return (
            <Box
                onClick={handleClick}
                sx={{
                    padding: 0,
                    margin: 0,
                    width: '24px',
                    height: '24px',
                    cursor: 'pointer',
                }}
            >
                {renderChevron()}
            </Box>
        );
    };

export default ColumnHeaderItemSortabled;
