import { CustomOptionResolver } from '@europrocurement/flexy-form';
import { FormulaireFormaliteApiObject, useFormulaireService } from '@europrocurement/l2d-domain';
import { jsonLdIdScraper } from '@europrocurement/l2d-utils';
import _ from 'lodash';

export const useListeSelectionDataResolver = () => {
    const { formulaireListeSelectionApi } = useFormulaireService();

    // Record<string, Promise<AxiosResponse<unknow, unknow>>>

    const createListeSelectionDataResolver = (form: FormulaireFormaliteApiObject) => {
        const listeSelectionDataResolver: CustomOptionResolver = async ({
            optionsResolverName,
            params,
            input,
        }) => {
            if (optionsResolverName === 'listeSelectionDataResolver') {
                let res = null;
                switch (params.api) {
                    case 'apiformulaire': {
                        const paramsGetListeSelection = {
                            idFormalite: jsonLdIdScraper(form.formalites[0]) * 1,
                            idFormulaireFormalite: form.id,
                            typeListe: _.kebabCase(input.name),
                            discriminant:
                                typeof params.discriminant === 'string' ? params.discriminant : '',
                        };

                        res =
                            await formulaireListeSelectionApi.apiFormulaireFormalitesIdFormulaireFormaliteformaliteIdFormalitelisteSelectionsTypeListeDiscriminantGet(
                                paramsGetListeSelection,
                            );

                        if (
                            !res.data['hydra:member'] ||
                            typeof res.data['hydra:member'] !== 'object'
                        ) {
                            throw new Error('list selection response mal formed');
                        }
                        break;
                    }
                    default: {
                        console.log('Source de données inconnu pour ', input, ':', params.api);
                    }
                }

                return res?.data['hydra:member'].map(
                    ({ id, value }: { id: number; value: string }) => ({
                        label: value,
                        value: id,
                    }),
                );
            }
            console.log('Resolver non géré:', optionsResolverName);
            return null;
        };
        return listeSelectionDataResolver;
    };

    return createListeSelectionDataResolver;
};
