import React from 'react';
import { styled, useTheme } from '@mui/material/styles';
import { OutlinedTextFieldProps, TextField } from '@mui/material';
import './inputs.css';

export type FlexyTextFieldProps = OutlinedTextFieldProps;

/**
 * Ce composant permet l'affichage d'un input au style personnalisé.
 *
 * ## Utilisation
 * Il hérite directement du [composant FlexyInput de Material UI](https://mui.com/material-ui/react-text-field/), et s'utilise donc de la même façon.
 */
const FlexyTextField: React.FunctionComponent<FlexyTextFieldProps> = styled(
    (props: FlexyTextFieldProps) => {
        // const [value, setValue] = React.useState( props.value || props.defaultValue );
        const theme = useTheme();
        // let onPaste;

        // if(props.type !== "number"){
        //     onPaste = (event:ClipboardEvent<HTMLInputElement>) => {
        //         if(event.clipboardData){
        //             setValue(event.clipboardData.getData("text").trim())
        //         }
        //     };
        // }

        // inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}

        return (
            <TextField
                {...props}
                InputProps={{
                    ...props.InputProps,
                    sx: {
                        ...props.InputProps?.sx,
                        backgroundColor:
                            theme.palette.mode === 'dark' ? 'rgba(0, 0, 0, 0.12) ' : '#FFFFFF ',
                    },
                }}
            />
        );
    },
)(({ theme }) => ({
    '& .MuiOutlinedInput-notchedOutline': {
        borderColor: `${theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.12)' : '#dee3e9'}`,
    },
    '& .MuiOutlinedInput-input.Mui-disabled': {
        backgroundColor: `${theme.palette.mode === 'dark' ? 'rgba(0, 0, 0, 0.12) ' : '#f8f9fb '}`,
    },
    '& .MuiOutlinedInput-input.Mui-disabled::-webkit-input-placeholder': {
        color: '#767e89',
        opacity: '1',
    },
}));

export default FlexyTextField;
