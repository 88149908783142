import React, { useCallback } from 'react';
import {
    CustomDivider,
    Information,
    InformationCard,
    InformationCardProps,
    NoteInformation,
    Previous,
    SelectItems,
    SelectItemsProps,
} from '@europrocurement/flexy-components';
import { DossierFormalite } from '@europrocurement/l2d-domain';
import { castIriToId, formatPrice } from '@europrocurement/l2d-utils';
import { Button, Grid, Switch, TextField, Typography, useTheme } from '@mui/material';
import { Box } from '@mui/system';
import { FichiersDossierJsonldDocumentfileRead } from '@europrocurement/l2d-domain/openApi/ApiFormalite';

type Items = InformationCardProps['items'];

type RecapFormalityShowProps = {
    // collection: CollectionDossierFormalite;
    dossier: DossierFormalite;
    fichiers: FichiersDossierJsonldDocumentfileRead[];
    onPrevious?: () => void;
    onSubmit?: (dossier: DossierFormalite, refInterne: string | null) => void;
};
type RecapArticlesLinesProps = {
    rubFac: string;
    tarif: number;
    rubFacTitle: string;
};

export const rubfacToTitle = (rubfac: string): string => {
    switch (rubfac) {
        case 'PUB':
            return 'Annonce légale';
        case 'PUBNS':
            return 'Annonce légale(non soumis à la TVA)';
        case 'JUS':
            return 'Exemplaires justificatifs';
        case 'FRS':
            return 'Frais de registre';
        case 'FRN':
            return 'Frais de registre (non soumis à la TVA)';
        case 'ADJ':
            return 'Adjudication';
        case 'VAC':
            return 'Honoraires';
        case 'REG':
            return 'Vente de registre';
        case 'DIV':
            return 'divers';
        default:
            return 'Traitement dossier';
    }
};

export const reduceLines = function (dossier: DossierFormalite) {
    if (!dossier.prestations) {
        return [];
    }
    let montantRubFac: Array<RecapArticlesLinesProps> = [];
    dossier.prestations.map((prestation) => {
        if (prestation.articles) {
            prestation.articles.map((article) => {
                if (article.tarif && !article.fMasqueCalcul && article.factArticle.rubArticle) {
                    if (
                        montantRubFac.map((e) => e.rubFac).indexOf(article.factArticle.rubArticle) >
                        -1
                    ) {
                        montantRubFac = montantRubFac.map((item) => {
                            if (article.factArticle.rubArticle === item.rubFac) {
                                return {
                                    ...item,
                                    tarif: item.tarif + article.tarif * article.quantite,
                                };
                            }
                            return { ...item };
                        });
                    } else {
                        montantRubFac.push({
                            rubFac: article.factArticle.rubArticle,
                            tarif: article.tarif * article.quantite,
                            rubFacTitle: rubfacToTitle(article.factArticle.rubArticle),
                        });
                    }
                }
                return montantRubFac;
            }, []);
        }
        return montantRubFac;
    });

    return montantRubFac;
};

const RecapFormalityShow: React.FunctionComponent<RecapFormalityShowProps> = function ({
    dossier,
    fichiers,
    onPrevious,
    onSubmit,
}) {
    // if (!collection.dossiers || collection.dossiers.length === 0) {
    //     return null;
    // }

    // const dossier = collection?.dossiers[0];

    // const dossierArticles = dossier?.prestations.map((prestation) =>
    //     prestation.articles ? prestation.articles : [],
    // );
    // console.log(dossierArticles);

    const [refInterne, setRefInterne] = React.useState<string | null>(null);

    let cardItemsSocite: InformationCardProps['items'] = [
        {
            label: 'Société concernée',
            value: dossier.societeDenomination,
        },
    ];
    if (dossier.societeSiren) {
        cardItemsSocite = [
            ...cardItemsSocite,
            {
                label: 'Numéro Siren',
                value: dossier.societeSiren,
            },
        ];
    }

    const cardItemsFormalite: () => Items = () => {
        if (!dossier.prestations || dossier.prestations.length === 0) {
            return [];
        }
        return dossier.prestations?.map((prestation) => ({
            label: 'Type de formalité',
            value: prestation.libelle,
        }));
    };

    const cardItemsArticles = reduceLines(dossier).map((line) => (
        <Box
            display="flex"
            key={line.rubFacTitle}
            justifyContent="space-between"
            alignItems="center"
            sx={{ paddingRight: '30px', width: '100%' }}
        >
            <Typography>{line.rubFacTitle}</Typography>
            <Typography>{`${formatPrice(line.tarif)} €`}</Typography>
        </Box>
    ));

    const theme = useTheme();

    const cardProps = {
        backgroundColor: theme.palette.background.dark,
        boxShadow: '0px 2px 2.5px 0px rgba(193, 202, 215, 0.25)',
    };

    const informationsDocuments: InformationCardProps['items'] =
        dossier?.documents
            ?.map((typedoc) => ({
                ...typedoc,
                libelle: typedoc.libelle || 'pas de libelle',
                id: typedoc.id || -1,
            }))
            .map((requiredType) => {
                const docOk = fichiers?.find(
                    (item) => item.typeDocDossier && item.typeDocDossier.id === requiredType.id,
                );
                if (docOk) {
                    console.log(docOk);
                    return {
                        label: requiredType.libelle,
                        value: (
                            <Typography color={theme.palette.primary.main}>
                                {docOk.fichier}
                            </Typography>
                        ),
                    };
                }
                return {
                    label: requiredType.libelle,
                    value: 'Manquant',
                };
            }) || [];

    const facturationChoice: SelectItemsProps['items'] = [
        {
            label: 'Nom Ma Société',
            value: '1',
            isChecked: false,
            baseline: '1 rue de la paix, 29590 Le Faou',
        },
        {
            label: 'Nom Sous-client',
            value: '6',
            isChecked: true,
            baseline: '1 Allée des paquerêtes jaunes 75015 Paris',
        },
    ];

    const recouvrementChoice: SelectItemsProps['items'] = [
        { label: 'Nom Ma Société', value: '1', isChecked: true },
        { label: 'Legal2Digital', value: '6', isChecked: false },
    ];

    const onSubmitHandler = useCallback(() => {
        if (onSubmit) {
            onSubmit(dossier, refInterne);
        }
    }, [dossier, onSubmit, refInterne]);

    return (
        <>
            {onPrevious ? <Previous onClick={onPrevious} /> : null}
            <Typography
                variant="l2dh4"
                sx={{
                    marginBottom: '10px',
                }}
            >
                Récapitulatif du dossier #{dossier.numero}
            </Typography>
            <Grid
                container
                flexWrap="nowrap"
                spacing={6}
            >
                <Grid
                    item
                    lg={8}
                    display="flex"
                    flexDirection="column"
                    gap="16px"
                >
                    <Box
                        className="information-card"
                        sx={{ ...cardProps }}
                    >
                        <Box
                            className="heading"
                            sx={{
                                borderLeftColor: theme.palette.primary.main,
                            }}
                        >
                            <Box
                                className="title"
                                sx={{
                                    color: theme.palette.text.primary,
                                }}
                            >
                                Paramètres du dossier
                            </Box>
                        </Box>
                        <Box sx={{ padding: '20px' }}>
                            <Box>
                                <Typography
                                    variant="l2dbodym"
                                    fontWeight={700}
                                >
                                    Référence interne du dossier
                                </Typography>
                                <Typography
                                    sx={{ marginLeft: '5px' }}
                                    variant="l2dbodym"
                                    color="text.secondary"
                                >
                                    (facultatif)
                                </Typography>
                            </Box>
                            <TextField
                                fullWidth
                                placeholder="Saisissez une référence pour ce dossier"
                                sx={{ marginTop: '10px', marginBottom: '25px' }}
                                onChange={(
                                    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
                                ) => {
                                    if (e.target.value && e.target.value !== '') {
                                        setRefInterne(e.target.value);
                                    } else {
                                        setRefInterne(null);
                                    }
                                }}
                            />
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    gap: '15px',
                                    padding: '10px',
                                    alignItems: 'start',
                                    justifyContent: 'space-between',
                                }}
                            >
                                <Typography
                                    variant="l2dbodym"
                                    fontWeight={700}
                                >
                                    Marquer ce dossier comme urgent ?
                                </Typography>

                                <Switch
                                    size="small"
                                    aria-label="switch"
                                    onChange={() => {
                                        alert('pastèque');
                                    }}
                                />
                            </Box>
                            <Box>
                                <NoteInformation title="Cette option entraînera des frais de traitement supplémentaires." />
                            </Box>
                        </Box>
                    </Box>

                    <Box
                        className="information-card"
                        sx={{ ...cardProps }}
                    >
                        <Box
                            className="heading"
                            sx={{
                                borderLeftColor: theme.palette.primary.main,
                            }}
                        >
                            <Box
                                className="title"
                                sx={{
                                    color: theme.palette.text.primary,
                                }}
                            >
                                Facturation
                            </Box>
                        </Box>
                        <Box sx={{ padding: '20px' }}>
                            {/* button={{
                            label: 'Éditer les informations',
                            onClick: () => {
                                console.log('blette !');
                            },
                        }} */}
                            <Typography
                                variant="l2dbodym"
                                fontWeight={700}
                            >
                                Facture éditée au nom de :
                            </Typography>
                            <SelectItems
                                items={facturationChoice}
                                mode="inline"
                                // value={facturationChoice.value}
                                onChange={() => {
                                    console.log('carotte !');
                                }}
                                multiple={false}
                            />
                            <Typography
                                variant="l2dbodym"
                                fontWeight={700}
                            >
                                Recouvrement effectué par :
                            </Typography>
                            <SelectItems
                                items={recouvrementChoice}
                                mode="inline"
                                // value={recouvrementChoice.value}
                                onChange={() => {
                                    console.log('navet !');
                                }}
                                multiple={false}
                            />
                        </Box>
                    </Box>
                    <InformationCard
                        title="Société"
                        items={cardItemsSocite}
                        sx={{ ...cardProps }}
                        button={{
                            label: 'Revenir à cette étape',
                            onClick: () => {
                                console.log('tomate !');
                            },
                        }}
                    />
                    <InformationCard
                        title="Formalité"
                        items={cardItemsFormalite()}
                        sx={{ ...cardProps }}
                        button={{
                            label: 'Revenir à cette étape',
                            onClick: () => {
                                console.log('patate !');
                            },
                        }}
                    />

                    {dossier.documents &&
                    dossier.documents.filter(
                        (item) => item.statut && castIriToId(item.statut) === 7,
                    ) ? (
                        <InformationCard
                            title="Documents"
                            sx={{ ...cardProps }}
                            items={informationsDocuments}
                            button={{
                                label: 'Revenir à cette étape',
                                onClick: () => {
                                    console.log('courgette !');
                                },
                            }}
                        />
                    ) : null}
                </Grid>
                <Grid
                    item
                    lg={4}
                    display="flex"
                    flexDirection="column"
                    gap="16px"
                >
                    <Box
                        className="information-card"
                        sx={{ ...cardProps, padding: '24px  32px' }}
                    >
                        <Typography variant="h2">Prix estimatif</Typography>
                        {cardItemsArticles}
                        {/* {dossier?.prestations
                            ? dossier?.prestations.map((prestation) =>
                                  prestation?.articles
                                      ? prestation?.articles.map((article) => (
                                            <Information
                                                label={article?.libelle}
                                                value={
                                                    article?.tarif
                                                        ? `${formatPrice(article?.tarif)} €`
                                                        : 'A déterminer'
                                                }
                                            />
                                        ))
                                      : null,
                              )
                            : null} */}
                        <CustomDivider />
                        <Information
                            label="Prix HT"
                            value={`${formatPrice(dossier.ht)} €`}
                        />
                        <Information
                            label="TVA"
                            value={`${formatPrice(dossier.tva)} €`}
                        />
                        <CustomDivider />
                        <Information
                            label="Prix TTC"
                            value={`${formatPrice(dossier.ttc)} €`}
                        />
                    </Box>
                    <Box
                        className="information-card"
                        sx={{ ...cardProps, padding: '24px  32px' }}
                    >
                        <Typography
                            variant="h4"
                            sx={{
                                marginBottom: '10px',
                            }}
                        >
                            <b>Commentaires</b> (facultatif)
                        </Typography>
                        <textarea
                            style={{ width: '100%', borderRadius: '10px', padding: '5px' }}
                            placeholder="Ajouter des informations complémentaires au dossier"
                        />
                    </Box>
                    <Box>
                        <Button
                            color="primary"
                            variant="contained"
                            sx={{ width: '100%' }}
                            onClick={onSubmitHandler}
                        >
                            Envoyer le dossier
                        </Button>
                    </Box>
                </Grid>
            </Grid>
        </>
    );
};

export default RecapFormalityShow;
