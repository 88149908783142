import { FormStructure } from '@europrocurement/flexy-form/components';
import { jsonDatetimeInput } from '../types/formTypes';

const jsonDatetimeInputToFormElement: (input: jsonDatetimeInput) => FormStructure = (input) => {
    const baseField: FormStructure = {
        type: input.type,
        name: input.name,
        inputlabel: input.inputlabel,
        placeholder: input.placeholder,
        inputProps: {
            startAdornment: input.startAdornment,
            endAdornment: input.endAdornment,
        },
        xs: input.xs,
        sm: input.sm,
        md: input.md,
        lg: input.lg,
        sx: {
            fontSize: '14px',
            marginTop: '15px',
            marginBottom: '15px',
            fontWeight: '500',
        },
    };

    return baseField;
};

export default jsonDatetimeInputToFormElement;
