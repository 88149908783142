import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { CustomizerStateType } from './types';

/**
 * Hooks that regroup prescriber actions / methods / accessors
 */

export const SocieteSelector = (s: CustomizerStateType) => s.customizer.xIdSociete;

export const useSociete = () => {
    const societe = useSelector(SocieteSelector);

    const [xIdSociete, setXIdSociete] = useState(societe);

    useEffect(() => {
        if (societe !== xIdSociete) {
            setXIdSociete(societe);
        }
    }, [societe, xIdSociete]);

    return xIdSociete;
};

export const IdPrescripteurCustomizerSelector = (s: CustomizerStateType) =>
    s.customizer.idPrescripteur;

export const useIdPrescripteurCustomizer = () => {
    const prescripteur = useSelector(IdPrescripteurCustomizerSelector);

    const [idPrescripteur, setIdPrescripteur] = useState(prescripteur);

    useEffect(() => {
        if (prescripteur !== idPrescripteur) {
            setIdPrescripteur(prescripteur);
        }
    }, [prescripteur, idPrescripteur]);

    return idPrescripteur;
};

export const PrescripteursCustomizerSelector = (s: CustomizerStateType) =>
    s.customizer.prescripteurs;

export const usePrescripteursCustomizer = () => {
    const prescripteurs = useSelector(PrescripteursCustomizerSelector);

    const [prescripteursState, setPrescripteurState] = useState(prescripteurs);

    useEffect(() => {
        if (prescripteurs !== prescripteursState) {
            setPrescripteurState(prescripteurs);
        }
    }, [prescripteursState, prescripteurs]);

    return prescripteursState;
};
