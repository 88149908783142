import {
    SliceFactory,
    FetchCollectionData,
    ApiCollectionResponse,
    FetchItemDataType,
} from '@europrocurement/l2d-redux-utils';
import {
    ConfigurationParameters as FormalityApiConfigurationParameters,
    Configuration,
    TypeDeDocumentApiApiTypesDocumentsGetCollectionRequest,
    TblTypeDocumentDocumentfileRead,
    TypeDeDocumentApi,
    TypeDeDocumentApiApiTypesDocumentsIdGetRequest,
} from '../../../openApi/ApiFormalite';
import { FORMALITE_DOCUMENT_SLICE_NAME, FORMALITES_REDUCER_NAME } from '../constants';

export type FormaliteDocumentApiObject = TblTypeDocumentDocumentfileRead & Record<string, unknown>;

export type FormaliteDocument = FormaliteDocumentApiObject;

export const mapperFormaliteDocument = function (document: FormaliteDocument) {
    return {
        ...document,
    } as FormaliteDocument;
};

export function createFormaliteDocumentSlice(configuration: FormalityApiConfigurationParameters) {
    const conf = new Configuration(configuration);
    const api = new TypeDeDocumentApi(conf);

    const fetchFormaliteDocumentsCollection: FetchCollectionData<FormaliteDocument> = ({
        pagination,
    }) => {
        const options = undefined;
        const requestParameters: TypeDeDocumentApiApiTypesDocumentsGetCollectionRequest = {
            page: pagination.page + 1,
            itemsPerPage: 100,
        };

        return api
            .apiTypesDocumentsGetCollection(requestParameters, options)
            .then((res) => res.data) as Promise<ApiCollectionResponse<FormaliteDocument>>;
    };

    const fetchFormaliteDocumentItem: FetchItemDataType<FormaliteDocument> = ({ idItem }) => {
        const requestParameters: TypeDeDocumentApiApiTypesDocumentsIdGetRequest = {
            id: String(idItem),
        };

        return api
            .apiTypesDocumentsIdGet(requestParameters)
            .then((res) => res.data) as Promise<FormaliteDocument>;
    };

    const { slice: formaliteDocumentSlice, dataSourcesThunks: formaliteDocumentDataSourcesThunks } =
        SliceFactory.createSlice<FormaliteDocument, FormaliteDocument>(
            FORMALITE_DOCUMENT_SLICE_NAME,
            FORMALITES_REDUCER_NAME,
            [],
            fetchFormaliteDocumentsCollection,
            fetchFormaliteDocumentItem,
            mapperFormaliteDocument,
            {},
        );

    return { formaliteDocumentSlice, formaliteDocumentDataSourcesThunks };
}
