import { combineReducers, configureStore } from '@reduxjs/toolkit';
import {
    DroitsJsonldDroitReadCollection as DroitsUtilisateurApiObject,
    GroupesJsonldGroupReadCollection as GroupsUtilisateurApiObject,
    JournauxJsonldJournauxReadCollection as JournauxUtilisateurApiObject,
    MarquesJsonldSocieteMarqueReadCollection as MarquesUtilisateurApiObject,
    PolesJsonldDefPoleReadCollection as PolesUtilisateurApiObject,
    ServicesJsonldServiceReadCollection as ServicesUtilisateurApiObject,
    UtilisateursJsonldUtilisateurRead as UtilisateurApiObject,
    ConfigurationParameters as UtilisateursConfigurationParameters,
} from '../../openApi/ApiUtilisateur';
import { buildUtilisateurRedux } from './utilisateursReducer';
import { UTILISATEUR_REDUCER_NAME } from './constants';

export {
    type DroitsUtilisateurApiObject,
    type GroupsUtilisateurApiObject,
    type JournauxUtilisateurApiObject,
    type MarquesUtilisateurApiObject,
    type PolesUtilisateurApiObject,
    type ServicesUtilisateurApiObject,
    type UtilisateurApiObject,
    type UtilisateursConfigurationParameters,
};

export type Mutable<T> = {
    -readonly [key in keyof T]: T[key];
};

const { reducer } = buildUtilisateurRedux({});

const utilisateursReducer = combineReducers({
    [UTILISATEUR_REDUCER_NAME]: reducer,
});

export const utilisateursStore = configureStore({
    reducer: utilisateursReducer,
});

export type UtilisateursReducerType = typeof utilisateursReducer;
export type UtilisateursStateType = ReturnType<typeof utilisateursStore.getState>;
export type UtilisateursAppDispatch = typeof utilisateursStore.dispatch;
