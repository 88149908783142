import React, { BaseSyntheticEvent, MouseEvent, useState } from 'react';

import { useDispatch } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/pro-duotone-svg-icons';
import { Box, Button, Stack, Typography, Menu, MenuItem } from '@mui/material';
import {
    CustomizerReducerType,
    useIdPrescripteurCustomizer,
    usePrescripteursCustomizer,
    setIdPrescripteur,
} from '@europrocurement/l2d-domain';

const DropdownPrescriber: React.FunctionComponent = function () {
    const dispatch = useDispatch();

    const idPrescripteurCustomizer = useIdPrescripteurCustomizer();
    const PrescripteursCustomizer = usePrescripteursCustomizer();

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const open = Boolean(anchorEl);

    const handleClickSelectIdSociete = (event: MouseEvent<HTMLButtonElement>) =>
        setAnchorEl(event.currentTarget);
    const handleCloseSelectIdSociete = () => setAnchorEl(null);
    const handleChangePrescriber = (e: BaseSyntheticEvent) => {
        dispatch(setIdPrescripteur(e.target.value as CustomizerReducerType['xIdSociete']));
        handleCloseSelectIdSociete();
    };

    const currentRaisonSciale = PrescripteursCustomizer.find(
        (pr) => pr.id === idPrescripteurCustomizer,
    )?.raisonSociale;

    return (
        <Box>
            <Button
                id="basic-button"
                aria-controls={open ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={(e) => {
                    if (PrescripteursCustomizer.length > 1) {
                        handleClickSelectIdSociete(e);
                    }
                }}
                color="inherit"
            >
                <Stack
                    alignItems="center"
                    display="flex"
                    flexDirection="row"
                    gap={1}
                >
                    <Typography
                        variant="l2dbodys"
                        color="text.gris50"
                    >
                        {currentRaisonSciale || 'Veuillez sélectionner un prescripteur'}
                    </Typography>
                    {PrescripteursCustomizer.length > 1 ? (
                        <FontAwesomeIcon
                            icon={faChevronDown}
                            flip={open ? 'vertical' : undefined}
                        />
                    ) : null}
                </Stack>
            </Button>

            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleCloseSelectIdSociete}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
            >
                {PrescripteursCustomizer.map((prescripteur) => (
                    <MenuItem
                        key={prescripteur.id}
                        value={prescripteur.id}
                        onClick={handleChangePrescriber}
                    >
                        {prescripteur.raisonSociale} {prescripteur.siret}
                    </MenuItem>
                ))}
            </Menu>
        </Box>
    );
};

export default DropdownPrescriber;
