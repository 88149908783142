import React, { useEffect, useState } from 'react';
import { Box, Button, Typography, useMediaQuery, useTheme } from '@mui/material';
import { FaOptionIcon } from '@europrocurement/l2d-icons';
import { faChevronDown } from '@fortawesome/pro-duotone-svg-icons';
import { PreFilterDatatable, PreFilterDatatableList } from '../DatatableHeader';
import FlexyPreFilter from '../FlexyPreFilter/FlexyPreFilter';
import FlexyMobilePopover from '../FlexyMobilePopover';

export type FlexyPreFiltersProps = {
    filters: PreFilterDatatableList;
    current?: PreFilterDatatable;
    onClick: (preFilter: PreFilterDatatable) => void;
};

const FlexyPreFilters: React.FunctionComponent<FlexyPreFiltersProps> = function (props) {
    const { filters, current, onClick } = props;
    const theme = useTheme();
    const { palette, breakpoints } = theme;
    const downSmBreakpoint = useMediaQuery(breakpoints.down('sm'));
    const [preSelected, setPreSelected] = useState<PreFilterDatatable>();
    const [showPanel, setShowPanel] = useState<boolean>(false);
    const { items } = filters;

    useEffect(() => {
        if (current !== undefined && current.label !== preSelected?.label) {
            setPreSelected(current);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [current]);

    const handleConfirmMobilePanel = () => {
        setShowPanel(false);

        if (preSelected) {
            onClick(preSelected);
        }
    };

    const handleCancelMobilePanel = () => {
        if (current) {
            setPreSelected(current);
        }

        setShowPanel(false);
    };

    if (downSmBreakpoint) {
        const btnStyle = {
            padding: '8px 24px',
            borderRadius: '12px',
            fontFamily: 'Inter',
            fontSize: '15px',
            fontWeight: '500',
            lineHeight: '24px',
            textAlign: 'center',
        };

        const controls = (
            <Box
                sx={{
                    width: '100%',
                    padding: '10px 24px',
                    display: 'flex',
                    justifyContent: 'center',
                    gap: '0 16px',
                    boxShadow: '1px 1px 15px 0px rgba(0, 0, 0, 0.1)',
                    marginTop: 'auto',
                }}
            >
                <Button
                    variant="text"
                    onClick={handleCancelMobilePanel}
                    color="secondary"
                    sx={btnStyle}
                >
                    Fermer
                </Button>
                <Button
                    variant="contained"
                    onClick={handleConfirmMobilePanel}
                    color="secondary"
                    sx={btnStyle}
                >
                    Voir les résultats
                </Button>
            </Box>
        );

        const heading = filters.label ? (
            <Typography
                variant="l2dcaption"
                component="h2"
                sx={{
                    color: palette.text.light,
                    padding: '10px 14px 0 14px',
                    lineHeight: '22px',
                }}
            >
                {filters.label}
            </Typography>
        ) : null;

        return (
            <>
                <FlexyMobilePopover
                    open={showPanel}
                    heading={heading}
                    controls={controls}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'flex-start',
                            overflowY: 'scroll',
                            padding: '10px',
                        }}
                    >
                        {items.map((preFilter) => {
                            const { label, count } = preFilter;

                            return (
                                <FlexyPreFilter
                                    label={label}
                                    key={label}
                                    current={
                                        preSelected !== undefined && preSelected.label === label
                                    }
                                    count={count}
                                    onClick={() => setPreSelected(preFilter)}
                                />
                            );
                        })}
                    </Box>
                </FlexyMobilePopover>
                <Box>
                    <Button
                        sx={{
                            display: 'flex',
                            gap: '0 8px',
                            alignItems: 'center',
                            fontSize: '16px',
                            fontWeight: '600',
                            lineHeight: '25.6px',
                            color: palette.text.primary,
                        }}
                        onClick={() => setShowPanel(true)}
                    >
                        {current !== undefined && current.label}
                        <FaOptionIcon
                            icon={faChevronDown}
                            sx={{ fontSize: '12px', lineHeight: '12px' }}
                        />
                    </Button>
                </Box>
            </>
        );
    }

    return (
        <Box
            sx={{
                display: 'flex',
                width: '100%',
                gap: '24px',
            }}
        >
            {items.map((preFilter) => {
                const { label, count } = preFilter;

                return (
                    <FlexyPreFilter
                        label={label}
                        key={label}
                        current={current !== undefined && current.label === label}
                        count={count}
                        onClick={() => onClick(preFilter)}
                    />
                );
            })}
        </Box>
    );
};

export default FlexyPreFilters;
