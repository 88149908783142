/* tslint:disable */
/* eslint-disable */
/**
 * Europrocurement Api Siren
 * API de récupération d\'information Siren
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface ActiviteJsonldRead
 */
export interface ActiviteJsonldRead {
    /**
     * 
     * @type {any}
     * @memberof ActiviteJsonldRead
     */
    '@context'?: any;
    /**
     * 
     * @type {any}
     * @memberof ActiviteJsonldRead
     */
    '@id'?: any;
    /**
     * 
     * @type {any}
     * @memberof ActiviteJsonldRead
     */
    '@type'?: any;
    /**
     * Get the value of categoryCode
     * @type {any}
     * @memberof ActiviteJsonldRead
     */
    'categoryCode'?: any;
    /**
     * Get the value of exerciceActivite
     * @type {any}
     * @memberof ActiviteJsonldRead
     */
    'exerciceActivite'?: any;
    /**
     * Get the value of formeExercice
     * @type {any}
     * @memberof ActiviteJsonldRead
     */
    'formeExercice'?: any;
    /**
     * Get the value of formeExerciceRef
     * @type {any}
     * @memberof ActiviteJsonldRead
     */
    'formeExerciceRef'?: any;
    /**
     * Get the value of formeExerciceRefLibelle
     * @type {any}
     * @memberof ActiviteJsonldRead
     */
    'formeExerciceRefLibelle'?: any;
    /**
     * Get the value of descriptionDetaillee
     * @type {any}
     * @memberof ActiviteJsonldRead
     */
    'descriptionDetaillee'?: any;
    /**
     * Get the value of categorisationActivite1
     * @type {any}
     * @memberof ActiviteJsonldRead
     */
    'categorisationActivite1'?: any;
    /**
     * Get the value of categorisationActivite2
     * @type {any}
     * @memberof ActiviteJsonldRead
     */
    'categorisationActivite2'?: any;
    /**
     * Get the value of categorisationActivite3
     * @type {any}
     * @memberof ActiviteJsonldRead
     */
    'categorisationActivite3'?: any;
    /**
     * Get the value of indicateurNonSedentaire
     * @type {any}
     * @memberof ActiviteJsonldRead
     */
    'indicateurNonSedentaire'?: any;
    /**
     * Get the value of codeApe
     * @type {any}
     * @memberof ActiviteJsonldRead
     */
    'codeApe'?: any;
    /**
     * Get the value of dateDebut
     * @type {any}
     * @memberof ActiviteJsonldRead
     */
    'dateDebut'?: any;
    /**
     * Get the value of dateFin
     * @type {any}
     * @memberof ActiviteJsonldRead
     */
    'dateFin'?: any;
    /**
     * Get the value of rolePrincipalPourEntreprise
     * @type {any}
     * @memberof ActiviteJsonldRead
     */
    'rolePrincipalPourEntreprise'?: any;
    /**
     * Get the value of indicateurActiviteeApe
     * @type {any}
     * @memberof ActiviteJsonldRead
     */
    'indicateurActiviteeApe'?: any;
    /**
     * Get the value of indicateurArtisteAuteur
     * @type {any}
     * @memberof ActiviteJsonldRead
     */
    'indicateurArtisteAuteur'?: any;
    /**
     * Get the value of indicateurMarinProfessionnel
     * @type {any}
     * @memberof ActiviteJsonldRead
     */
    'indicateurMarinProfessionnel'?: any;
    /**
     * Get the value of indicateurPrincipal
     * @type {any}
     * @memberof ActiviteJsonldRead
     */
    'indicateurPrincipal'?: any;
    /**
     * Get the value of activiteRattacheeEirl
     * @type {any}
     * @memberof ActiviteJsonldRead
     */
    'activiteRattacheeEirl'?: any;
    /**
     * Get the value of activiteId
     * @type {any}
     * @memberof ActiviteJsonldRead
     */
    'activiteId'?: any;
    /**
     * Get the value of codeAprm
     * @type {any}
     * @memberof ActiviteJsonldRead
     */
    'codeAprm'?: any;
    /**
     * Get the value of precisionActivite
     * @type {any}
     * @memberof ActiviteJsonldRead
     */
    'precisionActivite'?: any;
}
/**
 * 
 * @export
 * @interface ActiviteRead
 */
export interface ActiviteRead {
    /**
     * Get the value of categoryCode
     * @type {any}
     * @memberof ActiviteRead
     */
    'categoryCode'?: any;
    /**
     * Get the value of exerciceActivite
     * @type {any}
     * @memberof ActiviteRead
     */
    'exerciceActivite'?: any;
    /**
     * Get the value of formeExercice
     * @type {any}
     * @memberof ActiviteRead
     */
    'formeExercice'?: any;
    /**
     * Get the value of formeExerciceRef
     * @type {any}
     * @memberof ActiviteRead
     */
    'formeExerciceRef'?: any;
    /**
     * Get the value of formeExerciceRefLibelle
     * @type {any}
     * @memberof ActiviteRead
     */
    'formeExerciceRefLibelle'?: any;
    /**
     * Get the value of descriptionDetaillee
     * @type {any}
     * @memberof ActiviteRead
     */
    'descriptionDetaillee'?: any;
    /**
     * Get the value of categorisationActivite1
     * @type {any}
     * @memberof ActiviteRead
     */
    'categorisationActivite1'?: any;
    /**
     * Get the value of categorisationActivite2
     * @type {any}
     * @memberof ActiviteRead
     */
    'categorisationActivite2'?: any;
    /**
     * Get the value of categorisationActivite3
     * @type {any}
     * @memberof ActiviteRead
     */
    'categorisationActivite3'?: any;
    /**
     * Get the value of indicateurNonSedentaire
     * @type {any}
     * @memberof ActiviteRead
     */
    'indicateurNonSedentaire'?: any;
    /**
     * Get the value of codeApe
     * @type {any}
     * @memberof ActiviteRead
     */
    'codeApe'?: any;
    /**
     * Get the value of dateDebut
     * @type {any}
     * @memberof ActiviteRead
     */
    'dateDebut'?: any;
    /**
     * Get the value of dateFin
     * @type {any}
     * @memberof ActiviteRead
     */
    'dateFin'?: any;
    /**
     * Get the value of rolePrincipalPourEntreprise
     * @type {any}
     * @memberof ActiviteRead
     */
    'rolePrincipalPourEntreprise'?: any;
    /**
     * Get the value of indicateurActiviteeApe
     * @type {any}
     * @memberof ActiviteRead
     */
    'indicateurActiviteeApe'?: any;
    /**
     * Get the value of indicateurArtisteAuteur
     * @type {any}
     * @memberof ActiviteRead
     */
    'indicateurArtisteAuteur'?: any;
    /**
     * Get the value of indicateurMarinProfessionnel
     * @type {any}
     * @memberof ActiviteRead
     */
    'indicateurMarinProfessionnel'?: any;
    /**
     * Get the value of indicateurPrincipal
     * @type {any}
     * @memberof ActiviteRead
     */
    'indicateurPrincipal'?: any;
    /**
     * Get the value of activiteRattacheeEirl
     * @type {any}
     * @memberof ActiviteRead
     */
    'activiteRattacheeEirl'?: any;
    /**
     * Get the value of activiteId
     * @type {any}
     * @memberof ActiviteRead
     */
    'activiteId'?: any;
    /**
     * Get the value of codeAprm
     * @type {any}
     * @memberof ActiviteRead
     */
    'codeAprm'?: any;
    /**
     * Get the value of precisionActivite
     * @type {any}
     * @memberof ActiviteRead
     */
    'precisionActivite'?: any;
}
/**
 * 
 * @export
 * @interface AddressJsonldRead
 */
export interface AddressJsonldRead {
    /**
     * 
     * @type {any}
     * @memberof AddressJsonldRead
     */
    '@context'?: any;
    /**
     * 
     * @type {any}
     * @memberof AddressJsonldRead
     */
    '@id'?: any;
    /**
     * 
     * @type {any}
     * @memberof AddressJsonldRead
     */
    '@type'?: any;
    /**
     * 
     * @type {any}
     * @memberof AddressJsonldRead
     */
    'adresse'?: any;
    /**
     * 
     * @type {any}
     * @memberof AddressJsonldRead
     */
    'adresse2'?: any;
    /**
     * Get the value of typeVoie
     * @type {any}
     * @memberof AddressJsonldRead
     */
    'typeVoie'?: any;
    /**
     * Get the value of voie
     * @type {any}
     * @memberof AddressJsonldRead
     */
    'voie'?: any;
    /**
     * Get the value of numVoie
     * @type {any}
     * @memberof AddressJsonldRead
     */
    'numVoie'?: any;
    /**
     * 
     * @type {any}
     * @memberof AddressJsonldRead
     */
    'codePostal'?: any;
    /**
     * 
     * @type {any}
     * @memberof AddressJsonldRead
     */
    'codeInsee'?: any;
    /**
     * 
     * @type {any}
     * @memberof AddressJsonldRead
     */
    'commune'?: any;
    /**
     * 
     * @type {any}
     * @memberof AddressJsonldRead
     */
    'etablissementSiege'?: any;
    /**
     * Get the value of codePays
     * @type {any}
     * @memberof AddressJsonldRead
     */
    'codePays'?: any;
}
/**
 * 
 * @export
 * @interface AddressRead
 */
export interface AddressRead {
    /**
     * 
     * @type {any}
     * @memberof AddressRead
     */
    'adresse'?: any;
    /**
     * 
     * @type {any}
     * @memberof AddressRead
     */
    'adresse2'?: any;
    /**
     * Get the value of typeVoie
     * @type {any}
     * @memberof AddressRead
     */
    'typeVoie'?: any;
    /**
     * Get the value of voie
     * @type {any}
     * @memberof AddressRead
     */
    'voie'?: any;
    /**
     * Get the value of numVoie
     * @type {any}
     * @memberof AddressRead
     */
    'numVoie'?: any;
    /**
     * 
     * @type {any}
     * @memberof AddressRead
     */
    'codePostal'?: any;
    /**
     * 
     * @type {any}
     * @memberof AddressRead
     */
    'codeInsee'?: any;
    /**
     * 
     * @type {any}
     * @memberof AddressRead
     */
    'commune'?: any;
    /**
     * 
     * @type {any}
     * @memberof AddressRead
     */
    'etablissementSiege'?: any;
    /**
     * Get the value of codePays
     * @type {any}
     * @memberof AddressRead
     */
    'codePays'?: any;
}
/**
 * 
 * @export
 * @interface ApiRcsSirenGet400Response
 */
export interface ApiRcsSirenGet400Response {
    /**
     * 
     * @type {any}
     * @memberof ApiRcsSirenGet400Response
     */
    'error'?: any;
}
/**
 * 
 * @export
 * @interface ApiRcsSirenGet401Response
 */
export interface ApiRcsSirenGet401Response {
    /**
     * 
     * @type {any}
     * @memberof ApiRcsSirenGet401Response
     */
    'message'?: any;
}
/**
 * 
 * @export
 * @interface ApiRcsSirenGet403Response
 */
export interface ApiRcsSirenGet403Response {
    /**
     * 
     * @type {any}
     * @memberof ApiRcsSirenGet403Response
     */
    'error'?: any;
    /**
     * 
     * @type {ApiRcsSirenGet403ResponseException}
     * @memberof ApiRcsSirenGet403Response
     */
    'exception'?: ApiRcsSirenGet403ResponseException;
}
/**
 * 
 * @export
 * @interface ApiRcsSirenGet403ResponseException
 */
export interface ApiRcsSirenGet403ResponseException {
    /**
     * 
     * @type {any}
     * @memberof ApiRcsSirenGet403ResponseException
     */
    'message'?: any;
    /**
     * 
     * @type {any}
     * @memberof ApiRcsSirenGet403ResponseException
     */
    'code'?: any;
}
/**
 * 
 * @export
 * @interface ApiRcsSirenGet404Response
 */
export interface ApiRcsSirenGet404Response {
    /**
     * 
     * @type {any}
     * @memberof ApiRcsSirenGet404Response
     */
    'error'?: any;
    /**
     * 
     * @type {ApiRcsSirenGet404ResponseException}
     * @memberof ApiRcsSirenGet404Response
     */
    'exception'?: ApiRcsSirenGet404ResponseException;
}
/**
 * 
 * @export
 * @interface ApiRcsSirenGet404ResponseException
 */
export interface ApiRcsSirenGet404ResponseException {
    /**
     * 
     * @type {any}
     * @memberof ApiRcsSirenGet404ResponseException
     */
    'message'?: any;
    /**
     * 
     * @type {any}
     * @memberof ApiRcsSirenGet404ResponseException
     */
    'code'?: any;
}
/**
 * 
 * @export
 * @interface ApiRcsSirenGet503Response
 */
export interface ApiRcsSirenGet503Response {
    /**
     * 
     * @type {any}
     * @memberof ApiRcsSirenGet503Response
     */
    'error'?: any;
    /**
     * 
     * @type {ApiRcsSirenGet503ResponseException}
     * @memberof ApiRcsSirenGet503Response
     */
    'exception'?: ApiRcsSirenGet503ResponseException;
}
/**
 * 
 * @export
 * @interface ApiRcsSirenGet503ResponseException
 */
export interface ApiRcsSirenGet503ResponseException {
    /**
     * 
     * @type {any}
     * @memberof ApiRcsSirenGet503ResponseException
     */
    'message'?: any;
    /**
     * 
     * @type {any}
     * @memberof ApiRcsSirenGet503ResponseException
     */
    'code'?: any;
}
/**
 * 
 * @export
 * @interface CompositionJsonldRead
 */
export interface CompositionJsonldRead {
    /**
     * 
     * @type {any}
     * @memberof CompositionJsonldRead
     */
    '@context'?: any;
    /**
     * 
     * @type {any}
     * @memberof CompositionJsonldRead
     */
    '@id'?: any;
    /**
     * 
     * @type {any}
     * @memberof CompositionJsonldRead
     */
    '@type'?: any;
    /**
     * Get the value of beneficiaireId
     * @type {any}
     * @memberof CompositionJsonldRead
     */
    'beneficiaireId'?: any;
    /**
     * Get the value of representantId
     * @type {any}
     * @memberof CompositionJsonldRead
     */
    'representantId'?: any;
    /**
     * Get the value of actif
     * @type {any}
     * @memberof CompositionJsonldRead
     */
    'actif'?: any;
    /**
     * Get the value of nom
     * @type {any}
     * @memberof CompositionJsonldRead
     */
    'nom'?: any;
    /**
     * Get the value of nomUsage
     * @type {any}
     * @memberof CompositionJsonldRead
     */
    'nomUsage'?: any;
    /**
     * Get the value of prenoms
     * @type {any}
     * @memberof CompositionJsonldRead
     */
    'prenoms'?: any;
    /**
     * Get the value of nationalite
     * @type {any}
     * @memberof CompositionJsonldRead
     */
    'nationalite'?: any;
    /**
     * Get the value of detentionPartDirect
     * @type {any}
     * @memberof CompositionJsonldRead
     */
    'detentionPartDirect'?: any;
    /**
     * Get the value of detentionPartIndirect
     * @type {any}
     * @memberof CompositionJsonldRead
     */
    'detentionPartIndirect'?: any;
    /**
     * Get the value of partTotale
     * @type {any}
     * @memberof CompositionJsonldRead
     */
    'partTotale'?: any;
    /**
     * Get the value of modalitesDeControle
     * @type {any}
     * @memberof CompositionJsonldRead
     */
    'modalitesDeControle'?: any;
    /**
     * Get the value of roleEntreprise
     * @type {any}
     * @memberof CompositionJsonldRead
     */
    'roleEntreprise'?: any;
    /**
     * Get the value of libelleRoleEntreprise
     * @type {any}
     * @memberof CompositionJsonldRead
     */
    'libelleRoleEntreprise'?: any;
    /**
     * Get the value of typePersonne
     * @type {any}
     * @memberof CompositionJsonldRead
     */
    'typePersonne'?: any;
    /**
     * Get the value of typePersonneRef
     * @type {any}
     * @memberof CompositionJsonldRead
     */
    'typePersonneRef'?: any;
    /**
     * Get the value of genreRef
     * @type {any}
     * @memberof CompositionJsonldRead
     */
    'genreRef'?: any;
    /**
     * Get the value of genreRne
     * @type {any}
     * @memberof CompositionJsonldRead
     */
    'genreRne'?: any;
    /**
     * Get the value of codeNat
     * @type {any}
     * @memberof CompositionJsonldRead
     */
    'codeNat'?: any;
    /**
     * Get the value of siren
     * @type {any}
     * @memberof CompositionJsonldRead
     */
    'siren'?: any;
    /**
     * Get the value of formeJuridique
     * @type {any}
     * @memberof CompositionJsonldRead
     */
    'formeJuridique'?: any;
    /**
     * Get the value of libelleFormeJuridique
     * @type {any}
     * @memberof CompositionJsonldRead
     */
    'libelleFormeJuridique'?: any;
    /**
     * Get the value of stdFormeJuridique
     * @type {any}
     * @memberof CompositionJsonldRead
     */
    'stdFormeJuridique'?: any;
    /**
     * Get the value of stdLibelleFormeJuridique
     * @type {any}
     * @memberof CompositionJsonldRead
     */
    'stdLibelleFormeJuridique'?: any;
    /**
     * Get the value of denomination
     * @type {any}
     * @memberof CompositionJsonldRead
     */
    'denomination'?: any;
    /**
     * Get the value of genreLibelle
     * @type {any}
     * @memberof CompositionJsonldRead
     */
    'genreLibelle'?: any;
    /**
     * Get the value of lieuRegistre
     * @type {any}
     * @memberof CompositionJsonldRead
     */
    'lieuRegistre'?: any;
    /**
     * Get the value of address
     * @type {AddressJsonldRead}
     * @memberof CompositionJsonldRead
     */
    'address'?: AddressJsonldRead;
    /**
     * Get the value of dateDeNaissance
     * @type {any}
     * @memberof CompositionJsonldRead
     */
    'dateDeNaissance'?: any;
    /**
     * Get the value of modalite
     * @type {any}
     * @memberof CompositionJsonldRead
     */
    'modalite'?: any;
    /**
     * Get the value of situationMatrimonialeConjoint
     * @type {any}
     * @memberof CompositionJsonldRead
     */
    'situationMatrimonialeConjoint'?: any;
    /**
     * Get the value of civilite
     * @type {any}
     * @memberof CompositionJsonldRead
     */
    'civilite'?: any;
    /**
     * Get the value of civiliteLibelle
     * @type {any}
     * @memberof CompositionJsonldRead
     */
    'civiliteLibelle'?: any;
}
/**
 * 
 * @export
 * @interface CompositionRead
 */
export interface CompositionRead {
    /**
     * Get the value of beneficiaireId
     * @type {any}
     * @memberof CompositionRead
     */
    'beneficiaireId'?: any;
    /**
     * Get the value of representantId
     * @type {any}
     * @memberof CompositionRead
     */
    'representantId'?: any;
    /**
     * Get the value of actif
     * @type {any}
     * @memberof CompositionRead
     */
    'actif'?: any;
    /**
     * Get the value of nom
     * @type {any}
     * @memberof CompositionRead
     */
    'nom'?: any;
    /**
     * Get the value of nomUsage
     * @type {any}
     * @memberof CompositionRead
     */
    'nomUsage'?: any;
    /**
     * Get the value of prenoms
     * @type {any}
     * @memberof CompositionRead
     */
    'prenoms'?: any;
    /**
     * Get the value of nationalite
     * @type {any}
     * @memberof CompositionRead
     */
    'nationalite'?: any;
    /**
     * Get the value of detentionPartDirect
     * @type {any}
     * @memberof CompositionRead
     */
    'detentionPartDirect'?: any;
    /**
     * Get the value of detentionPartIndirect
     * @type {any}
     * @memberof CompositionRead
     */
    'detentionPartIndirect'?: any;
    /**
     * Get the value of partTotale
     * @type {any}
     * @memberof CompositionRead
     */
    'partTotale'?: any;
    /**
     * Get the value of modalitesDeControle
     * @type {any}
     * @memberof CompositionRead
     */
    'modalitesDeControle'?: any;
    /**
     * Get the value of roleEntreprise
     * @type {any}
     * @memberof CompositionRead
     */
    'roleEntreprise'?: any;
    /**
     * Get the value of libelleRoleEntreprise
     * @type {any}
     * @memberof CompositionRead
     */
    'libelleRoleEntreprise'?: any;
    /**
     * Get the value of typePersonne
     * @type {any}
     * @memberof CompositionRead
     */
    'typePersonne'?: any;
    /**
     * Get the value of typePersonneRef
     * @type {any}
     * @memberof CompositionRead
     */
    'typePersonneRef'?: any;
    /**
     * Get the value of genreRef
     * @type {any}
     * @memberof CompositionRead
     */
    'genreRef'?: any;
    /**
     * Get the value of genreRne
     * @type {any}
     * @memberof CompositionRead
     */
    'genreRne'?: any;
    /**
     * Get the value of codeNat
     * @type {any}
     * @memberof CompositionRead
     */
    'codeNat'?: any;
    /**
     * Get the value of siren
     * @type {any}
     * @memberof CompositionRead
     */
    'siren'?: any;
    /**
     * Get the value of formeJuridique
     * @type {any}
     * @memberof CompositionRead
     */
    'formeJuridique'?: any;
    /**
     * Get the value of libelleFormeJuridique
     * @type {any}
     * @memberof CompositionRead
     */
    'libelleFormeJuridique'?: any;
    /**
     * Get the value of stdFormeJuridique
     * @type {any}
     * @memberof CompositionRead
     */
    'stdFormeJuridique'?: any;
    /**
     * Get the value of stdLibelleFormeJuridique
     * @type {any}
     * @memberof CompositionRead
     */
    'stdLibelleFormeJuridique'?: any;
    /**
     * Get the value of denomination
     * @type {any}
     * @memberof CompositionRead
     */
    'denomination'?: any;
    /**
     * Get the value of genreLibelle
     * @type {any}
     * @memberof CompositionRead
     */
    'genreLibelle'?: any;
    /**
     * Get the value of lieuRegistre
     * @type {any}
     * @memberof CompositionRead
     */
    'lieuRegistre'?: any;
    /**
     * Get the value of address
     * @type {AddressRead}
     * @memberof CompositionRead
     */
    'address'?: AddressRead;
    /**
     * Get the value of dateDeNaissance
     * @type {any}
     * @memberof CompositionRead
     */
    'dateDeNaissance'?: any;
    /**
     * Get the value of modalite
     * @type {any}
     * @memberof CompositionRead
     */
    'modalite'?: any;
    /**
     * Get the value of situationMatrimonialeConjoint
     * @type {any}
     * @memberof CompositionRead
     */
    'situationMatrimonialeConjoint'?: any;
    /**
     * Get the value of civilite
     * @type {any}
     * @memberof CompositionRead
     */
    'civilite'?: any;
    /**
     * Get the value of civiliteLibelle
     * @type {any}
     * @memberof CompositionRead
     */
    'civiliteLibelle'?: any;
}
/**
 * 
 * @export
 * @interface CompositionWrapperJsonldRead
 */
export interface CompositionWrapperJsonldRead {
    /**
     * 
     * @type {any}
     * @memberof CompositionWrapperJsonldRead
     */
    '@context'?: any;
    /**
     * 
     * @type {any}
     * @memberof CompositionWrapperJsonldRead
     */
    '@id'?: any;
    /**
     * 
     * @type {any}
     * @memberof CompositionWrapperJsonldRead
     */
    '@type'?: any;
    /**
     * Get the value of beneficiaire
     * @type {any}
     * @memberof CompositionWrapperJsonldRead
     */
    'beneficiaire'?: any;
    /**
     * Get the value of structure
     * @type {any}
     * @memberof CompositionWrapperJsonldRead
     */
    'structure'?: any;
}
/**
 * 
 * @export
 * @interface CompositionWrapperRead
 */
export interface CompositionWrapperRead {
    /**
     * Get the value of beneficiaire
     * @type {any}
     * @memberof CompositionWrapperRead
     */
    'beneficiaire'?: any;
    /**
     * Get the value of structure
     * @type {any}
     * @memberof CompositionWrapperRead
     */
    'structure'?: any;
}
/**
 * 
 * @export
 * @interface EtablissementJsonldRead
 */
export interface EtablissementJsonldRead {
    /**
     * 
     * @type {any}
     * @memberof EtablissementJsonldRead
     */
    '@context'?: any;
    /**
     * 
     * @type {any}
     * @memberof EtablissementJsonldRead
     */
    '@id'?: any;
    /**
     * 
     * @type {any}
     * @memberof EtablissementJsonldRead
     */
    '@type'?: any;
    /**
     * get Siret number
     * @type {any}
     * @memberof EtablissementJsonldRead
     */
    'siret'?: any;
    /**
     * get Activite
     * @type {any}
     * @memberof EtablissementJsonldRead
     */
    'activite'?: any;
    /**
     * get if company is active
     * @type {any}
     * @memberof EtablissementJsonldRead
     */
    'actif'?: any;
    /**
     * get date creation company
     * @type {any}
     * @memberof EtablissementJsonldRead
     */
    'dateCreation'?: any;
    /**
     * Get the value of rolePourEntreprise
     * @type {any}
     * @memberof EtablissementJsonldRead
     */
    'rolePourEntreprise'?: any;
    /**
     * Get the value of statutPourFormalite
     * @type {any}
     * @memberof EtablissementJsonldRead
     */
    'statutPourFormalite'?: any;
    /**
     * Get the value of dateEffetFermeture
     * @type {any}
     * @memberof EtablissementJsonldRead
     */
    'dateEffetFermeture'?: any;
    /**
     * Get the value of codeApe
     * @type {any}
     * @memberof EtablissementJsonldRead
     */
    'codeApe'?: any;
    /**
     * Get the value of etablissementRdd
     * @type {any}
     * @memberof EtablissementJsonldRead
     */
    'etablissementRdd'?: any;
    /**
     * Get the value of indicateurEtablissementPrincipal
     * @type {any}
     * @memberof EtablissementJsonldRead
     */
    'indicateurEtablissementPrincipal'?: any;
    /**
     * Get the value of address
     * @type {AddressJsonldRead}
     * @memberof EtablissementJsonldRead
     */
    'address'?: AddressJsonldRead;
    /**
     * Get the value of aActivite
     * @type {any}
     * @memberof EtablissementJsonldRead
     */
    'aActivite'?: any;
}
/**
 * 
 * @export
 * @interface EtablissementRead
 */
export interface EtablissementRead {
    /**
     * get Siret number
     * @type {any}
     * @memberof EtablissementRead
     */
    'siret'?: any;
    /**
     * get Activite
     * @type {any}
     * @memberof EtablissementRead
     */
    'activite'?: any;
    /**
     * get if company is active
     * @type {any}
     * @memberof EtablissementRead
     */
    'actif'?: any;
    /**
     * get date creation company
     * @type {any}
     * @memberof EtablissementRead
     */
    'dateCreation'?: any;
    /**
     * Get the value of rolePourEntreprise
     * @type {any}
     * @memberof EtablissementRead
     */
    'rolePourEntreprise'?: any;
    /**
     * Get the value of statutPourFormalite
     * @type {any}
     * @memberof EtablissementRead
     */
    'statutPourFormalite'?: any;
    /**
     * Get the value of dateEffetFermeture
     * @type {any}
     * @memberof EtablissementRead
     */
    'dateEffetFermeture'?: any;
    /**
     * Get the value of codeApe
     * @type {any}
     * @memberof EtablissementRead
     */
    'codeApe'?: any;
    /**
     * Get the value of etablissementRdd
     * @type {any}
     * @memberof EtablissementRead
     */
    'etablissementRdd'?: any;
    /**
     * Get the value of indicateurEtablissementPrincipal
     * @type {any}
     * @memberof EtablissementRead
     */
    'indicateurEtablissementPrincipal'?: any;
    /**
     * Get the value of address
     * @type {AddressRead}
     * @memberof EtablissementRead
     */
    'address'?: AddressRead;
    /**
     * Get the value of aActivite
     * @type {any}
     * @memberof EtablissementRead
     */
    'aActivite'?: any;
}
/**
 * 
 * @export
 * @interface EtablissementsWrapperJsonldRead
 */
export interface EtablissementsWrapperJsonldRead {
    /**
     * 
     * @type {any}
     * @memberof EtablissementsWrapperJsonldRead
     */
    '@context'?: any;
    /**
     * 
     * @type {any}
     * @memberof EtablissementsWrapperJsonldRead
     */
    '@id'?: any;
    /**
     * 
     * @type {any}
     * @memberof EtablissementsWrapperJsonldRead
     */
    '@type'?: any;
    /**
     * Get the value of principal
     * @type {any}
     * @memberof EtablissementsWrapperJsonldRead
     */
    'principal'?: any;
    /**
     * Get the value of autres
     * @type {any}
     * @memberof EtablissementsWrapperJsonldRead
     */
    'autres'?: any;
}
/**
 * 
 * @export
 * @interface EtablissementsWrapperRead
 */
export interface EtablissementsWrapperRead {
    /**
     * Get the value of principal
     * @type {any}
     * @memberof EtablissementsWrapperRead
     */
    'principal'?: any;
    /**
     * Get the value of autres
     * @type {any}
     * @memberof EtablissementsWrapperRead
     */
    'autres'?: any;
}
/**
 * 
 * @export
 * @interface SirenJsonldRead
 */
export interface SirenJsonldRead {
    /**
     * 
     * @type {any}
     * @memberof SirenJsonldRead
     */
    '@context'?: any;
    /**
     * 
     * @type {any}
     * @memberof SirenJsonldRead
     */
    '@id'?: any;
    /**
     * 
     * @type {any}
     * @memberof SirenJsonldRead
     */
    '@type'?: any;
    /**
     * getSiren
     * @type {any}
     * @memberof SirenJsonldRead
     */
    'siren': any;
    /**
     * Get the value of id
     * @type {any}
     * @memberof SirenJsonldRead
     */
    'idRne'?: any;
    /**
     * getIsCapitalFix
     * @type {any}
     * @memberof SirenJsonldRead
     */
    'isCapitalvariable'?: any;
    /**
     * getCapital
     * @type {any}
     * @memberof SirenJsonldRead
     */
    'capital'?: any;
    /**
     * Get the value of codeApe
     * @type {any}
     * @memberof SirenJsonldRead
     */
    'codeApe'?: any;
    /**
     * Get the value of dateClotureExerciceSocial
     * @type {any}
     * @memberof SirenJsonldRead
     */
    'dateClotureExerciceSocial'?: any;
    /**
     * Get the value of nicSiege
     * @type {any}
     * @memberof SirenJsonldRead
     */
    'nicSiege'?: any;
    /**
     * Get the value of typePersonne
     * @type {any}
     * @memberof SirenJsonldRead
     */
    'typePersonne'?: any;
    /**
     * Get the value of typePersonneRef
     * @type {any}
     * @memberof SirenJsonldRead
     */
    'typePersonneRef'?: any;
    /**
     * Get the value of dateCreation
     * @type {any}
     * @memberof SirenJsonldRead
     */
    'dateCreation'?: any;
    /**
     * Get the value of societeEtrangere
     * @type {any}
     * @memberof SirenJsonldRead
     */
    'societeEtrangere'?: any;
    /**
     * Get the value of dateDebutActiv
     * @type {any}
     * @memberof SirenJsonldRead
     */
    'dateDebutActiv'?: any;
    /**
     * Get the value of capitalDisponible
     * @type {any}
     * @memberof SirenJsonldRead
     */
    'capitalDisponible'?: any;
    /**
     * Get the value of formeJuridique
     * @type {any}
     * @memberof SirenJsonldRead
     */
    'formeJuridique'?: any;
    /**
     * Get the value of libelleFormeJuridique
     * @type {any}
     * @memberof SirenJsonldRead
     */
    'libelleFormeJuridique'?: any;
    /**
     * Get the value of stdFormeJuridique
     * @type {any}
     * @memberof SirenJsonldRead
     */
    'stdFormeJuridique'?: any;
    /**
     * Get the value of stdLibelleFormeJuridique
     * @type {any}
     * @memberof SirenJsonldRead
     */
    'stdLibelleFormeJuridique'?: any;
    /**
     * Get the value of denomination
     * @type {any}
     * @memberof SirenJsonldRead
     */
    'denomination'?: any;
    /**
     * Get the value of nom
     * @type {any}
     * @memberof SirenJsonldRead
     */
    'nom'?: any;
    /**
     * Get the value of prenom
     * @type {any}
     * @memberof SirenJsonldRead
     */
    'prenom'?: any;
    /**
     * Get the value of civilite
     * @type {any}
     * @memberof SirenJsonldRead
     */
    'civilite'?: any;
    /**
     * Get the value of civiliteLibelle
     * @type {any}
     * @memberof SirenJsonldRead
     */
    'civiliteLibelle'?: any;
    /**
     * Get the value of genreRef
     * @type {any}
     * @memberof SirenJsonldRead
     */
    'genreRef'?: any;
    /**
     * Get the value of genreRne
     * @type {any}
     * @memberof SirenJsonldRead
     */
    'genreRne'?: any;
    /**
     * Get the value of genreLibelle
     * @type {any}
     * @memberof SirenJsonldRead
     */
    'genreLibelle'?: any;
    /**
     * Get the value of situationMatrimoniale
     * @type {any}
     * @memberof SirenJsonldRead
     */
    'situationMatrimoniale'?: any;
    /**
     * Get the value of natureGeranceRef
     * @type {any}
     * @memberof SirenJsonldRead
     */
    'natureGeranceRef'?: any;
    /**
     * Get the value of natureGeranceLibelle
     * @type {any}
     * @memberof SirenJsonldRead
     */
    'natureGeranceLibelle'?: any;
    /**
     * getEtablissements
     * @type {EtablissementsWrapperJsonldRead}
     * @memberof SirenJsonldRead
     */
    'etablissements'?: EtablissementsWrapperJsonldRead;
    /**
     * getComposition
     * @type {CompositionWrapperJsonldRead}
     * @memberof SirenJsonldRead
     */
    'composition'?: CompositionWrapperJsonldRead;
}
/**
 * 
 * @export
 * @interface SirenRead
 */
export interface SirenRead {
    /**
     * getSiren
     * @type {any}
     * @memberof SirenRead
     */
    'siren': any;
    /**
     * Get the value of id
     * @type {any}
     * @memberof SirenRead
     */
    'idRne'?: any;
    /**
     * getIsCapitalFix
     * @type {any}
     * @memberof SirenRead
     */
    'isCapitalvariable'?: any;
    /**
     * getCapital
     * @type {any}
     * @memberof SirenRead
     */
    'capital'?: any;
    /**
     * Get the value of codeApe
     * @type {any}
     * @memberof SirenRead
     */
    'codeApe'?: any;
    /**
     * Get the value of dateClotureExerciceSocial
     * @type {any}
     * @memberof SirenRead
     */
    'dateClotureExerciceSocial'?: any;
    /**
     * Get the value of nicSiege
     * @type {any}
     * @memberof SirenRead
     */
    'nicSiege'?: any;
    /**
     * Get the value of typePersonne
     * @type {any}
     * @memberof SirenRead
     */
    'typePersonne'?: any;
    /**
     * Get the value of typePersonneRef
     * @type {any}
     * @memberof SirenRead
     */
    'typePersonneRef'?: any;
    /**
     * Get the value of dateCreation
     * @type {any}
     * @memberof SirenRead
     */
    'dateCreation'?: any;
    /**
     * Get the value of societeEtrangere
     * @type {any}
     * @memberof SirenRead
     */
    'societeEtrangere'?: any;
    /**
     * Get the value of dateDebutActiv
     * @type {any}
     * @memberof SirenRead
     */
    'dateDebutActiv'?: any;
    /**
     * Get the value of capitalDisponible
     * @type {any}
     * @memberof SirenRead
     */
    'capitalDisponible'?: any;
    /**
     * Get the value of formeJuridique
     * @type {any}
     * @memberof SirenRead
     */
    'formeJuridique'?: any;
    /**
     * Get the value of libelleFormeJuridique
     * @type {any}
     * @memberof SirenRead
     */
    'libelleFormeJuridique'?: any;
    /**
     * Get the value of stdFormeJuridique
     * @type {any}
     * @memberof SirenRead
     */
    'stdFormeJuridique'?: any;
    /**
     * Get the value of stdLibelleFormeJuridique
     * @type {any}
     * @memberof SirenRead
     */
    'stdLibelleFormeJuridique'?: any;
    /**
     * Get the value of denomination
     * @type {any}
     * @memberof SirenRead
     */
    'denomination'?: any;
    /**
     * Get the value of nom
     * @type {any}
     * @memberof SirenRead
     */
    'nom'?: any;
    /**
     * Get the value of prenom
     * @type {any}
     * @memberof SirenRead
     */
    'prenom'?: any;
    /**
     * Get the value of civilite
     * @type {any}
     * @memberof SirenRead
     */
    'civilite'?: any;
    /**
     * Get the value of civiliteLibelle
     * @type {any}
     * @memberof SirenRead
     */
    'civiliteLibelle'?: any;
    /**
     * Get the value of genreRef
     * @type {any}
     * @memberof SirenRead
     */
    'genreRef'?: any;
    /**
     * Get the value of genreRne
     * @type {any}
     * @memberof SirenRead
     */
    'genreRne'?: any;
    /**
     * Get the value of genreLibelle
     * @type {any}
     * @memberof SirenRead
     */
    'genreLibelle'?: any;
    /**
     * Get the value of situationMatrimoniale
     * @type {any}
     * @memberof SirenRead
     */
    'situationMatrimoniale'?: any;
    /**
     * Get the value of natureGeranceRef
     * @type {any}
     * @memberof SirenRead
     */
    'natureGeranceRef'?: any;
    /**
     * Get the value of natureGeranceLibelle
     * @type {any}
     * @memberof SirenRead
     */
    'natureGeranceLibelle'?: any;
    /**
     * getEtablissements
     * @type {EtablissementsWrapperRead}
     * @memberof SirenRead
     */
    'etablissements'?: EtablissementsWrapperRead;
    /**
     * getComposition
     * @type {CompositionWrapperRead}
     * @memberof SirenRead
     */
    'composition'?: CompositionWrapperRead;
}

/**
 * SirenApi - axios parameter creator
 * @export
 */
export const SirenApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Retrieves a Siren resource.
         * @summary Récupérer information par siren
         * @param {any} siren Siren identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiRcsSirenGet: async (siren: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'siren' is not null or undefined
            assertParamExists('apiRcsSirenGet', 'siren', siren)
            const localVarPath = `/siren/rcs/{siren}`
                .replace(`{${"siren"}}`, encodeURIComponent(String(siren)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SirenApi - functional programming interface
 * @export
 */
export const SirenApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SirenApiAxiosParamCreator(configuration)
    return {
        /**
         * Retrieves a Siren resource.
         * @summary Récupérer information par siren
         * @param {any} siren Siren identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiRcsSirenGet(siren: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SirenJsonldRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiRcsSirenGet(siren, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SirenApi - factory interface
 * @export
 */
export const SirenApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SirenApiFp(configuration)
    return {
        /**
         * Retrieves a Siren resource.
         * @summary Récupérer information par siren
         * @param {SirenApiApiRcsSirenGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiRcsSirenGet(requestParameters: SirenApiApiRcsSirenGetRequest, options?: AxiosRequestConfig): AxiosPromise<SirenJsonldRead> {
            return localVarFp.apiRcsSirenGet(requestParameters.siren, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for apiRcsSirenGet operation in SirenApi.
 * @export
 * @interface SirenApiApiRcsSirenGetRequest
 */
export interface SirenApiApiRcsSirenGetRequest {
    /**
     * Siren identifier
     * @type {any}
     * @memberof SirenApiApiRcsSirenGet
     */
    readonly siren: any
}

/**
 * SirenApi - object-oriented interface
 * @export
 * @class SirenApi
 * @extends {BaseAPI}
 */
export class SirenApi extends BaseAPI {
    /**
     * Retrieves a Siren resource.
     * @summary Récupérer information par siren
     * @param {SirenApiApiRcsSirenGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SirenApi
     */
    public apiRcsSirenGet(requestParameters: SirenApiApiRcsSirenGetRequest, options?: AxiosRequestConfig) {
        return SirenApiFp(this.configuration).apiRcsSirenGet(requestParameters.siren, options).then((request) => request(this.axios, this.basePath));
    }
}


