import {
    CatalogApiObject,
    FormulaireFormaliteApiObject,
    Prescripteur,
    SousClientApi,
} from '@europrocurement/l2d-domain';
import {
    CollectionDeDossierJsonldCollectiondossierRead,
    DossierJsonldDossierRead,
    FproPrestationJsonldCollectiondossierCreated,
    FproPrestationJsonldDossierRead,
} from '@europrocurement/l2d-domain/openApi/ApiFormalite';
import { Breakpoint } from '@mui/material';
import { createContext } from 'react';

export type ParcoursFormaliteContextType = {
    // data
    prescripteur: Prescripteur;
    prescripteurId: number;
    currentCollectionId: number;
    currentDossierId: number;
    currentPrestaId: number;
    currentCatalog?: CatalogApiObject;
    currentCatalogs?: CatalogApiObject[];
    currentCatalogId?: number;
    collections: {
        [idSousClient: number]: CollectionDeDossierJsonldCollectiondossierRead;
    };
    dossiers: {
        [idSousClient: number]: DossierJsonldDossierRead;
    };
    prestas: Array<FproPrestationJsonldDossierRead | FproPrestationJsonldCollectiondossierCreated>;
    idFormalites: Array<number>;
    idSousClients: Array<number>;
    currentSousClient: SousClientApi | undefined;
    form: FormulaireFormaliteApiObject | null;
    fetchForm: (
        innerPresta?:
            | FproPrestationJsonldDossierRead
            | FproPrestationJsonldCollectiondossierCreated
            | null,
    ) => void;
    setForm: (form: FormulaireFormaliteApiObject) => void;
    // manipulation
    getPrescripteur: () => Prescripteur;
    getCurrentCollection: () => CollectionDeDossierJsonldCollectiondossierRead | null;
    getCurrentDossier: () => DossierJsonldDossierRead | null;
    getCurrentPresta: () =>
        | FproPrestationJsonldDossierRead
        | FproPrestationJsonldCollectiondossierCreated
        | null;
    getCollections: () => {
        [idSousClient: number]: CollectionDeDossierJsonldCollectiondossierRead;
    };
    setCollections: (collections: {
        [idSousClient: number]: CollectionDeDossierJsonldCollectiondossierRead;
    }) => void;
    refreshDossier: (id: number) => void;
    // Checks
    isFormaliteSimple: () => boolean;
    isFormaliteCombinee: () => boolean;
    isFormaliteMultiple: () => boolean;
    isFormaliteDupliquee: () => boolean;
    switchToNextPresta: () =>
        | false
        | FproPrestationJsonldDossierRead
        | FproPrestationJsonldCollectiondossierCreated;
    setIdFormalites: (ids: Array<number>) => void;
    setIdSousClients: (ids: Array<number>) => void;
    setCurrentCatalog: (catalog: CatalogApiObject) => void;
    setCurrentCatalogs: (catalogs: CatalogApiObject[]) => void;
    setCurrentCatalogId: (catalogId: number) => void;
    save: () => void;
    create: (
        newIdSousClients: Array<number>,
        newIdFormalite: Array<number>,
        sousClient: SousClientApi,
    ) => void;
    containerWidth: Breakpoint;
};

const fakePrescripteur = {
    idPrescripteur: -1,
    raisonSociale: 'Temp',
    adresses: [],
    contacts: [],
    personnePhysique: false,
    origine: {},
    relationsSocietes: [],
};

export const ParcoursFormaliteContext = createContext<ParcoursFormaliteContextType>({
    prescripteur: fakePrescripteur,
    collections: {},
    dossiers: {},
    currentCollectionId: -1,
    currentDossierId: -1,
    prescripteurId: -1,
    currentPrestaId: -1,
    idFormalites: [],
    prestas: [],
    idSousClients: [],
    currentCatalogs: [],
    form: null,
    currentSousClient: undefined,
    fetchForm: () => null,
    setForm: () => fakePrescripteur,
    getPrescripteur: () => fakePrescripteur,
    getCurrentCollection: () => null,
    getCurrentDossier: () => null,
    getCollections: () => [],
    isFormaliteSimple: () => false,
    isFormaliteCombinee: () => false,
    isFormaliteMultiple: () => false,
    isFormaliteDupliquee: () => false,
    getCurrentPresta: () => null,
    refreshDossier: () => null,
    setCollections: () => null,
    setIdFormalites: () => null,
    setIdSousClients: () => null,
    setCurrentCatalog: () => null,
    setCurrentCatalogs: () => null,
    switchToNextPresta: () => false,
    setCurrentCatalogId: () => null,
    save: () => null,
    create: () => null,
    containerWidth: 'sm',
});
