import { FaOptionIcon } from '@europrocurement/l2d-icons';
import { faCheck } from '@fortawesome/pro-duotone-svg-icons';
import { Typography } from '@mui/material';
import { Box, useTheme } from '@mui/system';
import React, { CSSProperties } from 'react';
import { BaseItemProps } from '../SelectItem';

export type TreeItemProps = BaseItemProps;

const TreeItem: React.FunctionComponent<TreeItemProps> = function (props) {
    const { label, value, isChecked = false, isDisabled = false, onChange = () => {} } = props;
    const theme = useTheme();

    const changecheck = () => {
        onChange(value, !isChecked);
    };

    let cssChecked: CSSProperties = {};
    const cssCheckedBox: CSSProperties = {
        position: 'absolute',
        top: '5px',
        right: '5px',
    };
    let cssDisabled: CSSProperties = {};

    if (isChecked) {
        cssChecked = {
            color: theme.palette.primary.dark,
        };
    }
    if (isDisabled) {
        cssDisabled = {
            color: theme.palette.grey[300],
            cursor: 'not-allowed',
        };
        if (isChecked) {
            cssChecked = {
                color: theme.palette.primary.dark,
            };
        }
    }

    return (
        <Box
            style={{
                width: '100%',
                display: 'flex',
                gap: 1,
                cursor: 'pointer',
                position: 'relative',
                alignItems: 'center',
                margin: '15px 0px 0px 0px',
                ...cssChecked,
                ...cssDisabled,
            }}
            data-testid="test-id-treeItem-cursor"
            onClick={() => {
                if (!isDisabled) {
                    changecheck();
                }
            }}
        >
            <Typography component="span">{label}</Typography>
            {isChecked ? (
                <Box
                    style={{
                        ...cssCheckedBox,
                    }}
                    className="treeItem-checked"
                    data-testid="test-id-treeItem-checked"
                >
                    <FaOptionIcon icon={faCheck} />
                </Box>
            ) : null}
        </Box>
    );
};

export default TreeItem;
