import { FormStructure } from '@europrocurement/flexy-form/components';
import { jsonSeparator } from '../types/formTypes';

const jsonSeparatorToFormElement: (header: jsonSeparator) => FormStructure = (separator) => {
    const baseField: FormStructure = {
        type: separator.type,
        name: separator.name,
        xs: separator.xs,
        sm: separator.sm,
        md: separator.md,
        lg: separator.lg,
    };

    return baseField;
};

export default jsonSeparatorToFormElement;
