import { createContext, useContext } from 'react';

export type FullLayoutContext = {
    isSidebarOpen: boolean;
    jobName?: string;
    customHeader?: React.ReactNode;
    setCustomHeader: React.Dispatch<React.SetStateAction<React.ReactNode>>;
    setJobName: React.Dispatch<React.SetStateAction<string>>;
    setSidebarOpen: React.Dispatch<React.SetStateAction<boolean>>;
    isCustomizerDrawerOpen: boolean;
    setCustomizerDrawerOpen: React.Dispatch<React.SetStateAction<boolean>>;
    toggleCustomizerDrawer: () => void;
};

export const FullLayoutContext = createContext<FullLayoutContext>({
    isSidebarOpen: true,
    setSidebarOpen: () => {},
    isCustomizerDrawerOpen: false,
    setCustomizerDrawerOpen: () => {},
    toggleCustomizerDrawer: () => {},
    setCustomHeader: () => {},
    setJobName: () => {},
});

export const FullLayoutProvider = FullLayoutContext.Provider;

export const useFullLayout = () => useContext(FullLayoutContext);
