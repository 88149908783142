import { GoogleMapApiKeySelector } from '@europrocurement/l2d-domain';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

export const useGoogleMapApi = () => {
    const apiKey = useSelector(GoogleMapApiKeySelector);
    const [googleMapApiKey, setGoogleMapApiKey] = useState(apiKey);

    useEffect(() => {
        if (apiKey !== googleMapApiKey) {
            setGoogleMapApiKey(apiKey);
        }
    }, [apiKey, googleMapApiKey]);

    return googleMapApiKey;
};
