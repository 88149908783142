import React from 'react';
import { FlexyInput, FlexyInputProps, FlexySelectProps } from '@europrocurement/flexy-components';
import { Controller, RegisterOptions, useFormContext } from 'react-hook-form';
import { FormHelperText } from '@mui/material';
import _ from 'lodash';

export type ControlledSelectProps = {
    name: string;
    rules: RegisterOptions;
    required?: boolean;
    options: FlexySelectProps['options'] | Promise<FlexySelectProps['options']>;
} & FlexyInputProps<FlexySelectProps>;

const ControlledSelect: React.FunctionComponent<ControlledSelectProps> = function ({
    name,
    rules,
    options,
    ...inputProps
}) {
    const { control } = useFormContext();

    const [asyncItems, setAsyncItems] = React.useState<FlexySelectProps['options']>([]);
    const [loadingText, setLoadingText] = React.useState<string>('Chargement ...');

    if (Array.isArray(options)) {
        if (!_.isEqual(asyncItems, options)) {
            setAsyncItems(options);
        }
    } else {
        options.then((items) => {
            setAsyncItems(items);
            setLoadingText('Aucun resultat');
        });
    }

    return (
        <Controller
            control={control}
            name={name}
            rules={rules}
            render={({
                field: { onChange, value },
                fieldState: { error },
                // formState,
            }) => (
                <>
                    <FlexyInput
                        onChange={onChange}
                        {...inputProps}
                        name={name}
                        value={value}
                        defaultValue={value}
                        options={asyncItems}
                        noOptionsMessage={() => loadingText}
                    />
                    {error ? (
                        <FormHelperText
                            sx={{
                                margin: '4px 14px 0px 14px',
                            }}
                            error
                        >
                            {error?.message}
                        </FormHelperText>
                    ) : null}
                </>
            )}
        />
    );
};

export default ControlledSelect;
