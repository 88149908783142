import * as React from 'react';
import { Box, SxProps, useTheme } from '@mui/material';
import {
    FaOptionIcon,
    NamedIconsType,
    alertErrorIcon,
    alertInfoIcon,
    alertWarningIcon,
} from '@europrocurement/l2d-icons';

export type NoteInformationProps = {
    title?: string;
    sx?: SxProps;
    variant?: 'info' | 'warning' | 'error';
};

export const variantToIcone = (variant: NoteInformationProps['variant']): NamedIconsType => {
    switch (variant) {
        case 'warning':
            return alertWarningIcon;
        case 'error':
            return alertErrorIcon;
        case 'info':
        default:
            return alertInfoIcon;
    }
};

const NoteInformation: React.FunctionComponent<NoteInformationProps> = function (
    props: NoteInformationProps,
) {
    const { title, sx, variant = 'info' } = props;

    const theme = useTheme();

    const variantToBgColor = (noteVariant: NoteInformationProps['variant']): string => {
        switch (noteVariant) {
            case 'warning':
                return theme.palette.warning.light;
            case 'error':
                return theme.palette.error.light;
            case 'info':
            default:
                return theme.palette.primary.light;
        }
    };
    const variantToColor = (noteVariant: NoteInformationProps['variant']): string => {
        switch (noteVariant) {
            case 'warning':
                return theme.palette.warning.dark;
            case 'error':
                return theme.palette.error.main;
            case 'info':
            default:
                return theme.palette.primary.main;
        }
    };
    const vibrantColor = variantToColor(variant);
    const bgColor = variantToBgColor(variant);
    return (
        <Box
            sx={{
                borderTop: `1px solid ${vibrantColor}`,
                borderBottom: `1px solid ${vibrantColor}`,
                borderLeft: `7px solid ${vibrantColor}`,
                borderRight: `1px solid ${vibrantColor}`,
                borderRadius: '5px',
                padding: '0px',
                backgroundColor: `${bgColor}`,
                color: `${vibrantColor}`,
                ...sx,
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    gap: '15px',
                    padding: '10px',
                    alignItems: 'start',
                }}
            >
                <FaOptionIcon
                    {...variantToIcone(variant).props}
                    color={vibrantColor}
                />
                {title}
            </Box>
        </Box>
    );
};

export default NoteInformation;
