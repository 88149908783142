/* tslint:disable */
/* eslint-disable */
/**
 * Europrocurement Api Formulaire
 * API de gestion des formulaires
 *
 * The version of the OpenAPI document: 1.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface ApiFormulaireFormalitesGetCollection200Response
 */
export interface ApiFormulaireFormalitesGetCollection200Response {
    /**
     * 
     * @type {any}
     * @memberof ApiFormulaireFormalitesGetCollection200Response
     */
    'hydra:member': any;
    /**
     * 
     * @type {any}
     * @memberof ApiFormulaireFormalitesGetCollection200Response
     */
    'hydra:totalItems'?: any;
    /**
     * 
     * @type {ApiFormulaireFormalitesGetCollection200ResponseHydraView}
     * @memberof ApiFormulaireFormalitesGetCollection200Response
     */
    'hydra:view'?: ApiFormulaireFormalitesGetCollection200ResponseHydraView;
    /**
     * 
     * @type {ApiFormulaireFormalitesGetCollection200ResponseHydraSearch}
     * @memberof ApiFormulaireFormalitesGetCollection200Response
     */
    'hydra:search'?: ApiFormulaireFormalitesGetCollection200ResponseHydraSearch;
}
/**
 * 
 * @export
 * @interface ApiFormulaireFormalitesGetCollection200ResponseHydraSearch
 */
export interface ApiFormulaireFormalitesGetCollection200ResponseHydraSearch {
    /**
     * 
     * @type {any}
     * @memberof ApiFormulaireFormalitesGetCollection200ResponseHydraSearch
     */
    '@type'?: any;
    /**
     * 
     * @type {any}
     * @memberof ApiFormulaireFormalitesGetCollection200ResponseHydraSearch
     */
    'hydra:template'?: any;
    /**
     * 
     * @type {any}
     * @memberof ApiFormulaireFormalitesGetCollection200ResponseHydraSearch
     */
    'hydra:variableRepresentation'?: any;
    /**
     * 
     * @type {any}
     * @memberof ApiFormulaireFormalitesGetCollection200ResponseHydraSearch
     */
    'hydra:mapping'?: any;
}
/**
 * 
 * @export
 * @interface ApiFormulaireFormalitesGetCollection200ResponseHydraView
 */
export interface ApiFormulaireFormalitesGetCollection200ResponseHydraView {
    /**
     * 
     * @type {any}
     * @memberof ApiFormulaireFormalitesGetCollection200ResponseHydraView
     */
    '@id'?: any;
    /**
     * 
     * @type {any}
     * @memberof ApiFormulaireFormalitesGetCollection200ResponseHydraView
     */
    '@type'?: any;
    /**
     * 
     * @type {any}
     * @memberof ApiFormulaireFormalitesGetCollection200ResponseHydraView
     */
    'hydra:first'?: any;
    /**
     * 
     * @type {any}
     * @memberof ApiFormulaireFormalitesGetCollection200ResponseHydraView
     */
    'hydra:last'?: any;
    /**
     * 
     * @type {any}
     * @memberof ApiFormulaireFormalitesGetCollection200ResponseHydraView
     */
    'hydra:previous'?: any;
    /**
     * 
     * @type {any}
     * @memberof ApiFormulaireFormalitesGetCollection200ResponseHydraView
     */
    'hydra:next'?: any;
}
/**
 * 
 * @export
 * @interface ApiFormulaireFormalitesIdFormulaireFormalitelisteSelectionsGetCollection200Response
 */
export interface ApiFormulaireFormalitesIdFormulaireFormalitelisteSelectionsGetCollection200Response {
    /**
     * 
     * @type {any}
     * @memberof ApiFormulaireFormalitesIdFormulaireFormalitelisteSelectionsGetCollection200Response
     */
    'hydra:member': any;
    /**
     * 
     * @type {any}
     * @memberof ApiFormulaireFormalitesIdFormulaireFormalitelisteSelectionsGetCollection200Response
     */
    'hydra:totalItems'?: any;
    /**
     * 
     * @type {ApiFormulaireFormalitesGetCollection200ResponseHydraView}
     * @memberof ApiFormulaireFormalitesIdFormulaireFormalitelisteSelectionsGetCollection200Response
     */
    'hydra:view'?: ApiFormulaireFormalitesGetCollection200ResponseHydraView;
    /**
     * 
     * @type {ApiFormulaireFormalitesGetCollection200ResponseHydraSearch}
     * @memberof ApiFormulaireFormalitesIdFormulaireFormalitelisteSelectionsGetCollection200Response
     */
    'hydra:search'?: ApiFormulaireFormalitesGetCollection200ResponseHydraSearch;
}
/**
 * 
 * @export
 * @interface ApiFormulaireFormalitesPost400Response
 */
export interface ApiFormulaireFormalitesPost400Response {
    /**
     * 
     * @type {any}
     * @memberof ApiFormulaireFormalitesPost400Response
     */
    'error'?: any;
}
/**
 * FproFormaliteArticle
 * @export
 * @interface Formalite
 */
export interface Formalite {
    /**
     * 
     * @type {any}
     * @memberof Formalite
     */
    'id'?: any;
}
/**
 * FproFormaliteArticle
 * @export
 * @interface FormaliteJsonld
 */
export interface FormaliteJsonld {
    /**
     * 
     * @type {any}
     * @memberof FormaliteJsonld
     */
    '@context'?: any;
    /**
     * 
     * @type {any}
     * @memberof FormaliteJsonld
     */
    '@id'?: any;
    /**
     * 
     * @type {any}
     * @memberof FormaliteJsonld
     */
    '@type'?: any;
    /**
     * 
     * @type {any}
     * @memberof FormaliteJsonld
     */
    'id'?: any;
}
/**
 * TblFormulaire
 * @export
 * @interface FormulaireFormalite
 */
export interface FormulaireFormalite {
    /**
     * 
     * @type {any}
     * @memberof FormulaireFormalite
     */
    'id'?: any;
    /**
     * 
     * @type {any}
     * @memberof FormulaireFormalite
     */
    'libelle': any;
    /**
     * 
     * @type {any}
     * @memberof FormulaireFormalite
     */
    'formulaire': any;
    /**
     * 
     * @type {any}
     * @memberof FormulaireFormalite
     */
    'formalites'?: any;
}
/**
 * TblFormulaire
 * @export
 * @interface FormulaireFormaliteJsonld
 */
export interface FormulaireFormaliteJsonld {
    /**
     * 
     * @type {any}
     * @memberof FormulaireFormaliteJsonld
     */
    '@context'?: any;
    /**
     * 
     * @type {any}
     * @memberof FormulaireFormaliteJsonld
     */
    '@id'?: any;
    /**
     * 
     * @type {any}
     * @memberof FormulaireFormaliteJsonld
     */
    '@type'?: any;
    /**
     * 
     * @type {any}
     * @memberof FormulaireFormaliteJsonld
     */
    'id'?: any;
    /**
     * 
     * @type {any}
     * @memberof FormulaireFormaliteJsonld
     */
    'libelle': any;
    /**
     * 
     * @type {any}
     * @memberof FormulaireFormaliteJsonld
     */
    'formulaire': any;
    /**
     * 
     * @type {any}
     * @memberof FormulaireFormaliteJsonld
     */
    'formalites'?: any;
}
/**
 * FormulaireListeSelection
 * @export
 * @interface FormulaireListeSelectionFormulairelisteCreate
 */
export interface FormulaireListeSelectionFormulairelisteCreate {
    /**
     * 
     * @type {any}
     * @memberof FormulaireListeSelectionFormulairelisteCreate
     */
    'formulaire': any;
    /**
     * 
     * @type {any}
     * @memberof FormulaireListeSelectionFormulairelisteCreate
     */
    'formalite': any;
    /**
     * 
     * @type {any}
     * @memberof FormulaireListeSelectionFormulairelisteCreate
     */
    'path': any;
    /**
     * 
     * @type {any}
     * @memberof FormulaireListeSelectionFormulairelisteCreate
     */
    'liste': any;
}
/**
 * FormulaireListeSelection
 * @export
 * @interface FormulaireListeSelectionFormulairelisteRead
 */
export interface FormulaireListeSelectionFormulairelisteRead {
    /**
     * 
     * @type {any}
     * @memberof FormulaireListeSelectionFormulairelisteRead
     */
    'id': any;
    /**
     * 
     * @type {any}
     * @memberof FormulaireListeSelectionFormulairelisteRead
     */
    'formulaire': any;
    /**
     * 
     * @type {any}
     * @memberof FormulaireListeSelectionFormulairelisteRead
     */
    'formalite': any;
    /**
     * 
     * @type {any}
     * @memberof FormulaireListeSelectionFormulairelisteRead
     */
    'path': any;
    /**
     * 
     * @type {any}
     * @memberof FormulaireListeSelectionFormulairelisteRead
     */
    'liste': any;
}
/**
 * FormulaireListeSelection
 * @export
 * @interface FormulaireListeSelectionJsonldFormulairelisteCreate
 */
export interface FormulaireListeSelectionJsonldFormulairelisteCreate {
    /**
     * 
     * @type {any}
     * @memberof FormulaireListeSelectionJsonldFormulairelisteCreate
     */
    'formulaire': any;
    /**
     * 
     * @type {any}
     * @memberof FormulaireListeSelectionJsonldFormulairelisteCreate
     */
    'formalite': any;
    /**
     * 
     * @type {any}
     * @memberof FormulaireListeSelectionJsonldFormulairelisteCreate
     */
    'path': any;
    /**
     * 
     * @type {any}
     * @memberof FormulaireListeSelectionJsonldFormulairelisteCreate
     */
    'liste': any;
}
/**
 * FormulaireListeSelection
 * @export
 * @interface FormulaireListeSelectionJsonldFormulairelisteRead
 */
export interface FormulaireListeSelectionJsonldFormulairelisteRead {
    /**
     * 
     * @type {any}
     * @memberof FormulaireListeSelectionJsonldFormulairelisteRead
     */
    '@context'?: any;
    /**
     * 
     * @type {any}
     * @memberof FormulaireListeSelectionJsonldFormulairelisteRead
     */
    '@id'?: any;
    /**
     * 
     * @type {any}
     * @memberof FormulaireListeSelectionJsonldFormulairelisteRead
     */
    '@type'?: any;
    /**
     * 
     * @type {any}
     * @memberof FormulaireListeSelectionJsonldFormulairelisteRead
     */
    'id': any;
    /**
     * 
     * @type {any}
     * @memberof FormulaireListeSelectionJsonldFormulairelisteRead
     */
    'formulaire': any;
    /**
     * 
     * @type {any}
     * @memberof FormulaireListeSelectionJsonldFormulairelisteRead
     */
    'formalite': any;
    /**
     * 
     * @type {any}
     * @memberof FormulaireListeSelectionJsonldFormulairelisteRead
     */
    'path': any;
    /**
     * 
     * @type {any}
     * @memberof FormulaireListeSelectionJsonldFormulairelisteRead
     */
    'liste': any;
}

/**
 * FormaliteApi - axios parameter creator
 * @export
 */
export const FormaliteApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Retrieves a Formalite resource.
         * @summary Récupérer les informations d\'une formalite
         * @param {any} id Formalite identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFormalitesIdGet: async (id: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiFormalitesIdGet', 'id', id)
            const localVarPath = `/formulaire/formalites/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FormaliteApi - functional programming interface
 * @export
 */
export const FormaliteApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = FormaliteApiAxiosParamCreator(configuration)
    return {
        /**
         * Retrieves a Formalite resource.
         * @summary Récupérer les informations d\'une formalite
         * @param {any} id Formalite identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiFormalitesIdGet(id: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FormaliteJsonld>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiFormalitesIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * FormaliteApi - factory interface
 * @export
 */
export const FormaliteApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = FormaliteApiFp(configuration)
    return {
        /**
         * Retrieves a Formalite resource.
         * @summary Récupérer les informations d\'une formalite
         * @param {FormaliteApiApiFormalitesIdGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFormalitesIdGet(requestParameters: FormaliteApiApiFormalitesIdGetRequest, options?: AxiosRequestConfig): AxiosPromise<FormaliteJsonld> {
            return localVarFp.apiFormalitesIdGet(requestParameters.id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for apiFormalitesIdGet operation in FormaliteApi.
 * @export
 * @interface FormaliteApiApiFormalitesIdGetRequest
 */
export interface FormaliteApiApiFormalitesIdGetRequest {
    /**
     * Formalite identifier
     * @type {any}
     * @memberof FormaliteApiApiFormalitesIdGet
     */
    readonly id: any
}

/**
 * FormaliteApi - object-oriented interface
 * @export
 * @class FormaliteApi
 * @extends {BaseAPI}
 */
export class FormaliteApi extends BaseAPI {
    /**
     * Retrieves a Formalite resource.
     * @summary Récupérer les informations d\'une formalite
     * @param {FormaliteApiApiFormalitesIdGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FormaliteApi
     */
    public apiFormalitesIdGet(requestParameters: FormaliteApiApiFormalitesIdGetRequest, options?: AxiosRequestConfig) {
        return FormaliteApiFp(this.configuration).apiFormalitesIdGet(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * FormulaireFormaliteApi - axios parameter creator
 * @export
 */
export const FormulaireFormaliteApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Retrieves the collection of FormulaireFormalite resources.
         * @summary Obtenir la liste des formulaires
         * @param {any} [page] The collection page number
         * @param {any} [itemsPerPage] The number of items per page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFormulaireFormalitesGetCollection: async (page?: any, itemsPerPage?: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/formulaire/formulaire_formalites`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (itemsPerPage !== undefined) {
                localVarQueryParameter['itemsPerPage'] = itemsPerPage;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves a FormulaireFormalite resource.
         * @summary Récupérer un formulaire
         * @param {any} id FormulaireFormalite identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFormulaireFormalitesIdGet: async (id: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiFormulaireFormalitesIdGet', 'id', id)
            const localVarPath = `/formulaire/formulaire_formalites/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Creates a FormulaireFormalite resource.
         * @summary Créer un nouveau formulaire
         * @param {FormulaireFormaliteJsonld} formulaireFormaliteJsonld The new FormulaireFormalite resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFormulaireFormalitesPost: async (formulaireFormaliteJsonld: FormulaireFormaliteJsonld, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'formulaireFormaliteJsonld' is not null or undefined
            assertParamExists('apiFormulaireFormalitesPost', 'formulaireFormaliteJsonld', formulaireFormaliteJsonld)
            const localVarPath = `/formulaire/formulaire_formalites`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/ld+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(formulaireFormaliteJsonld, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FormulaireFormaliteApi - functional programming interface
 * @export
 */
export const FormulaireFormaliteApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = FormulaireFormaliteApiAxiosParamCreator(configuration)
    return {
        /**
         * Retrieves the collection of FormulaireFormalite resources.
         * @summary Obtenir la liste des formulaires
         * @param {any} [page] The collection page number
         * @param {any} [itemsPerPage] The number of items per page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiFormulaireFormalitesGetCollection(page?: any, itemsPerPage?: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiFormulaireFormalitesGetCollection200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiFormulaireFormalitesGetCollection(page, itemsPerPage, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieves a FormulaireFormalite resource.
         * @summary Récupérer un formulaire
         * @param {any} id FormulaireFormalite identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiFormulaireFormalitesIdGet(id: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FormulaireFormaliteJsonld>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiFormulaireFormalitesIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Creates a FormulaireFormalite resource.
         * @summary Créer un nouveau formulaire
         * @param {FormulaireFormaliteJsonld} formulaireFormaliteJsonld The new FormulaireFormalite resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiFormulaireFormalitesPost(formulaireFormaliteJsonld: FormulaireFormaliteJsonld, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FormulaireFormaliteJsonld>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiFormulaireFormalitesPost(formulaireFormaliteJsonld, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * FormulaireFormaliteApi - factory interface
 * @export
 */
export const FormulaireFormaliteApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = FormulaireFormaliteApiFp(configuration)
    return {
        /**
         * Retrieves the collection of FormulaireFormalite resources.
         * @summary Obtenir la liste des formulaires
         * @param {FormulaireFormaliteApiApiFormulaireFormalitesGetCollectionRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFormulaireFormalitesGetCollection(requestParameters: FormulaireFormaliteApiApiFormulaireFormalitesGetCollectionRequest = {}, options?: AxiosRequestConfig): AxiosPromise<ApiFormulaireFormalitesGetCollection200Response> {
            return localVarFp.apiFormulaireFormalitesGetCollection(requestParameters.page, requestParameters.itemsPerPage, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieves a FormulaireFormalite resource.
         * @summary Récupérer un formulaire
         * @param {FormulaireFormaliteApiApiFormulaireFormalitesIdGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFormulaireFormalitesIdGet(requestParameters: FormulaireFormaliteApiApiFormulaireFormalitesIdGetRequest, options?: AxiosRequestConfig): AxiosPromise<FormulaireFormaliteJsonld> {
            return localVarFp.apiFormulaireFormalitesIdGet(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * Creates a FormulaireFormalite resource.
         * @summary Créer un nouveau formulaire
         * @param {FormulaireFormaliteApiApiFormulaireFormalitesPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFormulaireFormalitesPost(requestParameters: FormulaireFormaliteApiApiFormulaireFormalitesPostRequest, options?: AxiosRequestConfig): AxiosPromise<FormulaireFormaliteJsonld> {
            return localVarFp.apiFormulaireFormalitesPost(requestParameters.formulaireFormaliteJsonld, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for apiFormulaireFormalitesGetCollection operation in FormulaireFormaliteApi.
 * @export
 * @interface FormulaireFormaliteApiApiFormulaireFormalitesGetCollectionRequest
 */
export interface FormulaireFormaliteApiApiFormulaireFormalitesGetCollectionRequest {
    /**
     * The collection page number
     * @type {any}
     * @memberof FormulaireFormaliteApiApiFormulaireFormalitesGetCollection
     */
    readonly page?: any

    /**
     * The number of items per page
     * @type {any}
     * @memberof FormulaireFormaliteApiApiFormulaireFormalitesGetCollection
     */
    readonly itemsPerPage?: any
}

/**
 * Request parameters for apiFormulaireFormalitesIdGet operation in FormulaireFormaliteApi.
 * @export
 * @interface FormulaireFormaliteApiApiFormulaireFormalitesIdGetRequest
 */
export interface FormulaireFormaliteApiApiFormulaireFormalitesIdGetRequest {
    /**
     * FormulaireFormalite identifier
     * @type {any}
     * @memberof FormulaireFormaliteApiApiFormulaireFormalitesIdGet
     */
    readonly id: any
}

/**
 * Request parameters for apiFormulaireFormalitesPost operation in FormulaireFormaliteApi.
 * @export
 * @interface FormulaireFormaliteApiApiFormulaireFormalitesPostRequest
 */
export interface FormulaireFormaliteApiApiFormulaireFormalitesPostRequest {
    /**
     * The new FormulaireFormalite resource
     * @type {FormulaireFormaliteJsonld}
     * @memberof FormulaireFormaliteApiApiFormulaireFormalitesPost
     */
    readonly formulaireFormaliteJsonld: FormulaireFormaliteJsonld
}

/**
 * FormulaireFormaliteApi - object-oriented interface
 * @export
 * @class FormulaireFormaliteApi
 * @extends {BaseAPI}
 */
export class FormulaireFormaliteApi extends BaseAPI {
    /**
     * Retrieves the collection of FormulaireFormalite resources.
     * @summary Obtenir la liste des formulaires
     * @param {FormulaireFormaliteApiApiFormulaireFormalitesGetCollectionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FormulaireFormaliteApi
     */
    public apiFormulaireFormalitesGetCollection(requestParameters: FormulaireFormaliteApiApiFormulaireFormalitesGetCollectionRequest = {}, options?: AxiosRequestConfig) {
        return FormulaireFormaliteApiFp(this.configuration).apiFormulaireFormalitesGetCollection(requestParameters.page, requestParameters.itemsPerPage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves a FormulaireFormalite resource.
     * @summary Récupérer un formulaire
     * @param {FormulaireFormaliteApiApiFormulaireFormalitesIdGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FormulaireFormaliteApi
     */
    public apiFormulaireFormalitesIdGet(requestParameters: FormulaireFormaliteApiApiFormulaireFormalitesIdGetRequest, options?: AxiosRequestConfig) {
        return FormulaireFormaliteApiFp(this.configuration).apiFormulaireFormalitesIdGet(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Creates a FormulaireFormalite resource.
     * @summary Créer un nouveau formulaire
     * @param {FormulaireFormaliteApiApiFormulaireFormalitesPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FormulaireFormaliteApi
     */
    public apiFormulaireFormalitesPost(requestParameters: FormulaireFormaliteApiApiFormulaireFormalitesPostRequest, options?: AxiosRequestConfig) {
        return FormulaireFormaliteApiFp(this.configuration).apiFormulaireFormalitesPost(requestParameters.formulaireFormaliteJsonld, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * FormulaireListeSelectionApi - axios parameter creator
 * @export
 */
export const FormulaireListeSelectionApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Retrieves a FormulaireListeSelection resource.
         * @summary Récupérer une liste de sélection d\'un formulaire
         * @param {any} idFormulaireFormalite 
         * @param {any} idFormalite 
         * @param {any} typeListe 
         * @param {any} [discriminant] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFormulaireFormalitesIdFormulaireFormaliteformaliteIdFormalitelisteSelectionsTypeListeDiscriminantGet: async (idFormulaireFormalite: any, idFormalite: any, typeListe: any, discriminant?: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'idFormulaireFormalite' is not null or undefined
            assertParamExists('apiFormulaireFormalitesIdFormulaireFormaliteformaliteIdFormalitelisteSelectionsTypeListeDiscriminantGet', 'idFormulaireFormalite', idFormulaireFormalite)
            // verify required parameter 'idFormalite' is not null or undefined
            assertParamExists('apiFormulaireFormalitesIdFormulaireFormaliteformaliteIdFormalitelisteSelectionsTypeListeDiscriminantGet', 'idFormalite', idFormalite)
            // verify required parameter 'typeListe' is not null or undefined
            assertParamExists('apiFormulaireFormalitesIdFormulaireFormaliteformaliteIdFormalitelisteSelectionsTypeListeDiscriminantGet', 'typeListe', typeListe)
            const localVarPath = `/formulaire/formulaire_formalites/{idFormulaireFormalite}/formalite/{idFormalite}/liste-selections/{typeListe}/{discriminant}`
                .replace(`{${"idFormulaireFormalite"}}`, encodeURIComponent(String(idFormulaireFormalite)))
                .replace(`{${"idFormalite"}}`, encodeURIComponent(String(idFormalite)))
                .replace(`{${"typeListe"}}`, encodeURIComponent(String(typeListe)))
                .replace(`{${"discriminant"}}`, encodeURIComponent(String(discriminant)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves the collection of FormulaireListeSelection resources.
         * @summary Récupérer les surcharges de liste de sélection d\'un formulaire
         * @param {any} idFormulaireFormalite FormulaireListeSelection identifier
         * @param {any} [page] The collection page number
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFormulaireFormalitesIdFormulaireFormalitelisteSelectionsGetCollection: async (idFormulaireFormalite: any, page?: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'idFormulaireFormalite' is not null or undefined
            assertParamExists('apiFormulaireFormalitesIdFormulaireFormalitelisteSelectionsGetCollection', 'idFormulaireFormalite', idFormulaireFormalite)
            const localVarPath = `/formulaire/formulaire_formalites/{idFormulaireFormalite}/liste-selections`
                .replace(`{${"idFormulaireFormalite"}}`, encodeURIComponent(String(idFormulaireFormalite)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Creates a FormulaireListeSelection resource.
         * @summary Créer une nouvelle surcharge de liste de sélection
         * @param {FormulaireListeSelectionJsonldFormulairelisteCreate} formulaireListeSelectionJsonldFormulairelisteCreate The new FormulaireListeSelection resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFormulaireFormaliteslisteSelectionsPost: async (formulaireListeSelectionJsonldFormulairelisteCreate: FormulaireListeSelectionJsonldFormulairelisteCreate, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'formulaireListeSelectionJsonldFormulairelisteCreate' is not null or undefined
            assertParamExists('apiFormulaireFormaliteslisteSelectionsPost', 'formulaireListeSelectionJsonldFormulairelisteCreate', formulaireListeSelectionJsonldFormulairelisteCreate)
            const localVarPath = `/formulaire/formulaire_formalites/liste-selections`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/ld+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(formulaireListeSelectionJsonldFormulairelisteCreate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FormulaireListeSelectionApi - functional programming interface
 * @export
 */
export const FormulaireListeSelectionApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = FormulaireListeSelectionApiAxiosParamCreator(configuration)
    return {
        /**
         * Retrieves a FormulaireListeSelection resource.
         * @summary Récupérer une liste de sélection d\'un formulaire
         * @param {any} idFormulaireFormalite 
         * @param {any} idFormalite 
         * @param {any} typeListe 
         * @param {any} [discriminant] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiFormulaireFormalitesIdFormulaireFormaliteformaliteIdFormalitelisteSelectionsTypeListeDiscriminantGet(idFormulaireFormalite: any, idFormalite: any, typeListe: any, discriminant?: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiFormulaireFormalitesIdFormulaireFormaliteformaliteIdFormalitelisteSelectionsTypeListeDiscriminantGet(idFormulaireFormalite, idFormalite, typeListe, discriminant, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieves the collection of FormulaireListeSelection resources.
         * @summary Récupérer les surcharges de liste de sélection d\'un formulaire
         * @param {any} idFormulaireFormalite FormulaireListeSelection identifier
         * @param {any} [page] The collection page number
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiFormulaireFormalitesIdFormulaireFormalitelisteSelectionsGetCollection(idFormulaireFormalite: any, page?: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiFormulaireFormalitesIdFormulaireFormalitelisteSelectionsGetCollection200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiFormulaireFormalitesIdFormulaireFormalitelisteSelectionsGetCollection(idFormulaireFormalite, page, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Creates a FormulaireListeSelection resource.
         * @summary Créer une nouvelle surcharge de liste de sélection
         * @param {FormulaireListeSelectionJsonldFormulairelisteCreate} formulaireListeSelectionJsonldFormulairelisteCreate The new FormulaireListeSelection resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiFormulaireFormaliteslisteSelectionsPost(formulaireListeSelectionJsonldFormulairelisteCreate: FormulaireListeSelectionJsonldFormulairelisteCreate, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FormulaireListeSelectionJsonldFormulairelisteRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiFormulaireFormaliteslisteSelectionsPost(formulaireListeSelectionJsonldFormulairelisteCreate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * FormulaireListeSelectionApi - factory interface
 * @export
 */
export const FormulaireListeSelectionApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = FormulaireListeSelectionApiFp(configuration)
    return {
        /**
         * Retrieves a FormulaireListeSelection resource.
         * @summary Récupérer une liste de sélection d\'un formulaire
         * @param {FormulaireListeSelectionApiApiFormulaireFormalitesIdFormulaireFormaliteformaliteIdFormalitelisteSelectionsTypeListeDiscriminantGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFormulaireFormalitesIdFormulaireFormaliteformaliteIdFormalitelisteSelectionsTypeListeDiscriminantGet(requestParameters: FormulaireListeSelectionApiApiFormulaireFormalitesIdFormulaireFormaliteformaliteIdFormalitelisteSelectionsTypeListeDiscriminantGetRequest, options?: AxiosRequestConfig): AxiosPromise<any> {
            return localVarFp.apiFormulaireFormalitesIdFormulaireFormaliteformaliteIdFormalitelisteSelectionsTypeListeDiscriminantGet(requestParameters.idFormulaireFormalite, requestParameters.idFormalite, requestParameters.typeListe, requestParameters.discriminant, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieves the collection of FormulaireListeSelection resources.
         * @summary Récupérer les surcharges de liste de sélection d\'un formulaire
         * @param {FormulaireListeSelectionApiApiFormulaireFormalitesIdFormulaireFormalitelisteSelectionsGetCollectionRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFormulaireFormalitesIdFormulaireFormalitelisteSelectionsGetCollection(requestParameters: FormulaireListeSelectionApiApiFormulaireFormalitesIdFormulaireFormalitelisteSelectionsGetCollectionRequest, options?: AxiosRequestConfig): AxiosPromise<ApiFormulaireFormalitesIdFormulaireFormalitelisteSelectionsGetCollection200Response> {
            return localVarFp.apiFormulaireFormalitesIdFormulaireFormalitelisteSelectionsGetCollection(requestParameters.idFormulaireFormalite, requestParameters.page, options).then((request) => request(axios, basePath));
        },
        /**
         * Creates a FormulaireListeSelection resource.
         * @summary Créer une nouvelle surcharge de liste de sélection
         * @param {FormulaireListeSelectionApiApiFormulaireFormaliteslisteSelectionsPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFormulaireFormaliteslisteSelectionsPost(requestParameters: FormulaireListeSelectionApiApiFormulaireFormaliteslisteSelectionsPostRequest, options?: AxiosRequestConfig): AxiosPromise<FormulaireListeSelectionJsonldFormulairelisteRead> {
            return localVarFp.apiFormulaireFormaliteslisteSelectionsPost(requestParameters.formulaireListeSelectionJsonldFormulairelisteCreate, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for apiFormulaireFormalitesIdFormulaireFormaliteformaliteIdFormalitelisteSelectionsTypeListeDiscriminantGet operation in FormulaireListeSelectionApi.
 * @export
 * @interface FormulaireListeSelectionApiApiFormulaireFormalitesIdFormulaireFormaliteformaliteIdFormalitelisteSelectionsTypeListeDiscriminantGetRequest
 */
export interface FormulaireListeSelectionApiApiFormulaireFormalitesIdFormulaireFormaliteformaliteIdFormalitelisteSelectionsTypeListeDiscriminantGetRequest {
    /**
     * 
     * @type {any}
     * @memberof FormulaireListeSelectionApiApiFormulaireFormalitesIdFormulaireFormaliteformaliteIdFormalitelisteSelectionsTypeListeDiscriminantGet
     */
    readonly idFormulaireFormalite: any

    /**
     * 
     * @type {any}
     * @memberof FormulaireListeSelectionApiApiFormulaireFormalitesIdFormulaireFormaliteformaliteIdFormalitelisteSelectionsTypeListeDiscriminantGet
     */
    readonly idFormalite: any

    /**
     * 
     * @type {any}
     * @memberof FormulaireListeSelectionApiApiFormulaireFormalitesIdFormulaireFormaliteformaliteIdFormalitelisteSelectionsTypeListeDiscriminantGet
     */
    readonly typeListe: any

    /**
     * 
     * @type {any}
     * @memberof FormulaireListeSelectionApiApiFormulaireFormalitesIdFormulaireFormaliteformaliteIdFormalitelisteSelectionsTypeListeDiscriminantGet
     */
    readonly discriminant?: any
}

/**
 * Request parameters for apiFormulaireFormalitesIdFormulaireFormalitelisteSelectionsGetCollection operation in FormulaireListeSelectionApi.
 * @export
 * @interface FormulaireListeSelectionApiApiFormulaireFormalitesIdFormulaireFormalitelisteSelectionsGetCollectionRequest
 */
export interface FormulaireListeSelectionApiApiFormulaireFormalitesIdFormulaireFormalitelisteSelectionsGetCollectionRequest {
    /**
     * FormulaireListeSelection identifier
     * @type {any}
     * @memberof FormulaireListeSelectionApiApiFormulaireFormalitesIdFormulaireFormalitelisteSelectionsGetCollection
     */
    readonly idFormulaireFormalite: any

    /**
     * The collection page number
     * @type {any}
     * @memberof FormulaireListeSelectionApiApiFormulaireFormalitesIdFormulaireFormalitelisteSelectionsGetCollection
     */
    readonly page?: any
}

/**
 * Request parameters for apiFormulaireFormaliteslisteSelectionsPost operation in FormulaireListeSelectionApi.
 * @export
 * @interface FormulaireListeSelectionApiApiFormulaireFormaliteslisteSelectionsPostRequest
 */
export interface FormulaireListeSelectionApiApiFormulaireFormaliteslisteSelectionsPostRequest {
    /**
     * The new FormulaireListeSelection resource
     * @type {FormulaireListeSelectionJsonldFormulairelisteCreate}
     * @memberof FormulaireListeSelectionApiApiFormulaireFormaliteslisteSelectionsPost
     */
    readonly formulaireListeSelectionJsonldFormulairelisteCreate: FormulaireListeSelectionJsonldFormulairelisteCreate
}

/**
 * FormulaireListeSelectionApi - object-oriented interface
 * @export
 * @class FormulaireListeSelectionApi
 * @extends {BaseAPI}
 */
export class FormulaireListeSelectionApi extends BaseAPI {
    /**
     * Retrieves a FormulaireListeSelection resource.
     * @summary Récupérer une liste de sélection d\'un formulaire
     * @param {FormulaireListeSelectionApiApiFormulaireFormalitesIdFormulaireFormaliteformaliteIdFormalitelisteSelectionsTypeListeDiscriminantGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FormulaireListeSelectionApi
     */
    public apiFormulaireFormalitesIdFormulaireFormaliteformaliteIdFormalitelisteSelectionsTypeListeDiscriminantGet(requestParameters: FormulaireListeSelectionApiApiFormulaireFormalitesIdFormulaireFormaliteformaliteIdFormalitelisteSelectionsTypeListeDiscriminantGetRequest, options?: AxiosRequestConfig) {
        return FormulaireListeSelectionApiFp(this.configuration).apiFormulaireFormalitesIdFormulaireFormaliteformaliteIdFormalitelisteSelectionsTypeListeDiscriminantGet(requestParameters.idFormulaireFormalite, requestParameters.idFormalite, requestParameters.typeListe, requestParameters.discriminant, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves the collection of FormulaireListeSelection resources.
     * @summary Récupérer les surcharges de liste de sélection d\'un formulaire
     * @param {FormulaireListeSelectionApiApiFormulaireFormalitesIdFormulaireFormalitelisteSelectionsGetCollectionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FormulaireListeSelectionApi
     */
    public apiFormulaireFormalitesIdFormulaireFormalitelisteSelectionsGetCollection(requestParameters: FormulaireListeSelectionApiApiFormulaireFormalitesIdFormulaireFormalitelisteSelectionsGetCollectionRequest, options?: AxiosRequestConfig) {
        return FormulaireListeSelectionApiFp(this.configuration).apiFormulaireFormalitesIdFormulaireFormalitelisteSelectionsGetCollection(requestParameters.idFormulaireFormalite, requestParameters.page, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Creates a FormulaireListeSelection resource.
     * @summary Créer une nouvelle surcharge de liste de sélection
     * @param {FormulaireListeSelectionApiApiFormulaireFormaliteslisteSelectionsPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FormulaireListeSelectionApi
     */
    public apiFormulaireFormaliteslisteSelectionsPost(requestParameters: FormulaireListeSelectionApiApiFormulaireFormaliteslisteSelectionsPostRequest, options?: AxiosRequestConfig) {
        return FormulaireListeSelectionApiFp(this.configuration).apiFormulaireFormaliteslisteSelectionsPost(requestParameters.formulaireListeSelectionJsonldFormulairelisteCreate, options).then((request) => request(this.axios, this.basePath));
    }
}


