import { useFormaliteService } from '@europrocurement/l2d-domain';
import { DossierOpenSearchJsonldOpensearchDossierFormaliteRead } from '@europrocurement/l2d-domain/openApi/ApiFormalite';

export type DossierFormalite = DossierOpenSearchJsonldOpensearchDossierFormaliteRead &
    Record<string, unknown>;

const useDossierFormalite = () => {
    const { dossierOpenSearchApi } = useFormaliteService();
    let searchFormalisteTimeout: number | undefined;

    const searchDossierFormalite = (term: string): Promise<Array<DossierFormalite>> =>
        new Promise((resolve, reject) => {
            clearTimeout(searchFormalisteTimeout);
            const requestParameters = { q: term || undefined };

            searchFormalisteTimeout = setTimeout(() => {
                dossierOpenSearchApi
                    .apiOpensearchFormalitesGetCollection(requestParameters)
                    .then((res) => resolve(res.data['hydra:member'] as Array<DossierFormalite>))
                    .catch(() => reject());
            }, 300);
        });

    return { searchDossierFormalite };
};

export default useDossierFormalite;
