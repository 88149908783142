import { combineReducers } from '@reduxjs/toolkit';
import { FORMULAIRE_FORMALITE_SLICE_NAME } from './constants';

import {
    ConfigurationParameters as FormulaireConfigurationParameters,
    Configuration as FormulairesConfiguration,
    FormulaireFormaliteApi,
    FormulaireListeSelectionApi,
} from '../../openApi/ApiFormulaire';
import { createFormulairesFormaliteSlice } from './slices/formulairesFormaliteSlice';

export { type FormulaireConfigurationParameters };

export function createFormulaireFormaliteReducer(configuration: FormulaireConfigurationParameters) {
    const { formulaireFormaliteSlice, formulaireFormaliteDataSourcesThunks } =
        createFormulairesFormaliteSlice(configuration);
    return { formulaireFormaliteSlice, formulaireFormaliteDataSourcesThunks };
}

export function buildFormulairesRedux(configuration: FormulaireConfigurationParameters) {
    const configformulaires = new FormulairesConfiguration(configuration);

    const formulairesApi = new FormulaireFormaliteApi(configformulaires);

    const formulaireListeSelectionApi = new FormulaireListeSelectionApi(configformulaires);

    const { formulaireFormaliteSlice, formulaireFormaliteDataSourcesThunks } =
        createFormulairesFormaliteSlice(configuration);

    const combinedFormulaireReducer = combineReducers({
        [FORMULAIRE_FORMALITE_SLICE_NAME]: formulaireFormaliteSlice.reducer,
    });

    return {
        serviceConfigurationParameters: configuration,
        serviceConfiguration: configformulaires,
        reducer: combinedFormulaireReducer,
        apis: {
            formulairesApi,
            formulaireListeSelectionApi,
        },
        slices: {
            formulaireFormaliteSlice,
        },
        fetchItems: {
            getFormulaires: formulaireFormaliteDataSourcesThunks.main.getData,
        },
        getItems: {
            selectFormulaires: formulaireFormaliteDataSourcesThunks.main.getSelected,
        },
        thunks: {
            formulaireFormaliteDataSourcesThunks,
        },
        models: {},
    };
}
