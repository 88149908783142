import { useFormaliteService } from '@europrocurement/l2d-domain';
import { DossierOpenSearchJsonldOpensearchDossierStatutRead } from '@europrocurement/l2d-domain/openApi/ApiFormalite';

export type DossierStatut = DossierOpenSearchJsonldOpensearchDossierStatutRead &
    Record<string, unknown>;

const useDossierStatut = () => {
    const { dossierOpenSearchApi } = useFormaliteService();
    let searchStatutTimeout: number | undefined;

    const searchDossierStatut = (): Promise<Array<DossierStatut>> =>
        new Promise((resolve, reject) => {
            clearTimeout(searchStatutTimeout);
            const requestParameters = {};

            searchStatutTimeout = setTimeout(() => {
                dossierOpenSearchApi
                    .apiOpensearchStatusesGetCollection(requestParameters)
                    .then((res) => resolve(res.data['hydra:member'] as Array<DossierStatut>))
                    .catch(() => reject());
            }, 300);
        });

    return { searchDossierStatut };
};

export default useDossierStatut;
