import { FormControl, Typography } from '@mui/material';
import { Box, lighten, useTheme } from '@mui/system';
import React, { CSSProperties } from 'react';
import Checkbox from '../Checkbox';

export type ChoiceItemProps = {
    label: string;
    siren?: string;
    description?: string;
    isChecked?: boolean;
    isDisabled?: boolean;
    value: string;
    onChange?: (value: string) => void;
};

const ChoiceItem: React.FunctionComponent<ChoiceItemProps> = function (props) {
    const {
        label,
        siren,
        description = '',
        isChecked = false,
        isDisabled = false,
        value,
        onChange = () => {},
    } = props;
    const theme = useTheme();
    const checked = isChecked;
    const handleChange = React.useCallback(() => {
        onChange(value);
    }, [onChange, value]);
    let cssChecked: CSSProperties = {};
    let cssDisabled: CSSProperties = {};

    if (isDisabled) {
        cssDisabled = {
            background: theme.palette.grey[300],
            cursor: 'not-allowed',
        };
    } else if (checked) {
        cssChecked = {
            background: lighten(theme.palette.primary.main, 0.95),
        };
    } else {
        cssChecked = {
            background: theme.palette.background.paper,
        };
    }

    return (
        <Box
            key={value}
            sx={{
                width: '100%',
                display: 'flex',
                flexDirection: 'row',
                gap: '5px 5px',
                borderRadius: '15px',
                alignItems: 'center',
                minHeight: '61px',
                marginBottom: '2px',
                ...cssDisabled,
                ...cssChecked,
            }}
        >
            <FormControl>
                <Checkbox
                    name={`choiceItem-${value}`}
                    onChange={handleChange}
                    checked={checked}
                    value={value}
                    data-testid={`checkbox-${value}`}
                    data-id={value}
                />
            </FormControl>
            <Box
                onClick={handleChange}
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    padding: '10px',
                    paddingLeft: 0,
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                    }}
                >
                    <Typography
                        variant="l2dbodys"
                        sx={{
                            fontWeight: '600',
                        }}
                    >
                        {label}
                    </Typography>
                    {siren ? (
                        <Typography
                            variant="l2dbodys"
                            sx={{ paddingLeft: '6px' }}
                        >
                            {' '}
                            Siren n°{siren}
                        </Typography>
                    ) : (
                        ''
                    )}
                </Box>
                {description ? (
                    <Typography
                        sx={{
                            fontSize: '13px',
                            lineHeight: '16px',
                        }}
                        color="text.secondary"
                    >
                        {description}
                    </Typography>
                ) : null}
            </Box>
        </Box>
    );
};
export default ChoiceItem;
