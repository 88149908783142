import { useFormaliteService } from '@europrocurement/l2d-domain';
import { DossierOpenSearchJsonldOpensearchDossierRead } from '@europrocurement/l2d-domain/openApi/ApiFormalite';

export type Dossier = DossierOpenSearchJsonldOpensearchDossierRead & Record<string, unknown>;

const useDossierNumero = () => {
    const { dossierOpenSearchApi } = useFormaliteService();
    let searchTimeout: number | undefined;

    const searchDossierNumero = (term: string): Promise<Array<Dossier>> =>
        new Promise((resolve, reject) => {
            clearTimeout(searchTimeout);
            const requestParameters = { numero: term ? [term] : [] };

            searchTimeout = setTimeout(() => {
                dossierOpenSearchApi
                    .apiOpensearchDossiersGetCollection(requestParameters)
                    .then((res) => resolve(res.data['hydra:member'] as Array<Dossier>))
                    .catch(() => reject());
            }, 300);
        });

    return { searchDossierNumero };
};

export default useDossierNumero;
