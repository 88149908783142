import { useContext } from 'react';

import { OfferModel } from '@europrocurement/l2d-domain/reducers/offre/Models/offer';
import { OfferGroupModel } from '@europrocurement/l2d-domain/reducers/offre/Models/offerGroup';
import { ActionModel } from '@europrocurement/l2d-domain/reducers/offre/Models/action';
import { AdditionalFieldModel } from '@europrocurement/l2d-domain/reducers/offre/Models/additionalField';
import { ArticleModel } from '@europrocurement/l2d-domain/reducers/offre/Models/article';
import { CatalogModel } from '@europrocurement/l2d-domain/reducers/offre/Models/catalog';
import { DocumentModel } from '@europrocurement/l2d-domain/reducers/offre/Models/document';
import { OfferOptionValueModel } from '@europrocurement/l2d-domain/reducers/offre/Models/offerOptionValue';
import { OfferPackageModel } from '@europrocurement/l2d-domain/reducers/offre/Models/offerPackage';
import { type OfferReducerType } from '@europrocurement/l2d-domain/reducers/offre/types';
import {
    ActionDetailApi,
    ArticlesApi,
    CatalogueApi,
    ChampComplmentaireApi,
    DomainesApi,
    GroupementApi,
    OffresApi,
    OptionsApi,
    OptionValueApi,
    OriginesApi,
    PackageApi,
    TypeDeDocumentApi,
    TypeTvaApi,
    RubriqueFacturationApi,
} from '../../../openApi/ApiOffre';
import L2DApiContext from '../L2DApiContext';

export const useOffreService = () => {
    const context = useContext(L2DApiContext);
    if (!context) {
        throw new Error('useL2DApi must be used within a L2DApiProvider');
    }

    if (!context.offre) {
        throw new Error('Offre service is not registred in L2DApiProvider');
    }

    const { apis, slices, thunks, fetchItems, getItems, models, reducer } = context.offre;

    return {
        // [OFFRE] Apis

        vatApi: apis.vatApi as TypeTvaApi,
        domainApi: apis.domainApi as DomainesApi,
        offersApi: apis.offersApi as OffresApi,
        originApi: apis.originApi as OriginesApi,
        domaineApi: apis.domaineApi as DomainesApi,
        packageApi: apis.packageApi as PackageApi,
        actionsApi: apis.actionsApi as ActionDetailApi,
        catalogsApi: apis.catalogsApi as CatalogueApi,
        articlesApi: apis.articlesApi as ArticlesApi,
        documentsApi: apis.documentsApi as TypeDeDocumentApi,
        groupementApi: apis.groupementApi as GroupementApi,
        actionDetailApi: apis.actionDetailApi as ActionDetailApi,
        offerOptionsApi: apis.offerOptionsApi as OptionsApi,
        additionalFieldApi: apis.additionalFieldApi as ChampComplmentaireApi,
        invoicingCategoryApi: apis.invoicingCategoryApi as RubriqueFacturationApi,
        offerOptionValuesApi: apis.offerOptionValuesApi as OptionValueApi,

        // [OFFRE] Slices

        offersSlice: slices.offersSlice,
        offersDataSourcesThunks: slices.offersDataSourcesThunks,
        catalogsSlice: slices.catalogsSlice,
        packagesSlice: slices.packagesSlice,
        articlesSlice: slices.articlesSlice,
        actionsSlice: slices.actionsSlice,
        offerOptionsSlice: slices.offerOptionsSlice,
        offerOptionValuesSlice: slices.offerOptionValuesSlice,
        offerGroupsSlice: slices.offerGroupsSlice,
        offerAdditionalFieldsSlice: slices.offerAdditionalFieldsSlice,
        documentsSlice: slices.documentsSlice,
        vatsSlice: slices.vatsSlice,
        domainsSlice: slices.domainsSlice,
        invoicingCategorySlice: slices.invoicingCategorySlice,

        // [OFFRE] Thunks
        catalogsDataSourcesThunks: thunks.catalogsDataSourcesThunks,
        packagesDataSourcesThunks: thunks.packagesDataSourcesThunks,
        actionsDataSourcesThunks: thunks.actionsDataSourcesThunks,
        articlesDataSourcesThunks: thunks.articlesDataSourcesThunks,
        offerOptionsDataSourcesThunks: thunks.offerOptionsDataSourcesThunks,
        offerAdditionalFieldsDataSourcesThunks: thunks.offerAdditionalFieldsDataSourcesThunks,
        offerOptionValuesDataSourcesThunks: thunks.offerOptionValuesDataSourcesThunks,
        offerGroupsDataSourcesThunks: thunks.offerGroupsDataSourcesThunks,
        documentsDataSourcesThunks: thunks.documentsDataSourcesThunks,
        vatsDataSourcesThunks: thunks.vatsDataSourcesThunks,
        domainsDataSourcesThunks: thunks.domainsDataSourcesThunks,
        invoicingCategoryDataSourcesThunks: thunks.invoicingCategoryDataSourcesThunks,

        // [OFFRE] Actions - Fetch data
        getOffers: fetchItems.getOffers,
        getCatalogs: fetchItems.getCatalogs,
        getPackages: fetchItems.getPackages,
        getArticles: fetchItems.getArticles,
        getOfferOptions: fetchItems.getOfferOptions,
        getOfferAdditionalFields: fetchItems.getOfferAdditionalFields,
        getVats: fetchItems.getVats,

        // [OFFRE] Actions - select item
        selectOffer: getItems.selectOffer,
        selectCatalog: getItems.selectCatalog,
        selectPackage: getItems.selectPackage,
        selectArticle: getItems.selectArticle,
        selectOfferOption: getItems.selectOfferOption,
        selectOfferAdditionalField: getItems.selectOfferAdditionalField,

        actionModel: models.action as ActionModel,
        additionalFieldModel: models.additionalField as AdditionalFieldModel,
        articleModel: models.article as ArticleModel,
        catalogModel: models.catalog as CatalogModel,
        documentModel: models.document as DocumentModel,
        offerModel: models.offer as OfferModel,
        offerGroupModel: models.offerGroup as OfferGroupModel,
        offerOptionModel: models.offerOption as OfferModel,
        offerOptionValueModel: models.offerOptionValue as OfferOptionValueModel,
        offerPackageModel: models.offerPackage as OfferPackageModel,

        reducer: reducer as OfferReducerType,
    };
};
