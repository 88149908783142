import { Button, ButtonProps, TypographyProps } from '@mui/material';
import React from 'react';
import TextIcon, { TextIconProps } from '../TextIcon';

export interface TextIconButtonProps {
    children: React.ReactNode;
    icon: TextIconProps['icon'];
    gap: TextIconProps['gap'];
    btnProps?: ButtonProps;
    typoProps?: TypographyProps;
}

const TextIconButton: React.FunctionComponent<TextIconButtonProps> = function ({
    children,
    icon,
    gap = undefined,
    btnProps = {},
    typoProps = {},
}) {
    return (
        <Button
            color="primary"
            variant="text"
            disableElevation
            sx={{
                padding: '6px 0px',
            }}
            {...btnProps}
        >
            <TextIcon
                gap={gap}
                icon={icon}
                typoProps={typoProps}
            >
                {children}
            </TextIcon>
        </Button>
    );
};

export default TextIconButton;
