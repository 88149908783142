// In L2DApiProvider.tsx
import React from 'react';
import { APIS_ENUM } from '@europrocurement/l2d-domain/constants';
import {
    buildAnnonceLegalesRedux,
    buildFacturationRedux,
    buildFormaliteRedux,
    buildFormulairesRedux,
    buildGeolocRedux,
    buildOfferRedux,
    buildSirenRedux,
    buildTiersRedux,
    ConfigurationParameters,
} from '@europrocurement/l2d-domain/reducers';
import { buildUtilisateurRedux } from '@europrocurement/l2d-domain/reducers/utilisateurs';
import L2DApiContext, { ServiceStateType, StoreState } from './L2DApiContext';

export type L2DApiProviderType = {
    children: React.ReactNode;
    apiConfiguration: {
        [x in APIS_ENUM]?: ConfigurationParameters;
    };
};

const createServiceStateType = (
    apiEnumKey: APIS_ENUM,
    serviceConfigurationParameters: ConfigurationParameters,
): ServiceStateType => {
    switch (apiEnumKey) {
        case 'achats':
            throw new Error(`${apiEnumKey}to be implement`);
        case 'sign':
            throw new Error(`${apiEnumKey}to be implement`);
        case 'tiers':
            return buildTiersRedux(serviceConfigurationParameters);
        case 'dossiers':
            throw new Error(`${apiEnumKey}to be implement`);
        case 'offre':
            return buildOfferRedux(serviceConfigurationParameters);
        case 'formalite':
            return buildFormaliteRedux(serviceConfigurationParameters);
        case 'facturation':
            return buildFacturationRedux(serviceConfigurationParameters);
        case 'mail':
            throw new Error(`${apiEnumKey}to be implement`);
        case 'formulaire':
            return buildFormulairesRedux(serviceConfigurationParameters);
        case 'utilisateur':
            return buildUtilisateurRedux(serviceConfigurationParameters);
        case 'siren':
            return buildSirenRedux(serviceConfigurationParameters);
        case 'annoncesLegales':
            return buildAnnonceLegalesRedux(serviceConfigurationParameters);
        case 'geoloc':
            return buildGeolocRedux(serviceConfigurationParameters);
        default:
            throw new Error(`${apiEnumKey}to be implement`);
    }
};

// Create the provider component
const L2DApiProvider: React.FunctionComponent<L2DApiProviderType> = function ({
    children,
    apiConfiguration,
}) {
    // Iterate over configuration keys and create a switch with keys as API_ENUM
    const storeValue: StoreState = Object.keys(apiConfiguration).reduce((accumulator, key) => {
        const apiEnumKey = key as APIS_ENUM;
        const serviceConfigurationParameters = apiConfiguration[apiEnumKey];
        if (serviceConfigurationParameters) {
            return {
                ...accumulator,
                [apiEnumKey]: {
                    ...createServiceStateType(apiEnumKey, serviceConfigurationParameters),
                },
            };
        }
        return accumulator;
    }, {});

    // Return the context provider with the state and actions
    return <L2DApiContext.Provider value={storeValue}>{children}</L2DApiContext.Provider>;
};

export default L2DApiProvider;
