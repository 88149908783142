import * as React from 'react';
import { Box, Button, ListItem, useTheme } from '@mui/material';
import './InformationCardItem.css';
import { FaOptionIcon, chevronUpIcon, chevronDownIcon } from '@europrocurement/l2d-icons';

export type InformationCardItemProps = {
    label: string;
    value?: React.ReactNode;
    additionalInfo?: React.ReactNode;
};
const InformationCardItem: React.FunctionComponent<InformationCardItemProps> = function (
    props: InformationCardItemProps,
) {
    const theme = useTheme();
    const { label, value, additionalInfo } = props;
    const [expanded, setExpanded] = React.useState(false);
    const itemClassNames = ['information-item'];
    let additionalInfoBtn = null;
    let additionalInfoBox = null;
    const showMoreInfo = () => {
        setExpanded(!expanded);
    };

    if (additionalInfo) {
        itemClassNames.push('information-item-expandable');
        additionalInfoBox = (
            <Box
                data-testid="additional-info"
                className="information-item-additional-info"
                sx={{
                    backgroundColor: theme.palette.background.default,
                    color: theme.palette.text.secondary,
                    marginTop: '5px',
                    marginBottom: '5px',
                }}
            >
                {additionalInfo}
            </Box>
        );
        let icon = <FaOptionIcon {...chevronDownIcon.props} />;

        if (expanded) {
            itemClassNames.push('information-item-expanded');
            icon = <FaOptionIcon {...chevronUpIcon.props} />;
        }

        additionalInfoBtn = (
            <Button
                className="information-item-show-more"
                onClick={showMoreInfo}
                data-testid="show-more-button"
                sx={{
                    color: theme.palette.text.primary,
                }}
            >
                {icon}
            </Button>
        );
    }

    return (
        <ListItem sx={{ padding: '0' }}>
            <Box className={itemClassNames.join(' ')}>
                <Box
                    data-testid="information-card-item"
                    className="information-item-main-info"
                    sx={{
                        backgroundColor: theme.palette.background.default,
                    }}
                >
                    <Box
                        className="information-item-label"
                        sx={{
                            color: theme.palette.text.primary,
                        }}
                    >
                        {label}
                    </Box>
                    <Box
                        className="information-item-value"
                        sx={{
                            color: theme.palette.text.primary,
                        }}
                    >
                        {value || '-'}
                        {additionalInfoBtn}
                    </Box>
                </Box>
                {additionalInfoBox}
            </Box>
        </ListItem>
    );
};

export default InformationCardItem;
