import React, { createContext, PropsWithChildren, useMemo, useState } from 'react';

export const FlexyDatatableTableContext = createContext({
    selectedItems: [],
    setSelectedItems: (items: unknown) => items,
    currentData: [],
    setCurrentData: (items: unknown) => items,
});

export const FlexyDatatableTableProvider = function (props: PropsWithChildren) {
    const { children } = props;
    const [selectedItems, setSelectedItems] = useState([]);
    const [currentData, setCurrentData] = useState([]);
    const datatableContextValue = useMemo(
        () => ({ selectedItems, setSelectedItems, currentData, setCurrentData }),
        [selectedItems, setSelectedItems, currentData, setCurrentData],
    );

    return (
        <FlexyDatatableTableContext.Provider value={datatableContextValue}>
            {children}
        </FlexyDatatableTableContext.Provider>
    );
};
