import { Button, ButtonProps, TypographyProps } from '@mui/material';
import React from 'react';
import { Link, LinkProps } from 'react-router-dom';
import TextIcon, { TextIconProps } from '../TextIcon';

export interface TextIconLinkProps {
    children: React.ReactNode;
    gap: TextIconProps['gap'];
    icon: TextIconProps['icon'];
    to: LinkProps['to'];
    btnProps?: ButtonProps;
    typoProps?: TypographyProps;
}

const TextIconLink: React.FunctionComponent<TextIconLinkProps> = function ({
    children,
    icon,
    to,
    btnProps = {},
    typoProps = {},
    gap = undefined,
}) {
    return (
        <Button
            color="primary"
            variant="text"
            disableElevation
            component={Link}
            role="button"
            to={to}
            sx={{
                padding: '6px 0px',
            }}
            {...btnProps}
        >
            <TextIcon
                gap={gap}
                icon={icon}
                typoProps={typoProps}
            >
                {children}
            </TextIcon>
        </Button>
    );
};

export default TextIconLink;
