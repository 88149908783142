import {
    SliceFactory,
    FetchCollectionData,
    FetchItemDataType,
    ApiCollectionResponse,
} from '@europrocurement/l2d-redux-utils';
import { ActionActionRead } from '@europrocurement/l2d-domain/openApi/ApiAchats';
import {
    ConfigurationParameters as OffreConfigurationParameters,
    Configuration,
    ActionDetailApi,
} from '../../../openApi/ApiOffre';
import { AUTOCOMPLETE_KEY, OFFER_ACTIONS_SLICE_NAME, OFFRE_REDUCER_NAME } from '../constants';

export type ActionApiObject = ActionActionRead & Record<string, unknown>;

export type Actions = ActionApiObject;

const mapperActions = function (actions: ActionApiObject) {
    return {
        ...actions,
    } as Actions;
};

export function createActionsSlice(configuration: OffreConfigurationParameters) {
    const conf = new Configuration(configuration);
    const api = new ActionDetailApi(conf);

    const fetchActionsCollection: FetchCollectionData<ActionApiObject> = ({
        pagination: { page, itemsPerPage },
        xIdSociete,
    }) =>
        api
            .apiActionDetailsGetCollection({ xIdSociete, page: page + 1, itemsPerPage })
            .then((res) => res.data)
            .catch((error) => {
                console.error(error);
            }) as Promise<ApiCollectionResponse<ActionApiObject>>;

    const fetchActionsItem: FetchItemDataType<ActionApiObject> = ({
        idItem,
        // pagination,
        xIdSociete,
    }) =>
        api
            .apiActionDetailsIdGet({ id: String(idItem), xIdSociete })
            .then((res) => res.data) as Promise<ActionApiObject>;

    const { slice: actionsSlice, dataSourcesThunks: actionsDataSourcesThunks } =
        SliceFactory.createSlice<ActionApiObject, Actions>(
            OFFER_ACTIONS_SLICE_NAME,
            OFFRE_REDUCER_NAME,
            [AUTOCOMPLETE_KEY],
            fetchActionsCollection,
            fetchActionsItem,
            mapperActions,
        );

    return { actionsSlice, actionsDataSourcesThunks };
}
