import { FormStructure } from '@europrocurement/flexy-form/components';
import { jsonSubForm } from '../types/formTypes';

const jsonSubformArrayToStructure: (subForm: jsonSubForm) => FormStructure = () =>
    // const {
    //     // defaultlength, minlength, maxlength, ...base
    // } = subform;

    ({}) as FormStructure;
export default jsonSubformArrayToStructure;
